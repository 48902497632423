
import "./es2.css";
import { Button, Box, Grid, Typography } from '@mui/material'
import Modal from '@mui/material/Modal';
import { useState } from "react";
import cancel from "../../../assets/img/cancel_icn.png"
import setting from "../../../assets/img/settings_icn.png"
import search from "../../../assets/img/search_icn.png"
import component from "../../../assets/img/component_55.png"
import component1 from "../../../assets/img/component_56.png"
import dot from "../../../assets/img/ellipse_icn.png"
import green from "../../../assets/img/component_66.png"
import red from "../../../assets/img/red_minus.png"

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: '1000px',
//     bgcolor: 'background.paper',
//     border: '2px solid #000',
//     boxShadow: 24,
//     p: 4,
//   };

const Es2 = () => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="container">
          <Box style={{display:"flex",flexDirection:"row",justifyContent:"flex-end", marginTop: "20px",marginRight:"20px" }}><img src={cancel} /></Box>
          <Box className="header">
            <Box ><Typography className="heading" >ES2 (5)</Typography></Box>
            <Box className="input" style={{marginLeft:"50px"}}>
              <Box><img style={{ height: "60%", width: "65%", marginTop: "10px", marginLeft: "15px" }} src={search} /></Box>
              <Box><input style={{width:"650px",height:"100%",background:'#0000',fontSize:"18px",outline:"none",border:"none",color:"white",paddingLeft:"10px"}}  type="search" placeholder="Search.." /></Box>
            </Box>
            <Box ><img className="setting" src={setting} /></Box>
          </Box>
          <Box className="box">
            <Box style={{display:"flex",flexDirection:"row"}} >
              <Box className="box1">
                <Box style={{ display: "flex", flexDirection: "row", paddingTop:"10px",justifyContent:"space-between" }}>
                  <Typography style={{ color: "white",marginLeft:"25px",fontSize:"22px" }}>Name</Typography>
                  <img style={{ height: "20px", width: "20px",marginTop:"5px" }} src={component} />
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px",marginTop:"20px" }}>
                  <img style={{ height: "17px", width: "17px",color: "white",marginTop:"3px",marginLeft:"15px" }} src={dot} />
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>Kairos-1</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}>
                  <img style={{ height: "17px", width: "17px",color: "white",marginTop:"3px",marginLeft:"15px" }} src={dot} />
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px"}}>Kairosjobfile</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}>
                  <img style={{ height: "17px", width: "17px",color: "white",marginTop:"3px",marginLeft:"15px" }} src={dot} />
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>panachebackup</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}>
                  <img style={{ height: "17px", width: "17px",color: "white",marginTop:"3px",marginLeft:"15px" }} src={dot} />
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>panachewp</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}>
                  <img style={{ height: "17px", width: "17px",color: "white",marginTop:"3px",marginLeft:"15px" }} src={dot} />
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>www.kairosjob.com</Typography>
                </Box>
              </Box>
              <Box className="box2" style={{marginLeft:"20px"}}>
                <Box style={{ display: "flex", flexDirection: "row", paddingTop:"10px" }}>
                  <Typography style={{ color: "white",marginLeft:"25px",fontSize:"22px" }}>Access</Typography>
                  <img style={{ height: "20px", width: "20px",marginLeft:"80px",marginTop:"5px"}} src={component1} />
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px",marginTop:"20px" }}>
                  <img style={{ height: "17px", width: "17px",marginTop:"5px",marginLeft:"15px" }} src={green} />
                  <Typography style={{ color: "#46F700",marginLeft:"15px",fontSize:"18px" }}>Running</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}>
                  <img style={{ height: "17px", width: "17px",marginTop:"5px",marginLeft:"15px" }} src={green} />
                  <Typography style={{ color: "#46F700",marginLeft:"15px",fontSize:"18px"}}>Running</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}>
                  <img style={{ height: "17px", width: "17px",marginTop:"5px",marginLeft:"15px" }} src={red} />
                  <Typography style={{ color: "#FF0000",marginLeft:"15px",fontSize:"18px" }}>Not Running</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}>
                  <img style={{ height: "17px", width: "17px",marginTop:"5px",marginLeft:"15px" }} src={green} />
                  <Typography style={{ color: "#46F700",marginLeft:"15px",fontSize:"18px" }}>Running</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "row", marginLeft:"10px" }}>
                  <img style={{ height: "17px", width: "17px",marginTop:"5px",marginLeft:"15px" }} src={red} />
                  <Typography style={{ color: "#FF0000",marginLeft:"15px",fontSize:"18px" }}>Not Running</Typography>
                </Box>
              </Box>
              <Box className="box3">
                <Box style={{ display: "flex", flexDirection: "row", paddingTop:"10px" }}>
                  <Typography style={{ color: "white",marginLeft:"25px",fontSize:"22px" }}>Instance Type</Typography>
                  <img style={{ height: "20px", width: "20px",marginLeft:"50px",marginTop:"5px"}} src={component1} />
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"20px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>t2.micro</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>t2.micro</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>t2.micro</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>t2.micro</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>t2.micro</Typography>
                </Box>
              </Box>
              <Box className="box4">
                <Box style={{ display: "flex", flexDirection: "row", paddingTop:"10px" }}>
                  <Typography style={{ color: "white",marginLeft:"25px",fontSize:"22px" }}>Availability Zone</Typography>
                  <img style={{ height: "20px", width: "20px",marginLeft:"50px",marginTop:"5px"}} src={component1} />
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"20px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>ap-south-1a</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>ap-south-1a</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>ap-south-1a</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>ap-south-1a</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>ap-south-1a</Typography>
                </Box>
              </Box>
              <Box className="box5">
                <Box style={{ display: "flex", flexDirection: "row", paddingTop:"10px" }}>
                  <Typography style={{ color: "white",marginLeft:"25px",fontSize:"22px" }}>Launch Time</Typography>
                  <img style={{ height: "20px", width: "20px",marginLeft:"140px",marginTop:"5px"}} src={component1} />
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"20px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>July 22,2021,14:34:15(UTC+05:30)</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>July 22,2021,14:34:15(UTC+05:30)</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>July 22,2021,14:34:15(UTC+05:30)</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>July 22,2021,14:34:15(UTC+05:30)</Typography>
                </Box>
                <Box style={{ marginLeft:"10px",marginTop:"5px" }}>
                  <Typography style={{ color: "white",marginLeft:"15px",fontSize:"18px" }}>July 22,2021,14:34:15(UTC+05:30)</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box >
            <hr style={{color:"white",width:"90%",marginLeft:"60px",height:"20%"}}/>
          </Box>
          <Box style={{display:"flex",flexDirection:"row",justifyContent:"flex-end",marginTop:"35px",width:"90%"}}>
            <Typography style={{color:"#666C7C",fontSize:"22px",marginRight:"20px"}}>Previous</Typography>
            <Box style={{height:"35px",width:"35px",borderRadius:"50%",backgroundColor:"#666C7C",marginRight:"20px"}}><Typography style={{color:"white",textAlign:"center",fontSize:"22px"}}>1</Typography></Box>
            <Typography style={{color:"#666C7C",fontSize:"22px",marginRight:"5px"}}>Next</Typography>
          </Box>
        </Box>
      </Modal>

    </div>
  )
}

export default Es2