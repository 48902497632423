import { useState, useEffect, useContext } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Api } from "../../../MyNewUtils/Api";
import aws from "../../../assets/img/aws_cloud_icn.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SelectCloudServiceModel from "./selectCloudServiceModel";
import SelectAccountIdListModel from "./selectAccountListModel";
import RegionListModel from "./selectRegionListModel/index";
import { toast } from "react-toastify";
import myContext from "../../../screens/MyContext/MyContext";

const AccountInfo = ({ showRegionPart }) => {
  const [cloudServiceNameListModel, setCloudServiceNameListModel] =
    useState(false);
  const [accountIDListModel, setAccountIDListModel] = useState(false);
  const [regionListSelectModel, setRegionListSelectModel] = useState(false);
  const [cloudAccountIDList, setCloudAccountIDList] = useState([]);
  const [cloudAccountRegionsList, setCloudAccountRegionsList] = useState([]);
  const { cloudDetails, setCloudDetails } = useContext(myContext);
  const getAllCloudAccounts = async () => {
    const data = await Api.postRequest("/users/get-all-cloud-accounts", {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    });

    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      setCloudAccountIDList(res?.data);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
    }
  };

  const getAllRegions = async () => {
    const data = await Api.postRequest("/users/get-all-regions", {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    });
    const res = JSON.parse(data?.data);
    if (res?.status == 1) {
      setCloudAccountRegionsList(res?.data);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
    }
  };
  useEffect(() => {
    if (
      cloudAccountIDList.length !== 0 &&
      cloudAccountRegionsList.length !== 0
    ) {
      setCloudDetails({
        ...cloudDetails,
        cloudAccountIDList: cloudAccountIDList,
        selectedCloudAccountID: cloudAccountIDList[0]?.accountId,
        cloudRegionsList: cloudAccountRegionsList,
      });
    }
  }, [cloudAccountIDList, cloudAccountRegionsList]);

  useEffect(() => {
    if (cloudDetails?.cloudAccountIDList?.length === 0) getAllCloudAccounts();
    if (cloudDetails?.cloudRegionsList?.length === 0) getAllRegions();
  }, []);

  return (
    <Box
      style={{
        width: "100%",
        backgroundColor: "rgb(1,4,22)",
        height: "60px",
        display: "flex",
        alignItems: "center",
        position: "relative",
        zIndex: 13,
      }}
    >
      {cloudServiceNameListModel && (
        <SelectCloudServiceModel
          cloudServiceNameListModel={cloudServiceNameListModel}
          setCloudServiceNameListModel={setCloudServiceNameListModel}
        />
      )}

      {accountIDListModel && (
        <SelectAccountIdListModel
          accountIDListModel={accountIDListModel}
          setAccountIDListModel={setAccountIDListModel}
        />
      )}

      {regionListSelectModel && (
        <RegionListModel
          regionListSelectModel={regionListSelectModel}
          setRegionListSelectModel={setRegionListSelectModel}
        />
      )}

      <Box style={{ display: "flex", flexGrow: 1 }}>
        <Box style={{ display: "flex", flexGrow: 1, padding: "0 20px" }}>
          <Box
            style={{ margin: "20px", display: "flex", alignItems: "center" }}
          >
            <img src={aws} alt="" />

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setCloudServiceNameListModel(true)}
            >
              <Typography
                style={{
                  fontSize: "17px",
                  fontWeight: "700",
                  color: "white",
                  marginLeft: "10px",
                  marginTop: "5px",
                }}
              >
                {cloudDetails?.selectedCloudServers}
                <KeyboardArrowDownIcon
                  fontSize={"large"}
                  style={{ color: "white", paddingBottom: "2px" }}
                />
              </Typography>
            </Box>
          </Box>

          <Box
            style={{
              marginLeft: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "700",
                color: "white",
              }}
            >
              Account ID:
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setAccountIDListModel(true)}
            >
              <Typography
                style={{
                  fontSize: "17px",
                  fontWeight: "700",
                  color: "white",
                  marginLeft: "10px",
                  marginTop: "3px",
                }}
              >
                {cloudDetails?.selectedCloudAccountID}
                <KeyboardArrowDownIcon
                  fontSize={"large"}
                  style={{ color: "white", paddingBottom: "2px" }}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        {showRegionPart && (
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box
              onClick={() => setRegionListSelectModel(true)}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography
                style={{
                  // background: "#010A26",
                  color: "#fff",
                  fontSize: "18px",
                  fontWeight: "700",
                  display: "flex",
                  paddingRight: "10px",
                }}
              >
                {cloudDetails.selectedCloudRegionsID.length > 1
                  ? "All"
                  : cloudDetails.selectedCloudRegionsID.length === 0
                  ? "All"
                  : cloudDetails.selectedCloudRegionsID}
              </Typography>
              <KeyboardArrowDownIcon
                fontSize={"large"}
                style={{ color: "white" }}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};
export default AccountInfo;
