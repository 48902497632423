export const style = {
  contentTitle: {
    fontSize: '36px',
    color: '#FF8800',
  },
  captchaTitle: {
    color: 'black',
    paddingLeft: '20px',
    fontSize: '14px',
  },
  forgetText: {
    color: '#008CF2',
    paddingLeft: '20px',
    fontSize: '14px',
    marginTop: '5px',
    cursor: 'pointer',
  },
  contentDesc: {
    fontSize: '18px',
    marginTop: '10px',
  },
  otpText: {
    fontSize: '20px',
  },
  btn: {
    width: '40%',
    height: '100%',
    borderRadius: '100px',
    backgroundColor: '#008CF2',
    color: 'white',
    fontWeight: 'normal',
  },
  btnOutLine: {
    width: '40%',
    height: '100%',
    borderRadius: '100px',
    backgroundColor: 'transparent',
    color: 'white',
    fontWeight: 'normal',
    border: '1px solid #008CF2',
  },
}
