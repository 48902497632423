import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import roundClose from "../../../../assets/img/cancel_icn.png";
import { toast } from "react-toastify";
import { Api } from "../../../../MyNewUtils/Api";
import MainLoader from "../../../Spinner/MainLoader";
const DeleteRoleModel = ({
  deleteRolesModelOpen,
  setDeleteRolesModelOpen,
  actionRolesValue,
  setActionRolesValue,
  refreshList,
  setSelectedUserList,
  selectedUserList,
  selectedRolesList,
  setSelectedRolesList,
  singalRoleDelete,
  setSingalRoleDelete,
  setRefreshList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    setSingalRoleDelete(false);
    setSelectedRolesList([]);
    setActionRolesValue();
    setDeleteRolesModelOpen(false);
  };
  const onSubmit = async () => {
    setIsLoading(true);
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      roleids: singalRoleDelete
        ? JSON.stringify([actionRolesValue._id])
        : JSON.stringify(selectedRolesList),
    };
    const { data } = await Api.postRequest("/users/delete-role-by-id", final);
    const res = JSON.parse(data);

    if (res?.status === 1) {
      setRefreshList(!refreshList);
      toast.success(res?.message, { autoClose: 1000 });
      setIsLoading(false);
      setSelectedRolesList([]);
      // if (actionRolesValue.length > 1) {
      //   setSelectedUserList([]);
      // } else {
      //   const updateSelectedItem = selectedUserList.filter(
      //     (el) => el !== actionRolesValue
      //   );
      //   setSelectedUserList(updateSelectedItem);
      // }
      handleClose();
    } else {
      toast.error("validation error", { autoClose: 1000 });
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Modal
        open={deleteRolesModelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          className="Reset-Child-password-Model-box "
          sx={{
            minWidth: "25%",
            maxHeight: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          <MainLoader isLoading={isLoading} />
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "text",
              padding: "0px 0px 0px 0px",
            }}
          >
            <img
              src={roundClose}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              alt=""
            />
          </Box>
          <Typography
            variant="h5"
            align="center"
            style={{ marginTop: "30px", color: "white" }}
          >
            Are sure for Delete This Role?
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "40px 0px 20px 0px",
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                padding: "10px 20px 10px 20px",
                marginRight: "5px",
                background: "green",
                color: "white",
              }}
            >
              Close
            </Button>
            <Button
              style={{
                background: "red",
                color: "white",
                marginLeft: "5px",
                padding: "10px 20px 10px 20px",
              }}
              onClick={onSubmit}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default DeleteRoleModel;
