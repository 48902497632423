import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Captcha, captchaSettings } from "reactjs-captcha";
import logo from "../../assets/img/CO5_FINAL.png";
import roundClose from "../../assets/img/cancel_icn.png";
import { toast } from "react-toastify";
import RegistrationLayout from "../../Component/Layout/index";
import security_code from "../../assets/img/security_code.png";
import "./PasswordRecovery.css";
import { style } from "./style";
import CustomModel from "../../Component/Model/index";
import SentEmailModel from "../../Component/SentEmailModel/index";
import { useNavigate } from "react-router-dom";
import MainLoader from "../../Component/Spinner/MainLoader";
import ForgotEmailModel from "../../Component/ForgotEmailModel/index";
import { Api } from "../../MyNewUtils/Api";
const PasswordRecovery = () => {
  const cls = useMemo(() => "passwordRecovery", []);

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailFild, setIsEmailFild] = useState("");
  const [isNumberFild, setIsNumberFild] = useState("");
  const [isOtp, setIsOtp] = useState("");
  const [isEmailModelOpen, setIsEmailModelOpen] = useState(false);
  const [isForgetEmailModelOpen, setIsForgetEmailModelOpen] = useState(false);
  const [isResendVerification, setIsResendVerification] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTextMessageSent, setIsTextMessageSent] = useState(false);
  const [isTextMessageSentAgain, setIsTextMessageSentAgain] = useState(false);
  const [isSendotp, setIsSendotp] = useState("Send OTP");

  const handleSubmit = useCallback(() => {
    // if (isMobile) {
    //   if (isNumberFild.length == '0') {
    //     toast.error("Please enter valid Mobile Number")
    //   } else {
    //     setIsEmailModelOpen(false)
    //     setIsTextMessageSent(true)
    //   }
    // } else {
    //   that means it email
    //   if (isEmailFild.length == '0') {
    //     toast.error("Please enter valid Email address")
    //   } else {
    //     setIsTextMessageSent(false)
    //     setIsEmailModelOpen(true)
    //   }
    // }
    if (isEmailFild?.length == "0") {
      toast.error("Enter Valid email", { autoClose: 1000 });
    } else {
      if (isOtp?.length == "0") {
        toast.error("Enter Valid OTP", { autoClose: 1000 });
      } else {
        navigate("/reset-password", {
          state: { email: isEmailFild, otp: isOtp },
        });
        // toast.success("Successfully Change Password", { autoClose: 1000 })
      }
    }
  });

  const _handleCloseEmailModel = () => setIsEmailModelOpen(false);
  const _handleCloseForgetEmailModel = () => setIsForgetEmailModelOpen(false);

  const _handleResend = useCallback(
    async (event) => {
      event.preventDefault();

      setIsLoading(true);
      if (!isMobile) {
        if (isEmailFild.length == "0") {
          setIsLoading(false);
          setIsSendotp("Send OTP");
          toast.error("Enter valid Email", { autoClose: 1000 });
        } else {
          let final = { email: isEmailFild };
          const { statusCode, data } = await Api.postRequest(
            "/users/forgot-password",
            final
          );
          const res = JSON.parse(data);
          if (res == null) {
            setIsLoading(false);
            toast.error("Enter valid Email", { autoClose: 1000 });
          } else {
            setIsLoading(false);
            toast.success(res.message, { autoClose: 1000 });
            setIsSendotp("Resend the code");
            setIsTextMessageSent(false);
            setIsTextMessageSentAgain(true);
          }
        }
      } else {
        if (isNumberFild.length == "0") {
          setIsLoading(false);
          setIsSendotp("Send Otp");
          toast.error("Enter valid Number", { autoClose: 1000 });
        } else {
          toast.success("Enter valid Number", { autoClose: 1000 });
          // let final = { email: isEmailFild }
          // const { statusCode, data } = await Api.postRequest('/users/forgot-password', final)
          // const res = JSON.parse(data)
          // if (res == null) {
          //   toast.error("Enter valid Email")
          // } else {
          //   toast.success(res.message)
          //   setIsSendotp("Resend the code")
          //   setIsTextMessageSent(false)
          //   setIsTextMessageSentAgain(true)
          // }
        }
      }
      setIsLoading(false);
    },
    [isEmailFild],
    [isNumberFild],
    [isMobile]
  );

  const _handleClickLink = useCallback(() => {
    setIsForgetEmailModelOpen(true);
  }, []);

  return (
    <RegistrationLayout>
      <MainLoader isLoading={isLoading} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} className={`${cls}__gridItem`}>
            <img src={logo} alt="logo" className="img-fluid signup_logo" />
          </Grid>
          <Grid item xs={6} className={`${cls}__gridContentItem`}>
            <Box className={`${cls}__gridContentBox`}>
              <Box>
                <Typography style={style.contentTitle}>
                  Password Recovery
                </Typography>
              </Box>
              <Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="type"
                    // defaultValue="mobile"
                    value={isMobile ? "mobile" : "email"}
                    name="radio-buttons-group"
                    onChange={(e) => {
                      if (
                        isMobile &&
                        !(isTextMessageSent || isTextMessageSentAgain)
                      )
                        setIsMobile(false);
                      else setIsMobile(true);
                    }}
                  >
                    <FormControlLabel
                      value="email"
                      control={<Radio style={{ color: "#008fff" }} />}
                      label="Email"
                    />
                    {/* <FormControlLabel
                      value="mobile"
                      control={<Radio style={{ color: '#008fff' }} />}
                      label="Mobile number"
                    /> */}
                  </RadioGroup>
                </FormControl>
              </Box>
              <form onSubmit={_handleResend}>
                <Box className={`${cls}__d-flex`}>
                  <Box>
                    <label for="verfication" className={`${cls}__label`}>
                      {isMobile ? "Mobile number" : "Email"}
                    </label>

                    <input
                      type={isMobile ? "number" : "email"}
                      // name="verfication"
                      className={`${cls}__input`}
                      placeholder={
                        isMobile ? "+91 0000 000 001" : "your@email.com"
                      }
                      value={isMobile ? isNumberFild : isEmailFild}
                      onChange={(e) => {
                        isMobile
                          ? setIsNumberFild(e.target.value)
                          : setIsEmailFild(e.target.value);
                      }}
                      required
                    />
                    {/* {isTextMessageSent && ( */}
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "right",
                      }}
                    >
                      {/* <p
                        tabIndex="0"
                        style={{
                          textAlign: 'right',
                          color: '#008CF2',
                          marginTop: '10px',
                          fontWeight: 'bold',
                          cursor: 'pointer',
                        }}
                        onClick={_handleResend}
                      >
                        {isSendotp}
                      </p> */}
                      <Button
                        type="submit"
                        style={{
                          fontWeight: "bold",
                          fontSize: "17px",
                          marginTop: "10px",
                          textTransform: "none",
                        }}
                      >
                        {isSendotp}
                      </Button>
                    </Box>
                    {/* )} */}
                  </Box>
                  <Box style={{ position: "relative" }}>
                    {isShowError && (
                      <div className={`${cls}__error`}>
                        <div className={`${cls}__error_msgBody`}>
                          <p className={`${cls}__error_text`}>
                            This mobile number is not linked to any account.
                            Please try again.
                          </p>
                        </div>
                      </div>
                    )}
                    {(isTextMessageSent || isTextMessageSentAgain) && (
                      <div className={`${cls}__error`}>
                        <div
                          className={`${cls}__error_msgBody`}
                          style={{
                            backgroundColor: isTextMessageSentAgain
                              ? "#008CF2"
                              : "#808E9B",
                            width: isTextMessageSentAgain ? "180px" : "120px",
                            textAlign: "center",
                            bottom: "8px",
                          }}
                        >
                          <p className={`${cls}__error_text`}>
                            {isTextMessageSentAgain
                              ? "Message sent again"
                              : "Message sent"}
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                </Box>
              </form>
              {/* {!isTextMessageSent && !isTextMessageSentAgain && (
                <Box className={`${cls}__capetchaBox`}>
                  <Box>
                    <Box>
                      <Typography style={style.captchaTitle}>
                        Enter the characters seen in the image below
                      </Typography>
                    </Box>
                    <Box>
                      <img
                        src={security_code}
                        className={`${cls}__captcha_img`}
                        alt=""
                      />
                    </Box>
                  </Box>

                  <div className="pt-4 login_page_1_captchaBody">
                  <div className="login_page_1_captcha">
                    <p className="login_page_1_captcha_text mb-2">
                      Enter the characters seen in the image below
                    </p>
                    <div className="">
                      <img
                        src={security_code}
                        className="img-fluid login_page_1_captcha_img"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                 </Box>
              )} */}
              <form onSubmit={handleSubmit}>
                <Box className={`${cls}__margin30`}>
                  <Box>
                    <Typography
                      style={{
                        color: "white",
                        fontSize: "14px",
                        marginBottom: "5px",
                      }}
                    >
                      Enter OTP
                    </Typography>
                  </Box>
                  <input
                    type="number"
                    name="verfication"
                    className={`${cls}__input`}
                    placeholder={"Enter OTP here"}
                    value={isOtp}
                    onChange={(e) => {
                      setIsOtp(e.target.value);
                    }}
                    required
                  />
                </Box>
                <Box
                  className={`${cls}__margin30 ${cls}__width350 ${cls}__d-flex ${cls}__justifyBetween`}
                >
                  <Button style={style.btn} type="submit">
                    {isTextMessageSent || isTextMessageSentAgain
                      ? "Submit"
                      : isMobile
                        ? "Text me"
                        : "Send Email"}
                  </Button>
                  <Button
                    style={style.btnOutLine}
                    onClick={() => navigate("/")}
                  >
                    Cancel
                  </Button>
                </Box>
              </form>

              <Box>
                {/* <Typography style={style.forgetText} onClick={_handleClickLink}>
                  {' '}
                  I can't remember my email address or phone number
                </Typography> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Model */}
      <SentEmailModel
        isEmailModelOpen={isEmailModelOpen}
        _handleCloseEmailModel={_handleCloseEmailModel}
        onButtonClick={() => {
          navigate("/reset-password");
        }}
        onResendVerification={() => setIsResendVerification(true)}
        isResendVerification={isResendVerification}
      />
    </RegistrationLayout>
  );
};
export default PasswordRecovery;
