import * as React from "react";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { visuallyHidden } from "@mui/utils";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ background: "#272E42", marginBottom: "10px" }}>
      <TableRow>
        {headCells.map(
          (headCell) =>
            headCell?.show && (
              <TableCell
                key={headCell.id}
                align={"center"}
                padding={headCell.disablePadding ? "none" : "normal"}
                sortDirection={orderBy === headCell.id ? order : false}
                sx={{ padding: "0px" }}
                className="border_none"
              >
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={{ color: "#fff", paddingLeft: "50px" }}
                  IconComponent={ArrowDropDownIcon}
                  className="allcases_table_title"
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            )
        )}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const TableWithPagination = ({ headCells, rows, rowLimitEnd, filter }) => {
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(1);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(rowLimitEnd);
  let navigate = useNavigate();
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const Previous = () => {
    if (paginationStart > 0) {
      setPaginationStart(paginationStart - rowsPerPage);
      setPaginationEnd(paginationEnd - rowsPerPage);
      setPageCount(pageCount - 1);
    }
  };
  const Next = () => {
    if (paginationEnd < rows.length) {
      setPaginationStart(paginationStart + rowsPerPage);
      setPaginationEnd(paginationEnd + rowsPerPage);
      setPageCount(pageCount + 1);
    }
  };

  const handleChange = (row) => {
    navigate("/viewCasesDetails", { state: { supportData: row } });
  };
  useEffect(() => {
    setPaginationStart(0);
    setPaginationEnd(rowsPerPage);
    setPageCount(1);
  }, [rows]);

  useEffect(() => {
    setRowsPerPage(rowLimitEnd);
    setPaginationEnd(rowLimitEnd);
  }, [rowLimitEnd]);

  console.log("headCells", headCells, rows);
  return (
    <Box sx={{ width: "100%" }} className="text-bold">
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          background: "#0000",
          "&::-webkit-scrollbar": {
            width: 10,
            height: 10,
          },
          "&::-webkit-scrollbar-track": {
            backgroundColor: "#0000",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#ADD8E6",
            borderRadius: 2,
            border: "0px solid #0000",
          },
        }}
        className="margin-top-32"
      >
        {/* <TableContainer> */}
        {rows.length !== 0 ? (
          <Box>
            <Table
              sx={{ minWidth: "100%" }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
              style={{ boxSizing: "content-box" }}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              // className="allcases_table_title"
              />
              <TableBody className="TableWithPagination_Table_Body">
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(paginationStart, paginationEnd)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleChange(row)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.name}
                        selected={isItemSelected}
                        className="border_none"
                      >
                        {headCells.map((item, titleIndex) => {
                          console.log("item----item", item?.id);

                          return (
                            item?.show && (
                              <TableCell
                                align="center"
                                className={
                                  item?.label === "Subject" ||
                                    item?.label === "Case ID"
                                    ? "border_none text_color_primary text-bold pointer-cursor"
                                    : "border_none data_text pointer-cursor"
                                }
                                style={{ color: "red" }}
                              >
                                {item?.date
                                  ? moment(row[item?.id]).format("YYYY-MM-DD")
                                  : item?.label === "Status" &&
                                    row[item?.id] === "1"
                                    ? "Open"
                                    : item?.label === "Status" &&
                                      row[item?.id] === "0"
                                      ? "Closed"
                                      : row[item?.id]}
                              </TableCell>
                            )
                          );
                        })}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <Box className="TableWithPagination_Pagination_Box">
              <Box
                className="d-flex items-center"
                style={{
                  margin: "20px 0px ",
                }}
              >
                <Box
                  onClick={() => Previous()}
                  style={
                    pageCount != 1
                      ? {
                        marginRight: "30px",
                        color: "#666C7C",
                        cursor: "pointer",
                      }
                      : { display: "none" }
                  }
                >
                  Previous
                </Box>
                <Box className="TableWithPagination_Pagination_Count_Box">
                  {pageCount}
                </Box>
                <Box
                  onClick={() => Next()}
                  style={
                    paginationEnd < rows?.length
                      ? {
                        marginRight: "30px",
                        color: "#666C7C",
                        cursor: "pointer",
                      }
                      : { display: "none" }
                  }
                >
                  Next
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <Box>
            <Table
              sx={{ minWidth: "100%" }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                headCells={headCells}
              />
            </Table>
            <Box className="text-bold Open_Support_Cases_No_Data_Error_Text text-align-center  margin-top-32 ">
              No open cases
            </Box>
            <Box className="Open_Support_Cases_No_Data_Error_Text text-align-center">
              Choose <span className="text-bold"> View all cases </span> to see
              you case history
            </Box>
          </Box>
        )}
        {/* </TableContainer> */}
      </Paper>
    </Box>
  );
};
export default TableWithPagination;
