import {
  Button,
  FormControl,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useMemo } from "react";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import aws from "../../assets/img/aws_cloud_icn@2x.png";
import azure from "../../assets/img/azure_icn.png";
import firebase from "../../assets/img/firebase_icn.png";
import rightArrow from "../../assets/img/yellowArrow.png";
import questionMark from "../../assets/img/questionamerk.png";
import exportImg from "../../assets/img/export.png";
import plusRound from "../../assets/img/plusRound.png";
import search from "../../assets/img/search_icn.png";
import { Api } from "../../MyNewUtils/Api";
import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { keys } from "highcharts";
import TableContainer from "@mui/material/TableContainer";
import myContext from "../../screens/MyContext/MyContext";

const Header = ({ cloudPlateForm, data, setIsLoading }) => {
  const [sendData, setSendData] = useState(data);
  const { cloudDetails, setCloudDetails } = useContext(myContext);
  const [cloudAccountIDList, setCloudAccountIDList] = useState([]);
  const [cloudAccountRegionsList, setCloudAccountRegionsList] = useState([]);
  const navigate = useNavigate();
  const getAllCloudAccounts = async () => {
    const data = await Api.postRequest("/users/get-all-cloud-accounts", {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    });

    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      setCloudAccountIDList(res?.data);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
    }
  };

  const getAllRegions = async () => {
    const data = await Api.postRequest("/users/get-all-regions", {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    });
    const res = JSON.parse(data?.data);
    if (res?.status == 1) {
      setCloudAccountRegionsList(res?.data);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
    }
  };

  const next = async () => {
    setIsLoading(true);
    let params = {
      userid: sendData?.id,
      type: sendData?.type,
      sourceName: sendData?.sourceName,
      description: sendData?.description,
      accessKeyId: sendData?.accessKeyId,
      secretAccessKey: sendData?.secretAccessKey,
      region: sendData?.region,
      accountId: sendData?.accountId,
      accountname: sendData?.accountName,
    };
    console.log("fewgeesdgvsdgvesdgv", params);

    const { statusCode, data } = await Api.postRequest(
      "/users/add-cloud-account",
      params
    );

    const res = JSON.parse(data);
    console.log("fewgeesdgvsdgvesdgvcbdfhnfcgvm", res);

    if (res?.status == 1) {
      let localData = JSON.parse(localStorage.getItem("userData"));
      localData = { ...localData, cloudaccounts: res?.data?.cloudaccounts };
      localStorage.setItem("userData", JSON.stringify(localData));

      let user = JSON.parse(localStorage.getItem("user"));
      let data = { ...user["data"], ...localData };
      user["data"] = data;
      localStorage.setItem("user", JSON.stringify(user));
      getAllCloudAccounts();
      getAllRegions();
      navigate("/dashboard");
      setIsLoading(false);
    } else {
      toast.error(res.message, { autoClose: 1000 });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      cloudAccountIDList.length !== 0 &&
      cloudAccountRegionsList.length !== 0
    ) {
      setCloudDetails({
        ...cloudDetails,
        cloudAccountIDList: cloudAccountIDList,
        selectedCloudAccountID: cloudAccountIDList[0]?.accountId,
        cloudRegionsList: cloudAccountRegionsList,
      });
    }
  }, [cloudAccountIDList, cloudAccountRegionsList]);
  return (
    <Box
      style={{
        padding: "10px",
        marginBottom: "20px",
        display: "flex",
      }}
    >
      <Box className="custome_stepper">
        {/* custome stepper */}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <StepperItem
            number={"1"}
            title={"Select Cloud Infrastructure"}
            isLineShow={true}
            isActive={true}
          />
          <StepperItem
            number={"2"}
            title={"Setup Source"}
            isLineShow={true}
            isActive={true}
          />
          <StepperItem
            number={"3"}
            title={"Preview & Done"}
            isLineShow={false}
          />
        </Box>

        {/* button */}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "20%",
            height: "100px",
          }}
        >
          <Button
            variant="contained"
            style={{
              marginLeft: "20px",
              borderRadius: "20px",
              padding: "5px 25px",
              color: "white",
              width: "180px",
              margin: "10px",
            }}
            onClick={next}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            style={{
              marginLeft: "20px",
              borderRadius: "20px",
              padding: "5px 25px",
              color: "white",
              width: "180px",
              margin: "10px",
            }}
            onClick={() =>
              navigate("/setup-source", { state: { sourceBackData: sendData } })
            }
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const StepperItem = ({ number, title, isLineShow, isActive }) => {
  return (
    <Box
      className={`cloud_step_circle ${
        isLineShow
          ? "cloud_step_circle_line_show"
          : "cloud_step_circle_without_line_show"
      } `}
    >
      <Box
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        <Box
          style={{
            backgroundColor: isActive ? "#008CF2" : "rgb(3,7,42)",
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: `2px solid ${isActive ? "#008CF2" : "white"}`,
          }}
        >
          {isActive ? (
            <CheckIcon fontSize={"large"} />
          ) : (
            <Typography>{number}</Typography>
          )}
        </Box>
        {isLineShow && (
          <Box
            style={{
              backgroundColor: isActive ? "#008CF2" : "white",
            }}
            className="cloud_steps_Line"
          ></Box>
        )}
      </Box>
      <Box style={{ marginTop: "20px" }}>
        <Typography
          style={{ color: "#008CF2", fontWeight: "700" }}
          className="cloud_steps_title"
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

const DashBoardSctionPage3Body = ({ data, setIsLoading }) => {
  const cls = useMemo(() => "DahboardThreeBody", []);
  const [sendData, setSendData] = useState(data);
  const [cloudPlateForm, setCloudPlateForm] = useState("");
  const [AllTableData, setAllTableData] = useState([]);

  const [access, setAccess] = useState("role");
  const handleChange = (event) => {
    setAccess(event.target.value);
  };
  console.log("Asgvdawsfrsfhrtfgj", sendData);

  const TableData = {
    userid: sendData?.id,
    type: sendData?.type,
    sourceName: sendData?.sourceName,
    description: sendData?.description,
    accessKeyId: sendData?.accessKeyId,
    secretAccessKey: sendData?.secretAccessKey,
    region: sendData?.region,
    accountId: sendData?.accountId,
    // accountname: sendData.accountName
  };

  let titleTable = Object.keys(TableData);
  console.log("Asgvrsfhrtfgj", titleTable);

  // for (let key in TableData) {
  //   console.log("Dwasgvsd", key)
  // }

  return (
    <>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            width: "90%",
            marginTop: "30px",
            padding: "30px",
            marginBottom: "30px",
          }}
        >
          {/* Header */}

          <Header
            cloudPlateForm={cloudPlateForm}
            data={data}
            setIsLoading={setIsLoading}
          />

          {/* body */}
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {/* left part */}
            <Box
              style={{
                width: "90%",
                backgroundColor: "rgb(0,0,0,0.5)",
                padding: "40px 30px",
                borderRadius: "30px",
              }}
            >
              <Box style={{ width: "80%" }}>
                {/* <Typography style={{ color: '#FF8800', fontSize: '22px' }}>
                  Install App
                </Typography>
                <Typography style={{ fontSize: '12px', marginTop: '20px' }}>
                  Enter data source to sleep the logs that are included in the
                  app's dashboards and searches, active data source is required
                  to display content in app.
                </Typography>
                <Box
                  style={{
                    marginTop: '20px',
                    display: 'flex',
                    width: '100%',

                    justifyContent: 'space-between',
                  }}
                >
                  <Box style={{ width: '40%' }}>
                    <Typography style={{ color: '#008CF2', fontSize: '12px' }}>
                      Log data source
                    </Typography>
                    <Input
                      defaultValue={'Amazon S3 Audit source'}
                      InputProps={{
                        style: {
                          color: 'white',
                          // borderBottom: '2px solid rgb(0,117,210)',
                          //   borderBottomColor: 'white',
                        },
                      }}
                      style={{
                        borderBottom: '1px solid rgb(0,117,210)',
                        color: 'white',
                        width: '100%',
                      }}
                    />
                  </Box>
                  <Box style={{ width: '40%' }}>
                    <Typography style={{ color: '#008CF2', fontSize: '12px' }}>
                      Source Category
                    </Typography>
                    <Input
                      defaultValue={'Amazon S3 Audit source'}
                      InputProps={{
                        style: {
                          color: 'white',
                          // borderBottom: '2px solid rgb(0,117,210)',
                          //   borderBottomColor: 'white',
                        },
                      }}
                      style={{
                        borderBottom: '1px solid rgb(0,117,210)',
                        color: 'white',
                        width: '100%',
                      }}
                    />
                  </Box>
                </Box>

                <Box style={{ marginTop: '20px' }}>
                  <Box style={{ width: '100%' }}>
                    <Typography style={{ color: '#008CF2', fontSize: '12px' }}>
                      Folder Namae
                    </Typography>
                    <Input
                      defaultValue={'Amazon S3 Audit source'}
                      InputProps={{
                        style: {
                          color: 'white',
                          // borderBottom: '2px solid rgb(0,117,210)',
                          //   borderBottomColor: 'white',
                        },
                      }}
                      style={{
                        borderBottom: '1px solid rgb(0,117,210)',
                        color: 'white',
                        width: '100%',
                      }}
                    />
                  </Box>
                </Box> */}

                <Box style={{ marginTop: "10px" }}>
                  <img src={search} />
                </Box>
                {/* 
                <Box
                  style={{
                    marginTop: '30px',
                    padding: '10px',
                    backgroundColor: 'rgb(24,29,52)',
                    borderRadius: '10px',
                  }}
                >
                  <Typography
                    style={{
                      color: '#008CF2',
                      fontSize: '14px',
                      fontWeight: '700',
                      marginLeft: '20px',
                    }}
                  >
                    All Folders
                  </Typography>
                </Box> */}

                <Box
                  style={{
                    marginTop: "30px",
                    padding: "10px",
                    backgroundColor: "rgb(24,29,52)",
                    borderRadius: "10px",
                    display: "flex",
                  }}
                >
                  <Typography
                    style={{
                      color: "#008CF2",
                      fontSize: "14px",
                      fontWeight: "700",
                      width: "50%",
                      marginLeft: "20px",
                    }}
                  >
                    All Folders
                  </Typography>
                  <Typography
                    style={{
                      color: "#008CF2",
                      fontSize: "14px",
                      fontWeight: "700",
                    }}
                  >
                    Description
                  </Typography>
                </Box>

                <table>
                  {titleTable?.map((key) => (
                    <tr>
                      <td>
                        <Typography
                          style={{
                            textAlign: "left",
                            fontSize: "16px",
                          }}
                        >
                          {key}
                        </Typography>
                      </td>
                      <td>
                        <Typography
                          style={{
                            textAlign: "left",
                            fontSize: "16px",
                            lineBreak: "anywhere",
                          }}
                        >
                          {TableData[key]}
                        </Typography>
                      </td>
                    </tr>
                  ))}
                </table>

                {/* <Box
                  style={{
                    marginTop: '10px',
                    padding: '10px',
                    width: '100%',
                    borderRadius: '10px',
                    display: 'flex',
                  }}
                >
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '700',
                      width: '50%',
                      marginLeft: '20px',
                    }}
                  >
                    Personal
                  </Typography>
                  <Typography
                    style={{
                      fontSize: '14px',
                      fontWeight: '700',
                      width: '50%',
                    }}
                  >
                    My saved searches and dashboards
                  </Typography>
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DashBoardSctionPage3Body;
