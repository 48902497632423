import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Modal,
  Typography,
} from "@mui/material";
import { useEffect, useState, useContext } from "react";
import myContext from "../../../MyContext/MyContext";
import { Api } from "../../../../MyNewUtils/Api";
import { toast } from "react-toastify";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChartTable from "../chartTable";
import cancelicn from "../../../../assets/img/cancel_round_icn.png";
import MainLoader from "../../../../Component/Spinner/MainLoader";
const EditModal = (props) => {
  // --------------- Props & States & Hooks --------------- //

  const { isOpen, handleClose, dashid, handleReloadPage } = props;
  const [dashboardList, setDashboardList] = useState([]);
  const [doneData, setDoneData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showData, setShowData] = useState([]);
  const [remainFeatureList, setRemainFeatureList] = useState([]);
  const [allFeatureList, setAllFeatureList] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const { cloudDetails } = useContext(myContext);
  useEffect(() => {
    if (
      cloudDetails?.selectedCloudServers &&
      cloudDetails?.selectedCloudAccountID.length !== 0 &&
      cloudDetails?.selectedCloudAccountID.length !== undefined
    )
      getChartData();
  }, [
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudServers,
  ]);

  useEffect(() => {
    let tableDoneData = [];
    let CloseData = [];
    dashboardList?.map((item, index) => {
      let title = Object.keys(item?.data)[0];
      tableDoneData[index] = {
        ...tableDoneData[index],
        Title: item.Title,
        isFilterAllow: item.isFilterAllow,
        data: item?.data[title],
        accountResponseType: title,
        featureKey: item?.featureKey,
        request_data: item.request_data,
      };
      CloseData[index] = {
        ...CloseData[index],
        Title: item.Title,
        isFilterAllow: item.isFilterAllow,
        data: item?.data,
        accountResponseType: item.accountResponseType,
        show: true,
        featureKey: item?.featureKey,
        request_data: item.request_data,
      };
    });
    setDoneData(tableDoneData);
    setShowData(CloseData);

    console.log("test----------1");
  }, [dashboardList]);

  // ----------------------------------------------------- //

  // ----------------- Utility Function ----------------- //

  const getChartData = async () => {
    try {
      setIsLoading(true);
      let param = {
        account_type: cloudDetails?.selectedCloudServers,
        accountId: cloudDetails?.selectedCloudAccountID,
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        dashid: dashid,
      };
      const allFeatures = await Api.getRequest("/dashboard/get-iam-features");
      const allFeatureList = JSON.parse(allFeatures?.data);
      setAllFeatureList(allFeatureList?.data);
      if (dashid !== undefined) {
        const data = await Api.postRequest(
          "/dashboard/get-dashboard-data-by-id",
          param
        );
        const res = JSON.parse(data?.data);
        if (res?.status === 1) {
          setDashboardList(res?.data);
          setIsLoading(false);
        } else {
          toast.error(res?.message, { autoClose: 1000 });
          setIsLoading(false);
        }
      }
      if (dashid !== undefined) {
        const data = await Api.postRequest(
          "/dashboard/get-remain-feature-for-dashboard",
          param
        );
        const res = JSON.parse(data?.data);
        if (res?.status == 1) {
          setRemainFeatureList(res?.data);
          setIsLoading(false);
        } else {
          // toast.error(res?.message, { autoClose: 1000 });
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("error from getChartData: ", error);
      setIsLoading(false);
    }
  };

  console.log("finalData", showData);
  const onSave = async () => {
    try {
      let finalData = [];
      showData?.map((item, index) => {
        if (item?.show) {
          if (item?.chartType) {
            finalData[finalData.length] = {
              chartType: item?.chartType,
              dashboadFeatureKey: item?.featureKey,
              from_date: doneData[index]?.from_date?.split("T")[0] ?? "",
              to_date: doneData[index]?.to_date?.split("T")[0] ?? "",
            };
          } else {
            finalData[finalData.length] = {
              chartType: Object.keys(item?.data)[0],
              dashboadFeatureKey: item?.featureKey,
              from_date: doneData[index]?.from_date?.split("T")[0] ?? "",
              to_date: doneData[index]?.to_date?.split("T")[0] ?? "",
            };
          }
        }
      });
      let finalParam = {
        dashid: dashid,
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        data: JSON.stringify(finalData),
        isDoneEditingFinished: true,
        account_type: cloudDetails?.selectedCloudServers,
        accountId: cloudDetails?.selectedCloudAccountID,
      };
      const { data } = await Api.postRequest(
        "/dashboard/finish-editing-dashboard",
        finalParam
      );
      const res = JSON.parse(data);
      if (res.status === 1) {
        toast.success(res.message, { autoClose: 1000 });
        setIsLoading(false);
        props.ChartData();
        handleClose();
        handleReloadPage();
      } else {
        toast.error(res.message, { autoClose: 1000 });
        setIsLoading(false);
        handleClose();
        handleReloadPage();
      }
    } catch (error) {
      console.log("edoting done error:", error);
      handleClose();
      handleReloadPage();
    }
  };

  const handleRemoveFromList = (item, ev) => {
    console.log("item", item);

    try {
      let newShowData = showData?.filter((val) => val?.Title !== item?.Title);
      let itemFeture = allFeatureList?.find(
        (val) => val?.featureKey === item?.featureKey
      );
      setRemainFeatureList([...remainFeatureList, itemFeture]);
      setShowData(newShowData);
    } catch (error) {
      console.log("failed to fetch features,", error);
    }
  };

  const handleAddNewFeture = async (item) => {
    console.log("test----------2");
    console.log("featuer", item);
    console.log("item", JSON.parse(localStorage.getItem("user"))?.data?.id);
    try {
      setIsLoading(true);
      setAnchorEl(null);
      let param = {
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        feature_key: item?.featureKey,
      };
      if (item?.featureKey !== undefined) {
        const data = await Api.postRequest(
          "/dashboard/add-feature-in-dashboard",
          param
        );
        const res = JSON.parse(data?.data);

        if (res?.status == 1) {
          if (res?.data.length !== 0) {
            let newFeatureData = res?.data[0];
            newFeatureData = { ...newFeatureData, show: true };
            console.log("newFeatureData", newFeatureData);
            setShowData([...showData, newFeatureData]);
            let newFeature = remainFeatureList?.filter(
              (e) => e?.featureKey !== item?.featureKey
            );
            setRemainFeatureList(newFeature);
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        } else {
          toast.error(res?.message, { autoClose: 1000 });
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("error of add new feature", error);
    }
  };

  const handleChart = (item, chart) => {
    console.log("test----------3");
    let selectedChartShowData = showData?.map((val, index) => {
      if (item?.featureKey === val?.featureKey) {
        return { ...val, chartType: chart };
      } else {
        return val;
      }
    });
    setShowData(selectedChartShowData);
  };

  const openFeatureList = async (e) => {
    setAnchorEl(e.target);
  };

  // ----------------------------------------------------- //

  // -------------------- Render Part ----------------------- //

  const RemainFeatureList = (props) => {
    const { anchorEl } = props;
    return (
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            background: "#1A2B56",
            color: "#fff",
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          },
        }}
      >
        {remainFeatureList?.map((item, index) => (
          <MenuItem key={index} onClick={() => handleAddNewFeture(item)}>
            {item?.featureName}
          </MenuItem>
        ))}
      </Menu>
    );
  };

  console.log("showData", showData);

  return (
    <>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={modalStyle.modalView}
      >
        <Box sx={modalStyle.modalContView}>
          <MainLoader isLoading={isLoading} />
          <div
            style={{
              ...modalStyle.stickyHeader,
              backgroundColor: isLoading ? null : "#010A26",
              zIndex: "20",
            }}
          >
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                style={{ color: "#fff", fontSize: "1.5rem" }}
              >
                Edit Feature
              </Typography>
              <Box style={modalStyle.actionIconView}>
                {!isLoading && (
                  <Button
                    variant="contained"
                    onClick={() => onSave()}
                    style={{ marginRight: "10px" }}
                  >
                    Save
                  </Button>
                )}
                {!isLoading && (
                  <Button
                    variant="contained"
                    endIcon={<AddCircleOutlineIcon />}
                    onClick={(e) => openFeatureList(e)}
                  >
                    Add More Features
                  </Button>
                )}
                <img
                  src={cancelicn}
                  style={{
                    width: "25px",
                    height: "25px",
                    cursor: "pointer",
                    marginLeft: "10px",
                  }}
                  onClick={handleClose}
                  alt=""
                />
              </Box>
            </Box>
          </div>
          <Grid container sx={modalStyle.cardView}>
            {showData?.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={
                    item?.show === false
                      ? { display: "none" }
                      : { padding: "20px" }
                  }
                >
                  {/* --------------------- All Type Table / Chart Show -------------------*/}
                  <ChartTable
                    item={item}
                    doneData={doneData}
                    Num={index}
                    setDoneData={setDoneData}
                    allData={dashboardList}
                    setShowData={setShowData}
                    showData={showData}
                    downloadBtn={true}
                    handleRemove={(ev) => {
                      console.log("wweweweweew");
                    }}
                    selectChart={(item, chart) => {
                      handleChart(item, chart);
                    }}
                    EditModel={true}
                  />
                </Grid>
              );
            })}
          </Grid>
          <RemainFeatureList anchorEl={anchorEl} />
        </Box>
      </Modal>
    </>
  );
};

export default EditModal;

const modalStyle = {
  modalView: {
    background: "rgb(225, 225, 225, 0.4)",
    backdropFilter: "blur(5px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContView: {
    backgroundColor: "#010A26",
    padding: "0px 20px 20px 20px",
    width: "90%",
    borderRadius: "20px",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 10,
      height: 10,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#0000",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#ADD8E6",
      borderRadius: 2,
      border: "0px solid #0000",
    },
    height: "80vh",
  },
  actionIconView: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  cancleIconView: {
    color: "#000",
    fontSize: "1.5rem",
    background: "#fff",
    borderRadius: "100%",
    width: "30px",
    height: "30px",
    cursor: "pointer",
  },
  cardView: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "25px",
    borderRadius: "20px",
    maxWidth: "85vw",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    zIndex: 10,
    padding: "20px 0px",
  },
};
