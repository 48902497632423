import SearchIcon from '@mui/icons-material/Search'
import { Button, MenuItem, Select, Switch } from '@mui/material'
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded'
import DashBoardTable from '../DashBoardTable/DashBoardTable'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import Input from '@mui/material/Input'

import zoomOut from '../../assets/img/zoomOut.svg'
import billing_visa from '../../assets/img/billing_visa.png'
import billing_american_express from '../../assets/img/billing_american_express.png'

import billing_mastercard from '../../assets/img/billing_mastercard.png'

import triangle_icn from '../../assets/img/triangle_icn.png'

import graph from '../../assets/img/graph@2x.png'
import billing_building from '../../assets/img/billing_building.png'
import billing_location from '../../assets/img/billing_location.png'

import DesaiPic from '../../assets/img/DesaiPic@2x.png'

import DashBoardAnalysisTwoLeftPart from '../S3BucketTable/index'
import { useCallback, useMemo } from 'react'
import CustomModel from '../Model/index'

import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { border, Box } from '@mui/system'
import CustomButton from "../CustomButton";
import cardProto from '../../assets/img/cardProto.png'
import warning from '../../assets/img/triangle_icn@2x.png'
import { toast } from 'react-toastify'
import visa from '../../assets/img/visa.png'
import master from '../../assets/img/master.png'
import express from '../../assets/img/express.png'
import discovry from '../../assets/img/dicovery.png'
import roundClose from '../../assets/img/cancel_icn.png'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import DarkUnica from 'highcharts/themes/dark-unica'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts'
import Modal from '@mui/material/Modal';
import mapInit from 'highcharts/modules/map'
import { Api } from '../../MyNewUtils/Api'
import './style.css'
import MainLoader from '../../Component/Spinner/MainLoader'
require('highcharts/modules/map')(Highcharts)
require('highcharts/modules/networkgraph')(Highcharts)


const NewChartModel = ({ _handleCloseModel, setModel, data, isLoading, setIsLoading }) => {
  const [open, setOpen] = useState(true);
  // const handleClose = () => { setModel(false), setIsLoading(false) }
  const [public_rows, setPublicRows] = useState([]);
  const [private_rows, setPrivateRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState(data);
  const [NewChartData, setNewChartData] = useState([]);

  console.log('selectedItem', selectedItem)
  const [networkGraphOptions, setNetworkGraphOptions] = useState({
    chart: {
      type: 'networkgraph',
      height: '55%',
      backgroundColor: 'rgb(3, 7, 42)'
    },
    title: {
      text: '',
      style: { color: 'white' },
    },
    subtitle: {
      text: '',
    },
    plotOptions: {
      networkgraph: {
        keys: ['from', 'to'],
        layoutAlgorithm: {
          enableSimulation: true,
          friction: -0.9,
        },
      },
    },
    series: [
      {
        dataLabels: {
          enabled: true,
          linkFormat: '',
        },
        id: 'lang-tree',
        data: [

        ],
        dataLabels: {
          enabled: true,
          color: '#fff',
          border: 'red'
        },
        marker: {
          lineColor: '#ffffff',
          radius: 10,
          fillColor: '#fff'
        },
      },

    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
          theme: {
            fill: 'red',
            symbolFill: 'red'
          }
        },
        exportButton: {
          symbol: 'url(download.png)',
          theme: {
            fill: '#0000',
            symbolFill: '#0000',
          },

          // Use only the download related menu items from the default
          // context button
          menuItems: [
            'downloadPNG'
          ]
        },
        printButton: {
          onclick: function () {
            this.print();
          },
          symbol: 'url(printe.png)',
          theme: {
            fill: '#0000',
            symbolFill: '#0000'
          },
        }
      }
    }
  });
  console.log("fsedyhrfujy", networkGraphOptions)
  const handleClose = () => {
    setModel(false)
    setIsLoading(false)
  }
  const mapData = async () => {

    setIsLoading(true)
    const final = { regionName: selectedItem.name, userid: JSON.parse(localStorage.getItem('user'))?.data?.id }
    const { networkdata, data } = await Api.postRequest('/network/world-network-map', final)
    const res = JSON.parse(data)
    console.log('resscdhfrjn', res)
    // let iconUrl = []
    if (res?.status === 1) {
      let NewChartData = Object.values(res?.data)
      console.log("dswtgetg", NewChartData[0])
      // res?.['networkmapdata'].map((item) => {
      //   console.log('item$$$rfeyg', item)
      // })
      const series = [
        {
          link: {
            width: 2,
            color: '#fff'
          },
          dataLabels: {
            enabled: true,
            linkFormat: '',
            color: 'rgb(129, 245, 255)'
          },
          id: 'lang-tree',
          data: NewChartData[0],
          marker: {
            radius: 20,
            symbol: NewChartData[0]
          },

        },
      ]
      console.log("res$$$", series)
      setNetworkGraphOptions({ ...networkGraphOptions, series: series })
      setIsLoading(false)
    } else {
      toast.error(res?.message, { autoClose: 1000 })
      setIsLoading(false)
    }
  }
  useEffect(() => {
    mapData()

  }, [])
  const cls = useMemo(() => 'NetWorkMapTwo', [])
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      style={{ background: 'rgb(225, 225, 225, 0.4)', backdropFilter: 'blur(5px)', display: 'flex', alignItems: 'center', display: 'flex', alignItems: 'center' }}
    >
      <Box className={`${cls}__background`} sx={{ width: '70%', height: '80%', margin: 'auto', borderRadius: '20px', border: '1px solid #707070' }}>
        <MainLoader isLoading={isLoading} style={{ flexGrow: 1, width: '90%', height: '80%' }} />
        {/* Header */}
        {/* <DashboardHeader activeTab={'NETWORK MAP'} /> */}
        <Box style={{ flexGrow: 1, width: '90%' }}>
          <HighchartsReact
            highcharts={Highcharts}
            options={networkGraphOptions}
          />
        </Box>
      </Box>
    </Modal>
  )
}
export default NewChartModel
