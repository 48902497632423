import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/system";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import { Box, Grid, Typography } from "@mui/material";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import Checkbox from "@mui/material/Checkbox";
import cancelIcon from "../../../../../../assets/img/cancel_round_icn.png";
import "./ supportCaseTableSettingModel.css";
const style = {
  position: "absolute",
  width: "80%",
  bgcolor: "#01081D",
  border: "1px solid #707070",
  boxShadow: 24,
  p: 4,
  padding: "40px 60px",
  borderRadius: "30px",
};

const SupportCaseTableSettingModel = ({
  settingModel,
  setSettingModel,
  rowLimitEnd,
  setRowLimitEnd,
  tableTitle,
  setTableTitle,
}) => {
  const handleClose = () => setSettingModel(false);
  const [confirmColumn, setConfirmColumn] = useState(tableTitle);
  const [selectRowCount, setSelectRowCount] = useState(rowLimitEnd);
  const getSwitch = (item) => {
    let finalColumn = [];
    confirmColumn.map((confirmColumnitem, index) => {
      if (item.id === confirmColumnitem.id) {
        finalColumn[index] = {
          ...finalColumn[index],
          id: confirmColumnitem?.id,
          numeric: confirmColumnitem?.numeric,
          disablePadding: false,
          label: confirmColumnitem?.label,
          show: !confirmColumnitem.show,
        };
      } else {
        finalColumn[index] = confirmColumnitem;
      }
    });

    console.log("confirmColumnitem", finalColumn);
    setConfirmColumn(finalColumn);
  };
  const getConfirm = () => {
    setRowLimitEnd(selectRowCount);
    setTableTitle(confirmColumn);
    setSettingModel(false);
  };

  const blue = {
    500: "#007FFF",
  };

  const grey = {
    400: "#BFC7CF",
    500: "#AAB4BE",
    600: "#6F7E8C",
  };
  const Root = styled("span")(
    ({ theme }) => `
    font-size: 0;
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    margin: 10px;
    cursor: pointer;
  
    &.${switchUnstyledClasses.disabled} {
      opacity: 0.4;
      cursor: not-allowed;
    }
  
    & .${switchUnstyledClasses.track} {
      background: ${theme.palette.mode === "dark" ? grey[600] : grey[400]};
      border-radius: 10px;
      display: block;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  
    & .${switchUnstyledClasses.thumb} {
      display: block;
      width: 14px;
      height: 14px;
      top: 3px;
      left: 3px;
      border-radius: 16px;
      background-color: #fff;
      position: relative;
      transition: all 200ms ease;
    }
  
    &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
      background-color: ${grey[500]};
      box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
    }
  
    &.${switchUnstyledClasses.checked} {
      .${switchUnstyledClasses.thumb} {
        left: 22px;
        top: 3px;
        background-color: #fff;
      }
  
      .${switchUnstyledClasses.track} {
        background: ${blue[500]};
      }
    }
  
    & .${switchUnstyledClasses.input} {
      cursor: inherit;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      margin: 0;
    }
    `
  );
  console.log("tableTitle", tableTitle);
  return (
    <>
      <Modal
        open={settingModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style}>
          <Box>
            <Box className="cancel-icon-box">
              <img
                src={cancelIcon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => setSettingModel(false)}
              />
            </Box>
            <Typography className="clear-page-model-header-title">
              Preferences
            </Typography>
            <Box>
              <Box sx={{ display: "flex", padding: "15px 20px 0px 20px" }}>
                <Grid container>
                  <Grid item xs={6} lg={8}>
                    <Box>
                      <Box>
                        <Typography sx={{ color: "#fff" }}>
                          Page Size
                        </Typography>
                      </Box>
                      <Box>
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue={rowLimitEnd}
                            name="radio-buttons-group"
                          >
                            <FormControlLabel
                              sx={{
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onChange={(e) => setSelectRowCount(10)}
                              value={10}
                              control={<Radio sx={{ color: "#fff" }} />}
                              label="10 Cases"
                            />
                            <FormControlLabel
                              sx={{
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onChange={(e) => setSelectRowCount(20)}
                              value={20}
                              control={<Radio sx={{ color: "#fff" }} />}
                              label="20 Cases"
                            />
                            <FormControlLabel
                              sx={{
                                color: "#fff",
                                display: "flex",
                                alignItems: "center",
                              }}
                              onChange={(e) => setSelectRowCount(50)}
                              value={50}
                              control={<Radio sx={{ color: "#fff" }} />}
                              label="50 Cases"
                            />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                      {/* <Box className="margin-top-20">
                        <Grid container>
                          <Grid item xs={1} lg={0.5}>
                            <Checkbox />
                          </Grid>
                          <Grid
                            item
                            xs={11}
                            lg={11.5}
                            className="Wrap-lines-text-box"
                          >
                            <Typography className="text-white">
                              Wrap lines
                            </Typography>
                          </Grid>
                          <Grid item xs={1} lg={0.5} />
                          <Grid
                            item
                            xs={11}
                            lg={11.5}
                            className="Wrap-lines-text-box"
                          >
                            <Typography className="Wrap-lines-text-description">
                              Check to see all the text and wrap lines
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box> */}
                    </Box>
                  </Grid>
                  <Grid item xs={6} lg={4}>
                    <Box className="select-visible-columns-part">
                      <Typography sx={{ color: "#fff" }}>
                        Select visible columns
                      </Typography>
                      <Typography sx={{ color: "#ffffffa6" }}>
                        Main support case properties
                      </Typography>
                      <Box className="margin-top-20">
                        {confirmColumn.map((item, index) => {
                          return (
                            <>
                              <Typography
                                sx={{
                                  color: "#fff",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Grid container>
                                  <Grid item xs={6}>
                                    {item.label}
                                  </Grid>

                                  <Grid item xs={6}>
                                    <SwitchUnstyled
                                      component={Root}
                                      checked={item?.show}
                                      value={item}
                                      onClick={() => getSwitch(item)}
                                    />
                                  </Grid>
                                </Grid>
                              </Typography>
                            </>
                          );
                        })}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
              <Grid container>
                <Grid item xs={6} lg={8} />
                <Grid item xs={6} lg={4}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box className="clear-page-model-btn-part">
                      <Box
                        className="previous-btn SupportCenter_btn d-flex items-center cursor_pointer padding_top_bottom_10_15"
                        style={{ color: "#fff" }}
                        onClick={handleClose}
                      >
                        Cancel
                      </Box>
                      <Box
                        className="SupportCenter_btn_background_color SupportCenter_btn d-flex items-center margin-left-20px cursor_pointer padding_top_bottom_10_15 "
                        style={{ color: "#fff" }}
                        onClick={getConfirm}
                      >
                        Confirm
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default SupportCaseTableSettingModel;
