import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useMemo, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/img/CO5_FINAL.png'
import { style } from './style'
import './RegistrationLayout.css'

const RegistrationLayout = ({ isHeaderShow = true, children }) => {
  const cls = useMemo(() => 'registration', [])
  let location = useLocation()
  const isSignUp = useMemo(() => location.pathname === '/signup', [location])
  return (
    <section className={`${cls}__background`}>
      {/* Header */}
      {isHeaderShow && (
        <Box className={`${cls}__header`}>
          <Box className={`${cls}__linkBox`}>
            {/* <Link to={isSignUp ? '/' : '/signup'} className={`${cls}__link`}> */}
            {' '}
            <a> {isSignUp ? 'Already have an account?' : 'New here?'}</a>
            {/* </Link> */}
          </Box>
          <Box className={`${cls}__linkBox`}>
            {!isSignUp ? (
              <Link to="/signup" className={`${cls}__linkAccount`}>
                {' '}
                <a>CREATE AN ACCOUNT</a>
              </Link>
            ) : (
              <Link
                to="/"
                className={` ${cls}__linkAccount ${cls}__linkSignIn`}
              >
                {' '}
                <a>SIGN IN</a>
              </Link>
            )}
          </Box>
        </Box>
      )}

      {/* Body */}
      {children}
      {/* footer */}
      {/* <Box className={`${cls}__footerLayout`}>
        <Link to="#" className={`${cls}__footerLink ${cls}__footerBorderLink`}>
          <a>Terms & conditions</a>
        </Link>
        <Link to="#" className={`${cls}__footerLink ${cls}__footerBorderLink`}>
          <a>Privacy Policy</a>
        </Link>
        <Link to="#" className={`${cls}__footerLink`}>
          <a>Legal Repository</a>
        </Link>
      </Box> */}
      {/* ------------------------------------------------- */}
      <Box className={`${cls}__footerLayout`}>
        <Box to="#" className={`${cls}__footerLink ${cls}__footerBorderLink`}>
          <a></a>
        </Box>
        <Box to="#" className={`${cls}__footerLink ${cls}__footerBorderLink`}>
          <a></a>
        </Box>
        <Box to="#" className={`${cls}__footerLink`}>
          <a></a>
        </Box>
      </Box>
      {/* ------------------------------------------------- */}
    </section>
  )
}

export default RegistrationLayout
