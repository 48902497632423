import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import "./style.css";
import { Api } from "../../MyNewUtils/Api";
import { useEffect } from "react";
import { Button } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MainLoader from "../../Component/Spinner/MainLoader";
import refresh from "../../assets/img/refresh.png";
import roundThreeDot from "../../assets/img/roundThreeDot.png";
import DeleteRoleModel from "./Model/DeleteRoleModel/DeleteUsersModel";
import UpdateRoleModel from "./Model/updateRoleModel";
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    color: "#7CECF7",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #7CECF7",
    borderRadius: "50px",
  },
}));

const TableHeader = ({ handleSelectAllRoles, allRolesSelectorCheckbox }) => {
  const cls = useMemo(() => "roles", []);
  return (
    <tr
      style={{
        backgroundColor: "#272E42",
      }}
    >
      <th
        style={{
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        <Checkbox
          onClick={handleSelectAllRoles}
          checked={allRolesSelectorCheckbox}
        />
      </th>
      <th>
        <Typography
          style={{
            color: "white",
            fontSize: "18px",
            fontWeight: "700",
            textAlign: "left",
          }}
        >
          Role Name
        </Typography>
      </th>
      <th>
        <Typography
          style={{
            color: "white",
            fontSize: "18px",
            fontWeight: "700",
            textAlign: "left",
          }}
        >
          Role Permissions
        </Typography>
      </th>
      <th>
        <Typography
          style={{
            color: "white",
            fontSize: "18px",
            fontWeight: "700",
            textAlign: "left",
          }}
        >
          Role Description
        </Typography>
      </th>
      <th style={{ maxWidth: "50px" }}>
        <Typography
          style={{
            color: "white",
            fontSize: "18px",
            fontWeight: "700",
            textAlign: "left",
          }}
        >
          Action
        </Typography>
      </th>
    </tr>
  );
};

const TableRow = ({
  data,
  description,
  index,
  user,
  role,
  color,
  moreActionManu,
  selectRolesValidation,
  handleSelectRole,
  handleClickOpenMoreActionManu,
  handleCloseMoreActionManu,
  moreActionManuIndex,
  setActionRolesValue,
  setDeleteRolesModelOpen,
  setSingalRoleDelete,
  setUpdateRoleModelOpen,
}) => {
  const Rolepermissions = JSON.parse(data?.Rolepermissions);
  const _handleDeleteRoleModel = () => {
    setSingalRoleDelete(true);
    setDeleteRolesModelOpen(true);
  };

  const _handleUpdateModel = () => {
    setActionRolesValue(data);
    setUpdateRoleModelOpen(true);
  };
  console.log("data", data);
  return (
    <tr
      className={
        selectRolesValidation(data?._id) || index === moreActionManuIndex
          ? "role_selected_user"
          : "role__Table"
      }
    >
      <td
        style={{
          fontSize: "16px",
          textAlign: "center",
        }}
      >
        <Checkbox
          onClick={handleSelectRole}
          value={data._id}
          checked={selectRolesValidation(data._id)}
        />
      </td>
      <td>{data?.Rolename}</td>
      <td>
        {Rolepermissions.map((item, index) => {
          return <tr>{item}</tr>;
        })}
      </td>
      <td align="left">{data?.Roledesc}</td>
      <td className="user_date_table_action">
        {!data?.Isdefault && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              minHeight: "62px",
              alignItems: "center",
              maxWidth: "100px",
            }}
          >
            <HtmlTooltip title={"Update Role"}>
              <img
                src={refresh}
                style={{ cursor: "pointer" }}
                alt=""
                onClick={_handleUpdateModel}
              />
            </HtmlTooltip>
            <HtmlTooltip title={"More"}>
              <img
                src={roundThreeDot}
                style={{ cursor: "pointer" }}
                alt=""
                onClick={(e) => handleClickOpenMoreActionManu(e, index, data)}
              />
            </HtmlTooltip>
            <Menu
              anchorEl={moreActionManu}
              id="account-menu"
              open={moreActionManu}
              onClose={handleCloseMoreActionManu}
              onClick={handleCloseMoreActionManu}
              PaperProps={{
                elevation: 0,
                sx: {
                  background: "#010A26",
                  border: "1px solid #fff",
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    border: "1px solid #fff",
                    background: "#010A26",
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    borderTop: "1px solid #fff",
                    borderLeft: "1px solid #fff",
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "#010A26",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{
                horizontal: "right",
                vertical: "top",
              }}
              anchorOrigin={{
                horizontal: "right",
                vertical: "bottom",
              }}
            >
              <MenuItem onClick={_handleDeleteRoleModel} sx={{ color: "#fff" }}>
                Delete Role
              </MenuItem>
            </Menu>
          </Box>
        )}
      </td>
    </tr>
  );
};

const RolesTable = ({
  setRefreshRoleData,
  setSelectedUserCount,
  search,
  refreshRoleData,
}) => {
  const [data, setdata] = useState([]);
  const [alldata, setAlldata] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(10);
  const [showTotalPage, setShowTotalPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [moreActionManu, setMoreActionManu] = useState(null);
  const [selectedRolesList, setSelectedRolesList] = useState([]);
  const [allRolesSelectorCheckbox, setAllRolesSelectorCheckbox] =
    useState(false);
  const [moreActionManuIndex, setMoreActionManuIndex] = useState(null);
  const [deleteRolesModelOpen, setDeleteRolesModelOpen] = useState(false);
  const [actionRolesValue, setActionRolesValue] = useState();
  const [singalRoleDelete, setSingalRoleDelete] = useState(false);
  const [updateRoleModelOpen, setUpdateRoleModelOpen] = useState(false);
  const allRoleData = async () => {
    setIsLoading(true);
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };

    const { statusCode, data } = await Api.postRequest(
      "/users/get-all-roles",
      final
    );
    const res = JSON.parse(data);
    console.log("res", res);
    if (res?.status === 1) {
      setdata(res?.data);
      setShowTotalPage(Math.ceil(res?.data.length / 10));
      setAlldata(res?.data);
      setIsLoading(false);
    } else {
      setPaginationEnd(0);
      toast.error(res?.message, { autoClose: 1000 });
      setIsLoading(false);
    }
    setIsLoading(false);
  };
  const paginationLeft = () => {
    if (paginationStart > 0) {
      setCurrentPage(currentPage - 1);
      setPaginationStart(paginationStart - 10);
      setPaginationEnd(paginationEnd - 10);
    }
  };
  const paginationRight = () => {
    if (paginationEnd < data.length) {
      setCurrentPage(currentPage + 1);
      setPaginationStart(paginationStart + 10);
      setPaginationEnd(paginationEnd + 10);
    }
  };

  const requestSearch = () => {
    if (search) {
      const filteredRows = data.filter((row) => {
        return row?.Rolename.toLowerCase().includes(search.toLowerCase());
      });
      setdata(filteredRows);
      setShowTotalPage(Math.ceil(filteredRows.length / 10));
      setCurrentPage(1);
      setPaginationStart(0);
      setPaginationEnd(10);
    } else {
      setdata(alldata);
    }
  };

  const handleSelectAllRoles = (e) => {
    let allRolesList = [];
    if (e.target.checked) {
      data.map((item, index) => {
        allRolesList[index] = item._id;
      });
      setSelectedRolesList(allRolesList);
      setAllRolesSelectorCheckbox(true);
    } else {
      setSelectedRolesList([]);
      setAllRolesSelectorCheckbox(false);
    }
  };

  const selectRolesValidation = (e) => {
    console.log("e", e);
    let selectedRolesValidationArray = selectedRolesList?.filter(
      (item) => item === e
    );
    if (selectedRolesValidationArray.length !== 0) return true;

    return false;
  };

  const handleSelectRole = (e) => {
    if (!e.target.checked) {
      const updateSelectedItem = selectedRolesList.filter(
        (el) => el !== e.target.value
      );
      if (updateSelectedItem.length !== data.length)
        setAllRolesSelectorCheckbox(false);
      setSelectedRolesList(updateSelectedItem);
    } else {
      setSelectedRolesList([...selectedRolesList, e.target.value]);
    }
  };

  const _handleDeleteRoles = () => {
    setSingalRoleDelete(false);
    setDeleteRolesModelOpen(true);
  };

  const handleClickOpenMoreActionManu = (event, index, data) => {
    setMoreActionManu(event.currentTarget);
    setMoreActionManuIndex(index);
    setActionRolesValue(data);
  };

  const handleCloseMoreActionManu = () => {
    setMoreActionManu(null);
    setMoreActionManuIndex();
  };

  useEffect(() => {
    requestSearch();
  }, [search]);

  useEffect(() => {
    allRoleData();
  }, [refreshRoleData]);

  useEffect(() => {
    if (selectedRolesList.length === data.length)
      setAllRolesSelectorCheckbox(true);
    else setAllRolesSelectorCheckbox(false);
  }, [selectedRolesList, data]);
  console.log("refreshRoleData", refreshRoleData);
  return (
    <Box
      style={{
        padding: "10px",
      }}
    >
      {deleteRolesModelOpen && (
        <DeleteRoleModel
          deleteRolesModelOpen={deleteRolesModelOpen}
          setDeleteRolesModelOpen={setDeleteRolesModelOpen}
          actionRolesValue={actionRolesValue}
          setActionRolesValue={setActionRolesValue}
          setRefreshList={setRefreshRoleData}
          refreshList={refreshRoleData}
          singalRoleDelete={singalRoleDelete}
          setSingalRoleDelete={setSingalRoleDelete}
          selectedRolesList={selectedRolesList}
          setSelectedRolesList={setSelectedRolesList}
        />
      )}
      {updateRoleModelOpen && (
        <UpdateRoleModel
          updateRoleModelOpen={updateRoleModelOpen}
          setUpdateRoleModelOpen={setUpdateRoleModelOpen}
          actionRolesValue={actionRolesValue}
          setActionRolesValue={setActionRolesValue}
          setRefreshList={setRefreshRoleData}
          refreshList={refreshRoleData}
        />
      )}
      <MainLoader isLoading={isLoading} />
      {selectedRolesList.length > 0 && (
        <Box
          style={{
            display: "flex",
            // backgroundColor: "red",
            width: "100%",
            alignSelf: "center",
            justifyContent: "space-between",
          }}
        >
          <Box style={{ flexGrow: 1, textAlign: "center" }}>
            <Typography style={{ color: "white" }}>
              {selectedRolesList.length === 0 ? (
                <>
                  Roles
                  <span style={{ color: "rgb(103,108,130)" }}>
                    | Showing all Roles
                  </span>{" "}
                </>
              ) : (
                <span style={{ color: "rgb(103,108,130)" }}>
                  {selectedRolesList.length} Roles selected
                </span>
              )}
            </Typography>
          </Box>
          <Box
            sx={{ flexGrow: 1, cursor: "pointer", textAlign: "center" }}
            onClick={_handleDeleteRoles}
          >
            <Typography style={{ color: "#008CF2" }}>
              Delete selected Roles
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 1, textAlign: "center", cursor: "pointer" }}>
            <Typography style={{ color: "#008CF2" }}>
              Add selected Roles to group
            </Typography>
          </Box>
        </Box>
      )}
      <table style={{ width: "100%" }} cellSpacing={0}>
        <TableHeader
          handleSelectAllRoles={handleSelectAllRoles}
          allRolesSelectorCheckbox={allRolesSelectorCheckbox}
        />
        {data.slice(paginationStart, paginationEnd).map((d, index) => (
          <TableRow
            data={d}
            role={d?.RoleName}
            user={d?.user}
            description={d?.Description}
            color={"rgba(255,255,255,0.4)"}
            moreActionManu={moreActionManu}
            setMoreActionManu={setMoreActionManu}
            selectRolesValidation={selectRolesValidation}
            handleSelectRole={handleSelectRole}
            index={index}
            handleClickOpenMoreActionManu={handleClickOpenMoreActionManu}
            handleCloseMoreActionManu={handleCloseMoreActionManu}
            moreActionManuIndex={moreActionManuIndex}
            setActionRolesValue={setActionRolesValue}
            setDeleteRolesModelOpen={setDeleteRolesModelOpen}
            setSingalRoleDelete={setSingalRoleDelete}
            setUpdateRoleModelOpen={setUpdateRoleModelOpen}
          />
        ))}
      </table>
      {data.length > 10 ? (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {currentPage > 1 ? (
            <Button onClick={paginationLeft}>
              {" "}
              <KeyboardDoubleArrowLeftIcon style={{ color: "#272E42" }} />
            </Button>
          ) : null}
          {showTotalPage > 1 && showTotalPage > currentPage ? (
            <Button onClick={paginationRight}>
              {" "}
              <KeyboardDoubleArrowRightIcon style={{ color: "#272E42" }} />
            </Button>
          ) : null}
        </Box>
      ) : null}
    </Box>
  );
};

export default RolesTable;
