import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "../CustomButton/index";
import exportImg from "../../assets/img/export.png";
import visa from "../../assets/img/visa.png";
import master from "../../assets/img/master.png";
import express from "../../assets/img/express.png";
import discovry from "../../assets/img/dicovery.png";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Api } from "../../MyNewUtils/Api";
import MainLoader from "../Spinner/MainLoader";
import { path } from "../../MyNewUtils/screenPath";

const SignupStep4Col3 = ({ MyData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [signupData, setSignupData] = useState({
    id: JSON.parse(localStorage.getItem("user")).data.id,
    name: null,
    expirationdate: null,
    expirationyear: null,
    cardholdername: "",
    cvv: "",
    billingaddress: null,
  });

  const onNameSet = (e) => {
    if (e.target.value.length <= 16) {
      setSignupData({ ...signupData, name: e.target.value });
    }
  };
  const onExpirationdateSet = (e) => {
    if (e.target.value.length <= 2) {
      setSignupData({ ...signupData, expirationdate: e.target.value });
    }
  };
  const onExpirationyearSet = (e) => {
    if (e.target.value.length <= 2) {
      console.log("DFSDFSDDFs", e.target.value.length <= 2);
      setSignupData({ ...signupData, expirationyear: e.target.value });
    }
  };
  const onCardholdernameSet = (e) => {
    setSignupData({ ...signupData, cardholdername: e.target.value });
  };
  const onCcvSet = (e) => {
    // console.log("sagseuhuj", e.target.value.length)
    if (e.target.value.length <= 3) {
      setSignupData({ ...signupData, cvv: e.target.value });
    }
  };
  const onBillingaddressSet = (e) => {
    setSignupData({ ...signupData, billingaddress: e.target.value });
  };

  const [cardnumberError, setCardnumberError] = useState(false);
  const [expirationdate, setExpirationdateError] = useState(false);
  const [expirationyear, setExpirationyearError] = useState(false);
  const [cardholdername, setCardholdernameError] = useState(false);
  const [cvvError, setCvvError] = useState(false);

  const onSubmit = async (event) => {
    // var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
    // var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
    // var amexpRegEx = /^(?:3[47][0-9]{13})$/;
    // var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
    // event.preventDefault();
    if (signupData?.name != null && signupData.name.length != null) {
      if (
        signupData?.expirationdate != null &&
        signupData?.expirationdate.length != ""
      ) {
        if (
          signupData?.expirationyear != null &&
          signupData?.expirationyear.length != ""
        ) {
          if (
            signupData?.cardholdername != null &&
            signupData?.cardholdername.length != ""
          ) {
            if (signupData?.cvv != null && signupData?.cvv.length != "") {
              setIsLoading(true);
              let params = {
                cardNumber: signupData.name,
                cardExpMonth: signupData.expirationdate,
                cardExpYear: signupData.expirationyear,
                cardCVC: signupData.cvv,
                cardName: signupData.cardholdername,
              };
              let final = {
                id: signupData.id,
                step: "3",
                data: JSON.stringify(params),
              };
              const { statusCode, data } = await Api.postRequest(
                "/users/user-account-setup",
                final
              );
              const res = JSON.parse(data);
              if (res?.status === 1) {
                console.log("data", JSON.parse(data));
                toast.success(res?.message, { autoClose: 1000 });
                setIsLoading(false);
                navigate(path.step4, { state: { Data: MyData } });
              } else {
                setIsLoading(false);
                toast.error(res?.message, { autoClose: 1000 });
              }
              setIsLoading(false);

              // navigate('/signup-step-8')
            }
          }
        }
      }
    }

    if (signupData?.name != null && signupData?.name.length != "") {
      setCardnumberError(false);
    } else {
      setCardnumberError(true);
    }

    if (
      signupData?.expirationdate != null &&
      signupData?.expirationdate.length != ""
    ) {
      setExpirationdateError(false);
    } else {
      setExpirationdateError(true);
    }

    if (
      signupData?.expirationyear != null &&
      signupData?.expirationyear.length != ""
    ) {
      setExpirationyearError(false);
    } else {
      setExpirationyearError(true);
    }

    if (
      signupData?.cardholdername != null &&
      signupData?.cardholdername.length != ""
    ) {
      setCardholdernameError(false);
    } else {
      setCardholdernameError(true);
    }

    if (signupData?.cvv != null && signupData?.cvv.length != "") {
      setCvvError(false);
    } else {
      setCvvError(true);
    }

    // const data = await AddNewCard(signupData)
  };

  console.log(signupData);
  const navigate = useNavigate();

  return (
    <Box
      style={{
        width: "90%",
        border: "1px solid gray",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderRadius: "30px",
      }}
    >
      <MainLoader isLoading={isLoading} />
      <Box style={{ margin: "20px" }}>
        <Typography style={{ color: "#FF8800", fontSize: "36px" }}>
          Verification only Clevrone
        </Typography>
        <Typography style={{ fontSize: "22px", fontWeight: "bold" }}>
          Billing Information
        </Typography>
        {/* <form autoComplete="new-password" onSubmit={onSubmit}> */}
        <Box>
          <lable
            style={{
              display: "block",
              fontSize: "18px",
              color: "#008CF2",
            }}
          >
            Credit or Debit card number
          </lable>
          <input
            style={{
              width: "100%",
              backgroundColor: "black",
              border: "1px solid #363636",
              padding: "4px",
              color: "#FFF",
            }}
            placeholder={"Card Number"}
            value={signupData.name}
            type="number"
            maxLength="16"
            minLength="16"
            onChange={onNameSet}
            onKeyDown={(e) =>
              ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
            }
          />
          <Typography
            style={
              cardnumberError == false
                ? { display: "none" }
                : { color: "red", fontSize: "10px" }
            }
          >
            Enter Card Name
          </Typography>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={visa} />
            <img src={master} />
            <img src={express} />
            <img src={discovry} />
          </Box>

          <Typography style={{ fontSize: "14px" }}>
            Clevrone accept all major credit and debit cards. to learn more
            about payment options, review our{" "}
            <span style={{ color: "#81F5FF" }}>FAQ</span>
          </Typography>
        </Box>

        <Box style={{ marginTop: "5px" }}>
          <lable
            style={{
              display: "block",
              fontSize: "18px",
              color: "#008CF2",
            }}
          >
            Expiration date
          </lable>
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <input
              style={{
                width: "45%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              value={signupData.expirationdate}
              placeholder={"MM"}
              type="number"
              onChange={onExpirationdateSet}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
            <input
              style={{
                width: "45%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              type="number"
              value={signupData.expirationyear}
              placeholder={"YY"}
              onChange={onExpirationyearSet}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Box>

          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={
                expirationdate == false
                  ? { display: "none" }
                  : { color: "red", fontSize: "10px" }
              }
            >
              Enter Card Expir Date
            </Typography>
            <Typography
              style={
                expirationyear == false
                  ? { display: "none" }
                  : { color: "red", fontSize: "10px" }
              }
            >
              Enter Card Expir Year
            </Typography>
          </Box>
        </Box>

        <Box style={{ marginTop: "5px" }}>
          <lable
            style={{
              display: "block",
              fontSize: "18px",
              color: "#008CF2",
            }}
          >
            Cardholder's name
          </lable>
          <input
            type="text"
            onChange={onCardholdernameSet}
            value={signupData.cardholdername}
            style={{
              width: "100%",
              backgroundColor: "black",
              border: "1px solid #363636",
              padding: "4px",
              color: "#FFF",
            }}
            autoComplete="new-password"
            // autoComplete="off"
            // autoCapitalize="off"
            // id="new-password"
            // autoComplete="new-password"
            placeholder={"Cardholder Name"}
          />
          <Typography
            style={
              cardholdername == false
                ? { display: "none" }
                : { color: "red", fontSize: "10px" }
            }
          >
            Enter Card Holder Name
          </Typography>
        </Box>

        <Box style={{ marginTop: "5px" }}>
          <lable
            style={{
              display: "block",
              fontSize: "18px",
              color: "#008CF2",
            }}
          >
            CVV
          </lable>
          <input
            onChange={onCcvSet}
            style={{
              width: "100%",
              backgroundColor: "black",
              border: "1px solid #363636",
              padding: "4px",
              color: "#FFF",
            }}
            type="password"
            value={signupData.cvv}
            placeholder={"CVV"}
            onKeyPress={(event) => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            // autoCapitalize="off"
            // autoComplete="off"
            // id="new-password"
            autoComplete="new-password"
            // autoComplete="new-password"
            // onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
          />
          <Typography
            style={
              cvvError == false
                ? { display: "none" }
                : { color: "red", fontSize: "10px" }
            }
          >
            Enter Card CVV
          </Typography>
        </Box>

        {/* <Box style={{marginTop: '5px'}}>
          <lable
            style={{
              display: 'block',
              fontSize: '18px',
              color: '#008CF2',
            }}
          >
            Billing Address
          </lable>
          <Box style={{display: 'flex'}}>
            <Box style={{width: '50%'}}>
              <FormControlLabel
                value="business"
                control={<Radio style={{color: '#008fff'}} />}
                label="Use my contact address"
                style={{color: '#81F5FF'}}
                onChange={onBillingaddressSet}
              />
              <Typography>
                2nd floor A2, XYZ Apartment, 2366 21st st, Astoria, NY USA
              </Typography>
            </Box>
            <Box style={{width: '50%'}}>
              <FormControlLabel
                value="business2"
                control={<Radio style={{color: '#008fff'}} />}
                label="Use my contact address"
                style={{color: '#81F5FF'}}
                onChange={onBillingaddressSet}
              />
            </Box>
          </Box>
        </Box> */}
        <Box style={{ marginTop: "5px" }}>
          <CustomButton
            text={"Verify and Continue (Step 3 of 5)"}
            styles={{
              backgroundColor: "rgb(255,131,0)",
              color: "#FFF",
              marginTop: "20px",
              width: "100%",
              borderRadius: "2px",
              padding: "10px 18px",
            }}
            // onClick={() => {
            //     navigate('/upgrade-plan')
            //   }}
            // onButtonClick={() => {
            //   navigate('/upgrade-plan')
            // }}
            onButtonClick={onSubmit}
          />
        </Box>
        {/* </form> */}

        <Box style={{ marginTop: "15px" }}>
          <Typography style={{ fontSize: "14px" }}>
            You might be redirected to your bank's website to authorize
            verification charge.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default SignupStep4Col3;
