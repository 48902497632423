import { Typography } from '@mui/material'
import { Box } from '@mui/system'

import user from '../../assets/img/user.png'
const SignupStep7Col2 = () => {
  return (
    <Box style={{}}>
      <Box>
        <img src={user} />
      </Box>
    </Box>
  )
}
export default SignupStep7Col2
