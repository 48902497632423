import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@material-ui/core/TextField";
import roundClose from "../../assets/img/cancel_icn.png";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import IconButton from "@mui/material/IconButton";
import { Api } from "../../MyNewUtils/Api";
import SubLoader from "../Spinner/subLoader";
const ResetChildpasswordModel = ({
  resetChildpasswordModelOpen,
  setResetChildpasswordModelOpen,
  resetChildpasswordModelValue,
  setResetChildpasswordModelOpenValue,
}) => {
  const [open, setOpen] = useState(resetChildpasswordModelOpen);
  const [newPassword, setNewPassword] = useState();
  const [specialCharacters, setSpecialCharacters] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [lowerCaseLetters, setLowerCaseLetters] = useState(false);
  const [upperCaseLetters, setUpperCaseLetters] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState();
  const [passwordPolicyLoader, setPasswordPolicyLoader] = useState(false);
  const [validate, setValidate] = useState({
    charecterLong: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    special: false,
    isStrong: false,
  });
  const handleClose = () => {
    setResetChildpasswordModelOpen(false);
    setResetChildpasswordModelOpenValue();
  };
  const handleResetChildpassword = async () => {
    let checkValidObj = {};
    let passwordLength = passwordPolicy?.passwordMinimumLength;
    // checkLowerCase
    checkValidObj.lowerCase = /[a-z]/.test(newPassword);

    // checkUpperCase
    checkValidObj.upperCase = /[A-Z]/.test(newPassword);

    // check number
    checkValidObj.number = /[0-9]/.test(newPassword);

    // check special
    checkValidObj.special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      newPassword
    );
    if (newPassword.length >= passwordLength) {
      if (!upperCaseLetters || upperCaseLetters === checkValidObj?.upperCase) {
        if (
          !lowerCaseLetters ||
          lowerCaseLetters === checkValidObj?.lowerCase
        ) {
          if (!numbers || numbers === checkValidObj?.number) {
            if (
              !specialCharacters ||
              specialCharacters === checkValidObj?.special
            ) {
              let final = {
                userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
                childuserid: resetChildpasswordModelValue.id,
                newuserpassword: newPassword,
              };
              const { data } = await Api.postRequest(
                "/ldap/change-child-password",
                final
              );
              const res = JSON.parse(data);

              if (res?.status === 1) {
                handleClose();
                toast.success(res?.message, { autoClose: 1000 });
              } else if (res?.status === 0) {
                toast.error(res?.message, { autoClose: 1000 });
              } else {
                toast.error("validation error", { autoClose: 1000 });
              }
            } else {
              toast.error(
                `Your password must be have at least 1 Special Characters`,
                { autoClose: 1000 }
              );
            }
          } else {
            toast.error(
              `Your password must be have at least 1 Digit Numeric Number`,
              { autoClose: 1000 }
            );
          }
        } else {
          toast.error(
            `Your password must be have at least 1 lowercase Character`,
            { autoClose: 1000 }
          );
        }
      } else {
        toast.error(
          `Your password must be have at least 1 uppercase Character`,
          {
            autoClose: 1000,
          }
        );
      }
    } else {
      toast.error(
        `Your password must be have at least ${passwordLength} Characters Long`,
        { autoClose: 1000 }
      );
    }
  };

  const getPasswordPolicy = async () => {
    setPasswordPolicyLoader(true);
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { data } = await Api.postRequest("/users/get-password-policy", final);
    const res = JSON.parse(data);
    if (res.status == 1) {
      setPasswordPolicy(res?.data);
      let policy = res?.data?.passwordMustCotain;
      setPasswordPolicyLoader(false);
      policy?.map((item, index) => {
        if (item === "UPPERCASE") {
          setUpperCaseLetters(true);
        }
        if (item === "LOWERCASE") {
          setLowerCaseLetters(true);
        }
        if (item === "SPECIALCHARACTERS") {
          setSpecialCharacters(true);
        }
        if (item === "NUMBERS") {
          setNumbers(true);
        }
      });
      // toast.success(res.message, { autoClose: 1000 });
    } else {
      setPasswordPolicyLoader(false);
      toast.error(res.message, { autoClose: 1000 });
    }
  };

  const _handleChangePassword = useCallback(
    (e) => {
      const checkValidObj = {};
      const newVal = e.target.value;
      // check cherecter long
      checkValidObj.charecterLong =
        newVal.length >= passwordPolicy?.passwordMinimumLength;

      // checkLowerCase
      checkValidObj.lowerCase = /[a-z]/.test(newVal);

      // checkUpperCase
      checkValidObj.upperCase = /[A-Z]/.test(newVal);

      // check number
      checkValidObj.number = /[0-9]/.test(newVal);

      // check special
      checkValidObj.special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        newVal
      );

      checkValidObj.isStrong =
        checkValidObj.charecterLong &&
        checkValidObj.lowerCase &&
        checkValidObj.upperCase &&
        checkValidObj.number &&
        checkValidObj.special;
      setValidate(checkValidObj);
      setNewPassword(e.target.value);
    },
    [passwordPolicy]
  );

  useEffect(() => {
    getPasswordPolicy();
  }, []);
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          className="Reset-Child-password-Model-box "
          sx={{
            minWidth: "25%",
            maxHeight: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          <SubLoader isLoading={passwordPolicyLoader} />
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "text",
              padding: "0px 0px 0px 0px",
            }}
          >
            <img
              src={roundClose}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
          <Box>
            <Typography style={{ color: "#FF8800", fontSize: "22px" }}>
              Reset password
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ color: "#fff", marginTop: "20px" }}>
              Name
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                type="text"
                name="fname"
                variant="standard"
                inputProps={{
                  readOnly: true,
                }}
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",

                    readOnly: true,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                defaultValue={resetChildpasswordModelValue?.name}
                fullWidth
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ color: "#fff", marginTop: "20px" }}>
              Email
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                type="text"
                name="fname"
                variant="standard"
                inputProps={{
                  readOnly: true,
                }}
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",

                    readOnly: true,
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                defaultValue={resetChildpasswordModelValue?.email}
                fullWidth
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ color: "#fff", marginTop: "20px" }}>
              New Password
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                type={showPassword ? "text" : "password"}
                name="fname"
                variant="standard"
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                onChange={_handleChangePassword}
                InputProps={{
                  endAdornment: (
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      onMouseDown={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  ),
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                }}
                fullWidth
              />
            </Box>
            {/* <Typography
              style={{
                textAlign: "center",
                marginTop: "5px",
                color: mydata.length < passwordPolicy?.passwordMinimumLength ? "red" : "green",
              }}
            >
              {mydata.length === 0
                ? "Please enter your password"
                : mydata.length < passwordPolicy?.passwordMinimumLength
                ? "it must be passwordPolicy?.passwordMinimumLength charecter"
                : validate.isStrong
                ? "Your new password is Very strong!"
                : "Your new password is good!"}
            </Typography> */}
            <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    style={{ color: validate.charecterLong ? "green" : "red" }}
                  >
                    {passwordPolicy?.passwordMinimumLength} characters long{" "}
                  </Typography>
                </Grid>

                {lowerCaseLetters && (
                  <Grid item xs={6}>
                    <Typography
                      style={{ color: validate.lowerCase ? "green" : "red" }}
                    >
                      1 lowercase [a-z]{" "}
                    </Typography>
                  </Grid>
                )}

                {upperCaseLetters && (
                  <Grid item xs={6}>
                    <Typography
                      style={{ color: validate.upperCase ? "green" : "red" }}
                    >
                      1 uppercase[A-Z]{" "}
                    </Typography>
                  </Grid>
                )}

                {numbers && (
                  <Grid item xs={6}>
                    <Typography
                      style={{ color: validate.number ? "green" : "red" }}
                    >
                      1 number [0-9]{" "}
                    </Typography>
                  </Grid>
                )}

                {specialCharacters && (
                  <Grid item xs={6}>
                    <Typography
                      style={{ color: validate.special ? "green" : "red" }}
                    >
                      1 special character
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Box>
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "40px 0px 20px 0px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{ padding: "8px 40px", borderRadius: "20px" }}
                onClick={handleResetChildpassword}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default ResetChildpasswordModel;
