import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Button } from '@mui/material'
import exportImg from '../../assets/img/export.png'
import logo from '../../assets/img/logo_header.png'
import { useEffect, useState } from 'react'
const SignupStep3Header = ({ select, setSelect }) => {
  const [isMonthly, setIsMonthly] = useState(true)
  useEffect(() => {
    if (isMonthly == true) {
      setSelect(true)
    } else {
      setSelect(false)
    }
  }, [isMonthly])

  return (
    <>
      {' '}
      {/* <Box
        style={{
          width: '100%',
          height: '100px',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <img src={logo} />
      </Box> */}
      <Box>
        <Typography
          style={{
            color: '#FF8800',
            fontSize: '34px',
            fontWeight: '600',
            textAlign: 'center',
            marginTop: '20px',
          }}
        >
          Upgrade the plan
        </Typography>

        <Typography
          style={{ fontSize: '22px', textAlign: 'center', marginTop: '20px' }}
        >
          If you need more info, please check
          <span style={{ color: '#008CF2', opacity: '2', marginLeft: '5px' }}>
            Pricing Guidelines.
          </span>
        </Typography>
      </Box>
      <Box
        style={{ display: 'flex', justifyContent: 'Center', marginTop: '20px' }}
      >
        <Box
          style={{
            padding: '5px',
            borderRadius: '20px',
            backgroundColor: '#01081D',
          }}
        >
          <Button
            style={{
              backgroundColor: isMonthly && 'white',
              borderRadius: '20px',
              width: '100px',
            }}
            onClick={() => setIsMonthly(true)}
          >
            Monthly
          </Button>
          <Button
            style={{
              backgroundColor: !isMonthly && 'white',
              borderRadius: '20px',
              width: '100px',
            }}
            onClick={() => setIsMonthly(false)}
          >
            Yearly
          </Button>
        </Box>
      </Box>
    </>
  )
}
export default SignupStep3Header
