import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import DashBoardSctionPage1Body from "../../Component/selectCloudInfrastructureDetails/index";
import ConfigureCloudMessageModel from "./ConfigureCloudMessageModel";
import "./style.css";
import { useLocation } from "react-router-dom";

const SectionPageOne = ({ ConfigureCloudModel }) => {
  const cls = useMemo(() => "SectionPageOne", []);

  return (
    <Box className={`${cls}__background`} style={{}}>
      {/* <Header/> */}
      <DashboardHeader
        activeTab={"SETTINGS"}
        activeSettingSubTab={"Cloud Setup Wizard"}
      />
      {/* Setting inner header*/}
      {useLocation()?.state?.from ? <ConfigureCloudMessageModel /> : null}

      <DashBoardSctionPage1Body />
    </Box>
  );
};
export default SectionPageOne;
