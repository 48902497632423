import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import visa from "../../../assets/img/Component 105 – 2.png";
import Mastercard from "../../../assets/img/Component 106 – 2.png";
import American from "../../../assets/img/Component 107 – 2.png";
import Discover from "../../../assets/img/Component 108 – 2.png";
import cardcvv from "../../../assets/img/Component 153 – 1.svg";
import ibutton from "../../../assets/img/Component 103 – 2.png";
import alert from "../../../assets/img/triangle_icn@2x.png";
import cancelicon from "../../../assets/img/cancel_round_icn.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import { styled } from "@mui/system";
import CancelModel from "./cancelmodel";
import { Api } from "../../../MyNewUtils/Api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, Fragment } from "react";
import MainLoader from "../../../Component/Spinner/MainLoader";
import SubLoader from "../../../Component/Spinner/subLoader";
const style = {
  position: "absolute",
  margin: "auto",
  width: 689,
  bgcolor: "#01081D",
  border: "1px solid #707070",
  boxShadow: 24,
  p: 4,
  padding: "68px 114px 68px 114px",
};
const stylecancel = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#01081D",
  border: "1px solid #707070",
  boxShadow: 24,
  // p: 4,
  // padding: '68px 114px 68px 114px'
};
const Root = styled("span")`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }
  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }
  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 14px;
      top: 3px;
      background-color: #fff;
    }
    .${switchUnstyledClasses.track} {
      background: #007fff;
    }
  }
  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;
// cancel model
function ChildModal({ setAddCardmodel }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const ChildModalClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setAddCardmodel(false);
  };

  return (
    <Fragment>
      <Button
        onClick={handleOpen}
        variant="outlined"
        style={{ marginLeft: "10px" }}
      >
        Cancel
      </Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ ...stylecancel, width: "50%", padding: "14px 10px 60px 0" }}>
          <Typography style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={cancelicon} width="30px" onClick={ChildModalClose} />
          </Typography>
          <Typography
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Are you sure you would like to cancel?
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={alert} width="60px" />
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                background: "#008CF2",
                borderRadius: "20px",
                color: "#fff",
                marginRight: "27px",
              }}
            >
              Yes, Cancel it !
            </Button>
            <Button
              onClick={ChildModalClose}
              style={{
                background: "#0000",
                borderRadius: "20px",
                color: "#fff",
                border: "solid 1px #008CF2",
              }}
            >
              No, return
            </Button>
          </Typography>
        </Box>
      </Modal>
    </Fragment>
  );
}
const AddCardModel = ({ disable, setAddCardmodel }) => {
  const [lastTwentyYear, setLastTwentyYear] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [subloader, setSubloader] = useState(false);
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setAddCardmodel(false);
  useEffect(() => {
    const year = new Date().getFullYear();
    const years = Array.from(new Array(10), (val, index) => year + index);
    setLastTwentyYear(years);
  }, []);
  const [cardNumber, setCardNumber] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [cardName, setCardName] = useState("");
  const [month, setMonth] = useState("");
  const handleChange = (event) => {
    setMonth(event.target.value);
  };
  const [year, setYear] = useState("");
  const handleYearChange = (event) => {
    setYear(event.target.value);
  };
  const cardnum = (e) => {
    if (e.target.value.length <= 16) {
      setCardNumber(e.target.value);
    }
  };
  const cvv = (e) => {
    if (e.target.value.length <= 3) {
      setCardCVC(e.target.value);
    }
  };
  console.log("lastTwentyYear", lastTwentyYear);
  const addcard = async () => {
    setIsLoading(true);
    setSubloader(true);
    if (
      cardNumber.length != "0" &&
      cardCVC.length != "0" &&
      cardName.length != "0" &&
      month.length != "0" &&
      year.length != "0"
    ) {
      let final = {
        stripeCustomerID: JSON.parse(localStorage.getItem("user")).data
          .stripeCustomerID,
        cardNumber: cardNumber,
        cardExpMonth: month,
        cardExpYear: year,
        cardCVC: cardCVC,
        cardName: cardName,
      };

      console.log("final", final);
      const { statusCode, data } = await Api.postRequest(
        "/users/add-new-card",
        final
      );
      const res = JSON.parse(data);
      console.log("faseyeyh", res);
      // let message = "Card has been saved successfully"
      if (res?.status == 1) {
        toast.success(res.message, { autoClose: 1000 });
        setAddCardmodel(false);
        //     setIsLoading(false)
        //     navigate('/Profile')
      } else {
        setIsLoading(false);
        setSubloader(false);
        toast.error(res.message, { autoClose: 1000 });
      }
    } else {
      setIsLoading(false);
      setSubloader(false);
      toast.error("Fill all fields", { autoClose: 1000 });
    }
    setIsLoading(false);
    setSubloader(false);
  };
  return (
    <div>
      <MainLoader isLoading={isLoading} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontWeight: "bold", fontSize: "26px", color: "#FF8800" }}
          >
            Add New Card
          </Typography>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "30px",
            }}
          >
            Name on Card{" "}
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
            >
              *
            </span>
          </Typography>
          <Typography>
            <input
              type="text"
              style={{
                background: "#010613",
                color: "#fff",
                height: "35px",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
            />
          </Typography>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "30px",
            }}
          >
            Credit or Debit card number{" "}
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
            >
              *
            </span>
          </Typography>
          <Typography>
            <input
              type="number"
              value={cardNumber}
              onChange={cardnum}
              style={{
                background: "#010613",
                color: "#fff",
                height: "35px",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Typography>
          <Typography style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={visa} />
            <img src={Mastercard} />
            <img src={American} />
            <img src={Discover} />
          </Typography>

          <Typography
            style={{ color: "#008CF2", fontWeight: "bold", fontSize: "18px" }}
          >
            Expiration date
          </Typography>
          <Typography>
            {/* ---------------------------------------------month----------------------------------------------------------*/}
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              style={{ border: "1px solid #363636" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: "#707070" }}
              >
                Month
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={month}
                label="Month"
                onChange={handleChange}
                style={{ color: "#fff" }}
              >
                <MenuItem value="1">January</MenuItem>
                <MenuItem value="2">February</MenuItem>
                <MenuItem value="3">March</MenuItem>
                <MenuItem value="4">April</MenuItem>
                <MenuItem value="5">May</MenuItem>
                <MenuItem value="6">June</MenuItem>
                <MenuItem value="7">July</MenuItem>
                <MenuItem value="8">August</MenuItem>
                <MenuItem value="9">September</MenuItem>
                <MenuItem value="10">October</MenuItem>
                <MenuItem value="11">November</MenuItem>
                <MenuItem value="12">December</MenuItem>
              </Select>
            </FormControl>
            {/* ---------------------------------------------Year-----------------------------------------------------------*/}
            <FormControl
              sx={{ m: 1, minWidth: 120 }}
              style={{ border: "1px solid #363636" }}
            >
              <InputLabel
                id="demo-simple-select-label"
                style={{ color: "#707070" }}
              >
                Year
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={year}
                label="year"
                onChange={handleYearChange}
                style={{ color: "#fff", maxHeight: "360px" }}
              >
                {lastTwentyYear?.map((item) => {
                  return <MenuItem value={item}>{item}</MenuItem>;
                })}
              </Select>
            </FormControl>
          </Typography>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "30px",
            }}
          >
            Cardholder's name
          </Typography>
          <Typography>
            <input
              type="text"
              value={cardName}
              onChange={(e) => setCardName(e.target.value)}
              style={{
                background: "#010613",
                color: "#fff",
                height: "35px",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
            />
          </Typography>

          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "30px",
            }}
          >
            cvv{" "}
            <span
              style={{ color: "red", fontWeight: "bold", fontSize: "18px" }}
            >
              *
            </span>
          </Typography>
          <Typography>
            <input
              type="password"
              value={cardCVC}
              onChange={cvv}
              style={{
                background: "#010613",
                color: "#fff",
                height: "35px",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              autoComplete="new-password"
            // onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
            />
          </Typography>
          <Typography style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={cardcvv} />
          </Typography>

          <Typography
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography
              style={{ fontWeight: "bold", fontSize: "18px", color: "#008CF2" }}
            >
              {" "}
              Save Card for further billing?
            </Typography>
            <Typography
              style={{
                display: "flex",
                fontWeight: "normal",
                fontSize: "16px",
                color: "#008CF2",
                alignItems: "center",
              }}
            >
              {" "}
              <SwitchUnstyled component={Root} /> save card
            </Typography>
          </Typography>

          <Typography style={{ display: "flex", alignItems: "center" }}>
            <img src={ibutton} width="20px" height="20px" />
            <Typography
              style={{ fontSize: "14px", fontWeight: "normal", color: "#fff" }}
            >
              We temporarily hold 1 $ as a pending transaction for 3-5 days to
              verify your identity.
            </Typography>
          </Typography>
          <SubLoader isLoading={subloader} />
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "34px",
            }}
          >
            <Button variant="contained" onClick={addcard}>
              Verify
            </Button>
            <ChildModal setAddCardmodel={setAddCardmodel} />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
export default AddCardModel;
