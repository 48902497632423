import AccessAnalysisOne from "../../Component/IAMSecurityAnalysis/index"
import AccessAnalysisTwo from "../../Component/IAMAnalysisFilter/index";
import AccessAnalysisThree from "../../Component/IAMUsers/index";

const ACCESSANALYSIS = () => {
    return (
        <>
            <AccessAnalysisOne />
            {/* <AccessAnalysisTwo />
            <AccessAnalysisThree /> */}
        </>
    );
};
export default ACCESSANALYSIS;
