import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import DashboardSettingRolesBody from "../../Component/Dashboard2/DashboardSettingRolesBody";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import DashBoardSettingHeader from "../../Component/DashboardHeader/DashBoardSettingHeader";
import DashboardSettingPasswordPolicyBody from "../../Component/DashboardSettingPasswordPolicyBody/index";
import Header from "../dashboard/Header/header";
import MainLoader from "../../Component/Spinner/MainLoader";
import "./style.css";
const DashBoardThree = () => {
  const cls = useMemo(() => "DahboardThree", []);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box className={`${cls}__background`} style={{}}>
      <MainLoader isLoading={isLoading} />

      {/* <Header/> */}
      <DashboardHeader
        activeTab={"SETTINGS"}
        activeSettingSubTab={"Password Policy"}
      />
      {/* Setting inner header*/}

      <DashBoardSettingHeader selectItem={"Password Policy"} />
      <DashboardSettingPasswordPolicyBody setIsLoading={setIsLoading} />
    </Box>
  );
};
export default DashBoardThree;
