import { FETCH_CHART_DATA, RESET_CHART_DATA, SET_CHART_DATA, SET_CHART_LOADING } from "../constants/chartsActionType";

const initialState = {
	charts: {}
};

export const chartsReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_CHART_DATA:
			return {
				...state,
				charts: {
					...state.charts,
					[action.payload.chartId]: {
						...state.charts[action.payload.chartId],
						loading: true,
						filters: action.payload.filters
					}
				}
			};
		case SET_CHART_DATA:
			return {
				...state,
				charts: {
					...state.charts,
					[action.payload.chartId]: {
						...state.charts[action.payload.chartId],
						data: action.payload.data,
						loading: false
					}
				}
			};
		case SET_CHART_LOADING:
			return {
				...state,
				charts: {
					...state.charts,
					[action.payload.chartId]: {
						...state.charts[action.payload.chartId],
						loading: action.payload.isLoading
					}
				}
			};
		case RESET_CHART_DATA:
				return {
					...state,
					charts: {
						...state.charts,
						[action.payload.chartId]: {
							loading: false
						}
					}
				};
		default:
			return state;
	}
};

