import * as React from "react";
import { useContext } from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import morebtnicn from "../../../assets/img/more_btn_icn.png";
import cancelicn from "../../../assets/img/cancel_round_icn.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import ReactLoading from "react-loading";
import { Api } from "../../../MyNewUtils/Api";
import { useEffect, useState, useRef } from "react";
import Highcharts from "highcharts";
import Grid from "@mui/material/Grid";
import "./iamMap.css";
import ChartTable from "./chartTable";
import MainLoader from "../../../Component/Spinner/MainLoader";
import {
  exportComponentAsJPEG,
  exportComponentAsPNG,
} from "react-component-export-image";
import moment from "moment";

// import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import { SketchPicker } from "react-color";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Tooltip from "@mui/material/Tooltip";
import { DateRangePicker } from "mui-daterange-picker";
import { useSelector, useDispatch } from "react-redux";
import EditModal from "./components/EditModal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
  upadateDashboardTabData,
  activateTabIndex,
} from "../../../redux/action";
import MyContext from "../../MyContext/MyContext";
import ReplayIcon from "@mui/icons-material/Replay";
import DoneData from "./Charts";
require("highcharts/modules/exporting")(Highcharts);

const AnalysisDashboard = ({
  setRefresh,
  dashid,
  doneEditing,
  setDoneEditing,
  index,
  allDhashboardTabData,
  setAllDhashboardTabData,
}) => {
  const [open, setOpen] = React.useState(false);
  const [reloadCharts, setReloadCharts] = useState(false);
  const handleClose = () => setOpen(false);
  const [data, setData] = useState([]);
  const [loder, setLoder] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLiveDasBoardLoading, setIsLiveDasBoardLoading] = useState(false);
  const [doneData, setDoneData] = useState([]);
  const [showData, setShowData] = useState([]);

  const [value, setValue] = useState([null, null]);
  const [StartDate, setStartDate] = useState([new Date(), new Date()]);
  const [color, setColor] = useState("#fff");
  const [downloadTitleColor, setDownloadTitleColor] = useState("#fff");
  const [downloadTextColor, setDownloadTextColor] = useState("#fff");
  const [downloadTableTitleColor, setDownloadTableTitleColor] =
    useState("#fff");
  const [anchorElManu, setAnchorElManu] = useState(null);
  const [anchorElDeleteManu, setAnchorElDeleteManu] = useState(null);
  const [anchorElFilterManu, setAnchorElFilterManu] = useState(null);
  const [selectColorChang, setSelectColorChang] = useState();
  const [refreshDoneEditing, setRefreshDoneEditing] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [downloadTablesValue, setDownloadTablesValue] = useState("JPEG");
  const [reloadModel, setReloadModel] = useState(false);
  const handleChangeDownloadTablesValue = (event) => {
    setDownloadTablesValue(event.target.value);
  };
  const dispatch = useDispatch();
  const reduxTabData = useSelector((state) => state.reducer).data;
  const { cloudDetails } = useContext(MyContext);

  const API_URI_MAPPER = {
    "never-active-users": "never-active-users",
    "iam-inactive-users-since-x-days": "iam-inactive-users-since-x-days",
    "console-login-users": "console-login-users",
    "failed-login-attempt-statistics": "failed-login-attempt-statistics",
    "region-wise-count-users": "region-wise-user-count",
    "create-group-statistics": "create-group-logs",
    "delete-group-statistics": "delete-group-logs",
    "update-group-statistics": "update-group-logs",
    "create-role-statistics": "create-role-logs",
    "delete-role-statistics": "delete-role-logs",
    "update-role-statistics": "update-role-logs",
    "user-using-which-services": "user-using-which-services",
    "user-attached-policies": "user-attached-policies",
  }

  useEffect(() => {

    const fetchData = async () => {
      let body = {
        account_type: cloudDetails?.selectedCloudServers,
        accountId: cloudDetails?.selectedCloudAccountID,
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        dashid: dashid,
      };

      const res = await Api.axiospostRequest("/dashboard/get-dashboard-features", body);

      if (res?.status === 1) {
        const data = res.data.map((item) => {
          return {
            "Title": item.featureName,
            "isFilterAllow": JSON.parse(item.accountResponseType)?.length > 1,
            "data": [],
            "accountResponseType": JSON.parse(item.accountResponseType),
            "featureKey": item.featureKey,
            "uri": `/test/${API_URI_MAPPER[item.featureKey]}`
          }
        });

        setShowData(data);
      }
    };

    fetchData();
  }, [])

  const ChartData = async () => {
    setIsLoading(true);
    if (doneEditing === true) {
      let param = {
        account_type: cloudDetails?.selectedCloudServers,
        accountId: cloudDetails?.selectedCloudAccountID,
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        dashid: dashid,
      };
      if (dashid != undefined) {
        const data = await Api.postRequest(
          "/dashboard/live-dashboard-data-by-id",
          param
        );
        const res = JSON.parse(data?.data);
        if (res?.status === 1) {
          setData(res?.data);
          setIsLoading(false);
          let fecharData = allDhashboardTabData;
          fecharData[index] = res?.data;
          setAllDhashboardTabData(fecharData);
        } else if (
          res?.status === 0 &&
          res?.message === "Editing dashboard is pending"
        ) {
          setIsLoading(false);
          getDashboardById();
          setDoneEditing(false);
        } else {
          // toast.error(res?.message, { autoClose: 1000 });
          setIsLoading(false);
          // setIsLoading(true)
        }
      }
    } else {
      setIsLoading(true);

      let param = {
        account_type: cloudDetails?.selectedCloudServers,
        accountId: cloudDetails?.selectedCloudAccountID,
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        dashid: dashid,
      };

      const data = await Api.postRequest(
        "/dashboard/get-dashboard-data-by-id",
        param
      );
      const res = JSON.parse(data?.data);
      if (res?.status === 1) {
        setData(res?.data);
        setIsLoading(false);
      } else {
        toast.error(res?.message, { autoClose: 1000 });
        setIsLoading(false);
        // setIsLoading(true)
      }
    }
  };

  const getDashboardById = async () => {
    setIsLoading(true);
    let param = {
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      dashid: dashid,
    };

    const data = await Api.postRequest(
      "/dashboard/get-dashboard-data-by-id",
      param
    );
    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      setData(res?.data);
      setIsLoading(false);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setIsLoading(false);
      // setIsLoading(true)
    }
  };

  useEffect(() => {
    if (
      cloudDetails?.selectedCloudServers &&
      cloudDetails?.selectedCloudAccountID.length !== 0 &&
      cloudDetails?.selectedCloudAccountID.length !== undefined
    ) {
      let fecharData = allDhashboardTabData[index];
      if (!fecharData) {
        // ChartData();
      } else {
        setData(fecharData);
      }
    }
  }, [
    refreshDoneEditing,
    cloudDetails?.selectedCloudServers,
    cloudDetails?.selectedCloudAccountID,
  ]);

  useEffect(() => {
    let tableDoneData = [];
    let CloseData = [];
    data?.map((item, index) => {
      let title = Object.keys(item?.data)[0];
      tableDoneData[index] = {
        ...tableDoneData[index],
        Title: item.Title,
        isFilterAllow: item.isFilterAllow,
        data: item?.data[title],
        accountResponseType: title ? title : "table",
        featureKey: item?.featureKey,
        request_data: item.request_data,
      };
      CloseData[index] = {
        ...CloseData[index],
        Title: item.Title,
        isFilterAllow: item.isFilterAllow,
        data: item?.data,
        accountResponseType: item.accountResponseType
          ? item.accountResponseType
          : "table",
        show: true,
        featureKey: item?.featureKey,
        request_data: item.request_data,
      };
    });
    // setDoneData(tableDoneData);
    // setShowData(CloseData);
  }, [data]);

  const getLiveDashboardData = async () => {
    setIsLoading(true);
    setIsLiveDasBoardLoading(true);
    if (dashid != undefined) {
      let param = {
        account_type: cloudDetails?.selectedCloudServers,
        accountId: cloudDetails?.selectedCloudAccountID,
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        dashid: dashid,
      };
      const data = await Api.postRequest(
        "/dashboard/live-dashboard-data-by-id",
        param
      );
      const res = JSON.parse(data?.data);
      if (res?.status === 1) {
        setData(res?.data);
        setIsLoading(false);
        setIsLiveDasBoardLoading(false);
      } else {
        setIsLoading(false);
        setIsLiveDasBoardLoading(false);
      }
    }
  };

  const done = async () => {
    setIsLoading(true);
    let types = [];
    let count = 0;
    showData?.map((item, index) => {
      // console.log("item", doneData[index]);
      if (item.show === true) {
        types[count] = {
          ...types[count],
          chartType: doneData[index].accountResponseType,
          dashboadFeatureKey: doneData[index]?.featureKey,
          from_date: doneData[index]?.from_date?.split("T")[0] ?? "",
          to_date: doneData[index]?.to_date?.split("T")[0] ?? "",
        };
        count = count + 1;
      }
    });
    let final = {
      dashid: dashid,
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      data: JSON.stringify(types),
      isDoneEditingFinished: true,
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
    };
    const { statusCode, data } = await Api.postRequest(
      "/dashboard/finish-editing-dashboard",
      final
    );
    const res = JSON.parse(data);
    if (res.status === 1) {
      toast.success(res.message, { autoClose: 1000 });

      setDoneEditing(true);
      setData();
      // getLiveDashboardData();
      // setRefreshDoneEditing(!refreshDoneEditing);
      setIsLoading(false);
    } else {
      toast.error(res.message, { autoClose: 1000 });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const deletedashboard = async () => {
    handleClickCloseDeletManu();
    handleCloseManu();
    setIsLoading(true);
    let param = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      dashid: dashid,
    };
    if (dashid != undefined) {
      const data = await Api.postRequest("/dashboard/delete-dashboard", param);
      const res = JSON.parse(data?.data);
      if (res?.status === 1) {
        toast.success(res?.message);
        setRefresh(true);
        setIsLoading(false);

        const DeleteTableData = reduxTabData.filter(function (item) {
          return item.dashid !== dashid;
        });
        dispatch(upadateDashboardTabData(DeleteTableData));
        let tabData = DeleteTableData;
        let lastTabShowIndex = 0;

        tabData.map((item, index) => {
          if (item.show) {
            lastTabShowIndex = index;
          }
        });
        if (tabData.length > 8) {
          let fristShowTab = 0;
          let fristShow = true;
          tabData.map((item, index) => {
            if (item.show && fristShow) {
              fristShowTab = index;
              fristShow = false;
            }
          });
          tabData[fristShowTab - 1] = {
            ...tabData[fristShowTab - 1],
            show: true,
          };
          dispatch(upadateDashboardTabData(tabData));
        }

        dispatch(activateTabIndex(lastTabShowIndex));
      } else {
        toast.error(res?.message);
        setIsLoading(false);
      }
    }
  };

  const onChangeDate = (date) => {
    setStartDate(date);
  };

  const openManu = anchorElManu;
  const openEditDeletManu = anchorElDeleteManu;
  const openFilterManu = anchorElFilterManu;

  const handleClickOpenManu = (event) => {
    setAnchorElManu(event.currentTarget);
  };

  const handleCloseManu = () => {
    setAnchorElManu(null);
  };

  const handleOpen = () => {
    setOpen(true);
    handleCloseManu();
  };

  const handleEdit = () => {
    setEditModalOpen(true);
    handleCloseManu();
  };

  const handleClickOpenFilterManu = (event) => {
    setAnchorElFilterManu(!openFilterManu);
  };

  const handleClickOpenDeletManu = (event) => {
    setAnchorElDeleteManu(event.currentTarget);
  };
  const handleClickCloseDeletManu = (event) => {
    setAnchorElDeleteManu(null);
  };

  const handleDateRange = async (range) => {
    handleClickOpenFilterManu();
    setValue(range);
    setIsLoading(true);
    // if (doneEditing === true) {
    let param = {
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      dashid: dashid,
      from_date: moment(range.startDate).format("YYYY-MM-DD"),
      to_date: moment(range.endDate).format("YYYY-MM-DD"),
    };
    if (dashid != undefined) {
      const data = await Api.postRequest(
        "/dashboard/live-dashboard-data-by-id",
        param
      );
      const res = JSON.parse(data?.data);
      if (res?.status === 1) {
        setData(res?.data);
        let fecharData = allDhashboardTabData;
        fecharData[index] = res?.data;
        setAllDhashboardTabData(fecharData);
        setIsLoading(false);
      } else {
        // toast.error(res?.message, { autoClose: 1000 });
        setIsLoading(false);
        // setIsLoading(true)
      }
    }
    // }
  };

  const exportRef = useRef();
  // useEffect(() => {
  //     if (value[0] && value[1]) {
  //         onShowDateByDate()
  //     }
  // }, [value])

  // useEffect(() => {
  //   setTimeout(() => {
  //     setReloadModel(true);
  //   }, 300000);
  // }, [reloadModel]);

  return (
    <>
      <MainLoader isLoading={isLoading || isLiveDasBoardLoading} />
      {/* <MainLoader isLoading={true} /> */}
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography
            style={{
              color: "#FF8800",
              fontSize: "36px",
              fontWeight: "bold",
              marginLeft: "2%",
            }}
          >
            IAM Analysis
          </Typography>

          {/* {reloadModel && (
            <Box
              className="custom_toast"
              onClick={() => {
                ChartData();
                setReloadModel(false);
              }}
            >
              <Box className="replay_icon">
                <ReplayIcon />
              </Box>
              <Box className="custom_toast_massage_part">
                <Box className="custom_toast_massage">
                  The Configuration changed.
                </Box>
                <Box className="reload_btn">Reload</Box>
              </Box>
            </Box>
          )} */}

          <Box
            sx={
              doneEditing
                ? { display: "none" }
                : { display: "flex", alignItems: "center" }
            }
          >
            <Button
              style={{
                marginLeft: "16px",
                fontWeight: "normal",
                fontSize: "20px",
                background: "#008CF2",
                color: "#fff",
                borderRadius: "20px",
                padding: "8px 20px 5px 20px",
              }}
              onClick={() => {
                done();
              }}
            >
              Done Editing
            </Button>
            <Tooltip title="Action" placement="top">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openEditDeletManu ? "long-menu" : undefined}
                aria-expanded={openEditDeletManu ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClickOpenDeletManu}
              >
                <MoreVertIcon sx={{ fill: "#fff" }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorElDeleteManu}
              open={openEditDeletManu}
              onClose={handleClickCloseDeletManu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={deletedashboard}>
                <Typography sx={{ color: "#000" }}>
                  <DeleteIcon
                    style={{
                      cursor: "pointer",
                      fill: "#000",
                      marginRight: "5px",
                    }}
                  />
                  Delete
                </Typography>
              </MenuItem>
            </Menu>
          </Box>

          <Box
            sx={
              doneEditing
                ? { display: "flex", alignItems: "center" }
                : { display: "none" }
            }
          >
            {/* --------------------------  Reload Button --------------------------*/}
            <Box>
              <Tooltip title="Reload" placement="top">
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openFilterManu ? "long-menu" : undefined}
                  aria-expanded={openFilterManu ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={() => setReloadCharts(true)}
                >
                  <ReplayIcon sx={{ fill: "#fff" }} />
                </IconButton>
              </Tooltip>
            </Box>

            {/* --------------------------  Features Filter --------------------------*/}
            <Box>
              <Tooltip title="Filter" placement="top">
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={openFilterManu ? "long-menu" : undefined}
                  aria-expanded={openFilterManu ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClickOpenFilterManu}
                >
                  <FilterAltIcon sx={{ fill: "#fff" }} />
                </IconButton>
              </Tooltip>
              <Box sx={{ position: "absolute", right: "0px" }}>
                <DateRangePicker
                  open={openFilterManu}
                  toggle={handleClickOpenFilterManu}
                  onChange={(range) => handleDateRange(range)}
                />
              </Box>
            </Box>

            <Tooltip title="Action" placement="top">
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openManu ? "long-menu" : undefined}
                aria-expanded={openManu ? "true" : undefined}
                aria-haspopup="true"
                onClick={handleClickOpenManu}
              >
                <MoreVertIcon sx={{ fill: "#fff" }} />
              </IconButton>
            </Tooltip>
            <Menu
              id="basic-menu"
              anchorEl={anchorElManu}
              open={openManu}
              onClose={handleCloseManu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
              PaperProps={{
                style: {
                  background: "#1A2B56",
                  color: "#fff",
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#0000",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "#ADD8E6",
                    borderRadius: 2,
                    border: "0px solid #0000",
                  },
                },
              }}
            >
              <MenuItem onClick={deletedashboard}>
                <Typography sx={{ color: "#fff" }}>
                  <DeleteIcon
                    style={{
                      cursor: "pointer",
                      fill: "#fff",
                      marginRight: "5px",
                    }}
                  />
                  Delete
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleOpen}>
                <Typography sx={{ color: "#fff" }}>
                  <DownloadIcon
                    style={{
                      cursor: "pointer",
                      fill: "#fff",
                      marginRight: "5px",
                    }}
                  />
                  Download
                </Typography>
              </MenuItem>
              <MenuItem onClick={handleEdit}>
                <Typography sx={{ color: "#fff" }}>
                  <EditIcon
                    style={{
                      cursor: "pointer",
                      fill: "#fff",
                      marginRight: "5px",
                    }}
                  />
                  Edit
                </Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: "25px",
            borderRadius: "20px",
          }}
        >
          <Grid container spacing={2}>
            {/* {console.log("------------showData-------------------", showData, doneEditing)} */}
            {showData.map((item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={item.show === false ? { display: "none" } : null}
                >
                  {doneEditing ? (
                    <DoneData
                      isPreview={true}
                      titleColor={"#fff"}
                      textColor={"#fff"}
                      tabletitleColor={"#fff"}
                      itemData={item}
                      setShowData={setShowData}
                      showData={showData}
                      reloadCharts={reloadCharts}
                      setReloadCharts={setReloadCharts}
                    />
                  ) : (
                    <ChartTable
                      item={item}
                      doneData={doneData}
                      Num={index}
                      setDoneData={setDoneData}
                      allData={data}
                      setShowData={setShowData}
                      showData={showData}
                      downloadBtn={true}
                      EditModel={false}
                    />
                  )}
                </Grid>
              );
            })}

            <Grid item xs={4} md={4} sx={loder ? null : { display: "none" }}>
              <Box
                sx={{
                  marginLeft: "50px",
                  padding: "10px",
                  borderRadius: "20px",
                  width: "100%",
                  height: "500px",
                  background: "rgba(0 ,0 ,0 ,0.3)",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box style={{ display: "flex", paddingLeft: "90%" }}>
                  <img
                    src={cancelicn}
                    style={{ width: "25px", height: "25px" }}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    padding: "3%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "26px",
                      fontWeight: "bold",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    User vs Services{" "}
                    <SettingsIcon style={{ marginLeft: "20px" }} />{" "}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <FilterAltIcon
                      sx={{ width: "15px", height: "15px", marginLeft: "5px" }}
                    />
                    <img
                      src={morebtnicn}
                      style={{ height: "15px", marginLeft: "5px" }}
                      alt=""
                    />
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <ReactLoading type="spokes" color="#fff" />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* -------------------Done Features Edit Model ------------------ */}
      {editModalOpen && (
        <EditModal
          isOpen={editModalOpen}
          handleClose={() => setEditModalOpen(false)}
          handleReloadPage={() => setRefreshDoneEditing(!refreshDoneEditing)}
          dashid={dashid}
          ChartData={ChartData}
        />
      )}

      {/* -------------------Preview Dashboard  Model ------------------ */}
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            background: "#010A26",
            padding: "20px",
            width: "90%",
            borderRadius: "20px",
            maxHeight: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ color: "#fff", fontSize: "1.5rem" }}
            >
              Preview Dashboard
            </Typography>
            <Box
              onClick={handleClose}
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#000",
                fontSize: "1.5rem",
                background: "#fff",
                borderRadius: "100%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <CloseIcon style={{ fill: "#000" }} />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "79%",
                marginTop: "25px",
                background: color,
                borderRadius: "20px",
              }}
              ref={exportRef}
            >
              <Grid container spacing={2}>

                {showData.map((item, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      md={6}
                      style={{
                        padding: "50px",
                        display: item.show === false ? "none" : null,
                      }}
                    >
                      <DoneData
                        isPreview={false}
                        titleColor={downloadTitleColor}
                        textColor={downloadTextColor}
                        tabletitleColor={downloadTableTitleColor}
                        itemData={item}
                      />
                    </Grid>
                  );
                })}
              </Grid>
              <Grid item xs={4} md={4} sx={loder ? null : { display: "none" }}>
                <Box
                  sx={{
                    marginLeft: "50px",
                    padding: "10px",
                    borderRadius: "20px",
                    width: "100%",
                    height: "500px",
                    background: "rgba(0 ,0 ,0 ,0.3)",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box style={{ display: "flex", paddingLeft: "90%" }}>
                    <img
                      src={cancelicn}
                      style={{ width: "25px", height: "25px" }}
                      alt=""
                    />
                  </Box>
                  <Box
                    sx={{
                      padding: "3%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "26px",
                        fontWeight: "bold",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      User vs Services{" "}
                      <SettingsIcon style={{ marginLeft: "20px" }} />{" "}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FilterAltIcon
                        sx={{
                          width: "15px",
                          height: "15px",
                          marginLeft: "5px",
                        }}
                      />
                      <img
                        src={morebtnicn}
                        style={{ height: "15px", marginLeft: "5px" }}
                        alt=""
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ReactLoading type="spokes" color="#fff" />
                  </Box>
                </Box>
              </Grid>
            </Box>

            <Box sx={{ position: "relative", width: "20%" }}>
              <Box
                sx={{
                  position: "sticky",
                  top: "0",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "column",
                  justifyContent: "space-around",
                  width: "100%",
                  marginTop: "25px",
                  background: "#00000054",
                  borderRadius: "20px",
                  alignItems: "center",
                  padding: "10px 10px 20px 10px",
                }}
              >
                <Box
                  sx={{
                    color: "#fff",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "800",
                    fontSize: " 20px",
                  }}
                >
                  Color Selection
                </Box>

                <Box
                  sx={{
                    width: "94%",
                    marginTop: "20px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box className="preview_model_back_col_title">
                    Background Color
                  </Box>
                  <Box sx={{ width: "30%" }}>
                    <Box
                      className="background_color_design_box"
                      onClick={() =>
                        setSelectColorChang("Change Background Color")
                      }
                    >
                      <Box
                        style={{
                          background: color,
                        }}
                        className="color_inside_box"
                      ></Box>
                    </Box>
                  </Box>
                  {selectColorChang === "Change Background Color" ? (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "0",
                        top: "50px",
                        zIndex: "1",
                        background: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        borderRadius: "15px",
                      }}
                    >
                      {/* <CloseIcon sx={{ cursor: 'pointer', fill: '#fff', background: '#00000047', borderRadius: '100%', fontSize: '19px' }} onClick={() => { setSelectColorChang() }} /> */}
                      <SketchPicker
                        color={color}
                        onChange={(color) => {
                          setColor(color.hex);
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{ marginTop: "10px" }}
                        onClick={() => {
                          setSelectColorChang();
                        }}
                      >
                        ok
                      </Button>
                    </Box>
                  ) : null}
                </Box>

                <Box
                  sx={{
                    width: "94%",
                    marginTop: "20px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box className="preview_model_back_col_title">
                    Table Heading Color
                  </Box>
                  {/* <FormControl sx={{ width: '30%' }}>
                                        <OutlinedInput placeholder="Please enter text" value={downloadTitleColor} />
                                    </FormControl> */}
                  <Box sx={{ width: "30%" }}>
                    <Box
                      className="background_color_design_box"
                      onClick={() =>
                        setSelectColorChang("Change Table Heading Color")
                      }
                    >
                      <Box
                        style={{
                          background: downloadTitleColor,
                        }}
                        className="color_inside_box"
                      ></Box>
                    </Box>
                  </Box>
                  {selectColorChang === "Change Table Heading Color" ? (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "0",
                        top: "50px",
                        zIndex: "1",
                        background: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        borderRadius: "15px",
                      }}
                    >
                      {/* <CloseIcon sx={{ cursor: 'pointer', fill: '#fff', background: '#00000047', borderRadius: '100%', fontSize: '19px' }} onClick={() => { setSelectColorChang() }} /> */}
                      <SketchPicker
                        color={downloadTitleColor}
                        onChange={(color) => {
                          setDownloadTitleColor(color.hex);
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{ marginTop: "10px" }}
                        onClick={() => {
                          setSelectColorChang();
                        }}
                      >
                        ok
                      </Button>
                    </Box>
                  ) : null}
                </Box>

                <Box
                  sx={{
                    width: "94%",
                    marginTop: "20px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box className="preview_model_back_col_title">
                    Table Title Color{" "}
                  </Box>
                  {/* <FormControl sx={{ width: '30%' }}>
                                        <OutlinedInput placeholder="Please enter text" value={downloadTableTitleColor} />
                                    </FormControl> */}
                  <Box sx={{ width: "30%" }}>
                    <Box
                      className="background_color_design_box"
                      onClick={() =>
                        setSelectColorChang("Change Table Title Color")
                      }
                    >
                      <Box
                        style={{
                          background: downloadTableTitleColor,
                        }}
                        className="color_inside_box"
                      ></Box>
                    </Box>
                  </Box>
                  {selectColorChang === "Change Table Title Color" ? (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "0",
                        top: "-120px",
                        zIndex: "1",
                        background: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        borderRadius: "15px",
                      }}
                    >
                      {/* <CloseIcon sx={{ cursor: 'pointer', fill: '#fff', background: '#00000047', borderRadius: '100%', fontSize: '19px' }} onClick={() => { setSelectColorChang() }} /> */}
                      <SketchPicker
                        color={downloadTableTitleColor}
                        onChange={(color) => {
                          setDownloadTableTitleColor(color.hex);
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{ marginTop: "10px" }}
                        onClick={() => {
                          setSelectColorChang();
                        }}
                      >
                        ok
                      </Button>
                    </Box>
                  ) : null}
                </Box>

                <Box
                  sx={{
                    width: "94%",
                    marginTop: "20px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box className="preview_model_back_col_title">
                    Table Text Color{" "}
                  </Box>
                  {/* <FormControl sx={{ width: '30%' }}>
                                        <OutlinedInput placeholder="Please enter text" value={downloadTextColor} />
                                    </FormControl> */}
                  <Box sx={{ width: "30%" }}>
                    <Box
                      className="background_color_design_box"
                      onClick={() =>
                        setSelectColorChang("Change Table Text Color")
                      }
                    >
                      <Box
                        style={{
                          background: downloadTextColor,
                        }}
                        className="color_inside_box"
                      ></Box>
                    </Box>
                  </Box>
                  {selectColorChang === "Change Table Text Color" ? (
                    <Box
                      sx={{
                        position: "absolute",
                        right: "0",
                        top: "-135px",
                        zIndex: "1",
                        background: "#fff",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        borderRadius: "15px",
                      }}
                    >
                      {/* <CloseIcon sx={{ cursor: 'pointer', fill: '#fff', background: '#00000047', borderRadius: '100%', fontSize: '19px' }} onClick={() => { setSelectColorChang() }} /> */}
                      <SketchPicker
                        color={downloadTextColor}
                        onChange={(color) => {
                          setDownloadTextColor(color.hex);
                        }}
                      />
                      <Button
                        variant="contained"
                        sx={{ marginTop: "10px" }}
                        onClick={() => {
                          setSelectColorChang();
                        }}
                      >
                        ok
                      </Button>
                    </Box>
                  ) : null}
                </Box>
                <FormControl
                  sx={{
                    width: "100%",
                    padding: "0px 10px ",
                    marginTop: "15px",
                  }}
                >
                  <Box className="preview_model_back_col_title">
                    Select Download File Type{" "}
                  </Box>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={downloadTablesValue}
                    onChange={handleChangeDownloadTablesValue}
                  >
                    <FormControlLabel
                      value="JPEG"
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#fff",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ color: "#fff" }}>JPEG</Typography>
                      }
                    />
                    <FormControlLabel
                      value="PNG"
                      control={
                        <Radio
                          sx={{
                            "&, &.Mui-checked": {
                              color: "#fff",
                            },
                          }}
                        />
                      }
                      label={
                        <Typography sx={{ color: "#fff" }}>PNG</Typography>
                      }
                    />
                  </RadioGroup>
                </FormControl>
                <Box
                  sx={{
                    background: "#1976D2",
                    width: "95%",
                    margin: "4px 11px 0 11px",
                    display: "flex",
                    alignContent: "center",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "25px",
                    cursor: "pointer",
                    marginTop: "18px",
                  }}
                  onClick={() => {
                    downloadTablesValue === "JPEG"
                      ? exportComponentAsJPEG(exportRef, {
                        html2CanvasOptions: { backgroundColor: color },
                      })
                      : exportComponentAsPNG(exportRef, {
                        html2CanvasOptions: { backgroundColor: color },
                      });
                  }}
                >
                  <DownloadIcon className="preview_model_download_icon" />
                  <Typography className="prev_model_download">
                    Download
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
export default AnalysisDashboard;
