import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import CustomButton from "../CustomButton/index";
import exportImg from '../../assets/img/export.png'
import { conutryList } from '../../utils/country'
import securityCode from '../../assets/img/securityCode.png'
import master from '../../assets/img/master.png'
import express from '../../assets/img/express.png'
import discovry from '../../assets/img/dicovery.png'
import { useContext, useState } from 'react'
import myContext from '../../screens/MyContext/MyContext'
import { useNavigate } from 'react-router-dom'
import { path } from '../../MyNewUtils/screenPath'
import { Api } from '../../MyNewUtils/Api'
import { toast } from 'react-toastify'
import MainLoader from '../Spinner/MainLoader'
const SignupStep8Col3 = ({ MyData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [phoneNumberError, setPhoneNumberError] = useState(false)
  const [country, setCountry] = useState(conutryList)
  const { userId, myFunc } = useContext(myContext);
  const userData = JSON.parse(localStorage.getItem("userData"));
  const countryno = country?.filter(row => row?.name === userData?.country);
  console.log('countryno', countryno)
  const [signupData, setSignupData] = useState({
    id: JSON.parse(localStorage.getItem('user')).data.id,
    countryCode: countryno[0]?.dial_code,
    phone: userData?.phone,
  })

  const onountryCodeSet = (e) => {
    setSignupData({ ...signupData, countryCode: e.target.value })
  }
  const onPhoneSet = (e) => {
    setSignupData({ ...signupData, phone: e.target.value })
  }
  const Back = () => {
    navigate(path.step3)
  }
  const onSubmit = async (e) => {
    if (signupData.phone != null && signupData.phone.length >= 8) {
      setPhoneNumberError(false)
      setIsLoading(true)
      let params = {
        countryCode: signupData.countryCode,
        phone: signupData.phone
      }
      let final = { id: signupData.id, step: '4', data: JSON.stringify(params) }
      const { statusCode, data } = await Api.postRequest('/users/user-account-setup', final)
      const res = JSON.parse(data)
      if (data) {
        setIsLoading(false)
        if (res?.status === 1) {
          let localData = JSON.parse(localStorage.getItem("userData"));
          localData = res["data"]["userdata"] ? res["data"]["userdata"] : localData;
          localStorage.setItem("userData", JSON.stringify(localData))
          toast.success(res.message, { autoClose: 1000 })
          navigate(path.step5)
        } else {
          toast.error(res.message, { autoClose: 1000 })
        }

      } else {
        setIsLoading(false)
        toast.error("Some error occured", { autoClose: 1000 })
      }
      setIsLoading(false)

    } else {
      setPhoneNumberError(true)
    }
  }

  const navigate = useNavigate()
  return (
    <Box
      style={{
        width: '85%',
        padding: '20px 50px',
        border: '1px solid gray',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '30px',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <MainLoader isLoading={isLoading} />
      <Box style={{}}>
        <Box>
          <Typography style={{ color: '#FF8800', fontSize: '2rem' }}>
            Sign up for Clevrone
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: '22px', marginTop: '18px' }}>
            Confirm your identity
          </Typography>
        </Box>
        <Box>
          <Typography style={{ fontSize: '14px' }}>
            Before you can use your CLEVRONE Account, you must verify your phone
            number. When you continue, the CLEVRONE automated system will
            contact you with a verification code.
          </Typography>
          <Typography style={{ fontSize: '14px', marginTop: '20px' }}>
            {' '}
            How should we send you the verification code?
          </Typography>
          <Box>
            <FormControl component="fieldset">
              <RadioGroup aria-label="type" defaultValue="Text Message (SMS)" name="radio-buttons-group">
                <FormControlLabel
                  value="Text Message (SMS)"
                  control={<Radio style={{ color: '#008fff' }} />}
                  label="Text Message (SMS)"
                />
                {/* <FormControlLabel
                  value="Voice call"
                  control={<Radio style={{ color: '#008fff' }} />}
                  label="Voice call"
                /> */}
              </RadioGroup>
            </FormControl>
          </Box>
          <Box>
            <lable
              style={{
                display: 'block',
                fontSize: '18px',
                color: '#008CF2',
              }}
            >
              Country or region code
            </lable>
            <select
              style={{
                width: '100%',
                backgroundColor: 'black',
                border: '1px solid #363636',
                padding: '4px',
                color: '#FFF',
              }}
              defaultValue={signupData?.countryCode}
              onChange={onountryCodeSet}
            >
              {

                country.map((item) => (
                  <option value={item.dial_code}>{`${item.name}  (${item.dial_code})`}</option>
                ))
              }
            </select>
            {/* <input
          style={{
            width: '100%',
            backgroundColor: 'black',
            border: '1px solid #363636',
            padding: '4px',
          }}
        /> */}
          </Box>

          <Box style={{ marginTop: '10px' }}>
            <lable
              style={{
                display: 'block',
                fontSize: '18px',
                color: '#008CF2',
              }}
            >
              Moblie Number
            </lable>
            <input
              type="number"
              value={signupData?.phone}
              style={{
                width: '100%',
                backgroundColor: 'black',
                border: '1px solid #363636',
                padding: '4px',
                color: '#FFF',

              }}
              onChange={onPhoneSet}
              onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}

            />
            <Typography style={phoneNumberError == false ? { display: 'none' } : { color: 'red', fontSize: '10px' }}>Enter valid phone number</Typography>
          </Box>

          {/* <Box style={{marginTop: '10px'}}>
            <lable
              style={{
                display: 'block',
                fontSize: '18px',
                color: '#008CF2',
              }}
            >
              Security check
            </lable>
            <Box>
              <img src={securityCode} style={{width: '100%'}} />
            </Box>
          </Box> */}
        </Box>
        <Box style={{ marginTop: '20px' }}>
          {/* <Box style={{display: 'flex'}}>
            <input
              style={{
                width: '60%',
                backgroundColor: 'black',
                border: '1px solid #363636',
                padding: '4px',
                color: '#FFF',
              }}
            />
            <Typography
              style={{
                fontSize: '16px',
                color: '#008CF2',
                flexGrow: 1,
                textAlign: 'right',
              }}
            >
              Resend the code
            </Typography>
          </Box> */}
        </Box>

        <Box style={{ marginTop: '10px' }}>
          <CustomButton
            text={'Verify and Continue (Step 4 of 5)'}
            styles={{
              backgroundColor: 'rgb(255,131,0)',
              color: '#FFF',
              marginTop: '20px',
              width: '100%',
              borderRadius: '2px',
              padding: '10px ',
            }}
            onButtonClick={onSubmit}
          />
        </Box>
        <Box style={{ marginTop: '30px' }}>
          <Typography>
            Having trouble? Sometimes it takes up to 10 minutes to retrieve a
            verification code. if it's been longer than that,{' '}
            <span style={{ color: '#008CF2', cursor: 'pointer' }} onClick={Back}>return to previous page</span> and
            try again.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
export default SignupStep8Col3
