/* eslint-disable jsx-a11y/alt-text */

import { Box } from '@mui/system'
import { Button, Typography } from '@mui/material'

import logo from '../../assets/img/logo_header.png'
import home from '../../assets/img/home.png'
import ic_calendar from '../../assets/img/ic_calendar.png'

import lockminichart_1 from '../../assets/img/minichart_1.png'
import lockminichart_2 from '../../assets/img/minichart_2.png'
import lockminichart_3 from '../../assets/img/minichart_3.png'
import lockminichart_4 from '../../assets/img/minichart_4.png'
import lockminichart_5 from '../../assets/img/minichart_5.png'
import orangealert from '../../assets/img/alert_icn.png'
import redalert from '../../assets/img/alert_red.png'
import searchicon from '../../assets/img/glass.png'
import billing from '../../assets/img/billing.png'
import ec2 from '../../assets/img/ec2.png'
import s3 from '../../assets/img/s3.png'
import support from '../../assets/img/support.png'
import iam from '../../assets/img/iam.png'
import awsOrganization from '../../assets/img/awsOrganization.png'
import cloudFront from '../../assets/img/cloudFront.png'
import activateStartup from '../../assets/img/activateStartup.png'
import more_btn_icn from '../../assets/img/more_btn_icn.png'
import information from '../../assets/img/information.png'
import full_screen_icn from '../../assets/img/full_screen_icn.png'
import rds from '../../assets/img/rds.png'
import notification from '../../assets/img/notification.png'
import { useMemo, useState } from 'react'
import DashboardHeader from '../../Component/DashboardHeader/DashboardHeader'
import DashBoardSettingHeader from '../../Component/DashboardHeader/DashBoardSettingHeader'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'

import './style.css'
import { DataGrid } from '@mui/x-data-grid'
import DashBoardTable from '../../Component/DashBoardTable/DashBoardTable'
import DashboardSettingUserBody from '../../Component/DashboardSettingUserBody/DashboardSettingUserBody'
import DashboardAnalysisOneHeader from '../../Component/DashboardAnalysisHeader/index'
import DashboardAnalisisOneBody from '../../Component/SecurityAnalysisDetails/SecurityAnalysisDropDown'

import DonutChart from 'react-donut-chart'
import { PieChart } from 'react-minimal-pie-chart'
import CloudInfoTwo from '../../Component/cloudInfoChart/index'
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router-dom'
const AwsServices = () => {
    const firstColumn = useMemo(
        () => [
            { icon: billing, title: 'Billing' },
            { icon: activateStartup, title: 'Activate for Startups' },
            { icon: ec2, title: 'EC 2' },
            { icon: cloudFront, title: 'CloudFront' },
            { icon: s3, title: 'S3' },
        ],
        [],
    )

    const secondColumn = useMemo(
        () => [
            { icon: awsOrganization, title: 'AWS Organizations' },
            { icon: support, title: 'Support' },
            { icon: iam, title: 'IAM' },
            { icon: iam, title: 'Centificate Manager' },
            { icon: cloudFront, title: 'Route 53' },
        ],
        [],
    )

    const thirdColumn = useMemo(
        () => [
            { icon: cloudFront, title: 'AWS Cloud Map' },
            { icon: rds, title: 'RDS' },
            { icon: cloudFront, title: 'VPC' },
            { icon: iam, title: 'AWS Firewall Manager' },
        ],
        [],
    )
    return (
        <Box
            style={{
                width: '55%',
                backgroundColor: 'rgba(0,0,0,0.5)',
                display: 'flex',
                flexDirection: 'column',
                padding: '20px 40px',

                borderRadius: '20px',
                // alignItems:"f"
            }}
        >
            <Typography
                style={{ color: '#FF9900', fontSize: '22px', fontWeight: '600' }}
            >
                AWS services
            </Typography>
            <Box
                style={{
                    width: '100%',
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box style={{ width: '30%' }}>
                    <Box>
                        {firstColumn.map(item => (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItmes: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <img src={item.icon} style={{ width: '22px', height: '22px' }} />
                                <Typography
                                    style={{
                                        color: 'rgba(255,255,255,0.6)',
                                        fontSize: '14px',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box style={{ width: '30%' }}>
                    <Box>
                        {secondColumn.map(item => (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItmes: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <img src={item.icon} style={{ width: '22px', height: '22px' }} />
                                <Typography
                                    style={{
                                        color: 'rgba(255,255,255,0.6)',
                                        fontSize: '14px',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
                <Box style={{ width: '30%' }}>
                    <Box>
                        {thirdColumn.map(item => (
                            <Box
                                style={{
                                    display: 'flex',
                                    alignItmes: 'center',
                                    marginTop: '20px',
                                }}
                            >
                                <img src={item.icon} style={{ width: '22px', height: '22px' }} />
                                <Typography
                                    style={{
                                        color: 'rgba(255,255,255,0.6)',
                                        fontSize: '14px',
                                        marginLeft: '8px',
                                    }}
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

const CloudInfoChart2 = () => {
    const navigate = useNavigate()
    const cls = useMemo(() => 'CloudInfoChart', [])

    const data = [
        {
            label: 'S3',
            value: 20,
            color: '#FF5774',
        },
        {
            label: 'Lambda',
            value: 25,
            color: '#8A00FF',
        },
        {
            label: 'CloudFront',
            value: 8,
            color: '#00B1FF',
        },
        {
            label: 'Route 53',
            value: 12,
            color: '#64D600',
        },
        {
            label: 'Lightsail',
            value: 23,
            color: '#FFBB00',
        },
        {
            label: 'ES2',
            value: 10,
            color: '#F9874D',
        },
    ]
    const [selected, setSelected] = useState(0)
    const [hovered, setHovered] = useState(undefined)

    const lineWidth = 60

    // new parameter

    const reactDonutChartdata = [
        {
            label: 'S3',
            value: 20,
            color: '#FF5774',
        },
        {
            label: 'Lambda',
            value: 25,
            color: '#8A00FF',
        },
        {
            label: 'CloudFront',
            value: 8,
            color: '#00B1FF',
        },
        {
            label: 'Route 53',
            value: 12,
            color: '#64D600',
        },
        {
            label: 'Lightsail',
            value: 23,
            color: '#FFBB00',
        },
        {
            label: 'ES2',
            value: 10,
            color: '#F9874D',
        },
    ]
    const reactDonutChartBackgroundColor = [
        '#FF5774',
        '#8A00FF',
        '#00B1FF',
        '#64D600',
        '#FFBB00',
        '#F9874D',
    ]
    const reactDonutChartInnerRadius = 0.5
    const reactDonutChartSelectedOffset = 0.04
    const reactDonutChartHandleClick = (item, toggled) => {
        if (toggled) {
            console.log(item)
        }
    }
    let reactDonutChartStrokeColor = '#FFFFFF'
    const reactDonutChartOnMouseEnter = item => {
        let color = reactDonutChartdata.find(q => q.label === item.label).color
        reactDonutChartStrokeColor = color
    }

    const [anchorElNav, setAnchorElNav] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)

    const handleOpenNavMenu = event => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = event => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }
    function createData(name, icon, date) {
        return { name, icon, date };
    }

    const rows = [
        createData('Frozen yoghurt', orangealert, 159),
        createData('Ice cream sandwich', orangealert, 237),
        createData('Eclair', redalert, 262),
    ];
    // end new parameter

    return (
        <Box className={`${cls}__background`}>
            {/* Header */}
            <DashboardHeader activeTab={'CLOUD INFO'} />

            {/* Setting inner header*/}
            <DashboardAnalysisOneHeader />

            <Box style={{ flexGrow: 1, marginTop: '40px' }}>
                <Box
                    style={{
                        padding: '20px',
                    }}
                >
                    <Box
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <Box
                            style={{
                                width: '40%',
                                backgroundColor: 'rgba(0,0,0,0.5)',
                                padding: '20px 40px',
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: '20px',
                                // alignItems:"f"
                            }}
                        >
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box>
                                    <Typography style={{ color: 'white', fontSize: '14px' }}>
                                        AWS Services
                                    </Typography>
                                </Box>
                                <Box>
                                    <img onClick={() => { navigate('/zoomchart') }} src={full_screen_icn} />
                                    <img src={information} style={{ margin: '0px 20px' }} />
                                    <img src={more_btn_icn} />
                                </Box>
                            </Box>
                            <Box
                                style={{
                                    display: 'flex',
                                    marginTop: '10px',
                                    // justifyContent: 'space-between',
                                }}
                            >
                                <Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            color: 'white',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '17px',
                                                height: '11px',
                                                background: '#E37F51',
                                                marginRight: '5px',
                                            }}
                                        ></Box>
                                        <Typography style={{ fontSize: '12px' }}>EC2</Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            color: 'white',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '17px',
                                                height: '11px',
                                                background: '#E85472',
                                                marginRight: '5px',
                                            }}
                                        ></Box>
                                        <Typography style={{ fontSize: '12px' }}>S3</Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            color: 'white',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '17px',
                                                height: '11px',
                                                background: '#8106F0',
                                                marginRight: '5px',
                                            }}
                                        ></Box>
                                        <Typography style={{ fontSize: '12px' }}>Lambda</Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            color: 'white',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '17px',
                                                height: '11px',
                                                background: '#07A2EC',
                                                marginRight: '5px',
                                            }}
                                        ></Box>
                                        <Typography style={{ fontSize: '12px' }}>
                                            CloudFront
                                        </Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            color: 'white',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '17px',
                                                height: '11px',
                                                background: '#5FC60A',
                                                marginRight: '5px',
                                            }}
                                        ></Box>
                                        <Typography style={{ fontSize: '12px' }}>Route 53</Typography>
                                    </Box>
                                    <Box
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            color: 'white',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '17px',
                                                height: '11px',
                                                background: '#E5AB0E',
                                                marginRight: '5px',
                                            }}
                                        ></Box>
                                        <Typography style={{ fontSize: '12px' }}>
                                            Lightsail
                                        </Typography>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box className="App" style={{ background: '#0000', width: '350px', height: '350px' }}>
                                        <Box sx={{ paddiing: 'auto', display: 'flex', position: 'absolute', flexDirection: 'column', justifyContent: 'center', width: '350px', height: '350px', textAlign: 'center' }}>
                                            <DonutChart
                                                onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                                                strokeColor={reactDonutChartStrokeColor}
                                                data={reactDonutChartdata}
                                                colors={reactDonutChartBackgroundColor}
                                                selectedOffset={reactDonutChartSelectedOffset}
                                                onClick={(item, toggled) => reactDonutChartHandleClick(item, toggled)}
                                            />
                                        </Box>
                                        <Box sx={{ marginLeft: '200px', marginTop: '80px', position: 'absolute', color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: 'auto', height: 'auto', textAlign: 'center' }}>
                                            <Typography>S3 Bucket</Typography>
                                            <TableContainer component={Paper} style={{ width: '180px', height: '100px', background: 'rgba(232, 84, 114,0.25)', borderRadius: '30px', padding: '0 15px 0 15px' }}>
                                                <Table aria-label="simple table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell sx={{ border: 'none', padding: '0px', color: '#fff' }}>Name</TableCell>
                                                            <TableCell sx={{ border: 'none', padding: '0px', color: '#fff' }}>Date</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rows.map((row) => (
                                                            <TableRow
                                                                key={row.name}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell component="th" scope="row" sx={{ border: 'none', color: '#796473', fontSize: '12px', padding: '0px' }}>{row.name}</TableCell>
                                                                <TableCell align="right" sx={{ border: 'none', color: '#796473', fontSize: '12px', padding: '0px' }}><img src={row.icon} /> {row.date}</TableCell>
                                                            </TableRow>
                                                        ))}
                                                        <Typography style={{ fontSize: '12px', color: '#fff', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>View in Details <img style={{ width: '13px', height: '13px' }} src={searchicon} /></Typography>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <Typography style={{ fontSize: '36px', fontWeight: 'bold' }}>6</Typography>
                                            <Typography style={{ fontSize: '15px', color: 'rgba(255, 255, 255, 0.25)' }}>Services</Typography>
                                        </Box>
                                    </Box>


                                </Box>
                            </Box>
                        </Box>

                        <AwsServices />
                    </Box>

                    <CloudInfoTwo />
                </Box>
            </Box>
        </Box>
    )
}
export default CloudInfoChart2
