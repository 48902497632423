import DashboardHeader from '../../../Component/DashboardHeader/DashboardHeader'
import { useState } from 'react'
import Header from '../Header/header'
import UserDetails from './UserLogin'
import MainLoader from '../../../Component/Spinner/MainLoader'
const UserProfile = () => {
    const [isLoading, setIsLoading] = useState(false)

    return (
        <>
            <MainLoader isLoading={isLoading} />

            {/* <Header/> */}
            <DashboardHeader activeTab={"Profile"} />
            <UserDetails setIsLoading={setIsLoading} />
        </>
    )
}
export default UserProfile