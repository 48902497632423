import {Typography} from '@mui/material'
import {Box} from '@mui/system'

import iIcon from '../../assets/img/i_icon.png'
const SignupStep4Col2 = () => {
  return (
    <Box
      style={{
        border: '1px solid grey',
        padding: '20px 30px',
        borderRadius: '30px',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <Typography
        style={{
          color: '#008CF2',
          fontSize: '20px',
          fontWeight: '600',
        }}
      >
        Secure Verification
      </Typography>
      <Box style={{display: 'flex'}}>
        <Box>
          <img src={iIcon} />
        </Box>
        <Box style={{marginLeft: '10px'}}>
          <Typography style={{fontStyle: '14px'}}>
            We will not charge for usage below Clevrone free tier limits. We
            temporarily hold 1 $ as a pending transaction for 3-5 days to verify
            your identity.
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
export default SignupStep4Col2
