import { Box, padding } from "@mui/system";
import React from "react";
import { Typography } from "@mui/material";
import Left from "../../../../assets/img/left.png";
import Right from "../../../../assets/img/right.png";
import cancelIcon from "../../../../assets/img/cancel_icn.png";
import { map } from "lodash";
class IamTaskList extends React.Component {
  state = { tasks: [] };
  componentDidMount() {
    const { tasks } = this.props;
    this.setState({
      tasks,
    });
  }
  onDragStart = (evt) => {
    console.log("onDragStart", evt);
    let element = evt.currentTarget;
    element.classList.add("dragged");
    evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
    evt.dataTransfer.effectAllowed = "move";
  };
  onDragEnd = (evt) => {
    console.log("onDragEnd", evt);
    evt.currentTarget.classList.remove("dragged");
  };
  onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add("dragged-over");
    evt.dataTransfer.dropEffect = "move";
  };
  onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
      return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove("dragged-over");
  };
  onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
  };
  onDrop = (evt, value) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    let data = evt.dataTransfer.getData("text/plain");
    let tasks = this.state.tasks;
    let updated = tasks.map((task) => {
      if (task.id == data) task.done = value;
      return task;
    });
    this.setState({ tasks: updated });
    this.props.setDone(updated);
  };

  render() {
    console.log("done_01");
    const { tasks } = this.state;
    let pending = tasks?.filter((t) => !t.done);
    let done = tasks?.filter((t) => t.done);
    console.log("done_02");
    const getLeft = () => {
      if (done.length != 0) {
        let taskOne = done[0].id;
        let task = tasks?.map((task) => {
          if (task?.id == done[0].id) task.done = false;
          return task;
        });
        this.setState({});
        this.props.setDone(task);
        console.log("getRight", task);
      }
    };
    const getRight = () => {
      if (pending.length != 0) {
        let taskOne = pending[0].id;
        let task = tasks?.map((task) => {
          if (task?.id == pending[0].id) task.done = true;
          return task;
        });
        this.setState({
          tasks,
        });
        this.props.setDone(task);
        console.log("getRight", task);
      }
    };
    const getClearAll = () => {
      let task = tasks?.map((task) => {
        done?.map((done) => {
          if (task?.id == done.id) task.done = false;
          return task;
        });
      });
      this.setState({
        tasks,
      });
    };
    return (
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          className="pending small-box"
          onDragLeave={(e) => this.onDragLeave(e)}
          onDragEnter={(e) => this.onDragEnter(e)}
          onDragEnd={(e) => this.onDragEnd(e)}
          onDragOver={(e) => this.onDragOver(e)}
          onDrop={(e) => this.onDrop(e, false)}
          sx={{ width: "60%" }}
        >
          <Box sx={{ padding: "4px 0 4px 4px" }}>
            <Box
              sx={{
                background: "#010515",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  paddingLeft: "20px",
                  display: "flex",
                  alignItems: "center",
                  height: "50px",
                  width: "300px",
                  fontWeight: "bold",
                  fontSize: "20px",
                  background: "#272b38",
                }}
              >
                Dashboard Features
              </Box>
              <Box
                sx={{
                  width: "0",
                  height: "0",
                  borderTop: "25px solid transparent",
                  borderLeft: "30px solid #272b38",
                  borderBottom: "25px solid transparent",
                }}
              ></Box>
              <Typography
                style={{
                  paddingLeft: "20px",
                  fontSize: "20px",
                  fontWeight: "bold",
                }}
              >
                IAM Analysis
              </Typography>
            </Box>
            <Typography
              sx={{
                paddingLeft: "4%",
                marginTop: "5px",
                background: "#272B38",
                display: "flex",
              }}
              component="th"
            >
              <Typography sx={{ fontSize: "18px", fontWeight: "bold" }}>
                Name
              </Typography>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "bold", marginLeft: "20%" }}
              >
                Description
              </Typography>
            </Typography>
            {pending?.map((task) => (
              <Box
                className="task"
                sx={{
                  cursor: "pointer"
                }}
                key={task.name}
                id={task.id}
                draggable
                onDragStart={(e) => this.onDragStart(e)}
                onDragEnd={(e) => this.onDragEnd(e)}
              >
                <Typography
                  sx={{
                    paddingLeft: "4%",
                    display: "flex",
                    background: "#010515",
                    color: "#fff",
                    marginTop: "5px",
                    '&:hover': {
                      color: 'rgb(0, 140, 242)',
                      opacity: [0.9, 0.8, 0.7],
                    }
                  }}
                  component="th"
                  scope="row"
                >
                  {" "}
                  {task.name}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={Left}
            width="50px"
            height="34px"
            style={{ opacity: "0.2", cursor: "pointer" }}
            onClick={getLeft}
          />
          <img
            src={Right}
            width="50px"
            height="34px"
            style={{ opacity: "0.2", cursor: "pointer" }}
            onClick={getRight}
          />
        </Box>
        <Box
          className="done small-box"
          onDragLeave={(e) => this.onDragLeave(e)}
          onDragEnter={(e) => this.onDragEnter(e)}
          onDragEnd={(e) => this.onDragEnd(e)}
          onDragOver={(e) => this.onDragOver(e)}
          onDrop={(e) => this.onDrop(e, true)}
          sx={{
            width: "30%",
            background: "#010515",
            borderRadius: "20px",
            padding: "19px 41px 19px 41px",
          }}
        >
          <Typography
            sx={{
              color: "#FF8800",
              fontSize: "20px",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Selected Features
            <img
              src={cancelIcon}
              style={{ cursor: "pointer" }}
              onClick={getClearAll}
            />
          </Typography>
          {done?.map((task) => (
            <Box
              className="task"
              key={task.name}
              id={task.id}
              sx={{
                cursor: "pointer"
              }}
              draggable
              onDragStart={(e) => this.onDragStart(e)}
              onDragEnd={(e) => this.onDragEnd(e)}
            >
              <Typography
                sx={{
                  paddingLeft: "4%",
                  display: "flex",
                  background: "#010515",
                  color: "#008CF2",
                  marginTop: "5px",
                  '&:hover': {
                    color: 'rgba(255, 255, 255)',
                    opacity: [0.9, 0.8, 0.7],
                  }
                }}
                component="th"
                scope="row"
              >
                {task.name}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>
    );
  }
}

export default IamTaskList;
