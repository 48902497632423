import { Box } from '@mui/system'
import RegistrationLayout from '../../Component/Layout/index'
import SignupStep7Col1 from '../../Component/confirmIdentityVerificationDetails/logo'
import SignupStep7Col2 from '../../Component/confirmIdentityVerificationDetails/userIcon'
import SignupStep7Col3 from '../../Component/confirmIdentityVerificationDetails'

const SignupStepSeven = () => {
  return (
    <RegistrationLayout isHeaderShow={false}>
      <Box
        style={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between' }}
      >
        <Box
          style={{
            width: '30%',
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <SignupStep7Col1 />
        </Box>
        <Box
          style={{
            width: '30%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginRight: '20px',
          }}
        >
          <SignupStep7Col2 />
        </Box>
        <Box
          style={{
            // flexGrow: 1,

            width: '40%',
            display: 'flex',
            alignItems: 'center',
            // padding: '20px',
          }}
        >
          <SignupStep7Col3 />
        </Box>
      </Box>
    </RegistrationLayout>
  )
}
export default SignupStepSeven
