import React, { useEffect } from "react";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import { Api } from "../../MyNewUtils/Api";
import "./style.css";

const DashboardSettingPasswordPolicyBody = ({ setIsLoading }) => {
  const cls = useMemo(() => "DahboardThreeBody", []);
  const [passwordExpireInDays, setPasswordExpireInDays] = useState("365");
  const [passwordresumeAfterIn, setPasswordresumeAfterIn] = useState("10");
  const [passwordMinimumLength, setPasswordMinimumLength] = useState("8");
  const [userLockedAfterFailedAttempts, setUserLockedAfterFailedAttempts] =
    useState("6");
  const [
    userLockedAfterFailedAttemptsWithinMinutes,
    setUserLockedAfterFailedAttemptsWithinMinutes,
  ] = useState("10");
  const [
    userLockedAfterFailedAttemptsForMinutes,
    setUserLockedAfterFailedAttemptsForMinutes,
  ] = useState("30");
  const [twoStepVerification, setTwoStepVerification] = useState("true");
  const [rememberBrowserinDays, setRememberBrowserinDays] = useState("30");
  const [checkboxValue, setCheckboxValue] = useState([]);

  const [passwordPolicy, setPasswordPolicy] = useState();
  const [specialCharacters, setSpecialCharacters] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [lowerCaseLetters, setLowerCaseLetters] = useState(false);
  const [upperCaseLetters, setUpperCaseLetters] = useState(false);
  const ChangepasswordExpireInDays = (event) => {
    setPasswordExpireInDays(event.target.value);
  };

  const ChangepasswordresumeAfterIn = (event) => {
    setPasswordresumeAfterIn(event.target.value);
  };

  const ChangepasswordMinimumLength = (event) => {
    setPasswordMinimumLength(event.target.value);
  };

  const ChangeuserLockedAfterFailedAttempts = (event) => {
    setUserLockedAfterFailedAttempts(event.target.value);
  };

  const ChangeuserLockedAfterFailedAttemptsWithinMinutes = (event) => {
    setUserLockedAfterFailedAttemptsWithinMinutes(event.target.value);
  };

  const ChangeuserLockedAfterFailedAttemptsForMinutes = (event) => {
    setUserLockedAfterFailedAttemptsForMinutes(event.target.value);
  };

  const ChangetwoStepVerification = (event) => {
    setTwoStepVerification(event.target.value);
  };

  const ChangerememberBrowserinDays = (event) => {
    setRememberBrowserinDays(event.target.value);
  };

  const handleChangeCheckbox = (e) => {
    let array = checkboxValue;
    if (e.target.value === "UPPERCASE") {
      setUpperCaseLetters(!upperCaseLetters);
    }
    if (e.target.value === "LOWERCASE") {
      setLowerCaseLetters(!lowerCaseLetters);
    }
    if (e.target.value === "SPECIALCHARACTERS") {
      setSpecialCharacters(!specialCharacters);
    }
    if (e.target.value === "NUMBERS") {
      setNumbers(!numbers);
    }
    if (e.target.checked) {
      array[array.length] = e.target.value;
    } else {
      if (array?.length != 0) {
        array = array?.filter((item) => item != e.target.value);
        setCheckboxValue(array);
      } else {
        setCheckboxValue([]);
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    let PolicyData = {
      passwordExpireInDays: passwordExpireInDays,
      passwordresumeAfterInChanges: passwordresumeAfterIn,
      passwordMustCotain: checkboxValue,
      passwordMinimumLength: passwordMinimumLength,
      userLockedAfterFailedAttempts: userLockedAfterFailedAttempts,
      userLockedAfterFailedAttemptsWithinMinutes:
        userLockedAfterFailedAttemptsWithinMinutes,
      userLockedAfterFailedAttemptsForMinutes:
        userLockedAfterFailedAttemptsForMinutes,
      twoStepVerification: twoStepVerification,
      rememberBrowserinDays: rememberBrowserinDays,
    };
    console.log("PolicyData", PolicyData);
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      data: JSON.stringify(PolicyData),
    };

    const { data } = await Api.postRequest(
      "/users/update-password-policy",
      final
    );
    const res = JSON.parse(data);

    if (res?.status === 1) {
      toast.success(res?.message, { autoClose: 1000 });
      setIsLoading(false);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setIsLoading(false);
    }
  };

  const getPasswordPolicy = async () => {
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { data } = await Api.postRequest("/users/get-password-policy", final);
    const res = JSON.parse(data);
    if (res?.status === "1") setPasswordPolicy(res?.data);
    let policy = res?.data?.passwordMustCotain;
    setCheckboxValue(policy);
    policy?.map((item, index) => {
      if (item === "UPPERCASE") {
        setUpperCaseLetters(true);
      }
      if (item === "LOWERCASE") {
        setLowerCaseLetters(true);
      }
      if (item === "SPECIALCHARACTERS") {
        setSpecialCharacters(true);
      }
      if (item === "NUMBERS") {
        setNumbers(true);
      }
    });
    if (res?.data?.passwordExpireInDays !== undefined)
      setPasswordExpireInDays(res?.data?.passwordExpireInDays);
    if (res?.data?.passwordresumeAfterInChanges !== undefined)
      setPasswordresumeAfterIn(res?.data?.passwordresumeAfterInChanges);
    if (res?.data?.passwordMinimumLength !== undefined)
      setPasswordMinimumLength(res?.data?.passwordMinimumLength);
    if (res?.data?.userLockedAfterFailedAttempts !== undefined)
      setUserLockedAfterFailedAttempts(
        res?.data?.userLockedAfterFailedAttempts
      );
    if (res?.data?.userLockedAfterFailedAttemptsWithinMinutes !== undefined)
      setUserLockedAfterFailedAttemptsWithinMinutes(
        res?.data?.userLockedAfterFailedAttemptsWithinMinutes
      );
    if (res?.data?.userLockedAfterFailedAttemptsForMinutes !== undefined)
      setUserLockedAfterFailedAttemptsForMinutes(
        res?.data?.userLockedAfterFailedAttemptsForMinutes
      );
    if (res?.data?.twoStepVerification !== undefined)
      setTwoStepVerification(res?.data?.twoStepVerification);
    if (res?.data?.rememberBrowserinDays !== undefined)
      setRememberBrowserinDays(res?.data?.rememberBrowserinDays);
  };

  useEffect(() => {
    getPasswordPolicy();
  }, []);

  console.log("passwordPolicy", passwordPolicy);
  return (
    <>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            width: "90%",
            backgroundColor: "rgba(0,0,0,0.5)",
            marginTop: "30px",
            padding: "30px",
            border: "1px solid #707070",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            marginBottom: "30px",
          }}
        >
          {/* Header */}
          {/* <Box style={{width: '70%', alignSelf: 'center'}}>
          <Box style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button
              variant="contained"
              style={{borderRadius: '20px'}}
              startIcon={<AddCircleRoundedIcon />}
              onClick={() => {}}
            >
              Add User
            </Button>
          </Box>
        </Box> */}

          {/* Main Body */}
          <Box style={{ padding: "0px 40px" }}>
            <form onSubmit={onSubmit}>
              <Box>
                {/* psswrod expire */}
                <Box style={{ width: "250px" }}>
                  <lable
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#008CF2",
                      marginBottom: "4px",
                    }}
                  >
                    Password expire in
                  </lable>
                  <select
                    onChange={ChangepasswordExpireInDays}
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                      border: "1px solid #363636",
                      padding: "4px",
                      color: "#FFF",
                    }}
                    value={passwordExpireInDays}
                  >
                    <option value="365">365 Days</option>
                    <option value="90">90 Days</option>
                    <option value="60">60 Days</option>
                    <option value="60">60 Days</option>
                    <option value="15">15 Days</option>
                    <option value="7">7 Days</option>
                  </select>
                </Box>

                {/* psswrod reuse */}
                <Box style={{ width: "250px", marginTop: "15px" }}>
                  <lable
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#008CF2",
                      marginBottom: "4px",
                    }}
                  >
                    Password resume after
                  </lable>
                  <select
                    onChange={ChangepasswordresumeAfterIn}
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                      border: "1px solid #363636",
                      padding: "4px",
                      color: "#FFF",
                    }}
                    value={passwordresumeAfterIn}
                  >
                    <option value="10">10 Changes</option>
                    <option value="7">7 Changes</option>
                    <option value="5">5 Changes</option>
                    <option value="3">3 Changes</option>
                    <option value="1">2 Changes</option>
                    <option value="1">1 Changes</option>
                  </select>
                </Box>

                {/* psswrod contain */}
                <Box style={{ width: "300px", marginTop: "15px" }}>
                  <lable
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#008CF2",
                      marginBottom: "6px",
                    }}
                  >
                    Passwords must contain:
                  </lable>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="UPPERCASE"
                          onChange={handleChangeCheckbox}
                          checked={upperCaseLetters}
                        />
                      }
                      label="Upper case letters (A-Z)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="LOWERCASE"
                          onChange={handleChangeCheckbox}
                          checked={lowerCaseLetters}
                        />
                      }
                      label="Lower case letters (a-z)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="NUMBERS"
                          onChange={handleChangeCheckbox}
                          checked={numbers}
                        />
                      }
                      label="Numbers (0-9)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          value="SPECIALCHARACTERS"
                          onChange={handleChangeCheckbox}
                          checked={specialCharacters}
                        />
                      }
                      label="Special characters (#,$,%,&,etc..)"
                    />
                  </FormGroup>

                  {/* <Box style={{ display: 'flex', alignItems: 'center' }}>
                  <Box className={`${cls}__round`}>
                    <input type="checkbox" id="upperCase" />
                    <label for="upperCase"></label>
                  </Box>
                  <Typography
                    style={{
                      color: 'rgba(255,255,255,0.5)',
                      fontSize: '12px',
                      marginLeft: '10px',
                    }}
                  >
                    Upper case letters (A-Z)
                  </Typography>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '6px',
                  }}
                >
                  <Box className={`${cls}__round`}>
                    <input type="checkbox" id="lowerCase" />
                    <label for="lowerCase"></label>
                  </Box>
                  <Typography
                    style={{
                      color: 'rgba(255,255,255,0.5)',
                      fontSize: '12px',
                      marginLeft: '10px',
                    }}
                  >
                    Lower case letters (a-z)
                  </Typography>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '6px',
                  }}
                >
                  <Box className={`${cls}__round`}>
                    <input type="checkbox" id="numbers" />
                    <label for="numbers"></label>
                  </Box>
                  <Typography
                    style={{
                      color: 'rgba(255,255,255,0.5)',
                      fontSize: '12px',
                      marginLeft: '10px',
                    }}
                  >
                    Numbers (0-9)
                  </Typography>
                </Box>

                <Box
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '6px',
                  }}
                >
                  <Box className={`${cls}__round`}>
                    <input type="checkbox" id="special" />
                    <label for="special"></label>
                  </Box>
                  <Typography
                    style={{
                      color: 'rgba(255,255,255,0.5)',
                      fontSize: '12px',
                      marginLeft: '10px',
                    }}
                  >
                    Special characters (#,$,%,&,etc..)
                  </Typography>
                </Box> */}
                </Box>

                {/* password length */}
                <Box style={{ width: "250px", marginTop: "15px" }}>
                  <lable
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#008CF2",
                      marginBottom: "4px",
                    }}
                  >
                    Minimum Password length
                  </lable>
                  <select
                    onChange={ChangepasswordMinimumLength}
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                      border: "1px solid #363636",
                      padding: "4px",
                      color: "#FFF",
                    }}
                    value={passwordMinimumLength}
                  >
                    {[...Array(121)].map((x, i) => (
                      <option value={i + 8}>{i + 8} </option>
                    ))}
                  </select>
                  <Typography
                    style={{
                      fontSize: "10px",
                      color: "rgba(255,255,255,0.5)",
                      marginTop: "4px",
                    }}
                  >
                    Must be between 8 to 128 characters
                  </Typography>
                </Box>

                {/* user lockout */}
                <Box style={{ marginTop: "15px" }}>
                  <lable
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#008CF2",
                      marginBottom: "4px",
                    }}
                  >
                    Users locked out after
                  </lable>
                  <Box style={{ display: "flex" }}>
                    <Box style={{ width: "250px" }}>
                      <select
                        onChange={ChangeuserLockedAfterFailedAttempts}
                        style={{
                          width: "100%",
                          backgroundColor: "black",
                          border: "1px solid #363636",
                          padding: "4px",
                          color: "#FFF",
                        }}
                        value={userLockedAfterFailedAttempts}
                      >
                        <option value="6">6 Failed Attempts</option>
                        <option value="5">5 Failed Attempts</option>
                        <option value="4">4 Failed Attempts </option>
                        <option value="3">3 Failed Attempts </option>
                        <option value="2">2 Failed Attempts </option>
                        <option value="1">1 Failed Attempts </option>
                      </select>
                    </Box>
                    <Box style={{ width: "250px", marginLeft: "10px" }}>
                      <select
                        onChange={
                          ChangeuserLockedAfterFailedAttemptsWithinMinutes
                        }
                        style={{
                          width: "100%",
                          backgroundColor: "black",
                          border: "1px solid #363636",
                          padding: "4px",
                          color: "#FFF",
                        }}
                        value={userLockedAfterFailedAttemptsWithinMinutes}
                      >
                        <option value="10">Within 10 Minutes</option>
                        <option value="5">Within 5 Minutes</option>
                        <option value="4">Within 4 Minutes </option>
                        <option value="3">Within 3 Minutes </option>
                        <option value="2">Within 2 Minutes </option>
                        <option value="1">Within 1 Minutes </option>
                      </select>
                    </Box>
                    <Box style={{ width: "250px", marginLeft: "10px" }}>
                      <select
                        onChange={ChangeuserLockedAfterFailedAttemptsForMinutes}
                        style={{
                          width: "100%",
                          backgroundColor: "black",
                          border: "1px solid #363636",
                          padding: "4px",
                          color: "#FFF",
                        }}
                        value={userLockedAfterFailedAttemptsForMinutes}
                      >
                        <option value="30">For 30 Minutes</option>
                      </select>
                    </Box>
                  </Box>
                </Box>

                <Box style={{ width: "250px", marginTop: "15px" }}>
                  <lable
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#008CF2",
                      marginBottom: "4px",
                    }}
                  >
                    2 steps Verification for My Org
                  </lable>
                  <select
                    onChange={ChangetwoStepVerification}
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                      border: "1px solid #363636",
                      padding: "4px",
                      color: "#FFF",
                    }}
                    value={twoStepVerification}
                  >
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                </Box>

                <Box style={{ width: "250px", marginTop: "15px" }}>
                  <lable
                    style={{
                      display: "block",
                      fontSize: "12px",
                      color: "#008CF2",
                      marginBottom: "4px",
                    }}
                  >
                    Remember Browser
                  </lable>
                  <select
                    onChange={ChangerememberBrowserinDays}
                    style={{
                      width: "100%",
                      backgroundColor: "black",
                      border: "1px solid #363636",
                      padding: "4px",
                      color: "#FFF",
                    }}
                    value={rememberBrowserinDays}
                  >
                    <option value="30">30 Days</option>
                  </select>
                </Box>

                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "20px",
                  }}
                >
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      marginLeft: "20px",
                      borderRadius: "20px",
                      padding: "5px 25px",
                      color: "white",
                    }}
                  >
                    Update
                  </Button>
                  <Button
                    variant="outlined"
                    style={{
                      marginLeft: "20px",
                      borderRadius: "20px",
                      padding: "5px 25px",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DashboardSettingPasswordPolicyBody;
