import React, { useEffect, useState, useMemo } from 'react';
import { Route, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify'
import { tr } from 'date-fns/locale';



const ProtectedRoute = ({ Component, AddCloudComponent }) => {


  const [isLoggedIn, setisLoggedIn] = useState(false);
  const tokan = localStorage.getItem('user')
  const cloudaccounts = JSON.parse(localStorage.getItem('user'))?.data?.cloudaccounts?.length

  const [ConfigureCloudModel, setConfigureCloudModel] = useState(true)




  // useEffect(() => {
  //   if (tokan != null) {
  //     if (!AddCloudComponent && cloudaccounts === 0) {
  //       setConfigureCloudModel(true)
  //       console.log('Test1', cloudaccounts, AddCloudComponent)
  //       toast.error('Configure Cloud Account', { autoClose: 1000 })
  //     } else {
  //       console.log('Test2', cloudaccounts, AddCloudComponent)
  //     }
  //   }
  // }, [Component, ConfigureCloudModel])







  return (
    // tokan != null ? <Component /> : <Navigate to="/" />
    tokan != null ? cloudaccounts != 0 || AddCloudComponent ? <Component /> : <Navigate to="/cloud-setup-wizard" state={{ from: ConfigureCloudModel }} /> : <Navigate to="/" />
  );

}
export default ProtectedRoute;

