import { useState, useEffect, useRef, useContext } from "react";
import { Box } from "@mui/system";
import Table from "@mui/material/Table";
import { Typography } from "@mui/material";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core/styles";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { ChartHeader } from "./components/ChartHeader";
import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";

const DoneData = (props) => {
	const {
		textColor,
		tabletitleColor,
	} = props;
	const [showDoneData, setShowDoneData] = useState({});
	const [tableTitle, setTableTitle] = useState();
	const chart = useSelector(state => state.charts);
	const [isLoading, setIsLoading] = useState(false);
	const [chartType, setChartType] = useState('');
	useEffect(() => {
    setIsLoading(chart?.charts?.[props.itemData.uri]?.loading);
  }, [chart])

	const useStyles = makeStyles({
		table: {
			minWidth: 100,
		},
	});
	const classes = useStyles();
	const elementRef = useRef();

	useEffect(() => {
		const { accountResponseType, data } = showDoneData || {};
		if (accountResponseType?.includes("Table") && data?.[0]) {
			const titles = Object.keys(data[0]);
			setTableTitle(titles);
		}
	}, [showDoneData]);

	return (
		<>
			<Box
				className="h-[500px] rounded-lg bg-black relative"
			>

				<Box
					sx={{
						width: "100%",
						overflowY: "auto",
						"&::-webkit-scrollbar": {
							width: 3,
							height: 10,
						},
						"&::-webkit-scrollbar-track": {
							backgroundColor: "#0000",
						},
						"&::-webkit-scrollbar-thumb": {
							backgroundColor: "#ADD8E6",
							borderRadius: 2,
							border: "0px solid #0000",
						},
					}}
				>

					<ChartHeader
						chartData={showDoneData}
						elementRef={elementRef}
						tableTitle={tableTitle}
						setShowDoneData={setShowDoneData}
						chartType={chartType}
						setChartType={setChartType}
						{...props}
					/>

					{showDoneData?.accountResponseType === undefined ||
						showDoneData?.data === undefined || isLoading ? (
						<div className="flex items-center justify-center flex-col h-[400px] gap-2">
							<Loader type="Oval" height={40} width={40} color={"#0FBCF9"} />
							<h2 className="text-2xl">Hang Tight...</h2>
							<p className="text-lg">This will take approximately 1-2 minutes to complete.</p>
						</div>
					) : (chartType || 'Table') === "Table" ? (
						<Paper style={{ background: "#0000", padding: "0px 10px", maxHeight: "400px" }}>
							{Array.isArray(showDoneData?.data) && showDoneData?.data?.length ? (
								<TableContainer
									sx={{
										"&::-webkit-scrollbar": {
											width: 3,
											height: 10,
										},
										"&::-webkit-scrollbar-track": {
											backgroundColor: "#0000",
										},
										"&::-webkit-scrollbar-thumb": {
											backgroundColor: "#ADD8E6",
											borderRadius: 2,
											border: "0px solid #0000",
										},
									}}
								>
									<Table
										className={classes.table}
										aria-label="simple table"
										style={{ background: "#0000", width: "100%" }}
									>
										<TableHead>
											<TableRow>
												{tableTitle?.map((item, i) => {
													return (
														<>
															<TableCell
																align="Left"
																style={{
																	color: tabletitleColor,
																	borderBottom: "0px solid #fff",
																	fontSize: "auto",
																}}
															>
																{item ? item : ""}
															</TableCell>
														</>
													);
												})}
											</TableRow>
										</TableHead>
										<TableBody style={{ background: "#0000" }}>
											{showDoneData?.data?.map((row, index) => {
												return (
													<TableRow>
														{tableTitle?.map((item, i) => {
															return (
																<TableCell
																	style={{
																		color: textColor,
																		borderBottom: "0px solid #fff",
																	}}
																>
																	{row[`${item}`]}
																</TableCell>
															);
														})}
													</TableRow>
												);
											})}
										</TableBody>
									</Table>
								</TableContainer>
							) : (
								<Typography
									sx={{
										height: "380px",
									}}
									className="no_data_text_box"
								>
									No Data Available
								</Typography>
							)}
						</Paper>
					) : typeof showDoneData?.data === 'object' ? (
						Object.keys(showDoneData?.data).length === 0 ? (
							<Paper style={{ background: "#0000", padding: "0px 10px" }}>
								<Typography
									sx={{
										height: "380px",
									}}
									className="no_data_text_box"
								>
									No Data Available
								</Typography>
							</Paper>
						) : (
							<HighchartsReact
								options={showDoneData?.data}
								highcharts={Highcharts}
								ref={elementRef}
							/>
						)
					) : null}
				</Box>
			</Box>
		</>
	);
};
export default DoneData;