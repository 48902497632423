import { Button, Box, Grid } from '@mui/material'
import { PieChart, Pie, Cell, Label, Bar } from 'recharts';
import "./CloudInfoa.css"
import { useCallback, useMemo, useState } from 'react'
import logo from "../../assets/img/logo_header.png";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import home from "../../assets/img/home.png";
import globe from "../../assets/img/globe.png";
import lock from "../../assets/img/lock.png";
import cloud from "../../assets/img/cloud_computing_icn.png";
import setting from "../../assets/img/settings_1_icn.png";
import aws from "../../assets/img/aws_cloud_icn.png";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// ==left icon
import fullscreen from "../../assets/img/full_screen_icn.png"
import info from "../../assets/img/infoicn.png"
import MoreVertIcon from '@mui/icons-material/MoreVert';

// ====right icon
import billing from '../../assets/img/icon_3.png';
import user from '../../assets/img/icon_8.png';
import microchip from '../../assets/img/icon_4.png';
import cloudfront from '../../assets/img/icon_2.png';
import bucket from '../../assets/img/icon_1.png';

import notepad from '../../assets/img/icon-5.png';
import shield from '../../assets/img/icon_7.png';

import server from '../../assets/img/icon_6.png';

//footer icon
import calender from "../../assets/img/ic_calendar.png"
import minichart1 from "../../assets/img/minichart_1.png"
import minichart2 from "../../assets/img/minichart_2.png"
import minichart3 from "../../assets/img/minichart_3.png"
import minichart4 from "../../assets/img/minichart_4.png"
import minichart5 from "../../assets/img/minichart_5.png"

import DonutChart from "react-donut-chart";

const CloudInfoa = () => {
  const reactDonutChartdata = [
    {
      label: "S3",
      value: 20,
      color: "#FF5774"
    },
    {
      label: "Lambda",
      value: 25,
      color: "#8A00FF"
    },
    {
      label: "CloudFront",
      value: 8,
      color: "#00B1FF"
    },
    {
      label: "Route 53",
      value: 12,
      color: "#64D600"
    },
    {
      label: "Lightsail",
      value: 23,
      color: "#FFBB00"
    },
    {
      label: "ES2",
      value: 10,
      color: "#F9874D"
    }
  ];
  const reactDonutChartBackgroundColor = [
    "#FF5774",
    "#8A00FF",
    "#00B1FF",
    "#64D600",
    "#FFBB00",
    "#F9874D"
  ];
  const reactDonutChartInnerRadius = 0.5;
  const reactDonutChartSelectedOffset = 0.04;
  const reactDonutChartHandleClick = (item, toggled) => {
    if (toggled) {
      console.log(item);
    }
  };
  let reactDonutChartStrokeColor = "#FFFFFF";
  const reactDonutChartOnMouseEnter = (item) => {
    let color = reactDonutChartdata.find((q) => q.label === item.label).color;
    reactDonutChartStrokeColor = color;
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (

    <section className="background">

      <Box className="secNav">
        <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          <Box style={{ display: "flex", flexDirection: "row" }}>
            <Box style={{ display: "flex", flexDirection: "row", marginTop: "16px", marginLeft: "25px" }}>
              <Box><img src={aws} /></Box>
              <Box><Typography style={{ marginLeft: "7px" }}>AWS Cloud</Typography></Box>
              <Box style={{ marginLeft: "7px", marginTop: "7px" }}><img src={KeyboardArrowDownIcon} /></Box>
            </Box>
            <Box style={{ display: "flex", flexDirection: "row", marginTop: "16px", marginLeft: "25px" }}>
              <Box><Typography>Account ID: 9893123</Typography></Box>
              <Box style={{ marginLeft: "7px", marginTop: "7px" }}><img src={KeyboardArrowDownIcon} /></Box>
            </Box>
          </Box>
          <Box>
            <Box style={{ display: "flex", flexDirection: "row", marginTop: "16px", marginRight: "25px" }}>
              <Box><Typography>ap-south-1</Typography></Box>
              <Box style={{ marginLeft: "7px", marginTop: "7px" }}><img src={KeyboardArrowDownIcon} /></Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className="main">
        <Box className="left">
          <Box className="lefttop">
            <Box className="lefttl">
              <Typography>AWS Services</Typography>
            </Box>
            <Box className="lefttr">
              <Box style={{ marginLeft: "5px" }}> <img src={fullscreen} /></Box>
              <Box style={{ marginLeft: "5px" }}><img src={info} /></Box>
              <Box style={{ marginLeft: "5px" }}> <img src={MoreVertIcon} /></Box>
            </Box>
          </Box>
          {/* ------------------------chart---------------------------------------------------------- */}
          <Box className="App" style={{ background: '#0000', width: '350px', height: '350px' }}>
            <Box style={{ width: '10px', color: '#0000' }}>0</Box>
            <Box sx={{ position: 'absolute', background: '#00', display: 'flex', flexDirection: 'row' }}>
              <Box>
                {/* {reactDonutChartdata.map((bgcolor)=>{
                              console.log(bgcolor.color)
                              return( */}
                <Typography style={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ width: '17px', height: '11px', background: "#E37F51", marginRight: '5px' }}></Box><Box style={{ fontSize: '12px' }}>EC2</Box></Typography>
                <Typography style={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ width: '17px', height: '11px', background: "#E85472", marginRight: '5px' }}></Box><Box style={{ fontSize: '12px' }}>S3</Box></Typography>
                <Typography style={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ width: '17px', height: '11px', background: "#8106F0", marginRight: '5px' }}></Box><Box style={{ fontSize: '12px' }}>Lambda</Box></Typography>
                <Typography style={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ width: '17px', height: '11px', background: "#07A2EC", marginRight: '5px' }}></Box><Box style={{ fontSize: '12px' }}>CloudFront</Box></Typography>
                <Typography style={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ width: '17px', height: '11px', background: "#5FC60A", marginRight: '5px' }}></Box><Box style={{ fontSize: '12px' }}>Route 53</Box></Typography>
                <Typography style={{ display: 'flex', flexDirection: 'row' }}><Box sx={{ width: '17px', height: '11px', background: "#E5AB0E", marginRight: '5px' }}></Box><Box style={{ fontSize: '12px' }}>Lightsail</Box></Typography>
                {/* )
                           })}                           */}
              </Box>

              <Box sx={{ paddingLeft: '20%', marginTop: '-10%' }}>
                <DonutChart
                  width={600}
                  height={600}
                  onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                  strokeColor={reactDonutChartStrokeColor}
                  data={reactDonutChartdata}
                  colors={reactDonutChartBackgroundColor}
                  selectedOffset={reactDonutChartSelectedOffset}
                  onClick={(item, toggled) => reactDonutChartHandleClick(item, toggled)}
                />
              </Box>

            </Box>


            <Box sx={{ color: '#fff', display: 'flex', flexDirection: 'column', paddingLeft: '430px', paddingTop: '20%', textAlign: 'center', justifyContent: 'center', alignItems: 'center' }}>
              <Typography style={{ fontSize: '60px', fontWeight: 'bold' }}>6</Typography>
              <Typography style={{ fontSize: '20px' }}>Services</Typography>
            </Box>

          </Box>
          {/* --------------------chart--------------------------------------------------------------- */}
        </Box>
        <Box className="right">
          <Typography className="head">AWS services</Typography>
          <Box className="thre">
            <Box className="le">
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={billing} />
                <Typography style={{ marginLeft: "12px" }}>Billing</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={user} />
                <Typography style={{ marginLeft: "12px" }}>Activate for Startups</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={microchip} />
                <Typography style={{ marginLeft: "12px" }}>EC 2</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={cloudfront} />
                <Typography style={{ marginLeft: "12px" }}>CloudFront</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={bucket} />
                <Typography style={{ marginLeft: "12px" }}>S3</Typography>
              </Box>
            </Box>
            <Box className="center">
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={notepad} />
                <Typography style={{ marginLeft: "12px" }}>Billing</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={user} />
                <Typography style={{ marginLeft: "12px" }}>Activate for Startups</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={shield} />
                <Typography style={{ marginLeft: "12px" }}>EC 2</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={shield} />
                <Typography style={{ marginLeft: "12px" }}>CloudFront</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={cloudfront} />
                <Typography style={{ marginLeft: "12px" }}>S3</Typography>
              </Box>
            </Box>
            <Box className="ri">
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={microchip} />
                <Typography style={{ marginLeft: "12px" }}>Billing</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={server} />
                <Typography style={{ marginLeft: "12px" }}>Activate for Startups</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={cloudfront} />
                <Typography style={{ marginLeft: "12px" }}>EC 2</Typography>
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", padding: "15px" }}>
                <img src={shield} />
                <Typography style={{ marginLeft: "12px" }}>CloudFront</Typography>
              </Box>

            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="footer">
        <Box className="footerb">
          <Box className="footerbox">
            <Typography>EC2 Instances Running</Typography>
            <Box className="footerse">
              <Box><Typography style={{ fontWeight: "bolder", padding: "10px", fontSize: "20px" }}>21</Typography></Box>
              <Box><img src={minichart1} /></Box>
            </Box>
            <Box className="footert">
              <Box><img style={{ paddingTop: "5px" }} src={calender} /></Box>
              <Box><Typography style={{ color: "white", paddingTop: "5px", paddingRight: "5px" }}>11 Apr, 2021 - 18 Apr, 2021</Typography></Box>
            </Box>
          </Box>
          <Box className="footerbox">
            <Typography>EC2 Instances Running</Typography>
            <Box className="footerse">
              <Box><Typography style={{ fontWeight: "bolder", padding: "10px", fontSize: "20px" }}>10</Typography></Box>
              <Box><img src={minichart2} /></Box>
            </Box>
            <Box className="footert">
              <Box><img style={{ paddingTop: "5px" }} src={calender} /></Box>
              <Box><Typography style={{ color: "white", paddingTop: "5px", paddingRight: "5px" }}>11 Apr, 2021 - 18 Apr, 2021</Typography></Box>
            </Box>
          </Box>
          <Box className="footerbox">
            <Typography>EC2 Instances Running</Typography>
            <Box className="footerse">
              <Box><Typography style={{ fontWeight: "bolder", padding: "10px", fontSize: "20px" }}>13</Typography></Box>
              <Box><img src={minichart3} /></Box>
            </Box>
            <Box className="footert">
              <Box><img style={{ paddingTop: "5px" }} src={calender} /></Box>
              <Box><Typography style={{ color: "white", paddingTop: "5px", paddingRight: "5px" }}>11 Apr, 2021 - 18 Apr, 2021</Typography></Box>
            </Box>
          </Box>
          <Box className="footerbox">
            <Typography>EC2 Instances Running</Typography>
            <Box className="footerse">
              <Box><Typography style={{ fontWeight: "bolder", padding: "10px", fontSize: "20px" }}>11</Typography></Box>
              <Box><img src={minichart4} /></Box>
            </Box>
            <Box className="footert">
              <Box><img style={{ paddingTop: "5px" }} src={calender} /></Box>
              <Box><Typography style={{ color: "white", paddingTop: "5px", paddingRight: "5px" }}>11 Apr, 2021 - 18 Apr, 2021</Typography></Box>
            </Box>
          </Box>
          <Box className="footerbox">
            <Typography>EC2 Instances Running</Typography>
            <Box className="footerse">
              <Box><Typography style={{ fontWeight: "bolder", padding: "10px", fontSize: "20px" }}>16</Typography></Box>
              <Box><img src={minichart5} /></Box>
            </Box>
            <Box className="footert">
              <Box><img style={{ paddingTop: "5px" }} src={calender} /></Box>
              <Box><Typography style={{ color: "white", paddingTop: "5px", paddingRight: "5px" }}>11 Apr, 2021 - 18 Apr, 2021</Typography></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </section>
  )

}
export default CloudInfoa