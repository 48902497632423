/* eslint-disable jsx-a11y/alt-text */

import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import full_screen_icn from "../../assets/img/full_screen_icn.png";
import { useEffect, useMemo, useState, useContext } from "react";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import "./style.css";
import MainLoader from "../../Component/Spinner/MainLoader";
import DonutChart from "react-donut-chart";
// import CloudInfoTwo from "../../Component/cloudInfoChart";
import CloudInfoTwo from "../../Component/cloudInfoChart/index";
import { Api } from "../../MyNewUtils/Api";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useNavigate } from "react-router-dom";
import searchicon from "../../assets/img/glass.png";
import BucketList from "../CloudInfo/BucketList";
import Zoom from "./zoommodel";
import { toast } from "react-toastify";
import AccountIdList from "../CloudInfo/AccountIdList";
import AccountNameList from "../CloudInfo/AccountNameList";
import AwsServicesList from "../CloudInfo/AwsServicesList";
import Grid from "@mui/system/Unstable_Grid/Grid";
import myContext from "../MyContext/MyContext";
import { useRef } from "react";
import "../CloudInfoChart/style.css";
const CloudInfoChart = () => {
  const [isLoading, setIsLoading] = useState();
  const [model, setModel] = useState(false);
  const [awsChartData, setAwsChartData] = useState();
  const [showTable, setShowTable] = useState(false);
  const [showZoomChart, setShowZoomChart] = useState(false);
  const [allData, setAllData] = useState();
  const [reactDonutChartdataAll, setReactDonutChartdataAll] = useState([]);
  const [tableTitle, setTableTitle] = useState([]);
  const [footerData, setFooterdata] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [color, setColor] = useState([]);
  const [showTableData, setShowTableData] = useState([]);
  const [showerror, setShowError] = useState(false);
  const navigate = useNavigate();

  const { cloudDetails } = useContext(myContext);

  const elementRef = useRef(430);
  useEffect(() => {
    elementRef.current = elementRef.current - 100;
  });
  const getData = async () => {
    setIsLoading(true);
    let final = {
      id: JSON.parse(localStorage.getItem("user"))?.data?.id,
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
      region: JSON.stringify(cloudDetails?.selectedCloudRegionsID),
    };
    const { statusCode, data } = await Api.postRequest(
      "/accounts/get-account-statistics",
      final
    );
    const res = JSON.parse(data);
    if (res?.status == 1) {
      setAllData(res?.data);
      setFooterdata(res?.data?.servicedata);
      let dataArray = [];
      let color = [];
      res?.data.servicelist.map((item, index) => {
        dataArray[index] = {
          ...dataArray[index],
          label: item.text,
          value: item.totalNumberofData,
          color: item.color,
          icon: item.icon,
          data: item.data,
        };
        color[index] = item.color;
      });
      setColor(color);
      setReactDonutChartdataAll(dataArray);
      setAwsChartData(res?.data.servicelist);
      setIsLoading(false);
    } else {
      // toast.error(res?.message)
      setShowError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      cloudDetails?.selectedCloudAccountID !== undefined &&
      cloudDetails?.selectedCloudAccountID.length !== 0
    )
      getData();
  }, [
    cloudDetails?.selectedCloudServers,
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudRegionsID,
  ]);

  // useEffect(() => {
  // }, [])

  // useEffect(() => {
  //   A_id_List?.slice(0, 1)?.map((row) => {
  //     console.log("csxgdxeyhtf", row?.accountId)
  //     let aid = row?.accountId
  //     setRadioBttonValue(aid)
  //   })
  // }, [])

  // ----------------------------------
  const cls = useMemo(() => "CloudInfoChart", []);
  const [chartData, setChartData] = useState([]);
  const reactDonutChartdata =
    reactDonutChartdataAll.length != 0
      ? reactDonutChartdataAll
      : [
        {
          label: "",
          value: awsChartData?.numberOfS3Buckets,
          color: "",
          data: awsChartData?.S3BucketsArr,
        },
      ];

  const reactDonutChartSelectedOffset = 0.04;
  const reactDonutChartHandleClick = (item, toggled) => {
    if (toggled) {
    }
  };
  let reactDonutChartStrokeColor = "#FFFFFF";

  const reactDonutChartOnMouseEnter = (item) => {
    setModelData(item);
    setChartData(item);
    let color = reactDonutChartdata?.find((q) => q.label === item.label).color;
    reactDonutChartStrokeColor = color;

    let title = [];
    Object.keys(item?.data[0]).map((key, i) => (title[i] = key));
    let tableData = [];
    item.data.slice(0, 2).map((itemtable, index) => {
      console.log("itemtable----", itemtable);
      tableData[index] = {
        ...tableData[index],
        Name: itemtable?.Name,
        Date: itemtable["Creation Date"]
          ? itemtable["Creation Date"]
          : itemtable["Launch Date"],
      };
    });
    setShowTableData(tableData);
    console.log("tableData----", title);
    setTableTitle(title);
    setShowTable(true);
  };

  const modelOpen = () => {
    setModel(true);
  };

  let modified_servicelist = [];
  if (reactDonutChartdataAll?.length > 0) {
    modified_servicelist = reactDonutChartdataAll?.reduce(
      (rows, key, index) => {
        return (
          (index % 3 === 0
            ? rows.push([key])
            : rows[rows?.length - 1].push(key)) && rows
        );
      },
      []
    );
  }

  const tableButton = (item) => {
    setModelData(item);
    setChartData(item);
    let color = reactDonutChartdata?.find((q) => q.label === item.label).color;
    reactDonutChartStrokeColor = color;

    let title = [];
    Object.keys(item?.data[0]).map((key, i) => (title[i] = key));
    let tableData = [];
    item.data.slice(0, 2).map((item, index) => {
      console.log();
      tableData[index] = {
        ...tableData[index],
        Name: item?.Name,
        Date: item["Creation Date"],
      };
    });
    setShowTableData(tableData);
    setTableTitle(title);
    setShowTable(true);
    setModel(true);
  };

  return (
    <Box className={`${cls}__background`}>
      <MainLoader isLoading={isLoading} />
      {model ? (
        <BucketList
          setModel={setModel}
          allChartdata={modelData}
          tableTitle={tableTitle}
        />
      ) : null}
      {showZoomChart ? (
        <Zoom
          showZoomChart={showZoomChart}
          setShowZoomChart={setShowZoomChart}
          allData={allData}
          color={color}
          showerror={showerror}
        />
      ) : null}
      {/* Header */}
      <DashboardHeader
        activeTab={"CLOUD INFO"}
        accountSelectHeaderShow={true}
        showRegionPart={true}
      />

      <Box style={{ flexGrow: 1, marginTop: "40px", overflow: "auto" }}>
        <Box
          style={{
            padding: "20px",
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            className="full_box_container"
          >
            <Box
              style={{
                width: "40%",
                backgroundColor: "rgba(0,0,0,0.5)",
                padding: "20px 40px",
                display: "flex",
                flexDirection: "column",
                borderRadius: "20px",
                // alignItems:"f"
              }}
              className="first_grid_container_resp"
            >
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                <Box>
                  <Typography style={{ color: "white", fontSize: "14px" }}>
                    AWS Services
                  </Typography>
                </Box>
                <Box>
                  <img
                    onClick={() => {
                      setShowZoomChart(true);
                    }}
                    src={full_screen_icn}
                    style={{ cursor: "pointer" }}
                  />
                  {/* <img src={information} style={{ margin: '0px 20px' }} />
                  <img src={more_btn_icn} /> */}
                </Box>
              </Box>
              <Box
                style={{
                  display: "flex",
                  marginTop: "10px",
                  // justifyContent: "center",
                }}
              >
                <Box
                  style={
                    showerror ? { marginRight: "30px" } : { marginRight: "0px" }
                  }
                >
                  {reactDonutChartdata?.map((row, index) => (
                    <Box
                      style={{
                        display: "flex",
                        marginTop: "10px",
                        color: "white",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        sx={{
                          width: "17px",
                          height: "11px",
                          background: row?.color,
                          marginRight: "5px",
                        }}
                      ></Box>
                      <Typography style={{ fontSize: "12px" }}>
                        {row.label}
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box>
                  <Box
                    className="App"
                    style={{
                      background: "#0000",
                      width: "350px",
                      height: "350px",
                      position: "relative",
                    }}
                  >
                    <Box
                      sx={{
                        paddiing: "auto",
                        display: "flex",
                        position: "absolute",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "350px",
                        height: "350px",
                        textAlign: "left",
                      }}
                    >
                      <DonutChart
                        onMouseEnter={(item) =>
                          reactDonutChartOnMouseEnter(item)
                        }
                        strokeColor={reactDonutChartStrokeColor}
                        data={reactDonutChartdata}
                        colors={color}
                        selectedOffset={reactDonutChartSelectedOffset}
                        onClick={(item, toggled) =>
                          reactDonutChartHandleClick(item, toggled)
                        }
                        height={430}
                        width={430}
                        ref={elementRef}
                        legend={false}
                        className="donutchart_aws"
                      />
                    </Box>

                    <Box
                      sx={
                        showTable
                          ? {
                            marginLeft: "126px",
                            marginTop: "82px",
                            position: "absolute",
                            color: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "auto",
                            height: "auto",
                            textAlign: "center",
                          }
                          : {
                            marginTop: "45px",
                            position: "absolute",
                            color: "#fff",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "auto",
                            height: "auto",
                            textAlign: "center",
                          }
                      }
                      className={
                        showTable ? "don_label_chart" : "don_label_chart_labal"
                      }
                    >
                      <Typography
                        style={showTable ? null : { display: "none" }}
                        className="don_label_1"
                      >
                        {chartData?.label}
                      </Typography>
                      <TableContainer
                        component={Paper}
                        style={
                          showTable
                            ? {
                              scrollbarWidth: "none",
                              width: "180px",
                              height: "120px",
                              background: "rgba(232, 84, 114,0.25)",
                              borderRadius: "30px",
                              padding: "0px 0px 0px 15px",
                            }
                            : { display: "none" }
                        }
                        className="donut_chart_data_resp"
                      >
                        <Table sx={{ width: "100%", height: "10px" }}>
                          <TableRow
                            sx={{
                              paddingBottom: "15px",
                              display: "flex",
                              height: "5px",
                              margingLeft: "10px",
                              background: "#0000",
                              marginBotton: "15px",
                            }}
                            className="cloud_label_ass"
                          >
                            <TableCell
                              sx={{
                                background: "#0000",
                                fontSize: "15px",
                                border: "none",
                                padding: "0px",
                                color: "#fff",
                                paddingLeft: "5px",
                              }}
                              className="chart_data_label_resp"
                            >
                              Name
                            </TableCell>
                            <TableCell
                              sx={{
                                background: "#0000",
                                fontSize: "15px",
                                border: "none",
                                padding: "0px",
                                color: "#fff",
                                paddingLeft: "40px",
                                textAlign: "right",
                              }}
                              className="chart_data_label_resp"
                            >
                              Date
                            </TableCell>
                          </TableRow>
                          {showTableData.map((item, index) => {
                            console.log("item", item);
                            let date = new Date(item.Date);
                            let year = date.getFullYear();
                            let month = date.getMonth() + 1;
                            let dt = date.getDate();
                            let day = year + "-" + month + "-" + dt;
                            return (
                              <>
                                <TableRow
                                  sx={{
                                    display: "flex",
                                    height: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <TableCell
                                    sx={{
                                      lineBreak: "anywhere",
                                      width: "45%",
                                      fontSize: "10px",
                                      border: "none",
                                      color: "#796473",
                                      padding: "0px",
                                      paddingLeft: "5px",
                                    }}
                                  // className="donut_chart_data_resp"
                                  >
                                    {/* {item?.Name} */}
                                    {item?.Name?.length <= 24
                                      ? item?.Name
                                      : item?.Name?.slice(0, 24).concat("...")}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      width: "50%",
                                      paddingRight: "5px",
                                      fontSize: "10px",
                                      border: "none",
                                      color: "#796473",
                                      padding: "0px",
                                      paddingLeft: "5px",
                                    }}
                                  // className="donut_chart_data_resp"
                                  >
                                    {item.Date != undefined
                                      ? year + "-" + month + "-" + dt
                                      : null}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                          {/* </TableBody> */}
                        </Table>
                      </TableContainer>
                      <Typography
                        style={
                          showTable
                            ? {
                              fontSize: "12px",
                              color: "#fff",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                              cursor: "pointer",
                              marginTop: "-20px",
                            }
                            : { display: "none" }
                        }
                        className="donut_chart_data_resp"
                        onClick={() => {
                          modelOpen();
                        }}
                      >
                        View in Details{" "}
                        <img
                          style={{ width: "13px", height: "13px" }}
                          src={searchicon}
                        />
                      </Typography>
                      <Typography
                        style={
                          showTable
                            ? {
                              fontSize: "20px",
                              fontWeight: "bold",
                              marginBotton: "10px",
                              marginLeft: "0%",
                            }
                            : {
                              fontSize: "50px",
                              fontWeight: "bold",
                              marginLeft: "0%",
                              marginTop: "65px",
                            }
                        }
                        className="don_label_1"
                      >
                        {allData?.totalservices}
                      </Typography>
                      {showerror ? (
                        <Typography
                          variant="h5"
                          style={{ margin: "20px", color: "white" }}
                          className="donut_chart_text_data"
                        >
                          No Services
                          <br /> available.
                        </Typography>
                      ) : (
                        <Typography
                          style={
                            showTable
                              ? {
                                fontSize: "12px",
                                color: "rgba(255, 255, 255, 0.25)",
                                marginLeft: "0%",
                              }
                              : {
                                fontSize: "36px",
                                color: "rgba(255, 255, 255, 0.25)",
                              }
                          }
                          className={showTable ? "don_label_1" : "don_label_1"}
                        >
                          Services
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              style={{
                width: "55%",
                backgroundColor: "rgba(0,0,0,0.5)",
                display: "flex",
                flexDirection: "column",
                padding: "20px 40px",

                borderRadius: "20px",
                // alignItems:"f"
              }}
              className="cards_grid_container"
            >
              <Typography
                style={{
                  color: "#FF9900",
                  fontSize: "22px",
                  fontWeight: "600",
                }}
              >
                AWS services
              </Typography>
              {showerror ? (
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Typography variant="h5" style={{ color: "white" }}>
                    No Services available.
                  </Typography>
                </Box>
              ) : (
                <Box
                  style={{
                    width: "100%",
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <table width="100%">
                    {modified_servicelist?.map((row) => (
                      <tr style={{ background: "#0000" }}>
                        {row?.map((col) => (
                          <td
                            onClick={() => tableButton(col)}
                            style={{ width: "10px", cursor: "pointer" }}
                          >
                            <Button name={col} style={{ width: "5px" }}>
                              <img
                                src={col?.icon}
                                style={{ width: "22px", height: "22px" }}
                              />
                            </Button>
                            <Typography
                              style={{
                                color: "rgba(255,255,255,0.6)",
                                fontSize: "14px",
                                display: "initial",
                                textAlign: "center",
                              }}
                            >
                              {col?.label}
                            </Typography>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </table>
                </Box>
              )}
            </Box>
          </Box>
          <Grid>
            <CloudInfoTwo
              allData={footerData}
              accountId={cloudDetails?.selectedCloudAccountID}
              region={cloudDetails?.selectedCloudRegionsID}
            />
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default CloudInfoChart;
