import { Button } from "@mui/material";
import { Box } from "@mui/system";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useMemo } from "react";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import aws from "../../assets/img/aws_cloud_icn@2x.png";
import azure from "../../assets/img/azure_icn.png";
import firebase from "../../assets/img/firebase_icn.png";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import Grid from "@mui/system/Unstable_Grid/Grid";
import ConfigureCloudMessageModel from "../../screens/selectCloudInfrastructure/ConfigureCloudMessageModel";
import "../selectCloudInfrastructureDetails/style.css";

const StepperItem = ({ number, title, isLineShow, isActive }) => {
  return (
    <Box
      className={`cloud_step_circle ${isLineShow
          ? "cloud_step_circle_line_show"
          : "cloud_step_circle_without_line_show"
        } `}
    >
      <Box
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        <Box
          style={{
            backgroundColor: isActive ? "#008CF2" : "rgb(3,7,42)",
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: `2px solid ${isActive ? "#008CF2" : "white"}`,
          }}
        >
          {isActive ? (
            <CheckIcon fontSize={"large"} />
          ) : (
            <Typography>{number}</Typography>
          )}
        </Box>
        {isLineShow && (
          <Box
            style={{
              backgroundColor: isActive ? "#008CF2" : "white",
            }}
            className="cloud_steps_Line"
          ></Box>
        )}
      </Box>
      <Box style={{ marginTop: "20px" }}>
        <Typography
          style={{ color: "#008CF2", fontWeight: "700" }}
          className="cloud_steps_title"
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};
const DashBoardSctionPage1Body = () => {
  const cls = useMemo(() => "DahboardThreeBody", []);
  const navigate = useNavigate();
  const [cloudPlateForm, setCloudPlateForm] = useState("");

  const Next = () => {
    if (cloudPlateForm === "aws") {
      navigate("/setup-source", { state: { cloudPlateForm: cloudPlateForm } });
    } else {
      toast.error("Please Select Cloud Infrastructure", { autoClose: 1000 });
    }
  };

  return (
    <>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          style={{
            width: "90%",
            marginTop: "30px",
            padding: "30px",
            marginBottom: "30px",
          }}
        >
          <Box
            style={{
              padding: "10px",
              marginBottom: "20px",
              display: "flex",
            }}
            className="cloud_main_ui_box"
          >
            <Box className="custome_stepper">
              {/* custome stepper */}
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <StepperItem
                  number={"1"}
                  title={"Select Cloud Infrastructure"}
                  isLineShow={true}
                  isActive={cloudPlateForm !== ""}
                />
                <StepperItem
                  number={"2"}
                  title={"Setup Source"}
                  isLineShow={true}
                />
                <StepperItem
                  number={"3"}
                  title={"Preview & Done"}
                  isLineShow={false}
                />
              </Box>
              {/* button */}
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  height: "100px",
                }}
                className="cloud_next_btn"
              >
                <Button
                  variant="contained"
                  style={{
                    marginLeft: "20px",
                    borderRadius: "20px",
                    padding: "5px 25px",
                    color: "white",
                    width: "180px",
                    margin: "10px",
                  }}
                  onClick={Next}
                >
                  Next
                </Button>
                {/* <Button
              variant="outlined"
              style={{
                marginLeft: '20px',
                borderRadius: '20px',
                padding: '5px 25px',
                color: 'white',
                width: '180px',
                margin: '10px',
              }}
            >
              Back
            </Button> */}
              </Box>
            </Box>
          </Box>

          <Box
            style={{
              padding: "50px",
              display: "flex",
              borderRadius: "30px",
              backgroundColor: "rgba(0,0,0,0.5)",
              border: "1px solid #707070",
              justifyContent: "space-evenly",
            }}
            className="aws_cloud_box"
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                //   backgroundColor: 'red',
                padding: "20px 60px",
                border: cloudPlateForm === "aws" && "1px solid gray",
                borderRadius: "64px",
                cursor: "pointer",
              }}
              onClick={() => setCloudPlateForm("aws")}
            >
              <Box>
                <img src={aws} />
              </Box>
              <Box style={{ marginLeft: "10px" }}>
                <Typography style={{ fontSize: "28px", fontWeight: "bold" }}>
                  AWS Cloud
                </Typography>
              </Box>
            </Box>

            {/* <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              border: cloudPlateForm === 'azure' && '1px solid gray',
              padding: '20px 60px',
              borderRadius: '64px',
              cursor: 'pointer',
            }}
            onClick={() => setCloudPlateForm('azure')}
          >
            <Box>
              <img src={azure} />
            </Box>
            <Box style={{ marginLeft: '10px' }}>
              <Typography style={{ fontSize: '28px', fontWeight: 'bold' }}>
                Azure Cloud
              </Typography>
            </Box>
          </Box>

          <Box
            style={{
              display: 'flex',
              alignItems: 'center',
              border: cloudPlateForm === 'firebase' && '1px solid gray',
              padding: '20px 60px',
              borderRadius: '64px',
              cursor: 'pointer',
            }}
            onClick={() => setCloudPlateForm('firebase')}
          >
            <Box>
              <img src={firebase} />
            </Box>
            <Box style={{ marginLeft: '10px' }}>
              <Typography style={{ fontSize: '28px', fontWeight: 'bold' }}>
                Firebase Cloud
              </Typography>
            </Box>
          </Box> */}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DashBoardSctionPage1Body;
