import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Alerticon from '../../../assets/img/triangle_icn.png'
import Refresh from '../../../assets/img/refresh_icn.png'
import Avatar from '@mui/material/Avatar';
import { IconButton } from '@mui/material';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { CardMedia } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    bgcolor: '#01081D',
    border: ' 1px solid #707070',
    boxShadow: 24,
    p: 4,
  };
  
const VerifyNumberModel =()=>{
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return(
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize:'30px',textAlign:'center',color:'#008CF2'}}>
          Verify your mobile number...
          </Typography>

          <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize:'20px',textAlign:'center',color:'#FFFFFF',marginTop:'32px'}}>
          We sent a verification code to your mobile number.
          </Typography>          

          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:'22px',textAlign:'center',fontWeight:'bold',color:'#008CF2',marginTop:'32px'}}>
            Verify code
          </Typography>

          <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize:'20px',fontWeight:'normal',textAlign:'center',color:'#FFFFFF',marginTop:'32px'}}>
            <input type='number' style={{background:'#000',borderRadius:'20px',height:'40px',color:'#fff',fontSize:'20px'}} />
          </Typography>  

          <Typography style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'20px'}}>
            <hr style={{width:'70%'}}/>
          </Typography>        

          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:'22px',textAlign:'center',fontWeight:'bold',color:'#FFFFFF',marginTop:'32px'}}>
            <IconButton sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src={Alerticon}/>
            </IconButton>Haven't received the verification code?
          </Typography>
          

          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:'22px',textAlign:'center',fontWeight:'bold',color:'#FFFFFF',marginTop:'32px'}}>
            <IconButton sx={{ p: 0 }}>
              <Avatar alt="Remy Sharp" src={Refresh}/>
            </IconButton>Resend verification
          </Typography>
          
          <Typography style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'20px'}}>
            <hr style={{width:'40%'}}/>
          </Typography>
          
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{marginTop:'32px',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Stack spacing={2} direction="row">
                    <Button variant="contained">VERIFY</Button>
            </Stack>
          </Typography>

        </Box>
      </Modal>
    </div>
    )
}
export default VerifyNumberModel;