import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import alert from "../../../assets/img/triangle_icn@2x.png";
import cancelicon from "../../../assets/img/cancel_round_icn.png";
import SwitchUnstyled, {
  switchUnstyledClasses,
} from "@mui/base/SwitchUnstyled";
import { conutryList } from "../../../utils/country";
import { useState } from "react";
import { styled } from "@mui/system";
import { toast } from "react-toastify";
import { Api } from "../../../MyNewUtils/Api";
import { useNavigate } from "react-router";
import MainLoader from "../../../Component/Spinner/MainLoader";
import SubLoader from "../../../Component/Spinner/subLoader";
const style = {
  position: "absolute",
  width: 689,
  bgcolor: "#01081D",
  border: "1px solid #707070",
  boxShadow: 24,
  p: 4,
  padding: "68px 114px 68px 114px",
};
const stylecancel = {
  position: "absolute",
  bgcolor: "#01081D",
  border: "1px solid #707070",
  boxShadow: 24,
  // p: 4,
  // padding: '68px 114px 68px 114px'
};
const Root = styled("span")`
  font-size: 0;
  position: relative;
  display: inline-block;
  width: 32px;
  height: 20px;
  margin: 10px;
  cursor: pointer;
  &.${switchUnstyledClasses.disabled} {
    opacity: 0.4;
    cursor: not-allowed;
  }
  & .${switchUnstyledClasses.track} {
    background: #b3c3d3;
    border-radius: 10px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  & .${switchUnstyledClasses.thumb} {
    display: block;
    width: 14px;
    height: 14px;
    top: 3px;
    left: 3px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;
    transition: all 200ms ease;
  }
  &.${switchUnstyledClasses.checked} {
    .${switchUnstyledClasses.thumb} {
      left: 14px;
      top: 3px;
      background-color: #fff;
    }
    .${switchUnstyledClasses.track} {
      background: #007fff;
    }
  }
  & .${switchUnstyledClasses.input} {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
`;
// cancel model
function ChildModal({ setAddNewAddressModel }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const ChildModalClose = () => {
    setOpen(false);
  };
  const handleClose = () => {
    setAddNewAddressModel(false);
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleOpen}
        variant="outlined"
        style={{ marginLeft: "10px" }}
      >
        Cancel
      </Button>
      <Modal
        hideBackdrop
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
        style={{
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ ...stylecancel, width: "50%", padding: "14px 10px 60px 0" }}>
          <Typography style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={cancelicon} width="30px" onClick={ChildModalClose} />
          </Typography>
          <Typography
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "30px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            Are you sure you would like to cancel?
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img src={alert} width="60px" />
          </Typography>
          <Typography
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                background: "#008CF2",
                borderRadius: "20px",
                color: "#fff",
                marginRight: "27px",
              }}
            >
              Yes, Cancel it !
            </Button>
            <Button
              onClick={ChildModalClose}
              style={{
                background: "#0000",
                borderRadius: "20px",
                color: "#fff",
                border: "solid 1px #008CF2",
              }}
            >
              No, return
            </Button>
          </Typography>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
const AddNewAddress = ({ disable, setAddNewAddressModel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [countryCode, setCountryCode] = useState(conutryList);
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setAddNewAddressModel(false);
  React.useEffect(() => { }, [disable]);
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");

  const userData = JSON.parse(localStorage.getItem("userData"));
  const countryno = countryCode?.filter(
    (row) => row?.name === userData?.country
  );
  const [country, setCountry] = useState(countryno[0]?.name);
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [subloader, setSubloader] = useState(false);
  const [state, setState] = useState("");
  const Zip = (e) => {
    if (e.target.value.length <= 6) {
      setPincode(e.target.value);
    }
  };
  const addaddress = async () => {
    setSubloader(true);
    setIsLoading(true);
    if (
      address1.length != "0" &&
      address2.length != "0" &&
      country.length != "0" &&
      city.length != "0" &&
      state.length != "0" &&
      pincode.length != "0"
    ) {
      let final = {
        userid: JSON.parse(localStorage.getItem("user")).data.id,
        address1: address1,
        address2: address2,
        country: country,
        pincode: pincode,
      };
      const { statusCode, data } = await Api.postRequest(
        "/users/add-new-billing-address",
        final
      );
      const res = JSON.parse(data);

      // if (res?.status === 1) {
      //     setIsLoading(false)
      //     toast.success(res.message)
      if (res?.status === 1) {
        setSubloader(false);
      }
      setAddNewAddressModel(false);
      // navigate('/Profile')
      // } else {
      //     setIsLoading(false)
      //     toast.error(res.Error)
      // }
      toast.success(res?.message, { autoClose: 1000 });
      toast.error(res?.Error, { autoClose: 1000 });
    } else {
      setIsLoading(false);
      setSubloader(false);
      toast.error("Please Fill all Fields", { autoClose: 1000 });
    }
    setIsLoading(false);
    setSubloader(false);
  };
  return (
    <div>
      <MainLoader isLoading={isLoading} />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontWeight: "bold", fontSize: "26px", color: "#FF8800" }}
          >
            Add New Address
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "30px",
            }}
          >
            Address
          </Typography>
          <Typography>
            <input
              type="text"
              onChange={(e) => setAddress1(e.target.value)}
              style={{
                background: "#010613",
                color: "#fff",
                height: "35px",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
            />
          </Typography>
          <Typography>
            <input
              type="text"
              onChange={(e) => setAddress2(e.target.value)}
              placeholder="Apartment, suite, unit, building, floor, etc."
              style={{
                background: "#010613",
                height: "35px",
                color: "#fff",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "10px",
            }}
          >
            City
          </Typography>
          <SubLoader isLoading={subloader} />
          <Typography>
            <input
              type="text"
              onChange={(e) => setCity(e.target.value)}
              style={{
                background: "#010613",
                color: "#fff",
                height: "35px",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "10px",
            }}
          >
            State, Province, or Region
          </Typography>
          <Typography>
            <input
              type="text"
              onChange={(e) => setState(e.target.value)}
              style={{
                background: "#010613",
                color: "#fff",
                height: "35px",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
            />
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "10px",
            }}
          >
            Country or Region
          </Typography>
          <Typography>
            <select
              // onChange={onCountryoregionSet}
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              defaultValue={countryno[0]?.name}
              onChange={(e) => setCountry(e.target.value)}
            >
              {countryCode.map((item) => (
                <option
                  value={item.name}
                >{`${item.name}  (${item.dial_code})`}</option>
              ))}
            </select>
          </Typography>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "18px",
              marginTop: "10px",
            }}
          >
            Zip Code
          </Typography>
          <Typography>
            <input
              type="number"
              value={pincode}
              onChange={Zip}
              style={{
                background: "#010613",
                color: "#fff",
                height: "35px",
                border: "1px solid #363636",
                width: "100%",
                marginTop: "5px",
              }}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
          </Typography>
          <Typography style={{ display: "flex", justifyContent: "flex-end" }}>
            <Typography
              style={{
                display: "flex",
                fontWeight: "normal",
                fontSize: "16px",
                color: "#008CF2",
                alignItems: "center",
              }}
            >
              {" "}
              <SwitchUnstyled component={Root} /> use as billing address
            </Typography>
          </Typography>

          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "34px",
            }}
          >
            <Button
              variant="contained"
              onClick={addaddress}
              style={{ borderRadius: "20px" }}
            >
              Submit
            </Button>
            <ChildModal setAddNewAddressModel={setAddNewAddressModel} />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
export default AddNewAddress;
