import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@material-ui/core/TextField";
import roundClose from "../../assets/img/cancel_icn.png";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { Api } from "../../MyNewUtils/Api";
import { conutryList } from "../../utils/country";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#010A26",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const EditDashboardModel = ({
  editDashboardModelOpen,
  seteditDashboardModelOpen,
  resetChildpasswordModelValue,
  setResetChildpasswordModelOpenValue,
  refreshList,
  setRefresh,
}) => {
  const [open, setOpen] = useState(editDashboardModelOpen);
  const [countryCode, setCountryCode] = useState(conutryList);
  const [useUpdateData, setUseUpdateData] = useState({
    name: resetChildpasswordModelValue?.name,
    Email: resetChildpasswordModelValue?.email,
    number: resetChildpasswordModelValue?.Contact,
    countryCode: resetChildpasswordModelValue?.CountryCode,
  });
  const handleClose = () => {
    seteditDashboardModelOpen(false);
    setResetChildpasswordModelOpenValue();
  };
  const useUpdateDataName = (e) => {
    setUseUpdateData({ ...useUpdateData, name: e.target.value });
  };
  const useUpdateDataEmail = (e) => {
    setUseUpdateData({ ...useUpdateData, Email: e.target.value });
  };
  const useUpdateDataNumber = (e) => {
    setUseUpdateData({ ...useUpdateData, number: e.target.value });
  };
  const useUpdateCountryCode = (e) => {
    setUseUpdateData({ ...useUpdateData, countryCode: e.target.value });
  };
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setUseUpdateData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let lapdata = JSON.parse(localStorage.getItem("user")).data.ldapcred;
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      uid: lapdata?.uid,
      uidW: lapdata?.uidW,
      secret: lapdata?.secret,
      url: lapdata?.url,
      userphone: useUpdateData?.number,
      // usercountrycode:useUpdateData,
      username: useUpdateData?.name,
      useremail: useUpdateData?.Email,
      usercountrycode: useUpdateData?.countryCode,
      childuserid: resetChildpasswordModelValue?.id,
    };
    const { data } = await Api.postRequest("/ldap/update-user", final);
    const res = JSON.parse(data);
    if (res?.status == 1) {
      handleClose();
      setRefresh(!refreshList);
      toast.success(res.message, { autoClose: 1000 });
    } else {
      toast.error(res.message, { autoClose: 1000 });
    }
    console.log("save item", res);
  };
  console.log(
    "useUpdateData-useUpdateData-useUpdateData",
    resetChildpasswordModelValue
  );
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          className="Reset-Child-password-Model-box "
          sx={{
            minWidth: "25%",
            maxHeight: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "text",
              padding: "0px 0px 0px 0px",
            }}
          >
            <img
              src={roundClose}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              alt=""
            />
          </Box>

          <Box>
            <Typography style={{ color: "#FF8800", fontSize: "22px" }}>
              Update User
            </Typography>
          </Box>
          <form onSubmit={onSubmit}>
            <Box>
              <Typography sx={{ color: "#fff", marginTop: "20px" }}>
                Name
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <TextField
                  type="text"
                  name="fname"
                  variant="standard"
                  inputProps={{}}
                  InputProps={{
                    style: {
                      color: "white",
                      borderBottom: "2px solid rgb(0,117,210)",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "rgba(255,255,255,0.4)",
                    },
                  }}
                  onChange={useUpdateDataName}
                  defaultValue={resetChildpasswordModelValue?.name}
                  required
                  fullWidth
                />
              </Box>
            </Box>
            <Box>
              <Typography sx={{ color: "#fff", marginTop: "20px" }}>
                Email
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <TextField
                  type="email"
                  name="fname"
                  variant="standard"
                  inputProps={{}}
                  InputProps={{
                    style: {
                      color: "white",
                      borderBottom: "2px solid rgb(0,117,210)",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "rgba(255,255,255,0.4)",
                    },
                  }}
                  onChange={useUpdateDataEmail}
                  defaultValue={resetChildpasswordModelValue?.email}
                  required
                  fullWidth
                />
              </Box>
            </Box>
            <Box>
              <Typography sx={{ color: "#fff", marginTop: "20px" }}>
                Country Code
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <select
                  name="countryCode"
                  onChange={inputEvent}
                  value={useUpdateData?.countryCode}
                  style={{
                    width: "100%",
                    backgroundColor: "black",
                    border: "1px solid #363636",
                    padding: "4px",
                    color: "#FFF",
                    marginTop: "10px",
                  }}
                >
                  {countryCode.map((item) => (
                    <option
                      value={item.dial_code}
                    >{`${item.name}  (${item.dial_code})`}</option>
                  ))}
                </select>
              </Box>
            </Box>
            <Box>
              <Typography sx={{ color: "#fff", marginTop: "20px" }}>
                Contact Number
              </Typography>
              <Box sx={{ flexGrow: 1 }}>
                <TextField
                  type="number"
                  name="lnumber"
                  value={useUpdateData.number}
                  onChange={useUpdateDataNumber}
                  variant="standard"
                  InputProps={{
                    style: {
                      color: "white",
                      borderBottom: "2px solid rgb(0,117,210)",
                    },
                  }}
                  InputLabelProps={{
                    style: {
                      color: "rgba(255,255,255,0.4)",
                    },
                  }}
                  style={{ width: "100%" }}
                  required
                  fullWidth
                />
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "40px 0px 20px 0px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{ padding: "8px 40px", borderRadius: "20px" }}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};
export default EditDashboardModel;
