import Header from "../Header/header";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import aws_cloud_icn from "../../../assets/img/aws_cloud_icn.png";
import azure_icn from "../../../assets/img/azure_icn.png";
import firebase_icn from "../../../assets/img/firebase_icn.png";
import DashboardHeader from "../../../Component/DashboardHeader/DashboardHeader";
import { useNavigate } from "react-router-dom";
const CloudinfoHeader = ({
  cloudinfoManu,
  cloudinfoManuOpenSelect,
  cloudinfoManuCloseSelect,
}) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        style={cloudinfoManu ? {} : { display: "none" }}
        onMouseOver={cloudinfoManuOpenSelect}
        onMouseLeave={cloudinfoManuCloseSelect}
      >
        <Typography
          style={{
            background: "rgb(1, 4, 22)",
            display: "flex",
            flexDirection: "row",
            // paddingLeft: '20%',
            alignItems: "center",
            position: "relative",
            zIndex: 13,
          }}
        >
          <Box
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              className="borderBottom"
              onClick={() => {
                navigate("/cloudinfo-chart");
              }}
              style={
                window.location.pathname === `/cloudinfo-chart`
                  ? {
                    paddingBottom: "10px",
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "rgb(129, 245, 255)",
                    padding: "1% 0",
                    borderBottom: "1px solid rgb(129, 245, 255)",
                    display: "flex",
                  }
                  : {
                    paddingBottom: "10px",
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "#fff",
                    padding: "1% 0",
                    display: "flex",
                  }
              }
            >
              {/* <Typography onClick={() => { navigate('/cloudinfo-chart') }} style={{ fontSize: '16px', display: 'flex', alignItems: 'center', cursor: 'pointer' }} className='subHeaderTitle'> */}
              <img src={aws_cloud_icn} style={{ marginRight: "15px" }} />
              AWS Cloud
              {/* </Typography> */}
            </Box>
          </Box>
          {/* <Typography style={{ color: '#fff', fontSize: '16px', display: 'flex', alignItems: 'center', marginRight: '50px' }} >
                        <img src={azure_icn} style={{ marginRight: '15px' }} />
                        Azure Cloud
                    </Typography>
                    <Typography style={{ color: '#fff', fontSize: '16px', display: 'flex', alignItems: 'center' }} >
                        <img src={firebase_icn} style={{ marginRight: '15px' }} />
                        Firebase Cloud
                    </Typography> */}
        </Typography>
      </Box>
    </>
  );
};
export default CloudinfoHeader;
