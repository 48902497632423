import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/system';
import { useState } from "react";
import Modal from '@mui/material/Modal';
import { Button, Box, Grid, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import SwitchUnstyled, { switchUnstyledClasses } from '@mui/base/SwitchUnstyled';
import search from "../../assets/img/search_icn.png"
import Checkbox from '@mui/material/Checkbox';
import { CSVLink } from "react-csv";
import './settingModel.css';

const SettingModel = ({ setModelSetting, arrayColumns, setRowLimitEnd, setArrayColumns, allColumns, rows, tableTitle }) => {



    const [open, setOpen] = useState(true);
    const handleClose = () => setModelSetting(false);
    const [showRow, setShowRow] = useState(arrayColumns)
    const [confirmColumn, setConfirmColumn] = useState(arrayColumns)
    const [selectRowCount, setSelectRowCount] = useState(5)
    console.log('arrayColumns', tableTitle)
    const blue = {
        500: '#007FFF',
    };

    const grey = {
        400: '#BFC7CF',
        500: '#AAB4BE',
        600: '#6F7E8C',
    };
    const Root = styled('span')(
        ({ theme }) => `
        font-size: 0;
        position: relative;
        display: inline-block;
        width: 40px;
        height: 20px;
        margin: 10px;
        cursor: pointer;
      
        &.${switchUnstyledClasses.disabled} {
          opacity: 0.4;
          cursor: not-allowed;
        }
      
        & .${switchUnstyledClasses.track} {
          background: ${theme.palette.mode === 'dark' ? grey[600] : grey[400]};
          border-radius: 10px;
          display: block;
          height: 100%;
          width: 100%;
          position: absolute;
        }
      
        & .${switchUnstyledClasses.thumb} {
          display: block;
          width: 14px;
          height: 14px;
          top: 3px;
          left: 3px;
          border-radius: 16px;
          background-color: #fff;
          position: relative;
          transition: all 200ms ease;
        }
      
        &.${switchUnstyledClasses.focusVisible} .${switchUnstyledClasses.thumb} {
          background-color: ${grey[500]};
          box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
        }
      
        &.${switchUnstyledClasses.checked} {
          .${switchUnstyledClasses.thumb} {
            left: 22px;
            top: 3px;
            background-color: #fff;
          }
      
          .${switchUnstyledClasses.track} {
            background: ${blue[500]};
          }
        }
      
        & .${switchUnstyledClasses.input} {
          cursor: inherit;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          z-index: 1;
          margin: 0;
        }
        `,
    );
    const label = { componentsProps: { input: { 'aria-label': 'Demo switch' } } };
    const requestSearch = (searchedVal) => {
        if (searchedVal) {
            const filteredRows = showRow.filter((row) => {
                return row.label.toLowerCase().includes(searchedVal.toLowerCase());
            });
            console.log('filteredRows', searchedVal.length)
            setShowRow(filteredRows);
        } else {
            setShowRow(arrayColumns);
        }

    };
    const getSwitch = (item) => {
        console.log('item', item)
        let finalColumn = []
        confirmColumn.map((confirmColumnitem, index) => {
            console.log('confirmColumnitem', confirmColumnitem)
            if (item.label === confirmColumnitem.label) {
                finalColumn[index] = { ...finalColumn[index], label: confirmColumnitem.label, field: confirmColumnitem.field, id: confirmColumnitem.id, numeric: confirmColumnitem.numeric, show: !confirmColumnitem.show }

            } else {
                finalColumn[index] = confirmColumnitem
            }

        })
        console.log('finalColumn', finalColumn)
        setConfirmColumn(finalColumn)
    }
    const getConfirm = () => {
        console.log('confirmColumnF', confirmColumn)
        setRowLimitEnd(selectRowCount)
        setArrayColumns(confirmColumn)
        setModelSetting(false)

    }
    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ background: 'rgb(225, 225, 225, 0.4)', backdropFilter: 'blur(5px)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
            >
                <Box sx={{
                    "&::-webkit-scrollbar": {
                        width: 10,
                        height: 10
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#0000",

                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ADD8E6",
                        borderRadius: 2,
                        border: '0px solid #0000'
                    }
                }}
                    className="setting-preferences-model-container"
                >

                    <Box>
                        <Box sx={{ display: 'flex', display: 'flex', justifyContent: 'space-between', padding: '5px 0 5px 0', borderBottom: '1px solid rgb(39, 46, 66)', background: '#0000' }}>
                            <Typography sx={{ color: '#fff' }}>Preferences</Typography>
                            <CloseIcon onClick={handleClose} sx={{ cursor: 'pointer', color: '#fff' }} />
                        </Box>
                        <Box sx={{ display: 'flex', padding: '15px 0 0px 0' }}>
                            <Grid container >
                                <Grid item xs={4}>
                                    <Box>
                                        <Box>
                                            <Typography sx={{ color: '#fff' }}>Page Size</Typography>
                                        </Box>
                                        <Box>
                                            <FormControl>
                                                <RadioGroup
                                                    aria-labelledby="demo-radio-buttons-group-label"
                                                    defaultValue={5}
                                                    name="radio-buttons-group"
                                                >
                                                    <FormControlLabel sx={{ color: '#fff', display: 'flex', alignItems: 'center' }} onChange={e => setSelectRowCount(5)} value={5} control={<Radio sx={{ color: '#fff' }} defaultChecked />} label="5" />
                                                    <FormControlLabel sx={{ color: '#fff', display: 'flex', alignItems: 'center' }} onChange={e => setSelectRowCount(10)} value={10} control={<Radio sx={{ color: '#fff' }} />} label="10" />
                                                    <FormControlLabel sx={{ color: '#fff', display: 'flex', alignItems: 'center' }} onChange={e => setSelectRowCount(25)} value={25} control={<Radio sx={{ color: '#fff' }} />} label="25" />
                                                    <FormControlLabel sx={{ color: '#fff', display: 'flex', alignItems: 'center' }} onChange={e => setSelectRowCount(20)} value={50} control={<Radio sx={{ color: '#fff' }} />} label="50" />
                                                </RadioGroup>
                                            </FormControl>
                                        </Box>

                                    </Box>
                                </Grid>
                                <Grid item xs={8}>
                                    <Box>
                                        <Typography sx={{ color: '#fff' }}>Attribute columns</Typography>
                                        <Typography sx={{ color: '#ffffffa6' }}>Select visible Attribute columns</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', background: 'rgb(39, 46, 66)', borderRadius: '20px' }}><img style={{ margin: '10px' }} src={search} />
                                            <input
                                                placeholder={'Search for Attribute columns'}
                                                onChange={(e) => requestSearch(e.target.value)}
                                                style={{

                                                    border: 'none',
                                                    outline: 'none',
                                                    padding: '5px',
                                                    width: '100%',
                                                    backgroundColor: 'transparent',
                                                    color: 'white',
                                                    fontSize: '15px'
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            {showRow.map((item, index) => {
                                                console.log('item', item)
                                                return (
                                                    <>
                                                        {item.label === 'Name' || item.label === 'User Name'
                                                            ? <Typography sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}>
                                                                <SwitchUnstyled component={Root} {...label} defaultChecked disabled value={item} />
                                                                {item.label}
                                                            </Typography>
                                                            : <Typography sx={{ color: '#fff', display: 'flex', alignItems: 'center' }}>
                                                                {confirmColumn[index].show
                                                                    ? <SwitchUnstyled component={Root} defaultChecked value={item} onClick={() => getSwitch(item)} />
                                                                    : <SwitchUnstyled component={Root} value={item} onClick={() => getSwitch(item)} />
                                                                }
                                                                {item.label}
                                                            </Typography>
                                                        }
                                                    </>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <CSVLink data={rows} headers={tableTitle} filename={"Data.csv"} >
                                <Typography sx={{ color: '#7EF0FA' }}>
                                    Download
                                </Typography>
                            </CSVLink>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Button onClick={handleClose} sx={{ color: '#7EF0FA' }}>
                                    Close
                                </Button>
                                <Button onClick={getConfirm} sx={{ color: '#7EF0FA' }}>
                                    confirm
                                </Button>

                            </Box>
                        </Box>
                    </Box>

                </Box>
            </Modal>
        </>
    )
}
export default SettingModel