import {
  Button,
  FormControl,
  FormControlLabel,
  Input,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { useMemo } from "react";
import { Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import aws from "../../assets/img/aws_cloud_icn@2x.png";
import azure from "../../assets/img/azure_icn.png";
import firebase from "../../assets/img/firebase_icn.png";
import rightArrow from "../../assets/img/yellowArrow.png";
import questionMark from "../../assets/img/questionamerk.png";
import exportImg from "../../assets/img/export.png";
import { toast } from "react-toastify";
import plusRound from "../../assets/img/plusRound.png";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import { Api } from "../../MyNewUtils/Api";
import './style.css';
const Header = ({
  setSourceData,
  cloudPlateForm,
  sourceData,
  sourceDataverification,
  setSourceDataverification,
  setIsLoading,
}) => {
  console.log("sourceData-----", sourceData);

  const navigate = useNavigate();

  let checkValidObj = {};

  checkValidObj.special = /[ `!@#$%^&*()+\-=\[\]{};':"\\|,.<>\/?~]/.test(
    sourceData?.accountName
  );

  const next = async () => {
    if (
      sourceData.sourceName != null &&
      sourceData.sourceName.length != 0 &&
      sourceData.accessKeyId != null &&
      sourceData.accessKeyId.length >= 16 &&
      sourceData.accessKeyId.length <= 128 &&
      sourceData.secretAccessKey != null &&
      sourceData.secretAccessKey.length != 0 &&
      // sourceData.accountId != null && sourceData.accountId.length <= 12 && sourceData.accountId.length >= 12 &&
      // sourceData.accountName != null && sourceData.accountName.length != 0 && !checkValidObj?.special &&
      sourceData.description != null &&
      sourceData.description.length != 0
    ) {
      setIsLoading(true);

      let params = {
        userid: sourceData.id,
        type: sourceData.type,
        sourceName: sourceData.sourceName,
        description: sourceData.description,
        accessKeyId: sourceData.accessKeyId,
        secretAccessKey: sourceData.secretAccessKey,
        region: sourceData.region,
        // accountname: sourceData.accountName
      };
      const data = await Api.postRequest(
        "/users/validate-cloud-account",
        params
      );
      const res = JSON.parse(data?.data);

      if (res?.status === 1) {
        console.log("astgedhref", res?.data);
        let acountData = sourceData;
        acountData = { ...acountData, accountId: res?.data?.accountid };
        navigate("/preview-done", { state: { sourceData: acountData } });
      } else if (res?.status === 0) {
        toast.error(res?.message, { autoClose: 1000 });
        setIsLoading(false);
      } else {
        toast.error("validation error", { autoClose: 1000 });
        setIsLoading(false);
      }
    }

    if (
      sourceData?.accessKeyId === null ||
      sourceData?.accessKeyId?.length <= 16 ||
      sourceData?.accessKeyId?.length >= 128
    ) {
      if (sourceData?.accessKeyId === null || sourceData?.accessKeyId === "") {
        toast.error("Enter Your AWS Access Key Id", { autoClose: 1000 });
      } else {
        toast.error(
          "Your AWS Access Key Id should have minimum length of 16, maximum length of 128",
          { autoClose: 1000 }
        );
      }
    } else if (
      sourceData?.secretAccessKey === null ||
      sourceData?.secretAccessKey?.length === 0
    ) {
      toast.error("Enter Your Secret Access Key", { autoClose: 1000 });
    }
    // else if (sourceData?.accountName === null ||
    //   sourceData?.accountName?.length === 0 || checkValidObj?.special) {
    //   if (sourceData?.accountName === null || sourceData?.accountName === "") {
    //     toast.error(" Enter Your Account Name", { autoClose: 1000 })
    //   } else {
    //     toast.error("Account Name in only write Underscore.", { autoClose: 1000 })
    //   }
    // } else if (sourceData?.sourceName === null || sourceData?.sourceName?.length === 0) {
    //   toast.error("Enter Your Source Name", { autoClose: 1000 })
    // }
    else if (
      sourceData?.description === null ||
      sourceData?.description?.length === 0
    ) {
      toast.error("Enter Your Description", { autoClose: 1000 });
    }

    if (
      sourceData.accessKeyId != null &&
      sourceData.accessKeyId.length >= 16 &&
      sourceData.accessKeyId.length <= 128
    ) {
      if (
        sourceData.secretAccessKey != null &&
        sourceData.secretAccessKey.length != 0
      ) {
        if (
          sourceData.accountName != null &&
          sourceData.accountName.length != 0 &&
          !checkValidObj?.special
        ) {
          if (
            sourceData.sourceName != null &&
            sourceData.sourceName.length != 0
          ) {
            if (
              sourceData.description != null &&
              sourceData.description.length != 0
            ) {
              setSourceDataverification({
                ...sourceDataverification,
                sourceName: true,
                description: true,
                accessKeyId: true,
                secretAccessKey: true,
                accountName: true,
              });
            } else {
              setSourceDataverification({
                ...sourceDataverification,
                sourceName: true,
                description: false,
                accessKeyId: true,
                secretAccessKey: true,
                accountName: true,
              });
            }
          } else {
            setSourceDataverification({
              ...sourceDataverification,
              sourceName: false,
              description: true,
              accessKeyId: true,
              secretAccessKey: true,
              accountName: true,
            });
          }
        } else {
          setSourceDataverification({
            ...sourceDataverification,
            sourceName: true,
            description: true,
            accessKeyId: true,
            secretAccessKey: true,
            accountName: false,
          });
        }
      } else {
        setSourceDataverification({
          ...sourceDataverification,
          sourceName: true,
          description: true,
          accessKeyId: true,
          secretAccessKey: false,
          accountName: true,
        });
      }
    } else {
      setSourceDataverification({
        ...sourceDataverification,
        sourceName: true,
        description: true,
        accessKeyId: false,
        secretAccessKey: true,
        accountName: true,
      });
    }

    // if (sourceData?.accessKeyId === null || sourceData?.accessKeyId?.length <= 16 || sourceData?.accessKeyId?.length >= 128) {
    //   if (sourceData?.accessKeyId === null || sourceData?.accessKeyId === "") {
    //     toast.error("Enter Your AWS Access Key Id", { autoClose: 1000 })
    //   } else {
    //     toast.error("Your AWS Access Key Id should have minimum length of 16, maximum length of 128", { autoClose: 1000 })
    //   }
    // } else if (sourceData?.secretAccessKey === null || sourceData?.secretAccessKey?.length === 0) {
    //   toast.error("Enter Your Secret Access Key", { autoClose: 1000 })
    // } else if (sourceData?.accountId === null || sourceData?.accountId?.length != 12) {
    //   if (sourceData?.accountId === null || sourceData?.accountId === "") {
    //     toast.error("Enter Your Account ID", { autoClose: 1000 })
    //   } else {
    //     toast.error("Your Account Id must be 12 character long", { autoClose: 1000 })
    //   }
    // } else if (sourceData?.accountName === null || sourceData?.accountName?.length === 0) {
    //   toast.error(" Enter Your Account Name", { autoClose: 1000 })
    // } else if (sourceData?.sourceName === null || sourceData?.sourceName?.length === 0) {
    //   toast.error("Enter Your Source Name", { autoClose: 1000 })
    // }
    // else if (sourceData?.description === null || sourceData?.description?.length === 0) {
    //   toast.error("Enter Your Description", { autoClose: 1000 })
    // }

    // if (sourceData.accessKeyId != null && sourceData.accessKeyId.length >= 16 && sourceData.accessKeyId.length <= 128) {
    //   if (sourceData.secretAccessKey != null && sourceData.secretAccessKey.length != 0) {
    //     if (sourceData.accountId != null && sourceData.accountId.length <= 12 && sourceData.accountId.length >= 12) {
    //       if (sourceData.accountName != null && sourceData.accountName.length != 0) {
    //         if (sourceData.sourceName != null && sourceData.sourceName.length != 0) {
    //           if (sourceData.description != null && sourceData.description.length != 0) {
    //             setSourceDataverification({ ...sourceDataverification, sourceName: true, description: true, accessKeyId: true, secretAccessKey: true, accountId: true, accountName: true })
    //           } else {
    //             setSourceDataverification({ ...sourceDataverification, sourceName: true, description: false, accessKeyId: true, secretAccessKey: true, accountId: true, accountName: true })
    //           }
    //         } else {
    //           setSourceDataverification({ ...sourceDataverification, sourceName: false, description: true, accessKeyId: true, secretAccessKey: true, accountId: true, accountName: true })
    //         }
    //       } else {
    //         setSourceDataverification({ ...sourceDataverification, sourceName: true, description: true, accessKeyId: true, secretAccessKey: true, accountId: true, accountName: false })
    //       }
    //     } else {
    //       setSourceDataverification({ ...sourceDataverification, sourceName: true, description: true, accessKeyId: true, secretAccessKey: true, accountId: false, accountName: true })
    //     }
    //   } else {
    //     setSourceDataverification({ ...sourceDataverification, sourceName: true, description: true, accessKeyId: true, secretAccessKey: false, accountId: true, accountName: true })
    //   }
    // } else {
    //   setSourceDataverification({ ...sourceDataverification, sourceName: true, description: true, accessKeyId: false, secretAccessKey: true, accountId: true, accountName: true })
    // }
  };

  return (
    <Box
      style={{
        padding: "10px",
        marginBottom: "20px",
        display: "flex",
      }}
    >
      <Box className="custome_stepper">
        {/* custome stepper */}
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <StepperItem
            number={"1"}
            title={"Select Cloud Infrastructure"}
            isLineShow={true}
            isActive={true}
          />
          <StepperItem number={"2"} title={"Setup Source"} isLineShow={true} />
          <StepperItem
            number={"3"}
            title={"Preview & Done"}
            isLineShow={false}
          />
        </Box>

        {/* button */}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "20%",
            height: "100px",
          }}
        >
          <Button
            variant="contained"
            className="setup-source-next-btn"
            onClick={next}
          >
            Next
          </Button>
          <Button
            variant="outlined"
            className="setup-source-back-btn"
            onClick={() => navigate("/cloud-setup-wizard")}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const StepperItem = ({ number, title, isLineShow, isActive }) => {
  return (
    <Box
      className={`cloud_step_circle ${isLineShow
        ? "cloud_step_circle_line_show"
        : "cloud_step_circle_without_line_show"
        } `}
    >
      <Box
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        <Box
          style={{
            backgroundColor: isActive ? "#008CF2" : "rgb(3,7,42)",
            width: "50px",
            height: "50px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "50%",
            border: `2px solid ${isActive ? "#008CF2" : "white"}`,
          }}
        >
          {isActive ? (
            <CheckIcon fontSize={"large"} />
          ) : (
            <Typography>{number}</Typography>
          )}
        </Box>
        {isLineShow && (
          <Box
            style={{
              backgroundColor: isActive ? "#008CF2" : "white",
            }}
            className="cloud_steps_Line"
          ></Box>
        )}
      </Box>
      <Box style={{ marginTop: "20px" }}>
        <Typography
          style={{ color: "#008CF2", fontWeight: "700" }}
          className="cloud_steps_title"
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
};

const DashBoardSctionPage2Body = ({ setIsLoading }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [show, setShow] = useState(false);
  const [aWSAccess, setAWSAccess] = useState(false);
  const [ConfigureSource, setConfigureSource] = useState(false);

  const [addMetadata, setAddMetadata] = useState(2);
  const [typedata, setTypeData] = useState(location?.state?.cloudPlateForm);
  let sourceBackData = location?.state?.sourceBackData;

  const [sourceData, setSourceData] = useState({
    id: JSON.parse(localStorage.getItem("user")).data.id,
    type: null,
    sourceName: null,
    description: null,
    accessKeyId: null,
    secretAccessKey: null,
    region: "us-east-1",
    accountId: null,
    accountName: null,
  });

  // useLocation().state?.cloudPlateForm

  useEffect(() => {
    console.log("efedyhgryh", typedata);
    console.log("access", sourceBackData);
    if (typedata === undefined) {
      setSourceData({
        ...sourceData,
        type: sourceBackData?.type,
        sourceName: sourceBackData?.sourceName,
        description: sourceBackData?.description,
        accessKeyId: sourceBackData?.accessKeyId,
        secretAccessKey: sourceBackData?.secretAccessKey,
        region: sourceBackData?.region,
        accountName: sourceBackData?.accountName,
      });
      console.log("Ewxverbvdsv", typedata);
    } else {
      setSourceData({ ...sourceData, type: typedata });
    }
  }, [typedata]);

  const [sourceDataverification, setSourceDataverification] = useState({
    sourceName: true,
    description: true,
    accessKeyId: true,
    secretAccessKey: true,
    // accountId: true,
    // accountName: true
  });
  console.log("sourceDataverification------", sourceDataverification);

  const onSourceName = (e) => {
    setSourceData({ ...sourceData, sourceName: e.target.value });
  };

  const onDescription = (e) => {
    setSourceData({ ...sourceData, description: e.target.value });
  };
  const onAccessKeyId = (e) => {
    setSourceData({ ...sourceData, accessKeyId: e.target.value });
  };
  const onSecretAccessKey = (e) => {
    setSourceData({ ...sourceData, secretAccessKey: e.target.value });
  };

  // const onAccountId = (e) => {
  //   setSourceData({ ...sourceData, accountId: e.target.value })
  // }

  // const onAccountName = (e) => {
  //   setSourceData({ ...sourceData, accountName: e.target.value })

  // let accountName = /a-zA-Z0-9_/
  // if (accountName.test(e.target.value)) {
  //   console.log("wdstgferyh54ruj", e.target.value)
  //   setSourceData({ ...sourceData, accountName: e.target.value })
  // }
  // }

  const cls = useMemo(() => "DahboardThreeBody", []);

  const [cloudPlateForm, setCloudPlateForm] = useState("");

  const [access, setAccess] = useState("role");
  const handleChange = (event) => {
    setAccess(event.target.value);
  };

  const Metadata = () => {
    setAddMetadata(addMetadata + 1);
  };
  const handleClick = () => {
    setShow(!show);
  };
  const AWSAccessonoff = () => {
    setAWSAccess(!aWSAccess);
  };

  const ConfigureSourceonoff = () => {
    setConfigureSource(!ConfigureSource);
  };
  return (
    <Box
      style={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          width: "90%",
          marginTop: "30px",
          padding: "30px",
          marginBottom: "30px",
        }}
      >
        {/* Header */}

        <Header
          cloudPlateForm={cloudPlateForm}
          sourceData={sourceData}
          sourceDataverification={sourceDataverification}
          setSourceDataverification={setSourceDataverification}
          setIsLoading={setIsLoading}
          setSourceData={setSourceData}
        />

        {/* body */}
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {/* left part */}

          <Box
            style={{
              width: "50%",
              backgroundColor: "rgb(0,0,0,0.5)",
              padding: "40px 30px",
              borderRadius: "30px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  cursor: "pointer",
                }}
                onClick={AWSAccessonoff}
              >
                <Box>
                  <img
                    src={rightArrow}
                    style={aWSAccess ? { transform: "rotate(90deg)" } : null}
                  />
                </Box>
                <Box style={{ marginLeft: "12px" }}>
                  <Typography style={{ color: "#FF8800", fontSize: "22px" }}>
                    AWS Access
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={aWSAccess ? { display: "none" } : null}>
              <Box>
                <Box style={{ marginLeft: "40px" }}>
                  <Box>
                    <Typography style={{ fontSize: "12px" }}>
                      How should CLAVRONE access your AWS account?{" "}
                      <span style={{ color: "#008CF2" }}>Learn more</span>
                      <img src={exportImg} />
                    </Typography>
                  </Box>

                  <Box style={{ display: "flex", marginTop: "20px" }}>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "200px",
                      }}
                    >
                      <Radio
                        style={{ color: "white" }}
                        checked={access === "role"}
                        value={"role"}
                        onChange={handleChange}
                        name={"access"}
                      />
                      <lable className="role_based_access_label">Role-based access (recommended)</lable>
                    </Box>
                    <Box style={{ display: "flex", alignItems: "center" }}>
                      <Radio
                        style={{ color: "white" }}
                        checked={access === "key"}
                        value={"key"}
                        name={"access"}
                        onChange={handleChange}
                      />
                      <lable className="key_access_label">Key Access</lable>
                    </Box>
                  </Box>

                  {access === "role" && (
                    <Box style={{ marginTop: "20px" }}>
                      <Typography style={{ fontSize: "12px" }}>
                        Use an AWS CloudFormation template to create an IAM
                        role:
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>

              {access === "role" && (
                <Box
                  style={{ display: "flex", width: "100%", marginTop: "20px" }}
                >
                  <Box
                    style={{
                      flexGrow: 1,
                      width: "40%",

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <a href="https://console.aws.amazon.com/iamv2/home?#/users">
                      <Button
                        variant={"contained"}
                        style={{
                          backgroundColor: "rgb(0,44,95)",
                          borderRadius: "30px",
                          fontSize: "12px",
                        }}
                      >
                        Generate
                      </Button>
                    </a>
                    <Box>
                      <Typography
                        style={{ color: "#008CF2", fontSize: "12px" }}
                      >
                        Learn more <img src={exportImg} />
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      flexGrow: 1,
                      width: "20%",
                    }}
                  >
                    <Box
                      style={{
                        width: "2px",
                        height: "60px",
                        backgroundColor: "rgba(255,255,255,0.5)",
                      }}
                    ></Box>
                    <Box>
                      <Typography style={{ color: "rgba(255,255,255,0.5)" }}>
                        Or
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        width: "2px",
                        height: "60px",
                        backgroundColor: "rgba(255,255,255,0.5)",
                      }}
                    ></Box>
                  </Box>

                  <Box
                    style={{
                      flexGrow: 1,
                      width: "40%",

                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "12px",

                        width: "100%",
                      }}
                      className="or_manullay_create"
                    >
                      Or, manually create a role on your AWS IAM console using
                      the following information:
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "12px",

                        width: "100%",
                      }}
                      className="set_source_account_id"
                    >
                      {" "}
                      AccountID: 926226587429
                    </Typography>
                    <Typography
                      style={{ fontSize: "12px", width: "100%" }}
                      className="setup_source_external_id"
                    >
                      {" "}
                      External ID: in:000000000000016A33
                    </Typography>
                    <Typography style={{ color: "#008CF2", width: "100%" }}>
                      Learn more <img src={exportImg} />
                    </Typography>
                  </Box>
                </Box>
              )}
              {access === "role" && (
                <Box style={{ marginLeft: "40px", marginTop: "30px" }}>
                  <Typography style={{ color: "#008CF2", fontSize: "12px" }}>
                    Role ARN
                  </Typography>
                </Box>
              )}
              {access === "key" && (
                <Box style={{ marginLeft: "40px" }}>
                  <Box>
                    <Box style={{ marginTop: "25px" }}>
                      <Typography
                        style={{
                          color: "#008CF2",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        Access Key ID
                      </Typography>
                      <Input
                        type="text"
                        onChange={onAccessKeyId}
                        value={sourceData?.accessKeyId}
                        InputProps={{
                          style: {
                            color: "white",
                            // borderBottom: '2px solid rgb(0,117,210)',
                            //   borderBottomColor: 'white',
                          },
                        }}
                        style={{
                          borderBottom: "1px solid rgb(0,117,210)",
                          color: "white",
                          width: "100%",
                        }}
                      />
                      <Typography
                        style={{ color: "#008CF2", fontSize: "12px" }}
                      >
                        Your AWS Access Key Id length minimum 16
                      </Typography>
                      <Typography
                        style={
                          sourceDataverification?.accessKeyId
                            ? { display: "none" }
                            : { color: "red", fontSize: "10px" }
                        }
                      >
                        Enter Your AWS Access Key ID
                      </Typography>
                    </Box>
                    <Box style={{ marginTop: "25px" }}>
                      <Typography
                        style={{
                          color: "#008CF2",
                          fontSize: "12px",
                          fontWeight: "700",
                        }}
                      >
                        Secret Access Key
                      </Typography>
                      <Input
                        type="text"
                        onChange={onSecretAccessKey}
                        value={sourceData?.secretAccessKey}
                        InputProps={{
                          style: {
                            color: "white",
                            // borderBottom: '2px solid rgb(0,117,210)',
                            //   borderBottomColor: 'white',
                          },
                        }}
                        style={{
                          borderBottom: "1px solid rgb(0,117,210)",
                          color: "white",
                          width: "100%",
                        }}
                      />
                      <Typography
                        style={{ color: "#008CF2", fontSize: "12px" }}
                      >
                        Your Secret Access Key
                      </Typography>
                      <Typography
                        style={
                          sourceDataverification?.secretAccessKey
                            ? { display: "none" }
                            : { color: "red", fontSize: "10px" }
                        }
                      >
                        Enter Your Secret Access Key
                      </Typography>
                    </Box>
                    {/* <Box style={{ marginTop: '25px' }}>
                      <Typography
                        style={{
                          color: '#008CF2',
                          fontSize: '12px',
                          fontWeight: '700',
                        }}
                      >
                        Account ID
                      </Typography>
                      <Input
                        type='text'
                        onChange={onAccountId}
                        InputProps={{
                          style: {
                            color: 'white',
                          },
                        }}
                        style={{
                          borderBottom: '1px solid rgb(0,117,210)',
                          color: 'white',
                          width: '100%',
                        }}
                      />
                      <Typography style={{ color: '#008CF2', fontSize: '12px' }}>
                        Your Account Id must be 12 character long
                      </Typography>
                      <Typography style={sourceDataverification?.accountId ? { display: 'none' } : { color: 'red', fontSize: '10px' }}>Enter Your Account ID</Typography>
                    </Box> */}
                    {/* <Box style={{ marginTop: '25px' }}>
                      <Typography
                        style={{
                          color: '#008CF2',
                          fontSize: '12px',
                          fontWeight: '700',
                        }}
                      >
                        Account Name
                      </Typography>
                      <Input
                        type='text'
                        value={sourceData?.accountName}
                        onChange={onAccountName}
                        InputProps={{
                          style: {
                            color: 'white',
                            // borderBottom: '2px solid rgb(0,117,210)',
                            //   borderBottomColor: 'white',
                          },
                        }}
                        style={{
                          borderBottom: '1px solid rgb(0,117,210)',
                          color: 'white',
                          width: '100%',
                        }}
                      />
                      <Typography style={{ color: '#008CF2', fontSize: '12px' }}>
                        Your Account Name
                      </Typography>
                      <Typography style={sourceDataverification?.accountName ? { display: 'none' } : { color: 'red', fontSize: '10px' }}>Enter Your Account Name</Typography>
                    </Box> */}
                  </Box>
                </Box>
              )}

              <Box
                style={{
                  backgroundColor: "#008CF2",
                  width: "100%",
                  height: "1px",
                  marginTop: access === "role" ? "30px" : "80px",
                }}
              ></Box>
            </Box>

            <Box
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: "20px",
                flexDirection: "column",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexGrow: 1,
                  cursor: "pointer",
                }}
                onClick={ConfigureSourceonoff}
              >
                <Box>
                  <img
                    src={rightArrow}
                    style={
                      ConfigureSource ? { transform: "rotate(90deg)" } : null
                    }
                  />
                </Box>
                <Box style={{ marginLeft: "12px" }}>
                  <Typography style={{ color: "#FF8800", fontSize: "22px" }}>
                    Configure Source
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box style={ConfigureSource ? { display: "none" } : null}>
              <Box style={{ marginLeft: "40px" }}>
                <Typography style={{ fontSize: "12px", marginTop: "20px" }}>
                  A Source contains the configuration and logic that a Collector
                  uses to get logs, metrics, or traces from a particular product
                  or service.
                </Typography>
                <Box style={{ marginTop: "20px" }}>
                  <Typography style={{ color: "#008CF2", fontSize: "12px" }}>
                    Source Name
                  </Typography>
                  <Box>
                    <Input
                      type="text"
                      value={sourceData?.sourceName}
                      placeholder="Amazon S3 Audit source"
                      InputProps={{
                        style: {
                          color: "white",
                          // borderBottom: '2px solid rgb(0,117,210)',
                          //   borderBottomColor: 'white',
                        },
                      }}
                      onChange={onSourceName}
                      style={{
                        borderBottom: "1px solid rgb(0,117,210)",
                        color: "white",
                        width: "100%",
                      }}
                    />
                  </Box>
                  <Typography
                    style={
                      sourceDataverification?.sourceName
                        ? { display: "none" }
                        : { color: "red", fontSize: "10px" }
                    }
                  >
                    Enter Your Source Name
                  </Typography>
                </Box>

                <Box style={{ marginTop: "20px" }}>
                  <Typography style={{ color: "#008CF2", fontSize: "12px" }}>
                    Description
                  </Typography>
                  <Box>
                    <Input
                      type="text"
                      value={sourceData?.description}
                      onChange={onDescription}
                      InputProps={{
                        style: {
                          color: "white",
                          // borderBottom: '2px solid rgb(0,117,210)',
                          //   borderBottomColor: 'white',
                        },
                      }}
                      style={{
                        borderBottom: "1px solid rgb(0,117,210)",
                        color: "white",
                        width: "100%",
                      }}
                    />
                  </Box>
                  <Typography
                    style={
                      sourceDataverification?.description
                        ? { display: "none" }
                        : { color: "red", fontSize: "10px" }
                    }
                  >
                    Enter Your Description
                  </Typography>
                </Box>
              </Box>
            </Box>

            {/* <Box
              style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}
            >
              <Box style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }} onClick={handleClick}>
                <Box>
                  <img src={rightArrow} />
                </Box>
                <Box style={{ marginLeft: '12px' }}>
                  <Typography style={{ color: '#FF8800', fontSize: '22px' }}>
                    Metadata
                  </Typography>
                </Box>
              </Box>
              <Box>
                <img src={questionMark} />
              </Box>
            </Box>
            <Box>
              <Box style={show ? { display: 'none' } : null}>
                <Box
                  style={{
                    backgroundColor: 'rgb(23,29,53)',
                    borderRadius: '10px',
                    display: 'flex',
                    padding: '10px',
                  }}
                >
                  <Box style={{ flexGrow: 1, marginLeft: '20px' }}>
                    <Typography style={{ color: '#008CF2', fontSize: '12px' }}>
                      Key
                    </Typography>
                  </Box>
                  <Box style={{ flexGrow: 1, marginLeft: '20px' }}>
                    <Typography style={{ color: '#008CF2', fontSize: '12px' }}>
                      Value
                    </Typography>
                  </Box>
                </Box>
                <Box
                  style={{
                    backgroundColor: 'rgb(11,16,38)',
                    borderRadius: '10px',
                    display: 'flex',
                    padding: '10px',
                  }}
                >
                  <Box style={{ flexGrow: 1, marginLeft: '20px' }}>
                    {
                      [...Array(addMetadata)].map((elementInArray, index) => (
                        <Box sx={{ display: 'flex', marginTop: '20px' }}>
                          <Input
                            placeholder={'_sourceCategory'}
                            InputProps={{
                              style: {
                                color: 'white',

                              },
                            }}
                            style={{
                              borderBottom: '1px solid rgb(0,117,210)',
                              color: 'white',
                              width: '100%',
                            }}
                          />
                          <Input
                            defaultValue={'Source-category-1'}
                            placeholder={'_sourceCategory'}
                            InputProps={{
                              style: {
                                color: 'white'


                              },
                            }}
                            style={{
                              borderBottom: '1px solid rgb(0,117,210)',
                              color: 'white',
                              width: '100%',
                              marginLeft: '20px'
                            }}
                          />
                        </Box>
                      )
                      )
                    }
                    <Box
                      style={{
                        display: 'flex',
                        marginTop: '50px',
                        marginBottom: '20px',
                      }}

                      onClick={Metadata}
                    >
                      <Box>
                        <img src={plusRound} />
                      </Box>
                      <Box style={{ marginLeft: '10px' }}>
                        <Typography style={{ color: '#008CF2', fontSize: '14px' }}>
                          Add Metadata
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: '20px',
                }}
              >
                <Box
                  style={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}
                >
                  <Box>
                    <img src={rightArrow} />
                  </Box>
                  <Box style={{ marginLeft: '12px' }}>
                    <Typography style={{ color: '#FF8800', fontSize: '22px' }}>
                      Log File Discovery (Optional)
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <img src={questionMark} />
                </Box>
              </Box>
            </Box> */}
          </Box>

          {/* <Box style={{ marginTop: '15px' }}>
                    <Input
                      InputProps={{
                        style: {
                          color: 'white',
                          // borderBottom: '2px solid rgb(0,117,210)',
                          //   borderBottomColor: 'white',
                        },
                      }}
                      style={{
                        borderBottom: '1px solid rgb(0,117,210)',
                        color: 'white',
                        width: '100%',
                      }}
                    />
                    <Typography
                      style={{ color: 'rgba(255,255,255,0.5)', fontSize: '14px' }}
                    >
                      E.g. Enviroment
                    </Typography>
                  </Box> */}

          {/* <Box style={{ flexGrow: 1, marginLeft: '20px' }}>
                  <Box>
                    <Input
                      defaultValue={'Source-category-1'}
                      placeholder={'_sourceCategory'}
                      InputProps={{
                        style: {
                          color: 'white',
                          // borderBottom: '2px solid rgb(0,117,210)',
                          //   borderBottomColor: 'white',
                        },
                      }}
                      style={{
                        borderBottom: '1px solid rgb(0,117,210)',
                        color: 'white',
                        width: '100%',
                      }}
                    />
                  </Box>
                  <Box style={{ marginTop: '15px' }}>
                    <Input
                      InputProps={{
                        style: {
                          color: 'white',
                          // borderBottom: '2px solid rgb(0,117,210)',
                          //   borderBottomColor: 'white',
                        },
                      }}
                      style={{
                        borderBottom: '1px solid rgb(0,117,210)',
                        color: 'white',
                        width: '100%',
                      }}
                    />
                    <Typography
                      style={{ color: 'rgba(255,255,255,0.5)', fontSize: '14px' }}
                    >
                      E.g. Production
                    </Typography>
                  </Box>
                </Box> */}

          {/* right part */}
          <Box style={{ width: "40%" }}>
            <Box
              style={{
                backgroundColor: "rgba(0,0,0,0.5)",
                padding: "20px 40px",
                borderRadius: "30px",
              }}
            >
              <Box>
                <Typography style={{ color: "#FF8800", fontSize: "20px" }}>
                  Configure Source FAQs
                </Typography>
              </Box>
              <Box style={{ padding: "0px 10px", marginTop: "20px" }}>
                <Typography
                  style={{ color: "rgba(255,255,255,0.5)", marginTop: "10px" }}
                >
                  How many buckets can one Source collect from?
                </Typography>
                <Typography
                  style={{ color: "rgba(255,255,255,0.5)", marginTop: "10px" }}
                >
                  Where do I find my Bucket Name?
                </Typography>
                <Typography
                  style={{ color: "rgba(255,255,255,0.5)", marginTop: "10px" }}
                >
                  What is Path Expression?
                </Typography>
                <Typography
                  style={{ color: "rgba(255,255,255,0.5)", marginTop: "10px" }}
                >
                  What should I choose for Scan Interval?
                </Typography>
                <Typography
                  style={{ color: "rgba(255,255,255,0.5)", marginTop: "10px" }}
                >
                  What File types can be collected from a S3 bucket?
                </Typography>
                <Typography
                  style={{ color: "rgba(255,255,255,0.5)", marginTop: "10px" }}
                >
                  Can encrypted data be collected?
                </Typography>
                <Typography
                  style={{ color: "rgba(255,255,255,0.5)", marginTop: "10px" }}
                >
                  How should I configure my timestamp options?
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashBoardSctionPage2Body;
