import React from "react";
import Modal from "@mui/material/Modal";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

const AwsServicesList = ({ RegionsList, setModel2, setAwsServicesData }) => {
  const [open, setOpen] = useState(true);
  const [selectAll, setSelectAll] = useState(false);
  const [selectRegion, setSelectRegion] = useState(RegionsList);
  const handleClose = () => setModel2(false);
  console.log("dasgedhdrfj", RegionsList);

  // const handleClose = () => {
  //     let done = selectRegion?.filter(t => t.select);
  //     let list = []
  //     done.map((item, index) => {
  //         list[index] = item?.region_key;
  //     })
  //     console.log("wasygesdhrhtyj", list)
  //     setAwsServicesData(list)
  //     setModel2(false);
  // }

  const handleChangeCheckbox = (e) => {
    let selectRegion = [];
    selectRegion[0] = e.target.value;
    setAwsServicesData(selectRegion);
    setModel2(false);
  };

  const getSelectAll = (event) => {
    let selectAllRegionarray = [];
    selectRegion?.map((itm, idx) => {
      selectAllRegionarray[idx] = itm?.region_key;
    });
    console.log("tmpArr-tmpArr", selectAllRegionarray);
    setModel2(false);
    setSelectAll(true);
    setAwsServicesData(selectAllRegionarray);
  };
  const getRemoveAll = (event) => {
    let tmpArr = selectRegion?.map((itm, idx) => {
      return { ...itm, select: false };
    });
    setSelectAll(false);
    setSelectRegion(tmpArr);
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            background: "#010A26",
            padding: "40px",
            width: "20%",
            borderRadius: "0px",
            maxHeight: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#5A95A9", marginBottom: "20px" }}
          >
            Regions
          </Typography>
          <FormGroup>
            <FormControlLabel
              style={{ color: "white" }}
              label={selectAll ? "Remove All" : "Select All"}
              control={
                <Checkbox
                  checked={selectAll}
                  name="checkbox"
                  sx={{ color: "white" }}
                  onChange={selectAll ? getRemoveAll : getSelectAll}
                />
              }
            />
            {selectRegion?.map((item, index) => {
              return (
                <FormControlLabel
                  style={{ color: "white" }}
                  label={item?.region_key}
                  control={
                    <Checkbox
                      sx={{ color: "white" }}
                      value={item?.region_key}
                      onChange={handleChangeCheckbox}
                      checked={selectAll}
                    />
                  }
                />
              );
            })}

            {/* <FormControlLabel
                            style={{ color: "white" }}
                            label={selectAll ? "Remove All" : "Select All"}
                            control={
                                <Checkbox
                                    checked={selectAll}
                                    name="checkbox"
                                    sx={{ color: "white" }}
                                    onChange={selectAll ? getRemoveAll : getSelectAll}
                                />
                            }
                        />
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {selectRegion?.map((item, index) => {
                                return (
                                    <FormControlLabel
                                        style={{ color: "white" }}
                                        label={item?.region_key}
                                        control={<Checkbox sx={{ color: "white" }}
                                            checked={item.select} onChange={() => handleChange(item, index)} />}
                                    />
                                )
                            })}
                        </Box> */}
          </FormGroup>
        </Box>
      </Modal>
    </div>
  );
};

export default AwsServicesList;
