import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Api } from "../MyNewUtils/Api";
import { path } from "../MyNewUtils/screenPath";
import { toast } from "react-toastify";
import MainLoader from "../Component/Spinner/MainLoader";

const Verifyemail = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  let tokan = useParams();

  const verifytoken = async () => {
    const { data } = await Api.getRequest(
      "/users/verifyemail/" + tokan.verifytoken
    );
    const res = JSON.parse(data);
    if (res.isemailverified == true) {
      setIsLoading(false);
      toast.success(res.message, { autoClose: 1000 });
      navigate(path.login);
    } else {
      setIsLoading(false);
      toast.error(res.message, { autoClose: 1000 });
      navigate(path.signup);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    verifytoken();
  }, []);

  return (
    <>
      <MainLoader isLoading={isLoading} />
    </>
  );
};
export default Verifyemail;
