import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import { Api } from "../../../MyNewUtils/Api";
import CloudIcon from "@mui/icons-material/Cloud";
import SubLoader from "../../../Component/Spinner/subLoader";
import "../CloudAssessment/cloudAssessment.css";
import { Grid } from "@mui/material";
import { Document, Page, pdfjs, PDFDownloadLink } from "react-pdf";
import refresh from "../../../assets/img/refresh_icn@2x.png";
import { toast } from "react-toastify";
import { Table } from "reactstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import TableWithPaginationTwo from "./table/tableWithPagination";
import CreateNewReportModel from "../../../screens/CreateNewReportModel";
import moment from "moment";
import axios from "axios";
const CloudAssessment = ({
  iamOpen,
  setIamOpen,
  setAddNewDashboardData,
  slide,
  doneEditing,
  setDoneEditing,
  activateSlider,
  index,
  dashid,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cloudzapLogs, setCloudzapLogs] = useState([]);
  const [newReportName, setNewReportName] = useState("");
  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfview, setPdfView] = useState(null);

  const [cloudTable, setCloudTable] = useState([]);
  const TableTitle = [
    {
      id: "reportname",
      numeric: true,
      disablePadding: false,
      label: "Title",
      show: true,
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "Create Date",
      show: true,
    },
    {
      id: "reportstatus",
      numeric: true,
      disablePadding: false,
      label: "Status",
      show: true,
    },
  ];
  const [reportDownloadBtn, setReportDownloadBtn] = useState(false);
  const [rowLimitEnd, setRowLimitEnd] = useState(4);
  const [createReport, setCreateReport] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    getGenerateReports();
  }, []);
  const CreateNewReport = async () => {
    setCreateReport(true);
  };
  const CreateLog = async () => {
    const logsData = setTimeout(async () => {
      getGenerateReports();
    }, 1000);
    console.log("logsData", logsData);
    let logsDatapayload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      report_name: newReportName,
    };
    const logdata = await Api.postRequest(
      "/users/generate-cloudzap-report",
      logsDatapayload
    );
    const res = JSON.parse(logdata?.data);
    if (res?.status === 1) {
      setCloudzapLogs(res?.data?.logs);
      setIsLoading(false);
      setReportDownloadBtn(true);
    } else {
      setIsLoading(false);
    }
    return logdata;
  };
  const getGenerateReports = async () => {
    setCreateReport(false);
    setIsLoading(true);
    let logsDatapayload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    };
    const logdata = await Api.postRequest(
      "/users/get-all-cloudzap-reports",
      logsDatapayload
    );
    const res = JSON.parse(logdata?.data);
    if (res?.status === 1) {
      setCloudTable(res?.data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
    return logdata;
  };

  const handleChangeURL = (data) => {
    setPdfView(data);
  };
  const reportDownload = async () => {
    setIsLoading(true);
    let payload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    };
    const data = await Api.postRequest("/users/send-cloudzap-report", payload);
    const res = JSON.parse(data?.data);
    console.log("res", res);
    if (res?.status === 1) {
      setIsLoading(false);
      navigate("/LogPdfViewer", { state: { url: res?.s3_path } });
    } else {
      setIsLoading(false);
      toast.error(res.message, { autoClose: 1000 });
    }
  };
  const style = [
    {
      width: "1000px",
    },
  ];
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const Prev = () => {
    setPageNumber(pageNumber - 1);
  };
  const Next = () => {
    setPageNumber(pageNumber + 1);
  };
  const OnReload = () => {
    setPdfView(null);
    getGenerateReports();
  };

  const handleDownload = (url, filename) => {
    window.open(url, url);
  };
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch(pdfview?.reporturl).then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "Cloud Assessment Report.pdf";
        alink.click();
      });
    });
  };
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <>
      {/* <MainLoader isLoading={isLoading} /> */}
      {createReport ? (
        <CreateNewReportModel
          setCreateReport={setCreateReport}
          setNewReportName={setNewReportName}
          newReportName={newReportName}
          CreateNewReport={CreateLog}
        />
      ) : null}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CloudIcon style={{ width: "5%", height: "5%" }} alt="" />
        <Box sx={{ display: "flex", alignItems: "center", width: "80%" }}>
          <Typography
            style={{
              color: "#FF8800",
              fontSize: "36px",
              fontWeight: "bold",
              marginLeft: "2%",
            }}
          >
            Cloud Assessment
          </Typography>
        </Box>
        <Button
          onClick={() => CreateNewReport()}
          style={{
            textTransform: "capitalize",
            border: "1px solid #008CF2",
            background: "#008CF2",
            padding: "5px 20px 5px 20px",
            borderRadius: "20px",
            color: "#fff",
            fontWeight: "normal",
            fontSize: "20px",
          }}
        >
          Create New Report
        </Button>
      </Box>
      <Box sx={{ marginTop: "2%", padding: "0% 3% 3% 3%" }}>
        <Box sx={{ marginTop: "1%", display: "flex" }}></Box>
      </Box>

      <Box>
        <Box sx={{ position: "relative" }}>
          <Box className="cloud-main-box">
            <Grid container spacing={0} className="cloud-main-grid">
              <Grid
                className="cloud-pdf-view"
                style={{ flexDirection: "column", position: "relative" }}
                sm="12"
                md="5.8"
              >
                {isLoading ? (
                  <SubLoader isLoading={isLoading} />
                ) : (
                  <>
                    <Box style={{ textAlign: "end", marginBottom: "10px" }}>
                      <img
                        src={refresh}
                        alt="refresh-icon"
                        style={{
                          width: "25px",
                          cursor: "pointer",
                        }}
                        onClick={OnReload}
                      />
                    </Box>
                    <TableWithPaginationTwo
                      headCells={TableTitle}
                      rows={cloudTable}
                      rowLimitEnd={rowLimitEnd}
                      setRowLimitEnd={setRowLimitEnd}
                      filter={true}
                      handleChangeURL={handleChangeURL}
                    />
                  </>
                )}
              </Grid>
              {pdfview === null ? (
                <Grid
                  sm="12"
                  md="5.8"
                  className="cloud-no-pdf-view-grid"
                  style={{ position: "relative" }}
                >
                  <Box className="text-bold Open_Support_Cases_No_Data_Error_Text text-align-center  margin-top-32 ">
                    No Data Available
                  </Box>
                </Grid>
              ) : pdfview?.reporturl !== null || pdfview?.reporturl === "" ? (
                pdfview?.reportstatus === "completed" ? (
                  <Grid
                    sm="12"
                    md="5.8"
                    className="cloud-pdf-view-grid"
                    style={{ position: "relative" }}
                  >
                    <Box style={{ textAlign: "end", marginBottom: "10px" }}>
                      {/* <Link to={pdfview?.reporturl} target="_blank" download>
                        Download
                      </Link> */}

                      <Button
                        style={{
                          textTransform: "capitalize",
                          border: "1px solid #008CF2",
                          background: "#008CF2",
                          borderRadius: "20px",
                          color: "#fff",
                          fontWeight: "normal",
                          fontSize: "20px",
                        }}
                        onClick={() => {
                          handleDownload(pdfview?.reporturl, "report.pdf");
                        }}
                      >
                        Download
                      </Button>
                    </Box>
                    <Box className="pdf-box">
                      <Document
                        file={pdfview?.reporturl}
                        style={{ marginLeft: "20px", overflow: "hidden" }}
                        onLoadSuccess={onDocumentLoadSuccess}
                      >
                        <Page pageNumber={pageNumber} width={250} />
                      </Document>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        margin: "auto",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                      }}
                    >
                      <ArrowBackIcon
                        style={
                          pageNumber > 1
                            ? {
                              borderRadius: "50%",
                              color: "white",
                              marginRight: "10px",
                              backgroundColor: "#272E42",
                              fontSize: "20px",
                              cursor: "pointer",
                            }
                            : { display: "none" }
                        }
                        onClick={() => Prev()}
                      />
                      <p
                        style={{
                          textAlign: "center",
                        }}
                      >
                        Page {pageNumber} of {numPages}
                      </p>
                      <ArrowForwardIcon
                        style={
                          pageNumber < 96
                            ? {
                              borderRadius: "50%",
                              color: "white",
                              marginLeft: "10px",
                              backgroundColor: "#272E42",
                              fontSize: "20px",
                              cursor: "pointer",
                            }
                            : { display: "none" }
                        }
                        onClick={() => Next()}
                      />
                    </Box>
                  </Grid>
                ) : (
                  <Grid
                    sm="12"
                    md="5.8"
                    className="cloud-no-pdf-view-grid"
                    style={{ position: "relative" }}
                  >
                    <SubLoader
                      isLoading={
                        pdfview?.reportstatus === "process" ? true : false
                      }
                    />
                  </Grid>
                )
              ) : (
                <Grid
                  sm="12"
                  md="5.8"
                  className="cloud-no-pdf-view-grid"
                  style={{ position: "relative" }}
                >
                  <SubLoader isLoading={true} />
                </Grid>
              )}
            </Grid>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default CloudAssessment;
