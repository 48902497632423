import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import TextField from "@material-ui/core/TextField";
import roundClose from "../../assets/img/cancel_icn.png";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import { Api } from "../../MyNewUtils/Api";
import MainLoader from "../Spinner/MainLoader";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#010A26",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
const DeleteUsersModel = ({
  editDashboardModelOpen,
  seteditDashboardModelOpen,
  resetChildpasswordModelValue,
  setResetChildpasswordModelOpenValue,
  setRefresh,
  refreshList,
  setSelectedUserList,
  selectedUserList,
}) => {
  const [open, setOpen] = useState(editDashboardModelOpen);
  const [isLoading, setIsLoading] = useState(false);
  const handleClose = () => {
    seteditDashboardModelOpen(false);
    setResetChildpasswordModelOpenValue();
  };

  const onSubmit = async () => {
    setIsLoading(true);
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      childuserids: JSON.stringify(resetChildpasswordModelValue),
    };
    const { data } = await Api.postRequest("/ldap/delete-users", final);
    const res = JSON.parse(data);

    if (res?.status === 1) {
      toast.success(res?.message, { autoClose: 1000 });
      setIsLoading(false);
      setRefresh(!refreshList);
      if (resetChildpasswordModelValue.length > 1) {
        setSelectedUserList([]);
      } else {
        const updateSelectedItem = selectedUserList.filter(
          (el) => el !== resetChildpasswordModelValue
        );
        setSelectedUserList(updateSelectedItem);
      }
      handleClose();
    } else {
      toast.error("validation error", { autoClose: 1000 });
      setIsLoading(false);
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          className="Reset-Child-password-Model-box "
          sx={{
            minWidth: "25%",
            maxHeight: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          <MainLoader isLoading={isLoading} />
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "text",
              padding: "0px 0px 0px 0px",
            }}
          >
            <img
              src={roundClose}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>
          <Typography
            variant="h5"
            align="center"
            style={{ marginTop: "30px", color: "white" }}
          >
            Are sure for Delete This User?
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              padding: "40px 0px 20px 0px",
            }}
          >
            <Button
              onClick={handleClose}
              style={{
                padding: "10px 20px 10px 20px",
                marginRight: "5px",
                background: "green",
                color: "white",
              }}
            >
              Close
            </Button>
            <Button
              style={{
                background: "red",
                color: "white",
                marginLeft: "5px",
                padding: "10px 20px 10px 20px",
              }}
              onClick={onSubmit}
            >
              Delete
              {/* <ExitToAppIcon style={{ marginLeft: "5px" }} /> */}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default DeleteUsersModel;
