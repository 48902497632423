const DashboardTabData = {
  data: [],
  firstActivateTab: 0,
  activateTabIndex: 0,
  activateTabData: {},
  supportData: '',
};

const reducer = (state = DashboardTabData, action) => {
  switch (action.type) {
    case "GET_DASHBOARD_TAB_DATA":
      return state;

    case "UPADATE_DASHBOARD_TAB_DATA":
      return { ...state, data: action.payload };

    case "FIRST_ACTIVATE_TAB":
      return { ...state, firstActivateTab: action.payload };

    case "ACTIVATE_TAB_INDEX":
      return { ...state, activateTabIndex: action.payload };

    case "ACTIVATE_TAB_DATA":
      return { ...state, activateTabData: action.payload };

    case "SUPPORT_TICKET":
      return { ...state, supportData: action.payload };
    default:
      return state;
  }
};
export default reducer;
