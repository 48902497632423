import { Box } from "@mui/system";
import { memo } from "react";
import Loader from "react-loader-spinner";
function MainLoader({ isLoading, color = "#0FBCF9" }) {
  if (!isLoading) return null;
  return (
    <Box
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(0,0,0,0.3)",
        zIndex: 11,
        position: "fixed",
        top: 0,
        left: 0,
      }}
    >
      <Loader type="Circles" height={100} width={100} color={color} />
    </Box>
  );
}
export default memo(MainLoader);
