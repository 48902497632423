import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { MenuItem, Typography } from "@mui/material";
import reload from "../../assets/img/refresh_icn.png";
import circleRight from "../../assets/img/circleRight.png";
import alertRed from "../../assets/img/alert_red.png";
import { useState, useEffect, useContext } from "react";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Api } from "../../MyNewUtils/Api";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Checkbox from "@mui/material/Checkbox";
import SubLoader from "../Spinner/subLoader";
import TextField from "@mui/material/TextField";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@mui/material/Menu";
import myContext from "../../screens/MyContext/MyContext";
import './style.css';
const CssTextField = withStyles({
  root: {
    "& multilineColor": {
      color: "red",
    },
    "& label.Mui-focused": {
      color: "#8F9C0E",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#8F9C0E",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderBottomColor: "#8F9C0E",
        borderColor: "#0000",
      },
      "&:hover fieldset": {
        borderBottomColor: "#8F9C0E",
        borderColor: "#0000",
      },
      "&.Mui-focused fieldset": {
        borderBottomColor: "#8F9C0E",
        borderColor: "#0000",
      },
    },
  },
})(TextField);

const SectionOne = ({
  CloudServers,
  CloudAccountID,
  CloudRegionsID,
  setIsLoading,
  setRadioBttonData,
  searched,
  refresh,
  setRefresh,
  RadioBttondata,
}) => {
  const [showusername, setShowUserName] = useState(true);
  const [allData, setAllData] = useState([]);
  const [checkboxValue, setCheckboxValue] = useState([]);
  const [listusername, setListUserName] = useState([]);
  const [showerror2, setShowError2] = useState(true);
  const [userGroupShowTotalDataLength, setUserGroupShowTotalDataLength] =
    useState(2);
  const [
    userLastActivitySinceTotalDataLength,
    setUserLastActivitySinceTotalDataLength,
  ] = useState(2);
  const [
    userAdminAndExcessiverightsShowTotalDataLength,
    setUserAdminAndExcessiverightsShowTotalDataLength,
  ] = useState(2);
  const [
    userPasswordTypesShowTotalDataLength,
    setUserPasswordTypesShowTotalDataLength,
  ] = useState(2);
  const [lastActivitySinceStartDay, setLastActivitySinceStartDay] = useState();
  const [lastActivitySinceEndDay, setLastActivitySinceEndDay] = useState();
  const [lastActivitySinceLastCustomDate, setLastActivitySinceLastCustomDate] =
    useState();
  const [anchorElManu, setAnchorElManu] = useState(null);
  // const all_data = allData
  // const AllUsersList = allData?.AllUsersList?.data
  const [totaluser, setTotalUser] = useState(0);
  const [defaultCheckBox, setDefaultCheckBox] = useState([]);
  const openManu = anchorElManu;
  const searched_username = searched;
  const handleCloseManu = () => {
    setAnchorElManu(null);
  };
  const getData = async (array) => {
    setIsLoading(true);
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      filter: array,
      account_type: CloudServers,
      accountId: CloudAccountID,
      region: JSON.stringify(CloudRegionsID),
    };
    try {
      const { data } = await Api.postRequest("/users/get-all-iam-users", final);
      const res = JSON.parse(data);
      console.log("res--res--res--res", Object.values(res?.data));
      if (res?.status === 1) {
        let checkBoxDefault = Object.values(res?.data);
        let resArray = Object.values(res?.data);
        resArray.map((item, index) => {
          item.data.map((KeyItem, KeyIndex) => {
            checkBoxDefault[index][KeyIndex] = false;
          });
        });
        setDefaultCheckBox(checkBoxDefault);
        setAllData(Object.values(res?.data));
        setIsLoading(false);
        setShowError2(false);
      } else {
        setShowError2(true);
        setIsLoading(false);
      }
    } catch (e) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    allData?.map((rows, index) => {
      if (rows?.key === "username") {
        console?.log("Edghdfujtgik", rows?.data?.length);
        if (rows?.data?.length != undefined) {
          console?.log("Edghdfujtgik", rows?.data?.length);

          setTotalUser(rows?.data?.length);
        }
      }
    });
  }, [allData]);
  useEffect(() => {
    setIsLoading(true);
    if (CloudAccountID !== undefined && CloudAccountID.length !== 0) getData();
  }, [refresh, CloudServers, CloudAccountID]);
  const refreshItem = () => {
    setRefresh(!refresh);
  };
  const handleChangeCheckbox = (e, index, i) => {
    console.log("testigjfndnfbgdh", e, index, i);
    let array = checkboxValue;
    let defaultCheck = defaultCheckBox;
    defaultCheck[index][i] = !defaultCheck[index][i];
    setDefaultCheckBox(defaultCheck);
    if (e.target.checked) {
      array[array.length] = e.target.value;
    } else {
      if (array?.length != 0) {
        array = array?.filter((item) => item != e.target.value);
        setCheckboxValue(array);
      } else {
        setCheckboxValue([]);
      }
    }

    console.log("array", array);
    getData(array);
  };
  const handleChangeCheckboxParticularStartDateToEndDate = (e) => {
    console.log("bgfgfgfgfgfgfgf");
    let array = checkboxValue;
    if (e.target.checked) {
      array[
        array.length
      ] = `between${lastActivitySinceStartDay}to${lastActivitySinceEndDay}days`;
      setLastActivitySinceLastCustomDate(
        `between${lastActivitySinceStartDay}to${lastActivitySinceEndDay}days`
      );
    } else {
      if (array?.length != 0) {
        array = array?.filter(
          (item) => item != lastActivitySinceLastCustomDate
        );
        setCheckboxValue(array);
      } else {
        setCheckboxValue([]);
      }
    }
    getData(array);
  };
  console.log("allData", allData);
  useEffect(() => {
    if (searched_username != "") {
      allData?.map((rows, index) => {
        if (rows?.key === "username") {
          let filteredRows = rows?.data.filter((row) => {
            return row?.value
              .toLowerCase()
              .includes(searched_username.toLowerCase());
          });
          setListUserName(filteredRows);
        }
      });
    }
  }, [allData, searched_username]);

  const handleChange = (e) => {
    setRadioBttonData(e.target.value);
  };
  const handleChangeCheckboxFilter = (e) => {
    let array = checkboxValue;
    if (array?.length != 0) {
      array = array?.filter((item) => item != e);
      setCheckboxValue(array);
    } else {
      setCheckboxValue([]);
    }
    handleCloseManu();
    getData(array);
  };

  console.log("checkboxValue", checkboxValue);
  return (
    <Box
      style={{
        width: "40%",
        display: "flex",
        // backgroundColor: 'red',
        justifyContent: "space-between",
      }}
      className="sectionone_left_filter"
    >
      <Box style={{ width: "45%" }}>
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography
            style={{ color: "#FF9900", fontSize: "26px" }}
            className="ana_filter_title"
          >
            Filters
          </Typography>
          <Menu
            id="basic-menu"
            anchorEl={anchorElManu}
            open={openManu}
            onClose={handleCloseManu}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            PaperProps={{
              style: {
                background: "#1A2B56",
                color: "#fff",
                "&::-webkit-scrollbar-track": {
                  backgroundColor: "#0000",
                },
                "&::-webkit-scrollbar-thumb": {
                  backgroundColor: "#ADD8E6",
                  borderRadius: 2,
                  border: "0px solid #0000",
                },
              },
            }}
          >
            {checkboxValue.length != 0 ? (
              checkboxValue.map((item, index) => {
                return (
                  <MenuItem
                    value={item}
                    onClick={(e) => {
                      handleChangeCheckboxFilter(item);
                    }}
                  >
                    <Typography sx={{ color: "#fff" }}>{item}</Typography>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem>
                <Typography sx={{ color: "#fff" }}>No Data Filter</Typography>
              </MenuItem>
            )}
          </Menu>
        </Box>
        <Box style={{ marginTop: "40px" }}>
          {showerror2 ? (
            <Typography
              variant="h5"
              sx={{
                margin: "30px",
              }}
              className="no_data_text_box"
            >
              No Data Available
            </Typography>
          ) : (
            allData?.map((row, index) =>
              row?.key != "username" ? (
                <Box>
                  <Typography
                    style={{
                      fontSize: "16px",
                      color: "white",
                      fontWeight: "bold",
                      margin: "10px 0px 10px 0px",
                    }}
                    className="acc_ana_filter_item_data_label"
                  >
                    {row.title}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height:
                        row.key === "IAMUserGroups" ||
                          row.key === "LastActivitySince" ||
                          row.key === "IAMRoles" ||
                          row.key === "IAMPasswordTypes"
                          ? "100%"
                          : "80px",
                      overflowY: "auto",
                      "&::-webkit-scrollbar": {
                        width: 3,
                        height: 10,
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#0000",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "white",
                        borderRadius: 2,
                        border: "0px solid #0000",
                      },
                    }}
                  >
                    {row.key === "IAMRoles" ? (
                      <Box>
                        {row?.data
                          ?.slice(0, userGroupShowTotalDataLength)
                          .map((item, i) => (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px",
                              }}
                            >
                              {console.log(
                                "defaultCheckBox[index][i]",
                                defaultCheckBox[index][i]
                              )}
                              <Checkbox
                                size="small"
                                value={item?.key}
                                onChange={(e) =>
                                  handleChangeCheckbox(e, index, i)
                                }
                                style={{ color: "white" }}
                                labelStyle={{ color: "white" }}
                                iconStyle={{ fill: "white" }}
                              />
                              <lable
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "15px",
                                }}
                                className="acc_ana_checkbox_value_label"
                              >
                                {item?.title} ({item?.user_count})
                              </lable>
                            </Box>
                          ))}
                        {row?.data?.length > 2 &&
                          row?.data?.length != userGroupShowTotalDataLength ? (
                          <Box
                            style={{
                              color: "#EEFF06",
                              fontSize: "18px",
                              opacity: "0.6",
                              padding: "0px 0px 0px 40px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setUserGroupShowTotalDataLength(
                                row?.data?.length
                              );
                            }}
                          >
                            + {row?.data?.length - 2} more
                          </Box>
                        ) : null}
                      </Box>
                    ) : row.key === "LastActivitySince" ? (
                      <Box>
                        {row?.data
                          ?.slice(0, userLastActivitySinceTotalDataLength)
                          .map((item, i) => (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px",
                              }}
                            >
                              <Checkbox
                                size="small"
                                value={item?.key}
                                onChange={(e) =>
                                  handleChangeCheckbox(e, index, i)
                                }
                                style={{ color: "white" }}
                                labelStyle={{ color: "white" }}
                                iconStyle={{ fill: "white" }}
                              />
                              <lable
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "15px",
                                }}
                                className="acc_ana_user_groups_label"
                              >
                                {item?.title} ({item?.user_count})
                              </lable>
                            </Box>
                          ))}
                        {row?.data?.length <= 1 ||
                          row?.data?.length ===
                          userLastActivitySinceTotalDataLength ? (
                          <Box
                            style={{
                              display: "flex",
                              alignItems: "center",
                              padding: "0px",
                            }}
                          >
                            {lastActivitySinceStartDay &&
                              lastActivitySinceEndDay &&
                              lastActivitySinceStartDay <=
                              lastActivitySinceEndDay ? (
                              <Checkbox
                                size="small"
                                value={"Between ___ days to ___ days"}
                                onChange={
                                  handleChangeCheckboxParticularStartDateToEndDate
                                }
                                style={{ color: "white" }}
                                labelStyle={{ color: "white" }}
                                iconStyle={{ fill: "white" }}
                              />
                            ) : (
                              <Checkbox
                                size="small"
                                value={"Between ___ days to ___ days"}
                                onChange={(e) => handleChangeCheckbox(e, index)}
                                style={{ color: "white" }}
                                labelStyle={{ color: "white" }}
                                iconStyle={{ fill: "white" }}
                                disabled
                              />
                            )}
                            <Box>
                              <lable
                                style={{
                                  color: "#8F9C0E",
                                  fontSize: "15px",
                                }}
                              >
                                Between
                                <CssTextField
                                  hiddenLabel
                                  defaultValue="Small"
                                  size="small"
                                  variant="outlined"
                                  id="custom-css-outlined-input"
                                  sx={{
                                    input: { color: "#8F9C0E", padding: "0px" },
                                    width: "13%",
                                    textAlign: "center",
                                  }}
                                  type="number"
                                  onChange={(e) => {
                                    setLastActivitySinceStartDay(
                                      e.target.value
                                    );
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 3);
                                  }}
                                />
                                days to
                                <CssTextField
                                  hiddenLabel
                                  defaultValue="Small"
                                  size="small"
                                  variant="outlined"
                                  id="custom-css-outlined-input"
                                  sx={{
                                    input: { color: "#8F9C0E", padding: "0px" },
                                    width: "13%",
                                    textAlign: "center",
                                  }}
                                  type="number"
                                  onChange={(e) => {
                                    setLastActivitySinceEndDay(e.target.value);
                                  }}
                                  onInput={(e) => {
                                    e.target.value = Math.max(
                                      0,
                                      parseInt(e.target.value)
                                    )
                                      .toString()
                                      .slice(0, 3);
                                  }}
                                />
                                days
                              </lable>
                            </Box>
                          </Box>
                        ) : null}

                        {row?.data?.length > 2 &&
                          row?.data?.length !=
                          userLastActivitySinceTotalDataLength ? (
                          <Box
                            style={{
                              color: "#EEFF06",
                              fontSize: "18px",
                              opacity: "0.6",
                              padding: "0px 0px 0px 40px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setUserLastActivitySinceTotalDataLength(
                                row?.data?.length
                              );
                            }}
                          >
                            + {row?.data?.length - 1} more
                          </Box>
                        ) : null}
                      </Box>
                    ) : row.key === "IAMRoles" ? (
                      <Box>
                        {row?.data
                          ?.slice(
                            0,
                            userAdminAndExcessiverightsShowTotalDataLength
                          )
                          .map((item, i) => (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px",
                              }}
                            >
                              <Checkbox
                                size="small"
                                value={item?.key}
                                onChange={(e) =>
                                  handleChangeCheckbox(e, index, i)
                                }
                                style={{ color: "white" }}
                              />
                              <lable
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "15px",
                                }}
                                className="acc_ana_user_count"
                              >
                                {item?.title} ({item?.user_count})
                              </lable>
                            </Box>
                          ))}
                        {row?.data?.length > 2 &&
                          row?.data?.length !=
                          userAdminAndExcessiverightsShowTotalDataLength ? (
                          <Box
                            style={{
                              color: "#EEFF06",
                              fontSize: "18px",
                              opacity: "0.6",
                              padding: "0px 0px 0px 40px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setUserAdminAndExcessiverightsShowTotalDataLength(
                                row?.data?.length
                              );
                            }}
                          >
                            + {row?.data?.length - 2} more
                          </Box>
                        ) : null}
                      </Box>
                    ) : row.key === "IAMPasswordTypes" ? (
                      <Box>
                        {row?.data
                          ?.slice(0, userPasswordTypesShowTotalDataLength)
                          .map((item, i) => (
                            <Box
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px",
                              }}
                            >
                              <Checkbox
                                size="small"
                                value={item?.key}
                                onChange={(e) =>
                                  handleChangeCheckbox(e, index, i)
                                }
                                style={{ color: "white" }}
                              />
                              <lable
                                style={{
                                  color: "rgba(255,255,255,0.6)",
                                  fontSize: "15px",
                                }}
                                className="acc_ana_user_userdata_count"
                              >
                                {item?.title} ({item?.user_count})
                              </lable>
                            </Box>
                          ))}
                        {row?.data?.length > 2 &&
                          row?.data?.length !=
                          userPasswordTypesShowTotalDataLength ? (
                          <Box
                            style={{
                              color: "#EEFF06",
                              fontSize: "18px",
                              opacity: "0.6",
                              padding: "0px 0px 0px 40px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setUserPasswordTypesShowTotalDataLength(
                                row?.data?.length
                              );
                            }}
                          >
                            + {row?.data?.length - 2} more
                          </Box>
                        ) : null}
                      </Box>
                    ) : (
                      row?.data?.map((item, i) => (
                        <Box
                          style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "0px",
                          }}
                        >
                          <Checkbox
                            size="small"
                            value={item?.key}
                            onChange={(e) => handleChangeCheckbox(e, index, i)}
                            style={{ color: "white" }}
                          />
                          <lable
                            style={{
                              color: "rgba(255,255,255,0.6)",
                              fontSize: "15px",
                            }}
                            className="acc_ana_user_detail_label"
                          >
                            {item?.title} ({item?.user_count})
                          </lable>
                        </Box>
                      ))
                    )}
                  </Box>
                </Box>
              ) : null
            )
          )}
        </Box>
      </Box>
      <Box
        style={{
          width: "1px",
          paddingTop: "50px",
          height: "90%",
          alignSelf: "flex-end",
          backgroundColor: "rgb(112,112,112)",
        }}
      ></Box>

      <Box
        style={{
          width: "50%",
          display: "flex",
          flexDirection: "column",

          // height: '90%',
        }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",

            width: "100%",
            // height: '50px',
            alignItems: "center",
          }}
        >
          <Typography
            style={{ color: "#FF9900", fontSize: "26px" }}
            className="ana_User_title"
          >
            Users
            {totaluser != 0 ? "(" + totaluser + ")" : null}
          </Typography>
          <Box style={{ cursor: "pointer" }} onClick={refreshItem}>
            <img src={reload} className="aca_ana_user_refresh_img" />
          </Box>
        </Box>
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgb(0,0,0,0.3)",
            height: "100%",
            marginTop: "40px",
            padding: "20px",
            borderRadius: "20px",
          }}
        >
          <Box
            style={{
              display: "flex",

              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ fontSize: "16px", color: "white", fontWeight: "bold" }}
            >
              User Name
            </Typography>
            <Box
              onClick={() =>
                showusername ? setShowUserName(false) : setShowUserName(true)
              }
              style={{ cursor: "pointer" }}
            >
              {showusername ? (
                <ArrowDropDownIcon width="20px" style={{ fontSize: "35px" }} />
              ) : (
                <ArrowDropUpIcon width="20px" style={{ fontSize: "35px" }} />
              )}
            </Box>
          </Box>
          {showerror2 ? (
            <Typography
              variant="h5"
              sx={{
                margin: "30px",
              }}
              className="no_data_text_box"
            >
              No Data Available
            </Typography>
          ) : showusername ? (
            <FormControl>
              {allData?.map((row, index) =>
                row?.key === "username" ? (
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="radio-buttons-group"
                  >
                    {searched_username === ""
                      ? row?.data?.map((item, index) => (
                        <FormControlLabel
                          value={item?.value}
                          onChange={handleChange}
                          control={
                            <Radio size="small" style={{ color: "white" }} />
                          }
                          label={item.title}
                          style={{
                            color: "rgba(255,255,255,0.6)",
                            opacity:
                              RadioBttondata === item?.value ? "1" : "0.4",
                          }}
                        />
                      ))
                      : listusername?.map((item, index) => (
                        <FormControlLabel
                          value={item?.value}
                          onChange={handleChange}
                          control={
                            <Radio size="small" style={{ color: "white" }} />
                          }
                          label={item.title}
                          style={{
                            color: "rgba(255,255,255,0.6)",
                            opacity:
                              RadioBttondata === item?.value ? "1" : "0.4",
                          }}
                        />
                      ))}
                  </RadioGroup>
                ) : null
              )}
            </FormControl>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

const SectionTwo = ({
  setIsLoading,
  RadioBttondata,
  setSearched,
  refresh,
  loader,
  allIamIsersLoader,
}) => {
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(4);
  const [total, setTotal] = useState();
  const [showItem, setShowItem] = useState(true);
  const [policyTitle, setPolicyTitle] = useState("");
  const userName = RadioBttondata;
  const [arraylength, setArrayLength] = useState();
  const [allData, setAllData] = useState();
  const [iAMPoliciesShowDataLength, setIAMPoliciesShowDataLength] = useState(4);
  console.log("CshGSEJHDRFJ", allData);

  const getData1 = async () => {
    console.log("setIsLoading __ 06");
    setIsLoading(true);
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      username: userName,
    };
    const { data } = await Api.postRequest(
      "/users/get-user-policy-by-username",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      setAllData(res?.data?.data?.finaldata[0]?.data);
      console.log("setIsLoading __ 07", res?.data?.data?.finaldata[0]?.data);
      if (res?.data?.data?.finaldata[0]?.data > 4) {
        setIAMPoliciesShowDataLength(4);
      } else {
        setIAMPoliciesShowDataLength(
          res?.data?.data?.finaldata[0]?.data.length
        );
      }

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userName != "") {
      getData1();
    }
  }, []);

  useEffect(() => {
    if (userName != "") {
      getData1();
    }
  }, [userName, refresh]);
  console.log("userName", userName);
  useEffect(() => {
    allData?.data?.map((rows, index) => {
      setArrayLength(rows?.data?.length);
      if (rows?.data?.length > 4) {
        setTotal(rows?.data?.length - 4);
      }
    });
  }, [allData]);

  const showAll = () => {
    setEnd(arraylength);
    setShowItem(false);
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
  };

  return (
    <Box style={{ width: "60%", position: "relative" }}>
      <SubLoader isLoading={!loader && !allIamIsersLoader ? false : true} />
      <Box style={{ padding: "0px 30px" }}>
        <Box>
          <Box
            style={{
              display: "flex",
              backgroundColor: "#272E42",
              borderRadius: "10px",
              padding: "5px 30px",
              flex: 1,
            }}
            className="acc_ana_right_user_search_filter"
          >
            <SearchIcon style={{ color: "white", margin: "5px" }} />
            <input
              placeholder={"Search user"}
              onChange={(e) => requestSearch(e.target.value)}
              style={{
                border: "none",
                outline: "none",
                padding: "5px",
                width: "100%",
                backgroundColor: "transparent",
                color: "#fff",
              }}
            />
          </Box>
        </Box>
        <Box
          style={{

          }}
          className="policy_recommendation_main_container"
        >
          <Box style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography style={{ color: "white" }}>Policies</Typography>
            <Typography
              style={{
                color: "#FF9900",
                fontWeight: "600",
                marginRight: "30px",
              }}
            >
              {" "}
              Recommendation
            </Typography>
          </Box>
          <Box
            style={{ width: "100%", height: "2px", backgroundColor: "#707070" }}
          ></Box>
          <Box>
            <Typography
              style={{ fontSize: "14px", color: "white", paddingTop: "7px" }}
            >
              Selected User: {userName}
            </Typography>
          </Box>
          {console.log("allData--dsdfsdsd", allData)}
          {allData?.map((val, ind) => {
            let valueIsArray = Array.isArray(val?.value);
            console.log();
            return (
              <TableContainer
                component={Paper}
                sx={{
                  background: "#0000",
                  "&::-webkit-scrollbar": {
                    width: 3,
                    height: 2,
                  },
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#0000",
                  },
                  "&::-webkit-scrollbar-thumb": {
                    backgroundColor: "white",
                    borderRadius: 2,
                    // border: "0px solid #0000",
                    marginBottom: "10px",
                  },
                }}
              >
                <Table
                  sx={{
                    minWidth: 650,
                    background: "#0000",
                  }}
                  className="select_user_policy_table"
                  size="small"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        className="selected_user_table_titles"
                      >
                        {val?.title}
                      </TableCell>
                      <TableCell style={{ border: "0px" }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ background: "#0000" }}>
                    {valueIsArray ? (
                      val?.key === "AWSManaged" ? (
                        <Box>
                          {val?.value
                            ?.slice(0, iAMPoliciesShowDataLength)
                            .map((rows, index) => {
                              return (
                                <TableRow
                                  key={index}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    sx={{
                                      fontSize: "14px",
                                      color: "rgba(255,255,255,0.6)",
                                      marginLeft: "5px",
                                      border: "0px",
                                    }}
                                  >
                                    <img
                                      src={circleRight}
                                      style={{ marginRight: "10px" }}
                                    />
                                    {rows}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          {iAMPoliciesShowDataLength != val?.value?.length &&
                            val?.value?.length > 4 ? (
                            <Box
                              sx={{
                                color: "#EEFF06",
                                opacity: "0.6",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setIAMPoliciesShowDataLength(
                                  val?.value?.length
                                );
                              }}
                            >
                              + {val?.value?.length - 4} more
                            </Box>
                          ) : null}
                        </Box>
                      ) : val?.value?.length != 0 ? (
                        <Box>
                          {val?.value?.map((rows, index) => {
                            return (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{
                                    fontSize: "14px",
                                    color: "rgba(255,255,255,0.6)",
                                    marginLeft: "5px",
                                    border: "0px",
                                  }}
                                >
                                  <img
                                    src={circleRight}
                                    style={{ marginRight: "10px" }}
                                  />
                                  {rows}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </Box>
                      ) : (
                        <TableRow
                          key={1}
                          sx={{
                            "&:last-child td, &:last-child th": {
                              border: 0,
                            },
                          }}
                        >
                          <TableCell
                            component="th"
                            scope="row"
                            sx={{
                              fontSize: "14px",
                              color: "#fff",
                              marginLeft: "5px",
                              border: "0px",
                              display: "flex",
                              alignItems: "center",
                              opacity: "0.5",
                            }}
                          >
                            There are no policies to display!
                          </TableCell>
                        </TableRow>
                      )
                    ) : val?.value == "Not set" || val?.value == "Not Set" ? (
                      <TableRow
                        key={1}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: "14px",
                            color: "red",
                            marginLeft: "5px",
                            border: "0px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img src={alertRed} style={{ marginRight: "10px" }} />
                          Not Set
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        key={1}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            fontSize: "14px",
                            color: "#fff",
                            marginLeft: "5px",
                            border: "0px",
                            display: "flex",
                            alignItems: "center",
                            opacity: "0.5",
                          }}
                        >
                          There are no policies to display!
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  {arraylength > 4 ? (
                    <Typography
                      style={
                        showItem
                          ? {
                            fontSize: "14px",
                            color: "rgba(230,255,0,0.6)",
                            marginLeft: "15px",
                          }
                          : { display: "none" }
                      }
                      onClick={showAll}
                    >
                      + {total} more
                    </Typography>
                  ) : null}
                </Table>
              </TableContainer>
            );
          })}

          {/* <Box style={{ marginTop: '10px' }}>
            <Typography style={{ color: 'white', fontWeight: '600' }}>
              Custom IAM Policies
            </Typography>
            <Typography
              style={{
                color: 'rgba(255,255,255,0.6)',
                marginLeft: '20px',
                fontSize: '14px',
              }}
            >
              There are no policies to display!
            </Typography>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Typography style={{ color: 'white', fontWeight: '600' }}>
              Permissions Boundary Policy
            </Typography>
            <Typography
              style={{
                color: 'rgba(255,255,255,0.6)',
                marginLeft: '20px',
                fontSize: '14px',
              }}
            >
              There are no policies to display!
            </Typography>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Typography style={{ color: 'white', fontWeight: '400' }}>
              Password Complexity Policy
            </Typography>
            <Box
              style={{
                display: 'flex',
                alignItems: 'center',
                marginLeft: '20px',
              }}
            >
              <img src={alertRed} />
              <Typography
                style={{
                  color: 'rgba(255,0,0,0.6)',
                  marginLeft: '5px',
                  fontSize: '14px',
                }}
              >
                Not Set
              </Typography>
            </Box>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Typography style={{ color: 'white', fontWeight: '400' }}>
              Custom IAM Permissions Boundary Policy
            </Typography>
            <Typography
              style={{
                color: 'rgba(255,255,255,0.6)',
                marginLeft: '20px',
                fontSize: '14px',
              }}
            >
              There are no policies to display!
            </Typography>
          </Box>

          <Box style={{ marginTop: '20px' }}>
            <Typography style={{ color: 'white', fontWeight: '400' }}>
              Resource Policies
            </Typography>
            <Typography
              style={{
                color: 'rgba(255,255,255,0.6)',
                marginLeft: '20px',
                fontSize: '14px',
              }}
            >
              There are no policies to display!
            </Typography>
          </Box> */}
        </Box>
      </Box>
    </Box>
  );
};

const AnalysisSecondBody = () => {
  const [searched, setSearched] = useState("");
  const [RadioBttondata, setRadioBttonData] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [loader, setLoader] = useState(false);
  const [allIamIsersLoader, setAllIamIsersLoader] = useState(false);

  const { cloudDetails } = useContext(myContext);
  return (
    <>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          margin: "50px 0px 20px 0px",
        }}
      >
        <Box>
          <Typography
            style={{ fontSize: "24px", color: "white", fontWeight: "700" }}
            className="acc_ana_iam_filter_title"
          >
            IAM ANALYSIS FILTER
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
          borderRadius: "30px",
          display: "flex",
          padding: "30px",
          position: "relative",
        }}
      >
        <SectionOne
          CloudServers={cloudDetails?.selectedCloudServers}
          CloudAccountID={cloudDetails?.selectedCloudAccountID}
          CloudRegionsID={cloudDetails?.selectedCloudRegionsID}
          setIsLoading={setAllIamIsersLoader}
          searched={searched}
          setRadioBttonData={setRadioBttonData}
          refresh={refresh}
          setRefresh={setRefresh}
          RadioBttondata={RadioBttondata}
        />
        <SectionTwo
          setIsLoading={setLoader}
          setSearched={setSearched}
          RadioBttondata={RadioBttondata}
          refresh={refresh}
          setRefresh={setRefresh}
          loader={loader}
          allIamIsersLoader={allIamIsersLoader}
        />
      </Box>
    </>
  );
};

export default AnalysisSecondBody;
