import { Box } from '@mui/system'
import { memo } from 'react'
import Loader from 'react-loader-spinner'
function SubLoader({ isLoading, color = '#0FBCF9' }) {
  if (!isLoading) return null
  return (
    <Box
      style={{
        flex: 1,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0,0,0,0.3)',
        // backgroundColor: 'red',
        zIndex: 1,
        position: 'absolute',
        top: 0,
        left: 0,
      }}
    >
      <Loader type="Oval" height={60} width={60} color={color} />
    </Box>
  )
}
export default memo(SubLoader)
