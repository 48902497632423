import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { React } from "react";
// import wallet from '../../../assets/img/wallet.png'
import "./FeturesCard.css";
const FeturedItem = ({ description }) => {
  // const [age, setAge] = React.useState('');

  return (
    <Box>
      <Box style={{ display: "flex", alignItems: "center" }}>
        <Box>
          <Box
            style={{
              width: "15px",
              height: "15px",
              borderRadius: "50%",
              backgroundColor: "white",
            }}
            className="bullet_point_resp"
          ></Box>
        </Box>
        <Box style={{ marginTop: "10px", color: "#fff" }}>
          <Typography
            style={{ fontSize: "1rem", paddingLeft: "11px" }}
            className="plan_card_font"
          >
            {description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
const FeturesCard = ({
  title,
  fetures,
  icon,
  planid,
  price,
  annual,
  select,
  selectedPlan,
  setSelectedPlan,
  backgroundColor,
  annualplan,
}) => {
  // console.log(planid)
  const [noOfusers, setNoOfusers] = useState(1);
  const onSubmit = (e) => {
    console.log("destgershy", noOfusers);
    setSelectedPlan({
      ...selectedPlan,
      planid: planid,
      transactionAmount: price,
      isannualplan: !select,
      annualplan: annualplan,
      noofusers: noOfusers,
    });
  };

  const handleChange = (event) => {
    setNoOfusers(event.target.value);
  };

  useEffect(() => {
    if (selectedPlan.planid == planid) {
      setSelectedPlan({
        ...selectedPlan,
        noofusers: noOfusers,
      });
    }
  }, [noOfusers]);
  return (
    <Box
      style={{
        width: "25%",
        // backgroundColor: 'red',
        paddingRight: "20px",
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
      }}
      className="plan_detail_box"
    >
      <Box
        style={{
          flexGrow: 1,
          borderRadius: "30px",
          backgroundColor: backgroundColor
            ? "rgb(0,0,0,0.40)"
            : "rgb(0,0,0,0.40)",
          padding: "20px",
          border: "3px solid #008CF2",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <FormControlLabel
            value={title}
            control={<Radio style={{ color: "#008fff" }} />}
            label={`${title} ${price} ${price != "Free" ? `$` : ""} ${annual}`}
            // planid={planid}
            // onClick={(e) => setSelectedPlan({ ...selectedPlan, planid })}
            onClick={onSubmit}
            style={{
              color: "#008CF2",
              fontWeight: "bold",
              fontSize: "22px",
            }}
          />

          {fetures.map((item) => (
            <FeturedItem description={item} />
          ))}
        </Box>

        <Box
          style={{
            marginTop: "20px",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              style={{ fontSize: "16px", color: "#fff" }}
              className="plan_upgrade_text"
            >
              Number of users
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={noOfusers}
              // label="Age"
              defaultValue={1}
              style={{
                color: "#fff",
                border: "1px solid blue",
                marginLeft: "15px",
                padding: "0px",
              }}
              className="plan_upgrade_text"

              // onChange={handleChange}
            >
              <MenuItem
                value={1}
                style={{ background: "#010a26", color: "#fff" }}
              >
                1
              </MenuItem>
              <MenuItem
                value={2}
                style={{ background: "#010a26", color: "#fff" }}
              >
                2
              </MenuItem>
              <MenuItem
                value={3}
                style={{ background: "#010a26", color: "#fff" }}
              >
                3
              </MenuItem>
              <MenuItem
                value={4}
                style={{ background: "#010a26", color: "#fff" }}
              >
                4
              </MenuItem>
              <MenuItem
                value={5}
                style={{ background: "#010a26", color: "#fff" }}
              >
                5
              </MenuItem>
            </Select>
          </Box>
          <Box
            style={{ display: "flex", justifyContent: "center" }}
            className="plan_upgrade_img"
          >
            <img src={icon} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default FeturesCard;
