import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useMemo, useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/CO5_FINAL.png";
import cross from "../../assets/img/cross.png";
import RegistrationLayout from "../../Component/Layout/index";
import "./login.css";
import { Authenticate } from "../../api/users";
import { style } from "./style";
import MainLoader from "../../Component/Spinner/MainLoader";
import { Api } from "../../MyNewUtils/Api";
import { useNavigate } from "react-router-dom";
import { path } from "../../MyNewUtils/screenPath";
import myContext from "../MyContext/MyContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { toast } from "react-toastify";
import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const Login = () => {
  const [remember, setRemember] = useState(false);
  const [loginError, setLoginError] = useState("");
  const { userId, myFunc } = useContext(myContext);
  const cls = useMemo(() => "login", []);
  const [isShowError, setIsShowError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isEmailShowError, setIsEmailShowError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [loginData, setLoginData] = useState({
    email: null,
    password: null,
  });
  useEffect(() => {
    const rememberedata = JSON.parse(localStorage.getItem("rememberemail"));
    if (rememberedata) {
      setLoginData({
        ...loginData,
        email: rememberedata?.email,
        password: rememberedata?.password,
      });
    } else {
      // console.log("rememberedata", rememberedata)
      console.log("null");
      // console.log("123456", loginData.email);
    }
  }, []);

  const onEmailSet = (e) => {
    setLoginData({ ...loginData, email: e.target.value });
  };

  const onPasswordSet = (e) => {
    setLoginData({ ...loginData, password: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    // console.log({loginData})
    // setIsLoading(true)
    // const {statusCode, data} = await Api.postRequest('/users/authenticate', {
    //   ...loginData,
    // })
    // const res = JSON.parse(data)
    // myFunc(res.data.id)
    // if(res.message == 'Logged in successfully')
    // {
    //   localStorage.setItem('')
    //   navigate(path.dashBoardHome)
    // }else{
    //   navigate(path.login)

    var emailregex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (emailregex.test(loginData.email)) {
      if (loginData.password != null && loginData.password.length != 0) {
        setIsEmailShowError(false);
        // setIsShowError(false)
        let body = {
          email: loginData?.email.toLowerCase(),
          password: loginData?.password,
        };
        const { statusCode, data } = await Api.postRequest(
          "/users/authenticate",
          body
        );
        const res = JSON.parse(data);
        setIsLoading(false);
        if (res.status == 1) {
          // console.log(res)
          setIsLoading(true);
          myFunc(res.data.id);
          localStorage.setItem("user", JSON.stringify(res));
          localStorage.setItem("userData", JSON.stringify(res?.data));
          if (res) {
            if (remember === true) {
              let rememberdata = {
                email: loginData.email.toLowerCase(),
                password: loginData.password,
              };
              localStorage.setItem(
                "rememberemail",
                JSON.stringify(rememberdata)
              );
            } else {
              localStorage.setItem("rememberemail", JSON.stringify("null"));
            }
            const stepRemaining = parseInt(res?.data?.AccountSetupLastStep) + 1;

            if (res?.data?.isAccountSetupFinished) {
              navigate(path.dashBoardHome);
            }
            if (stepRemaining == 2) {
              navigate(path.step2);
            }
            if (stepRemaining == 3) {
              navigate(path.step3);
            }

            if (stepRemaining == 4) {
              navigate(path.step4);
            }

            if (stepRemaining == 5) {
              navigate(path.step6);
            }

            if (stepRemaining == 6) {
              navigate(path.dashBoardHome);
            }
          }
        } else {
          setIsLoading(false);
          toast.error(res.message, { autoClose: 1000 });
        }
      } else {
        setIsEmailShowError(false);
        // setIsShowError(true)
      }
    } else {
      if (loginData.password != null && loginData.password.length != 0) {
        setIsEmailShowError(true);
        // setIsShowError(true)
      } else {
        setIsEmailShowError(false);
        // setIsShowError(false)
      }
    }

    setIsLoading(false);
  };

  return (
    <RegistrationLayout>
      <MainLoader isLoading={isLoading} />
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} className={`${cls}__gridItem`}>
            <img src={logo} alt="logo" className="img-fluid signup_logo" />
          </Grid>
          <Grid item xs={6} className={`${cls}__gridContentItem`}>
            <Box className={`${cls}__gridContentBox`}>
              <Box>
                <Typography style={style.contentTitle}>
                  Great have you back!
                </Typography>
              </Box>

              {/* <Box>
                <label for="email" className={`${cls}__label`}>
                  Email
                </label>

                <input
                  type="email"
                  name="email"
                  className={`${cls}__input`}
                  placeholder="Enter your email id"
                  value={loginData.email}
                  onChange={onEmailSet}
                />
              </Box> */}
              <form onSubmit={onSubmit}>
                <Box className={`${cls}__d-flex`}>
                  <Box
                    style={{
                      position: "relative",
                      displat: "flex",
                      alignItem: "center",
                    }}
                  >
                    <label for="Password" className={`${cls}__label`}>
                      Email
                    </label>

                    <input
                      type="email"
                      // name="email"
                      placeholder="Enter your email"
                      className={`${cls}__input`}
                      value={loginData.email}
                      onChange={onEmailSet}
                      required
                    // style={{ textTransform: 'lowercase' }}
                    />
                    {isEmailShowError && (
                      <img
                        src={cross}
                        alt={"wrong"}
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "15px",
                        }}
                      />
                    )}
                  </Box>
                  <Box style={{ position: "relative" }}>
                    {isEmailShowError && (
                      <div className={`${cls}__error`}>
                        <div className={`${cls}__error_msgBody`}>
                          <p className={`${cls}__error_text`}>
                            Sorry, enter valid email
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                </Box>

                <Box className={`${cls}__d-flex`}>
                  <Box
                    style={{
                      position: "relative",
                      displat: "flex",
                      alignItem: "center",
                    }}
                  >
                    <label for="Password" className={`${cls}__label`}>
                      Password
                    </label>

                    <input
                      type={showPassword ? "text" : "password"}
                      name="Password"
                      placeholder="Enter your paassword"
                      className={`${cls}__input`}
                      value={loginData.password}
                      onChange={onPasswordSet}
                      required
                    />
                    <Box
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon
                          sx={{
                            position: "absolute",
                            right: "13px",
                            bottom: "13px",
                            color: "#020922",
                          }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{
                            position: "absolute",
                            right: "13px",
                            bottom: "13px",
                            color: "#020922",
                          }}
                        />
                      )}
                    </Box>
                    {isShowError && (
                      <img
                        src={cross}
                        alt={"wrong"}
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "15px",
                        }}
                      />
                    )}
                  </Box>
                  <Box style={{ position: "relative" }}>
                    {isShowError && (
                      <div className={`${cls}__error`}>
                        <div className={`${cls}__error_msgBody`}>
                          <p className={`${cls}__error_text`}>
                            Sorry, enter valid password
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                </Box>

                <Box className={`${cls}__alignItems`}>
                  {/* <Box className={`${cls}__alignCheckbox`}>
                    <Box className={`${cls}__round`} tabIndex="0">
                      <input type="checkbox" id="checkbox" onChange={(e) => setRemember(!remember)} tabIndex="0" />
                      <label for="checkbox"></label>
                    </Box>
                    <span className={`${cls}__cheackbox_text`}>Remember me</span>
                  </Box> */}
                  <Box>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkbox"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 30 },
                            color: "white",
                          }}
                          onChange={(e) => setRemember(!remember)}
                        />
                      }
                      label="Remember me"
                    />
                  </Box>
                  <Box>
                    <Link
                      to="/password-recovery"
                      className={`${cls}__forgotlink`}
                    >
                      <a>Forgot Password?</a>
                    </Link>
                  </Box>
                </Box>

                <Box className={`${cls}__margin30 ${cls}__width350`}>
                  <Button type="submit" style={style.btn}>
                    SIGN IN
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </RegistrationLayout>
  );
};
export default Login;
