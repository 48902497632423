import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import Input from '@mui/material/Input';
const style = {
    position: 'absolute',
    top: '50%',
    left: '60%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    bgcolor: '#01081D',
    border: ' 1px solid #707070',
    boxShadow: 24,
    p: 4,
  };
  
const AddNumber =()=>{
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    return(
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize:'30px',textAlign:'center',color:'#008CF2'}}>
            Add Mobile Number
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize:'22px',textAlign:'center',color:'#FFFFFF',marginTop:'32px'}}>
            Add a mobile number for your resources
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{fontSize:'22px',textAlign:'center',color:'#008CF2',marginTop:'32px'}}>
          Mobile number
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{fontSize:'22px',textAlign:'center',color:'#FFFFFF',marginTop:'16px'}}>
              <Input type='Number' placeholder="+91 00000 00000" style={{color:'#fff',border:' 1px solid #008CF2',borderRadius:'20px',textAlign:'center'}}></Input>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{marginTop:'32px',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <Stack spacing={2} direction="row">
                    <Button variant="outlined">cancel</Button>
                    <Button variant="contained">Add Contact</Button>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
    )
}
export default AddNumber;