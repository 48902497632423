
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';


const ForgetEmailAndPhoneNumber =()=>{
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        bgcolor: '#01081D',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const ForgetEmailModelClose =()=>{
        setOpen(!open);
    }
    
    return (
        <div>
          <Button onClick={handleOpen}>I can't remember my email address or phone number</Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style} style={{textAlign:'center'}}>
              <Typography id="modal-modal-title" variant="h6" component="h2" style={{color:'#008CF2',fontSize:'30px',fontWeight:'bold'}}>
              Forgot Email & Phone number
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{color:'#fff',fontSize:'20px'}}>
              If you need more help, mail us at below email ID with First name & Last name.
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{color:'#fff',fontSize:'20px',display:'flex',flexDirection:'row',paddingLeft:'auto',paddingRight:'auto',textAlign:'center',alignItems:'center',justifyContent:'center'}}>
                <Typography style={{fontSize:'20px'}}> Email to </Typography><Typography style={{fontSize:'20px',color:'#FF8800'}}>support@clevrone.com</Typography>
              </Typography>
              <Button variant="contained" style={{marginTop:'20px'}} onClick={ForgetEmailModelClose}>I understand</Button>
            </Box>
          </Modal>
        </div>
      );

}
export default ForgetEmailAndPhoneNumber;