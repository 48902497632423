import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";

import logo from "../../assets/img/logo_header.png";
import home from "../../assets/img/home.png";
import globe from "../../assets/img/globe.png";
import lock from "../../assets/img/lock_lock.png";
import cloudComputing from "../../assets/img/cloud-computing.png";
import settings from "../../assets/img/settings_icn.png";
import oUser from "../../assets/img/319946.webp";
import money from "../../assets/img/money.png";

import notification from "../../assets/img/notification.png";
import { useMemo, useState } from "react";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import DashBoardSettingHeader from "../../Component/DashboardHeader/DashBoardSettingHeader";
import { styled, alpha } from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";

import "./style.css";
import { DataGrid } from "@mui/x-data-grid";
import DashBoardTable from "../../Component/DashBoardTable/DashBoardTable";
import DashboardSettingUserBody from "../../Component/DashboardSettingUserBody/DashboardSettingUserBody";
import DashboardSettingRolesBody from "../../Component/Dashboard2/DashboardSettingRolesBody";
import Header from "../dashboard/Header/header";
const DashBoardTwo = () => {
  const cls = useMemo(() => "DahboardTwo", []);
  return (
    <Box className={`${cls}__background`} style={{}}>
      {/* <Header/> */}
      <DashboardHeader activeTab={"SETTINGS"} activeSettingSubTab={"Roles"} />
      {/* Setting inner header*/}
      {/* <DashBoardSettingHeader selectItem={'Roles'} /> */}
      <DashboardSettingRolesBody />
    </Box>
  );
};
export default DashBoardTwo;
// 2,7,41
