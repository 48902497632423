import { Box } from "@mui/system";

import { useMemo, useState } from "react";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import "./style.css";
import DashboardSettingUserBody from "../../Component/DashboardSettingUserBody/DashboardSettingUserBody";
import MainLoader from "../../Component/Spinner/MainLoader";

const DashBoardOne = () => {
  const cls = useMemo(() => "DahboardOne", []);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box className={`${cls}__background`}>
      <MainLoader isLoading={isLoading} />

      {/* Header */}
      <DashboardHeader activeTab={"SETTINGS"} activeSettingSubTab={"Users"} />

      {/* Setting inner header*/}
      {/* <DashBoardSettingHeader selectItem={'Users'} /> */}

      <DashboardSettingUserBody setIsLoading={setIsLoading} />
    </Box>
  );
};
export default DashBoardOne;
// 2,7,41
