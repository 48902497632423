import { Box } from '@mui/system'
import RegistrationLayout from '../../Component/Layout/index'
import Button from '@mui/material/Button';
import logo from '../../assets/img/CO5_FINAL.png'
import { Typography } from '@mui/material'
import { useCallback, useMemo, useState } from 'react'
import { Api } from '../../MyNewUtils/Api'
import key from '../../assets/img/key@2x.png'
import './ResetPassword.css'
import CustomButton from "../../Component/CustomButton/index";
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'

const ResetPassword = (props) => {
  const [email, setEmail] = useState(useLocation().state.email)
  const [otp, setOtp] = useState(useLocation().state.otp)

  const cls = useMemo(() => 'resetPassword', [])
  const [value, setValue] = useState('')

  const [confirmPassword, setConfirmPassword] = useState('')
  const navigate = useNavigate()
  const [validate, setValidate] = useState({
    charecterLong: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    special: false,
    isStrong: false,
  })
  const _handleChange = useCallback(e => {
    const checkValidObj = {}
    const newVal = e.target.value

    // check 8 cherecter
    checkValidObj.charecterLong = newVal.length >= 8

    // checkLowerCase
    checkValidObj.lowerCase = /[a-z]/.test(newVal)

    // checkUpperCase
    checkValidObj.upperCase = /[A-Z]/.test(newVal)

    // check number
    checkValidObj.number = /[0-9]/.test(newVal)

    // check special
    checkValidObj.special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      newVal,
    )

    checkValidObj.isStrong =
      checkValidObj.charecterLong &&
      checkValidObj.lowerCase &&
      checkValidObj.upperCase &&
      checkValidObj.number &&
      checkValidObj.special
    setValidate(checkValidObj)
    setValue(e.target.value)
  }, [])

  const _handleContinue = useCallback(async (event) => {

    event.preventDefault();

    if (value != confirmPassword) {
      toast.error("Password not Match", { autoClose: 1000 })
    } else {
      if (validate.charecterLong && validate.lowerCase && validate.upperCase && validate.number && validate.special) {
        let final = { email: email, emailOtp: otp, mobileOtp: otp, password: value, OTP: otp }
        const { statusCode, data } = await Api.postRequest('/users/reset-password', final)
        const res = JSON.parse(data)
        if (res?.status == 1) {
          navigate('/')
        } else {
          toast.error(res?.message, { autoClose: 1000 })
        }
      } else {
        toast.error("please enter valid Password", { autoClose: 1000 })
      }
    }

  })
  return (
    <RegistrationLayout>
      {' '}
      <Box
        style={{
          //   backgroundColor: 'red',
          //   flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            maxWidth: '40%',
          }}
        >
          {/* mainLogo */}
          <Box>
            <img src={logo} width={200} height={200} />
          </Box>
          <Box>
            <img src={key} width={80} height={80} />
          </Box>
          <Box>
            <Typography
              style={{ color: '#008CF2', fontSize: '30px', textAlign: 'center' }}
            >
              Create a new password
            </Typography>
            <Typography style={{ fontSize: '20px', textAlign: 'center' }}>
              You may now set your new password. Your account will be enabled
              with this new password.
            </Typography>
          </Box>

          <form onSubmit={_handleContinue} >

            {/* Input */}
            <Box
              style={{
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <input
                type={'password'}
                className={`${cls}__input`}
                placeholder={'Enter new password'}
                value={value}
                onChange={_handleChange}
                required />
              <Typography
                style={{
                  textAlign: 'center',
                  marginTop: '5px',
                  color: value.length < 8 ? 'red' : 'green',
                }}
              >
                {value.length === 0
                  ? 'Please enter your password'
                  : value.length < 8
                    ? 'it must be 8 charecter'
                    : validate.isStrong
                      ? 'Your new password is Very strong!'
                      : 'Your new password is good!'}
              </Typography>
              <Box style={{ width: '300px', marginTop: '10px' }}>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    style={{ color: validate.charecterLong ? 'green' : 'red' }}
                  >
                    8 characters long{' '}
                  </Typography>
                  <Typography
                    style={{ color: validate.lowerCase ? 'green' : 'red' }}
                  >
                    1 lowercase [a-z]{' '}
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography
                    style={{ color: validate.upperCase ? 'green' : 'red' }}
                  >
                    1 uppercase[A-Z]{' '}
                  </Typography>
                  <Typography style={{ color: validate.number ? 'green' : 'red' }}>
                    1 number [0-9]{' '}
                  </Typography>
                </Box>
                <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Typography style={{ color: validate.special ? 'green' : 'red' }}>
                    1 special character
                  </Typography>
                </Box>
              </Box>
            </Box>
            {/* Comfirm password */}
            <Box
              style={{
                marginTop: '30px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <input
                type={'password'}
                className={`${cls}__input`}
                placeholder={'Confirm password'}
                value={confirmPassword}
                onChange={(e) => { setConfirmPassword(e.target.value) }}
                required />
            </Box>
            <Box style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
              <Button type="submit" style={{ background: "#008CF2", color: "#FFF", marginTop: "20px", borderRadius: "20px", padding: "10px 18px", marginBottom: "10px" }}>
                Continue
              </Button>

            </Box>
          </form>


        </Box>
      </Box>
    </RegistrationLayout>
  )
}
export default ResetPassword
