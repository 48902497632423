import React from "react";
import Modal from "@mui/material/Modal";
import { useEffect, useState, useCallback } from "react";
import { Button, TextField, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { toast } from "react-toastify";
import SubLoader from "../Spinner/subLoader";
import roundClose from "../../assets/img/cancel_icn.png";
import { Api } from "../../MyNewUtils/Api";
import { conutryList } from "../../utils/country";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const SearchBarMenu = ({ refresh, setSearchBarMenuModel, setRefresh }) => {
  const [open, setOpen] = useState(true);
  const [passwordPolicyLoader, setPasswordPolicyLoader] = useState(false);
  const [passwordPolicy, setPasswordPolicy] = useState();
  const [specialCharacters, setSpecialCharacters] = useState(false);
  const [numbers, setNumbers] = useState(false);
  const [lowerCaseLetters, setLowerCaseLetters] = useState(false);
  const [upperCaseLetters, setUpperCaseLetters] = useState(false);
  const [countryCode, setCountryCode] = useState(conutryList);
  const [passwordVisibil, setPasswordVisibil] = useState(false);

  const [mydata, setMyData] = useState({
    fname: "",
    lname: "",
    email: "",
    pwd: "",
    aroles: "",
    lnumber: "",
    lcountryCode: "+93",
  });
  const [validate, setValidate] = useState({
    charecterLong: false,
    lowerCase: false,
    upperCase: false,
    number: false,
    special: false,
    isStrong: false,
  });

  const handleClose = () => setSearchBarMenuModel(false);
  const inputEvent = (event) => {
    const { name, value } = event.target;
    setMyData((preValue) => {
      return {
        ...preValue,
        [name]: value,
      };
    });
  };
  const _handleChangePassword = useCallback(
    (e) => {
      const checkValidObj = {};
      const newVal = e.target.value;

      const { name, value } = e.target;

      // check  cherecter Long
      checkValidObj.charecterLong =
        newVal.length >= passwordPolicy?.passwordMinimumLength;

      // checkLowerCase
      checkValidObj.lowerCase = /[a-z]/.test(newVal);

      // checkUpperCase
      checkValidObj.upperCase = /[A-Z]/.test(newVal);

      // check number
      checkValidObj.number = /[0-9]/.test(newVal);

      // check special
      checkValidObj.special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
        newVal
      );

      checkValidObj.isStrong =
        checkValidObj.charecterLong &&
        checkValidObj.lowerCase &&
        checkValidObj.upperCase &&
        checkValidObj.number &&
        checkValidObj.special;
      setValidate(checkValidObj);

      setMyData((preValue) => {
        return {
          ...preValue,
          [name]: value,
        };
      });
    },
    [passwordPolicy]
  );
  const onSubmit = async (event) => {
    let checkValidObj = {};
    let passwordLength = passwordPolicy?.passwordMinimumLength;
    let regex = passwordPolicy?.passwordMustCotain;

    event.preventDefault();

    // checkLowerCase
    checkValidObj.lowerCase = /[a-z]/.test(mydata?.pwd);

    // checkUpperCase
    checkValidObj.upperCase = /[A-Z]/.test(mydata?.pwd);

    // check number
    checkValidObj.number = /[0-9]/.test(mydata?.pwd);

    // check special
    checkValidObj.special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      mydata?.pwd
    );

    if (mydata?.pwd.length >= passwordLength) {
      if (!upperCaseLetters || upperCaseLetters === checkValidObj?.upperCase) {
        if (
          !lowerCaseLetters ||
          lowerCaseLetters === checkValidObj?.lowerCase
        ) {
          if (!numbers || numbers === checkValidObj?.number) {
            if (
              !specialCharacters ||
              specialCharacters === checkValidObj?.special
            ) {
              let lapdata = JSON.parse(localStorage.getItem("user")).data
                .ldapcred;
              // let secret = lapdata?.secret.split("secret");
              let final = {
                userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
                uid: lapdata?.uid,
                uidW: lapdata?.uidW,
                secret: lapdata?.secret,
                url: lapdata?.url,
                newuserphone: mydata?.lnumber,
                newusercountrycode: mydata?.lcountryCode,
                newusername: `${mydata?.fname}${mydata?.lname}`,
                newuserfirstname: mydata?.fname,
                newuserlastname: mydata?.lname,
                newuseremail: mydata?.email.toLowerCase(),
                newuserpassword: mydata?.pwd,
              };
              const { data } = await Api.postRequest("/ldap/add-user", final);
              const res = JSON.parse(data);
              if (res?.status === 1) {
                setSearchBarMenuModel(false);
                setRefresh(!refresh);
                toast.success(res?.message, { autoClose: 1000 });
              } else if (res?.status === 0) {
                toast.error(res?.message, { autoClose: 1000 });
              } else {
                toast.error("validation error", { autoClose: 1000 });
              }
            } else {
              toast.error(
                `Your password must be have at least 1 Special Characters`,
                { autoClose: 1000 }
              );
            }
          } else {
            toast.error(
              `Your password must be have at least 1 Digit Numeric Number`,
              { autoClose: 1000 }
            );
          }
        } else {
          toast.error(
            `Your password must be have at least 1 lowercase Character`,
            { autoClose: 1000 }
          );
        }
      } else {
        toast.error(
          `Your password must be have at least 1 uppercase Character`,
          { autoClose: 1000 }
        );
      }
    } else {
      toast.error(
        `Your password must be have at least ${passwordLength} Characters Long`,
        { autoClose: 1000 }
      );
    }

    // if (mydata?.pwd.match(/^[A-Z]*$/)) {
    //     toast.error('true')
    // } else {
    //     toast.error('false')
    // }
  };

  const getPasswordPolicy = async () => {
    setPasswordPolicyLoader(true);
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { data } = await Api.postRequest("/users/get-password-policy", final);
    const res = JSON.parse(data);
    if (res.status == 1) {
      setPasswordPolicy(res?.data);
      let policy = res?.data?.passwordMustCotain;
      setPasswordPolicyLoader(false);
      policy?.map((item, index) => {
        if (item === "UPPERCASE") {
          setUpperCaseLetters(true);
        }
        if (item === "LOWERCASE") {
          setLowerCaseLetters(true);
        }
        if (item === "SPECIALCHARACTERS") {
          setSpecialCharacters(true);
        }
        if (item === "NUMBERS") {
          setNumbers(true);
        }
      });
      // toast.success(res.message, { autoClose: 1000 });
    } else {
      setPasswordPolicyLoader(false);
      toast.error(res.message, { autoClose: 1000 });
    }
  };
  useEffect(() => {
    getPasswordPolicy();
  }, []);
  return (
    <div>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "none",
          display: "flex",
          alignItems: "right",
          justifyContent: "right",
        }}
      >
        <Box
          sx={{
            background: "rgba(0,0,0,0.5)",
            borderRadius: "30px",
            padding: "40px",
            border: "1px solid #707070",
            // width: '23%',
            maxHeight: "55%",
            overflow: "auto",
            margin: "290px 20px 0px 0px",
            backdropFilter: "blur(10px)",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          <SubLoader isLoading={passwordPolicyLoader} />
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "text",
              padding: "0px 0px 0px 0px",
            }}
          >
            <img
              src={roundClose}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </Box>

          <Box>
            <Typography style={{ color: "#FF8800", fontSize: "22px" }}>
              Create New User
            </Typography>
          </Box>

          <form onSubmit={onSubmit}>
            <Box style={{ display: "flex", margin: "10px 0px 10px 0px" }}>
              <TextField
                type="text"
                name="fname"
                value={mydata.fname}
                onChange={inputEvent}
                label="First Name"
                variant="standard"
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                required
              />
              <TextField
                type="text"
                label="Last Name"
                name="lname"
                value={mydata.lname}
                onChange={inputEvent}
                variant="standard"
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                style={{
                  marginLeft: "10px",
                }}
                required
              />
            </Box>
            <Box style={{ margin: "10px 0px 10px 0px" }}>
              <TextField
                label="Email"
                variant="standard"
                name="email"
                type="email"
                value={mydata.email}
                onChange={inputEvent}
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                    textTransform: "lowercase",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                    textTransform: "lowercase",
                  },
                }}
                style={{ width: "100%", textTransform: "lowercase" }}
                required
              />
            </Box>
            <Box style={{ margin: "10px 0px 10px 0px" }}>
              <TextField
                label="Password"
                variant="standard"
                name="pwd"
                type={passwordVisibil ? "text" : "password"}
                value={mydata.pwd}
                onChange={_handleChangePassword}
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setPasswordVisibil(!passwordVisibil)}
                        onMouseDown={() => setPasswordVisibil(!passwordVisibil)}
                        edge="end"
                      >
                        {passwordVisibil ? (
                          <VisibilityOffOutlinedIcon />
                        ) : (
                          <VisibilityOutlinedIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                style={{ width: "100%" }}
                required
              />
            </Box>
            {/* <Typography
              style={{
                textAlign: "center",
                marginTop: "5px",
                color: mydata.length < passwordPolicy?.passwordMinimumLength ? "red" : "green",
              }}
            >
              {mydata.length === 0
                ? "Please enter your password"
                : mydata.length < passwordPolicy?.passwordMinimumLength
                ? "it must be passwordPolicy?.passwordMinimumLength charecter"
                : validate.isStrong
                ? "Your new password is Very strong!"
                : "Your new password is good!"}
            </Typography> */}
            <Box sx={{ flexGrow: 1, maxWidth: "100%" }}>
              <Grid container>
                <Grid item xs={6}>
                  <Typography
                    style={{ color: validate.charecterLong ? "green" : "red" }}
                  >
                    {passwordPolicy?.passwordMinimumLength} characters long{" "}
                  </Typography>
                </Grid>

                {lowerCaseLetters && (
                  <Grid item xs={6}>
                    <Typography
                      style={{ color: validate.lowerCase ? "green" : "red" }}
                    >
                      1 lowercase [a-z]{" "}
                    </Typography>
                  </Grid>
                )}

                {upperCaseLetters && (
                  <Grid item xs={6}>
                    <Typography
                      style={{ color: validate.upperCase ? "green" : "red" }}
                    >
                      1 uppercase[A-Z]{" "}
                    </Typography>
                  </Grid>
                )}

                {numbers && (
                  <Grid item xs={6}>
                    <Typography
                      style={{ color: validate.number ? "green" : "red" }}
                    >
                      1 number [0-9]{" "}
                    </Typography>
                  </Grid>
                )}

                {specialCharacters && (
                  <Grid item xs={6}>
                    <Typography
                      style={{ color: validate.special ? "green" : "red" }}
                    >
                      1 special character
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Box>

            <Box style={{ display: "flex", margin: "10px 0px 10px 0px" }}>
              <TextField
                type="Number"
                name="lnumber"
                value={mydata.lnumber}
                onChange={inputEvent}
                label="Number"
                variant="standard"
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                style={{ width: "100%" }}
                required
              />
            </Box>
            <Box style={{ display: "flex", margin: "10px 0px 10px 0px" }}>
              <select
                name="lcountryCode"
                onChange={inputEvent}
                style={{
                  width: "100%",
                  backgroundColor: "black",
                  border: "1px solid #363636",
                  padding: "4px",
                  color: "#FFF",
                  marginTop: "10px",
                }}
              >
                {countryCode.map((item) => (
                  <option
                    value={item.dial_code}
                  >{`${item.name}  (${item.dial_code})`}</option>
                ))}
              </select>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "30px 0px 0px 0px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{ padding: "8px 40px", borderRadius: "20px" }}
              >
                Save
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

export default SearchBarMenu;
