import Header from '../Header/header'
import UserDetails from '../deshboardhome/UserLogin'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import { Typography } from '@mui/material'
import aws_cloud_icn from '../../../assets/img/aws_cloud_icn.png'
import azure_icn from '../../../assets/img/azure_icn.png'
import firebase_icn from '../../../assets/img/firebase_icn.png'
import DashboardHeader from '../../../Component/DashboardHeader/DashboardHeader'
import { useNavigate } from 'react-router-dom'
import CloudinfoHeader from './CloudinfoHeader'

const Cloudinfo = () => {
  const navigate = useNavigate()
  return (
    <>
      {/* <Header /> */}
      <DashboardHeader activeTab={'CLOUD INFO'} />
      <CloudinfoHeader />
      <UserDetails></UserDetails>
    </>
  )
}
export default Cloudinfo
