import { Box } from "@mui/system";
import { MenuItem, Select, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useNavigate } from "react-router-dom";
import reload from "../../assets/img/refresh_icn.png";
import { useState, useContext } from "react";
import AnalysisSecondBody from "./AnalysisSecondBody";
import AnalysisThirdBody from "./AnalysisThirdBody";
import SubLoader from "../Spinner/subLoader";
import myContext from "../../screens/MyContext/MyContext";
import './style.css';
// how to parameter in useNavigate in react js

const ProgressItem = ({ color, persentage, title, value }) => {
  return (
    <Box style={{ marginTop: "20px" }}>
      <Box style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography
          style={{ color: "rgba(255,255,255,0.9)", fontSize: "16px" }}
        >
          {title}
        </Typography>
        <Typography
          style={{ color: "rgba(255,255,255,0.9)", fontSize: "16px" }}
        >
          {value}({persentage <= 100 ? persentage : "100"}%)
        </Typography>
      </Box>
      <Box
        style={{
          width: "100%",
          height: "20px",
          backgroundColor: "white",
          marginTop: "10px",
        }}
      >
        <Box
          // style={{
          //   width: persentage + '%',
          //   height: '100%',
          //   backgroundColor: color,
          // }}
          style={
            persentage <= 100
              ? {
                width: persentage + "%",
                height: "100%",
                backgroundColor: color,
              }
              : { width: "100%", height: "100%", backgroundColor: color }
          }
        ></Box>
      </Box>
    </Box>
  );
};

const AnalysisFirstHeader = ({
  showerror1,
  subLoader,
  allData,
  rType,
  setRType,
  refresh,
  setRefresh,
  allS3BucketsKey,
}) => {
  const data = allData;
  const resource_type = rType;
  const statistics_data = data?.statistics?.data;
  const finaldata = data?.finaldata;
  const [age, setAge] = useState("");
  const navigate = useNavigate();
  const { cloudDetails } = useContext(myContext);
  const handleChange = (event) => {
    setAge(event.target.value);
    setRType(event.target.value);
  };

  const refreshItem = () => {
    setRefresh(!refresh);
  };

  return (
    <>
      {" "}
      <Box sx={{ padding: "100px" }}>
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Box>
            <Typography
              style={{ fontSize: "24px", color: "white", fontWeight: "700" }}
              className="ana_secure_analysis_title"
            >
              Security Analysis
            </Typography>
          </Box>
          <Box style={{ marginLeft: "20px" }}>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{
                "aria-label": "Without label",
                style: { padding: "0px", margin: "0px" },
              }}
              style={{
                backgroundColor: "#272E42",
                width: "300px",
                color: "white",
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    background: "#010A26",
                  },
                },
              }}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value="" style={{ color: "#fff" }}>
                <em>S3</em>
              </MenuItem>
              <MenuItem value="rds" style={{ color: "#fff" }}>
                RDS
              </MenuItem>
              <MenuItem value="ec2" style={{ color: "#fff" }}>
                EC2
              </MenuItem>
            </Select>
          </Box>
          <Box
            style={{ marginLeft: "20px", cursor: "pointer" }}
            onClick={refreshItem}
          >
            <img src={reload} />
          </Box>
        </Box>
        <Box
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            borderRadius: "30px",
            display: "flex",
            flexDirection: "column",
            padding: "30px",
            position: "relative",
          }}
        >
          <SubLoader isLoading={subLoader} />
          {/* first header */}
          {showerror1 ? (
            <Box
              style={{
                flexGrow: 1,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography variant="h5" className="no_data_text_box">
                No Data Available
              </Typography>
            </Box>
          ) : (
            <Box>
              <Box
                style={{ flexGrow: 1, display: "flex", alignItems: "center" }}
              >
                <Box style={{ flexGrow: 1 }}>
                  {console.log("rType", rType)}
                  <Typography style={{ fontSize: "20px", color: "#FF8800" }}>
                    {rType.toUpperCase()} {rType === "s3" ? "Buckets" : null}{" "}
                  </Typography>
                </Box>
                <Box
                  style={{
                    flexGrow: 1,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {statistics_data?.map((item, index) => (
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "16px",
                          color: "rgba(255,255,255,0.8)",
                        }}
                        className="sec_analysis_state_item"
                      >
                        {item?.title}
                      </Typography>
                      <Typography
                        style={{
                          color: "#008CF2",
                          fontSize: "18px",
                          fontWeight: "700",
                        }}
                        className="sec_analysis_state_value"
                      >
                        {item?.value}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
              {/* second body */}
              <Box
                style={{
                  flexGrow: 1,
                  display: "flex",
                  marginTop: "40px",
                  justifyContent: "space-around",
                }}
              >
                {finaldata?.map((row, index) => (
                  <Box style={{ width: "30%" }}>
                    <Typography style={{ color: "#008CF2", fontSize: "26px" }}
                      className="acc_ana_public_acces_data"
                    >
                      {row?.title}
                    </Typography>
                    {row?.data?.map((item, index) => (
                      <Box
                        onClick={() =>
                          navigate("/AccessAnalysisS3Bucket", {
                            state: {
                              r_type: resource_type,
                              key: item.key,
                              title: item.title,
                              AllTitle: allS3BucketsKey,
                            },
                          })
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <ProgressItem
                          color={item?.percentage > 50 ? "#FF8800" : "#7CECF7"}
                          persentage={parseFloat(item?.percentage).toFixed(2)}
                          title={item.title}
                          value={item.value}
                          width={100}
                        />
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            </Box>
          )}
        </Box>

        <AnalysisSecondBody />

        <AnalysisThirdBody />
      </Box>
    </>
  );
};

export default AnalysisFirstHeader;
