import { useContext } from "react";
import Header from "../Header/header";
import "./Profile.css";
import { Box, color } from "@mui/system";
import { Grid } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import parson from "../../../assets/img/pro_pic.png";
import billing from "../../../assets/img/billing_building.png";
import location from "../../../assets/img/billing_location.png";
import barchart from "../../../assets/img/Component 149 – 1.png";
import talert from "../../../assets/img/triangle_icn.png";
import visa from "../../../assets/img/Component 150 – 1.svg";
import amrican from "../../../assets/img/Component 152 – 1.svg";
import defaultimg from "../../../assets/img/default.png";
import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddCardModel from "./AddCardModel";
import moment from "moment";
import React, { useEffect, useMemo } from "react";
import Highcharts from "highcharts";
import AddNewAddress from "./AddNewAddressModel";
import Upgradeplan from "./Upgrademodel";
import DashboardHeader from "../../../Component/DashboardHeader/DashboardHeader";
import MainLoader from "../../../Component/Spinner/MainLoader";
import { Api } from "../../../MyNewUtils/Api";
import EditCard from "./EditCard";
import EditAddress from "./EditAddress ";
import ProgressBar from "react-customizable-progressbar";
// -------------------------------------------------------------------------------------
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useState } from "react";
import HighchartsReact from "highcharts-react-official";
import { toast } from "react-toastify";
import myContext from "../../../screens/MyContext/MyContext";
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/networkgraph")(Highcharts);

// ----------------------------------------donut chart---------------------------------------------
function Example(props) {
  return (
    <div>
      <div
        style={{
          width: "137px",
          display: "flex",
          margin: "auto",
          padding: "5px",
        }}
      >
        {props.children}
      </div>
    </div>
  );
}

const percentage = 18;
// -----------------------------donut chart-----------------------------------------------------------

//------------------------------table------------------------------------------------------------
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Nov 01, 2020", "Invoice for Ocrober 2021", "$123.79"),
  createData("Nov 01, 2020", "Invoice for Ocrober 2021", "$123.79"),
  createData("Nov 01, 2020", "Invoice for Ocrober 2021", "$123.79"),
  createData("Nov 01, 2020", "Invoice for Ocrober 2021", "$123.79"),
  createData("Nov 01, 2020", "Invoice for Ocrober 2021", "$123.79"),
];
//------------------------------table------------------------------------------------------------

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const Profile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [addCardmodel, setAddCardmodel] = useState(false);
  const [addNewAddressModel, setAddNewAddressModel] = useState(false);
  const [upgradeplanmodel, setUpgradeplanmodel] = useState(false);
  const [cardID, setCardID] = useState("");
  const [card, setCard] = useState([]);
  const [upgradeCard, setUpgradeCard] = useState(false);
  const [upgradeAddress, setUpgradeAddress] = useState(false);
  const [address, setAddress] = useState([]);
  const [upgradeCardId, setUpgradeCardId] = useState("");
  const [upgradeAddressId, setUpgradeAddressId] = useState("");
  const [CircularData, setCircularData] = useState({
    outofDays: "0",
    days: "0",
  });
  const [Six_MonthData, setSix_MonthData] = useState([]);
  const [Six_MonthTitle, setSix_MonthTitle] = useState([]);

  const [One_YearData, setOne_YearData] = useState([]);
  const [One_YearTitle, setOne_YearTitle] = useState([]);

  const [AllData, setAllData] = useState([]);
  const [AllTitle, setAllTitle] = useState([]);

  const [BillingHistoryError, SetBillingHistoryError] = useState(false);
  const [BillingTypeData, SetBillingTypeData] = useState("six_month");
  const [subscriptionDetails, SetSubscriptionDetails] = useState();
  const [activesubscriptionDate, SetActivesubscriptionDate] =
    useState("Not set");
  const { profileImageUrl } = useContext(myContext);
  // console.log(address);
  const allcard = async () => {
    setIsLoading(true);
    let final = {
      stripeCustomerID: JSON.parse(localStorage.getItem("user"))?.data
        ?.stripeCustomerID,
    };
    const { statusCode, data } = await Api.postRequest(
      "/users/get-all-cards",
      final
    );
    const res = JSON.parse(data);
    // console.log("res", res)
    if (res?.status === 1) {
      setCard(res?.data);
      setIsLoading(false);
    }
  };
  const detelcard = async (e) => {
    setIsLoading(true);
    let final = {
      stripeCustomerID: JSON.parse(localStorage.getItem("user"))?.data
        ?.stripeCustomerID,
      cardID: e,
    };
    const { statusCode, data } = await Api.postRequest(
      "/users/delete-card",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      toast.success(res?.message, { autoClose: 1000 });
    } else {
      toast.error(res?.message, { autoClose: 1000 });
    }
    allcard();
    setIsLoading(false);
  };
  const billingaddress = async () => {
    setIsLoading(true);
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { statusCode, data } = await Api.postRequest(
      "/users/get-all-billing-address",
      final
    );
    const res = JSON.parse(data);
    // console.log("res2", res)
    if (res?.status === 1) {
      setAddress(res?.data);

      setIsLoading(false);

      const { Addressnumber } = 1;
    }
  };
  const deteladdress = async (e) => {
    setIsLoading(true);
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      id: e,
    };
    const { statusCode, data } = await Api.postRequest(
      "/users/delete-address",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      toast.success(res?.message, { autoClose: 1000 });
    } else {
      toast.error(res?.message, { autoClose: 1000 });
    }
    billingaddress();
    setIsLoading(false);
  };
  const updatecard = (e) => {
    console.log("e-e-e-e-ee-e-e-e-e-ee-e-e-e-e-", e);
    setUpgradeCardId(e);
    setUpgradeCard(true);
  };
  const updateAddress = (e) => {
    setUpgradeAddressId(e);
    setUpgradeAddress(true);
  };

  const CircularProgressbarData = async () => {
    setIsLoading(true);
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { statusCode, data } = await Api.postRequest(
      "/users/get-subscription-statistics-for-profile",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      setCircularData(res?.data);
      setIsLoading(false);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setIsLoading(false);
    }
  };

  const BillingHistoryData = async () => {
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { statusCode, data } = await Api.postRequest(
      "/transactions/billing-history",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      setSix_MonthData(res?.data?.six_month);
      let titleTableMonth = Object.keys(res?.data?.six_month[0]);
      setSix_MonthTitle(titleTableMonth);

      setOne_YearData(res?.data?.one_year);
      let titleTableYear = Object.keys(res?.data?.one_year[0]);
      setOne_YearTitle(titleTableYear);

      setAllData(res?.data?.all);
      let titleTableAll = Object.keys(res?.data?.all[0]);
      setAllTitle(titleTableAll);
      // console.log("Asgvrsfhrtfgj", titleTable)
    } else {
      SetBillingHistoryError(true);
    }
  };

  const SetMonthData = () => {
    SetBillingTypeData("six_month");
  };
  const SetYearData = () => {
    SetBillingTypeData("one_year");
  };
  const SetAllTimeData = () => {
    SetBillingTypeData("all");
  };

  useEffect(() => {
    setIsLoading(true);
    BillingHistoryData();
    setIsLoading(false);
  }, [BillingTypeData]);

  useEffect(() => {
    setIsLoading(true);
    allcard();
    billingaddress();
    CircularProgressbarData();
    setIsLoading(false);
  }, [addCardmodel, addNewAddressModel, upgradeCard, upgradeAddress]);

  const cancelSubscription = async () => {
    let body = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      subscriptionId: subscriptionDetails?.subscriptionId,
    };
    const { networkdata, data } = await Api.postRequest(
      "/users/cancel-subscription",
      body
    );
    const res = JSON.parse(data);
    toast(res?.message, { autoClose: 1000 });
  };

  const getSubscriptionDetails = async () => {
    let body = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { networkdata, data } = await Api.postRequest(
      "/users/get-latest-subscription",
      body
    );
    const res = JSON.parse(data);
    SetSubscriptionDetails(res?.data);
    SetActivesubscriptionDate(
      moment(res?.data?.subscriptionEndDate).local().format("MMM DD,YYYY")
    );
  };

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  // -------------------------------------------------------------------------------------------
  const chartData = useMemo(
    () => [
      ["Jan", 14],
      ["Feb", 14],
      ["Mar", 14],
      ["Apr", 14],
      ["May", 14],
      ["Jun", 14],
      ["Jul", 15],
      ["Aug", 14],
      ["Sep", 14],
      ["Oct", 14],
      ["Nov", 14],
      ["Dec", 14],
    ],
    []
  );
  const Barcolor = "#2562FF";
  const mapOptions = useMemo(() => ({
    chart: {
      type: "column",
      height: "191px",
      backgroundColor: "rgb(0, 0, 0,0)",
    },
    title: {
      text: "Monthly Payments",
      style: {
        fontSize: "19px",
        fontWeight: "bold",
        color: "#008CF2",
      },
    },
    subtitle: {
      text: null,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    xAxis: {
      type: "category",
      labels: {
        rotation: -50,
        style: {
          fontSize: "13px",
          fontFamily: "Verdana, sans-serif",
          color: "#fff",
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      pointFormat: null,
    },
    series: [
      {
        name: "Monthly Payments",

        data: [
          {
            name: "Jan",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Feb",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Mar",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Apr",
            y: 0,
            color: Barcolor,
          },
          {
            name: "May",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Jun",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Jul",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Aug",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Sep",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Oct",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Nov",
            y: 0,
            color: Barcolor,
          },
          {
            name: "Dec",
            y: 0,
            color: Barcolor,
          },
        ],
        exporting: {
          enabled: true,
        },
        dataLabels: {
          enabled: false,
          rotation: -90,
          color: "#FFFFFF",
          align: "right",
          format: "{point.y:.1f}", // one decimal
          y: 10, // 10 pixels down from the top
          style: {
            fontSize: "13px",
            fontFamily: "Verdana, sans-serif",
          },
        },
      },
    ],
  }));

  return (
    <>
      <MainLoader isLoading={isLoading} />
      <DashboardHeader activeTab={"Profile-Billing"} />
      {addCardmodel ? <AddCardModel setAddCardmodel={setAddCardmodel} /> : null}
      {addNewAddressModel ? (
        <AddNewAddress setAddNewAddressModel={setAddNewAddressModel} />
      ) : null}
      {upgradeplanmodel ? (
        <Upgradeplan setUpgradeplanmodel={setUpgradeplanmodel} />
      ) : null}
      {upgradeCard ? (
        <EditCard
          allcard={allcard}
          setUpgradeCard={setUpgradeCard}
          upgradeCardId={upgradeCardId}
        />
      ) : null}
      {upgradeAddress ? (
        <EditAddress
          billingaddress={billingaddress}
          setUpgradeAddress={setUpgradeAddress}
          upgradeAddressId={upgradeAddressId}
        />
      ) : null}
      <Box className="Profile__background">
        <Box>
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Typography style={{ fontSize: "20", fontWeight: "bold" }}>
              Profile
            </Typography>
            <Typography style={{ fontSize: "20", fontWeight: "bold" }}>
              <ChevronRightIcon />
            </Typography>
            <Typography style={{ fontSize: "20", fontWeight: "bold" }}>
              Billing
            </Typography>
          </Box>

          <Grid
            style={{
              background: "rgba(0,0,0,0.3)",
              display: "flex",
              justifyContent: "space-evenly",
              padding: "45px 60px 45px 60px",
              border: "1px solid #707070",
              borderRadius: "20px",
            }}
            className="profile-img-box"
          >
            <Grid
              style={{
                display: "flex",
                flexWrap: "wrap",
                alignContent: "center",
              }}
            >
              {profileImageUrl === "" ? (
                <img src={defaultimg} width="164px" height="164px" alt="" />
              ) : (
                <img
                  src={profileImageUrl}
                  width="164px"
                  height="164px"
                  alt=""
                  className="profil_bill_resp_img"
                />
              )}
            </Grid>

            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                marginLeft: "20px",
              }}
              className="tim_res_box"
            >
              <Typography
                style={{ fontSize: "26px", fontWeight: "bold" }}
                className="resp_profile_name_bill"
              >
                {JSON.parse(localStorage.getItem("user")).data.name}
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "11px",
                }}
              >
                <img style={{ marginRight: "13px" }} src={billing} />
                {JSON.parse(localStorage.getItem("user")).data.address}
              </Typography>
              <Typography
                style={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  marginTop: "9px",
                }}
              >
                <img style={{ marginRight: "13px" }} src={location} />
                {JSON.parse(localStorage.getItem("user")).data.state},{" "}
                {JSON.parse(localStorage.getItem("user")).data.country}
              </Typography>
            </Grid>

            <Grid
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                width: "20%",
              }}
              className="time_resp"
            >
              <Typography
                style={{
                  fontSize: "19px",
                  fontWeight: "bold",
                  color: "#008CF2",
                  display: "flex",
                  justifyContent: "center",
                }}
                className="time_label_resp"
              >
                Timeline
              </Typography>
              <Typography style={{ display: "flex", justifyContent: "center" }}>
                {/* <Example >
                                    <CircularProgressbar
                                        value={CircularData?.days}
                                        text={`${CircularData?.outofDays}/${CircularData?.days}`}
                                        styles={buildStyles({
                                            textColor: "#fff",
                                            pathColor: "#b8eef3",
                                            trailColor: "#328cf2"
                                        })}
                                    />

                                </Example> */}
                <Box>
                  <ProgressBar
                    progress={CircularData?.days}
                    radius={60}
                    steps={CircularData?.outofDays}
                    strokeColor="#328cf2"
                    trackStrokeWidth={13}
                    strokeWidth={13}
                    trackStrokeColor="#b8eef3"
                  >
                    <div className="your-indicator">
                      <div>{`${CircularData?.days}/${CircularData?.outofDays}`}</div>
                    </div>
                  </ProgressBar>
                </Box>
              </Typography>
              <Typography
                style={{ fontSize: "19px", margin: "0 auto 0 auto" }}
                className="day_rem_resp"
              >
                Days remaining
              </Typography>
            </Grid>

            <Grid style={{ width: "44%" }} className="high_timeline_resp">
              {/* <Typography style={{ display: 'flex', justifyContent: ' flex-end' }}>
                                <Typography style={{ fontSize: '19px', paddingLeft: '5px', paddingRight: '5px', fontWeight: 'bold', color: '#FF8800', background: '#FF88002c', display: 'flex', borderRadius: '20px' }}>Renew Subscription</Typography>
                            </Typography> */}
              <HighchartsReact options={mapOptions} highcharts={Highcharts} />
            </Grid>
          </Grid>
        </Box>

        <Box
          style={{
            padding: "45px 60px 45px 60px",
            background: "rgba(0,0,0,0.3)",
            border: "1px solid #707070",
            borderRadius: "20px",
            marginTop: "60px",
          }}
        >
          <Box style={{ background: "#008CF230" }}>
            <Typography
              style={{ display: "flex", padding: "42px 0px 42px 30px" }}
            >
              <Typography style={{ margin: "auto 30px auto 0" }}>
                <img src={talert} width="37px" height="37px" />
              </Typography>
              <Typography>
                <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
                  We need your attention!
                </Typography>
                <Typography style={{ fontSize: "20px" }}>
                  Your payment was declined. To start using tools, please{" "}
                  <b
                    style={{
                      fontSize: "20px",
                      color: "#008CF2",
                      paddingLeft: "5px",
                      fontWeight: "normal",
                    }}
                  >
                    Add Payment Method.
                  </b>
                </Typography>
              </Typography>
            </Typography>
          </Box>

          <Typography
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "34px",
              flexWrap: "wrap",
            }}
          >
            <Typography>
              <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
                Active until {activesubscriptionDate}
              </Typography>
              <Typography style={{ fontSize: "20px" }}>
                We will send you a notification upon Subscription expiration
              </Typography>
            </Typography>
            <Typography style={{ width: "50%" }}>
              <Typography
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#008CF2",
                  }}
                >
                  Users
                </Typography>
                <Typography
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    color: "#fff",
                  }}
                >
                  84 of 100 Used
                </Typography>
              </Typography>
              <BorderLinearProgress
                variant="determinate"
                value={84}
                style={{ width: "100%" }}
              />
              <Typography style={{ fontSize: "16px" }}>
                14 Users remaining until your plan requires update
              </Typography>
            </Typography>
          </Typography>

          <Typography
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "34px",
              flexWrap: "wrap",
            }}
          >
            <Typography>
              <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
                $24.99 Per Month
              </Typography>
              <Typography style={{ fontSize: "20px" }}>
                Extended Pro Package. Up to 100 Agents & 25 Projects
              </Typography>
            </Typography>
            <Typography>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
                // className="upgrade-cancel-btn-resp"
              >
                <Button
                  style={{
                    fontWeight: "normal",
                    fontSize: "20px",
                    color: "#fff",
                  }}
                  onClick={cancelSubscription}
                >
                  Cancel Subscription
                </Button>
                <Button
                  onClick={() => {
                    setUpgradeplanmodel(true);
                  }}
                  style={{
                    fontWeight: "normal",
                    fontSize: "20px",
                    color: "#fff",
                    background: "#008CF2",
                    borderRadius: "20px",
                    marginLeft: "37px",
                    padding: "5px 10px 5px 10px",
                  }}
                >
                  Upgrade Plan
                </Button>
              </Typography>
            </Typography>
          </Typography>
        </Box>

        <Box
          style={{
            padding: "45px 60px 45px 60px",
            background: "rgba(0,0,0,0.3)",
            border: "1px solid #707070",
            borderRadius: "20px",
            marginTop: "60px",
          }}
        >
          <Typography
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
              Payment Methods
            </Typography>
            <Typography style={{ display: "flex" }}>
              <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
                Credit / Debit Card
              </Typography>
              {/* <Typography style={{ fontWeight: 'normal', fontSize: '22px', paddingLeft: '90px' }}>PayPal</Typography> */}
            </Typography>
          </Typography>

          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "22px",
              margin: "36px 0 0 25px",
            }}
          >
            My Cards
          </Typography>

          <Grid
            container
            md="12"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {card?.map((item) => {
              return (
                <Grid md="5" style={{ paddingTop: "50px" }}>
                  <Grid
                    style={{
                      background: "rgba(0,0,0,0.5)",
                      padding: "16px 0px 32px 32px",
                      borderRadius: "10px",
                      border: "1px solid #707070",
                    }}
                    className="my_card_box_resp"
                  >
                    <Grid
                      style={{
                        fontWeight: "bold",
                        fontSize: "20px",
                        marginBottom: "16px",
                      }}
                    >
                      {item?.cardName}
                    </Grid>
                    <Grid style={{ display: "flex", alignItems: "center" }}>
                      <Grid md="auto">
                        <img
                          src={visa}
                          width="97px"
                          height="44px"
                          className="visa_img_resp"
                        />
                      </Grid>
                      <Grid
                        md="6"
                        style={{ paddingLeft: "20px" }}
                        className="visa_text_resp"
                      >
                        <Grid style={{ fontSize: "100%", fontWeight: "bold" }}>
                          {item?.cardType}
                          {item?.cardNumber}
                        </Grid>
                        <Grid style={{ fontSize: "100%" }}>
                          Card expires at {item?.cardExpDetails}
                        </Grid>
                      </Grid>
                      <Grid md="5" className="pr_bill_resp">
                        <Button
                          style={{
                            fontSize: "100%",
                            fontWeight: "normal",
                            background: "#707070",
                            height: "30px",
                            color: "#fff",
                          }}
                          className="pr_bil_delete"
                          onClick={() => {
                            detelcard(item?.cardId);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          style={{
                            fontSize: "100%",
                            fontWeight: "normal",
                            background: "#707070",
                            height: "30px",
                            color: "#fff",
                            marginLeft: "7px",
                          }}
                          className="delete_edit_section"
                          onClick={() => {
                            updatecard(item);
                          }}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <Grid md="5" style={{ paddingTop: "50px" }}>
              <Grid
                style={{
                  background: "#011d3e",
                  padding: "16px 0px 32px 32px",
                  borderRadius: "10px",
                  border: "1px solid #707070",
                }}
              >
                <Grid
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginBottom: "16px",
                  }}
                >
                  Important Note !
                </Grid>
                <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                  <Grid md="8">
                    <Typography
                      style={{
                        fontWeight: "normal",
                        fontSize: "20px",
                        marginBottom: "16px",
                        display: "flex",
                      }}
                    >
                      Please carefully read Metronic Terms adding your new
                      payment card
                    </Typography>
                  </Grid>
                  <Grid md="4">
                    <Button
                      style={{
                        fontSize: "20px",
                        fontWeight: "normal",
                        background: "#344A64",
                        color: "#fff",
                      }}
                      className="rsp_add_card"
                      onClick={() => {
                        setAddCardmodel(true);
                      }}
                    >
                      Add Card
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box
          style={{
            padding: "45px 60px 45px 60px",
            background: "rgba(0,0,0,0.3)",
            border: "1px solid #707070",
            borderRadius: "20px",
            marginTop: "60px",
          }}
        >
          <Typography
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
              Billing Address
            </Typography>
          </Typography>
          {/* {console.log('addressaddressaddress', address)} */}
          <Grid
            container
            md="12"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            {address?.map((item, index) => {
              return (
                <Grid md="5" style={{ paddingTop: "50px", width: "100%" }}>
                  <Grid
                    style={{
                      background: "rgba(0,0,0,0.5)",
                      padding: "20px 30px 20px 30px",
                      borderRadius: "10px",
                      border: "1px solid #707070",
                      width: "100%",
                    }}
                    className="address_box"
                  >
                    <Grid
                      style={{
                        fontWeight: "bold",
                        fontSize: "22px",
                        marginBottom: "16px",
                        display: "flex",
                      }}
                    >
                      Address {index + 1}
                      <Typography
                        style={{
                          background: "#00B7002c",
                          color: "#00B700",
                          marginLeft: "29px",
                          fontWeight: "bold",
                        }}
                      >
                        {item?.prime}
                      </Typography>
                    </Grid>
                    <Grid style={{ display: "flex" }}>
                      <Grid md="7">
                        <Typography
                          style={{
                            fontWeight: "normal",
                            fontSize: "17px",
                            color: "#D7D8D9",
                          }}
                        >
                          {item?.address1}
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "normal",
                            fontSize: "17px",
                            color: "#D7D8D9",
                          }}
                        >
                          {item?.address2},{item?.pincode}
                        </Typography>
                        <Typography
                          style={{
                            fontWeight: "normal",
                            fontSize: "17px",
                            color: "#D7D8D9",
                          }}
                        >
                          {item?.country}
                        </Typography>
                      </Grid>
                      <Grid
                        md="5"
                        style={{ marginTop: "auto", marginLeft: "auto" }}
                        className="billling_resp"
                      >
                        <Button
                          style={{
                            fontSize: "100%",
                            fontWeight: "normal",
                            background: "#707070",
                            height: "33px",
                            color: "#fff",
                          }}
                          className="added_delete_resp"
                          onClick={() => {
                            deteladdress(item?._id);
                          }}
                        >
                          Delete
                        </Button>
                        <Button
                          style={{
                            fontSize: "100%",
                            fontWeight: "normal",
                            background: "#707070",
                            height: "33px",
                            color: "#fff",
                            marginLeft: "7px",
                          }}
                          className="added_edit_resp"
                          onClick={() => {
                            updateAddress(item);
                          }}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <Grid md="5" style={{ paddingTop: "50px" }}>
              <Grid
                style={{
                  background: "#011d3e",
                  padding: "16px 0px 32px 32px",
                  borderRadius: "10px",
                  border: "1px solid #707070",
                }}
              >
                <Grid
                  style={{
                    fontWeight: "bold",
                    fontSize: "20px",
                    marginBottom: "22px",
                  }}
                >
                  This is a very important note!
                </Grid>
                <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                  <Grid md="8" style={{ margin: "auto" }}>
                    <Typography
                      style={{
                        fontWeight: "normal",
                        fontSize: "20px",
                        marginBottom: "16px",
                        display: "flex",
                      }}
                    >
                      Writing headlines for blog posts is much science and
                      probably cool audience
                    </Typography>
                  </Grid>
                  <Grid md="4">
                    <Button
                      onClick={() => {
                        setAddNewAddressModel(true);
                      }}
                      style={{
                        fontSize: "100%",
                        fontWeight: "normal",
                        background: "#344A64",
                        color: "#fff",
                      }}
                      className="add_addrress_resp"
                    >
                      Add Address
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid md="12" style={{ marginTop: "53px" }}>
              <Typography style={{ fontSize: "bold", fontSize: "22px" }}>
                Tax Location
              </Typography>
              <Typography style={{ fontSize: "normal", fontSize: "20px" }}>
                United State - 10 % VAT
              </Typography>
              <Typography
                style={{ fontSize: "bold", fontSize: "20px", color: "#008CF2" }}
              >
                more info
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          style={{
            padding: "45px 60px 45px 60px",
            marginBottom: "50px",
            background: "rgba(0,0,0,0.3)",
            border: "1px solid #707070",
            borderRadius: "20px",
            marginTop: "60px",
          }}
        >
          <Typography
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Typography style={{ fontWeight: "bold", fontSize: "22px" }}>
              Billing History
            </Typography>
          </Typography>
          <Box style={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              sx={
                BillingTypeData === "six_month"
                  ? { borderBottom: "2px solid #707070" }
                  : null
              }
              onClick={SetMonthData}
            >
              <Typography
                className="B_H_btnhover"
                style={
                  BillingTypeData === "six_month"
                    ? {
                        color: "#008CF2",
                        fontWeight: "normal",
                        fontSize: "22px",
                        cursor: "pointer",
                      }
                    : {
                        fontWeight: "normal",
                        fontSize: "22px",
                        cursor: "pointer",
                      }
                }
              >
                Month
              </Typography>
            </Box>
            <Box
              onClick={SetYearData}
              style={
                BillingTypeData === "one_year"
                  ? { marginLeft: "56px", borderBottom: "2px solid #707070" }
                  : { marginLeft: "56px" }
              }
            >
              <Typography
                className="B_H_btnhover"
                style={
                  BillingTypeData === "one_year"
                    ? {
                        color: "#008CF2",
                        fontWeight: "normal",
                        fontSize: "22px",
                        cursor: "pointer",
                      }
                    : {
                        fontWeight: "normal",
                        fontSize: "22px",
                        cursor: "pointer",
                      }
                }
              >
                Year
              </Typography>
            </Box>
            <Box
              onClick={SetAllTimeData}
              style={
                BillingTypeData === "all"
                  ? { marginLeft: "56px", borderBottom: "2px solid #707070" }
                  : { marginLeft: "56px" }
              }
            >
              <Typography
                className="B_H_btnhover"
                style={
                  BillingTypeData === "all"
                    ? {
                        color: "#008CF2",
                        fontWeight: "normal",
                        fontSize: "22px",
                        cursor: "pointer",
                      }
                    : {
                        fontWeight: "normal",
                        fontSize: "22px",
                        cursor: "pointer",
                      }
                }
              >
                All Time
              </Typography>
            </Box>
          </Box>

          <TableContainer
            component={Paper}
            style={{ background: "rgba(0,0,0,0)" }}
          >
            <Table aria-label="simple table">
              {BillingHistoryError ? (
                <Box style={{ padding: "60px 0px 20px 0px" }}>
                  <Typography
                    align="center"
                    variant="h5"
                    style={{ color: "white" }}
                  >
                    Data is not found.
                  </Typography>
                </Box>
              ) : (
                <>
                  {BillingTypeData === "six_month" ? (
                    <>
                      <TableHead>
                        <TableRow>
                          {Six_MonthTitle?.map((item, i) => {
                            return (
                              <>
                                <TableCell
                                  align="left"
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    fontSize: "22px",
                                  }}
                                >
                                  {item.charAt(0).toUpperCase() + item.slice(1)}
                                </TableCell>
                              </>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Six_MonthData?.map((row, index) => {
                          return (
                            <TableRow>
                              {Six_MonthTitle?.map((item, i) => {
                                return item != "invoiceUrl" ? (
                                  <TableCell
                                    align="left"
                                    style={{
                                      color: "rgba(255,255,255,0.4)",
                                      border: "none",
                                      fontSize: "18px",
                                      paddingTop: "0px",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    {row[`${item}`]}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    style={{
                                      color: "rgba(255,255,255,0.4)",
                                      border: "none",
                                      fontSize: "18px",
                                      paddingTop: "0px",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    <a href={row?.invoiceUrl} target="_blank">
                                      <Button
                                        style={{
                                          background: "#fff",
                                          fontSize: "16px",
                                          color: "#000",
                                          fontWeight: "normal",
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                        }}
                                      >
                                        PDF
                                      </Button>
                                    </a>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : BillingTypeData === "one_year" ? (
                    <>
                      <TableHead>
                        <TableRow>
                          {One_YearTitle?.map((item, i) => {
                            return (
                              <>
                                <TableCell
                                  align="left"
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    fontSize: "22px",
                                  }}
                                >
                                  {item.charAt(0).toUpperCase() + item.slice(1)}
                                </TableCell>
                              </>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {One_YearData?.map((row, index) => {
                          return (
                            <TableRow>
                              {One_YearTitle?.map((item, i) => {
                                return item != "invoiceUrl" ? (
                                  <TableCell
                                    align="left"
                                    style={{
                                      color: "rgba(255,255,255,0.4)",
                                      border: "none",
                                      fontSize: "18px",
                                      paddingTop: "0px",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    {row[`${item}`]}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    style={{
                                      color: "rgba(255,255,255,0.4)",
                                      border: "none",
                                      fontSize: "18px",
                                      paddingTop: "0px",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    <a href={row?.invoiceUrl} target="_blank">
                                      <Button
                                        style={{
                                          background: "#fff",
                                          fontSize: "16px",
                                          color: "#000",
                                          fontWeight: "normal",
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                        }}
                                      >
                                        PDF
                                      </Button>
                                    </a>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : BillingTypeData === "all" ? (
                    <>
                      <TableHead>
                        <TableRow>
                          {AllTitle?.map((item, i) => {
                            return (
                              <>
                                <TableCell
                                  align="left"
                                  style={{
                                    color: "#fff",
                                    border: "none",
                                    fontSize: "22px",
                                  }}
                                >
                                  {item.charAt(0).toUpperCase() + item.slice(1)}
                                </TableCell>
                              </>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {AllData?.map((row, index) => {
                          return (
                            <TableRow>
                              {AllTitle?.map((item, i) => {
                                return item != "invoiceUrl" ? (
                                  <TableCell
                                    align="left"
                                    style={{
                                      color: "rgba(255,255,255,0.4)",
                                      border: "none",
                                      fontSize: "18px",
                                      paddingTop: "0px",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    {row[`${item}`]}
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    align="left"
                                    style={{
                                      color: "rgba(255,255,255,0.4)",
                                      border: "none",
                                      fontSize: "18px",
                                      paddingTop: "0px",
                                      paddingBottom: "3px",
                                    }}
                                  >
                                    <a href={row?.invoiceUrl} target="_blank">
                                      <Button
                                        style={{
                                          background: "#fff",
                                          fontSize: "16px",
                                          color: "#000",
                                          fontWeight: "normal",
                                          paddingTop: "0px",
                                          paddingBottom: "0px",
                                        }}
                                      >
                                        PDF
                                      </Button>
                                    </a>
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </>
                  ) : null}
                </>
              )}
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};
export default Profile;

{
  /* <TableBody>
                                        {BillingTypeData === "six_month" ?
                                            Six_MonthData?.map((row) => (
                                                <TableRow>
                                                    <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.entryDateTime}</TableCell>
                                                    <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.description}</TableCell>
                                                    <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.transactionAmount}</TableCell>
                                                    <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>
                                                        <a href={row?.invoiceUrl} target="_blank">
                                                            <Button style={{ background: '#fff', fontSize: '16px', color: '#000', fontWeight: 'normal', paddingTop: '0px', paddingBottom: '0px' }}>PDF</Button>
                                                        </a>
                                                    </TableCell>
                                                    <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}><Button style={{ background: '#fff', fontSize: '16px', color: '#000', fontWeight: 'normal', paddingTop: '0px', paddingBottom: '0px' }}>VIEW</Button></TableCell>
                                                </TableRow>
                                            )) :
                                            BillingTypeData === "one_year" ?
                                                One_YearData?.map((row) => (
                                                    <TableRow>
                                                        <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.entryDateTime}</TableCell>
                                                        <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.description}</TableCell>
                                                        <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.transactionAmount}</TableCell>
                                                        <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>
                                                            <a href={row?.invoiceUrl} target="_blank">
                                                                <Button style={{ background: '#fff', fontSize: '16px', color: '#000', fontWeight: 'normal', paddingTop: '0px', paddingBottom: '0px' }}>PDF </Button>
                                                            </a>
                                                        </TableCell>
                                                        <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}><Button style={{ background: '#fff', fontSize: '16px', color: '#000', fontWeight: 'normal', paddingTop: '0px', paddingBottom: '0px' }}>VIEW</Button></TableCell>
                                                    </TableRow>
                                                )) :
                                                BillingTypeData === "all" ?
                                                    AllData?.map((row) => (
                                                        <TableRow>
                                                            <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.entryDateTime}</TableCell>
                                                            <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.description}</TableCell>
                                                            <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>{row?.transactionAmount}</TableCell>
                                                            <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}>
                                                                <a href={row?.invoiceUrl} target="_blank">
                                                                    <Button style={{ background: '#fff', fontSize: '16px', color: '#000', fontWeight: 'normal', paddingTop: '0px', paddingBottom: '0px' }}>PDF</Button>
                                                                </a></TableCell>
                                                            <TableCell align="left" style={{ color: 'rgba(255,255,255,0.4)', border: 'none', fontSize: '18px', paddingTop: '0px', paddingBottom: '3px' }}><Button style={{ background: '#fff', fontSize: '16px', color: '#000', fontWeight: 'normal', paddingTop: '0px', paddingBottom: '0px' }}>VIEW</Button></TableCell>
                                                        </TableRow>
                                                    )) : null
                                        }
                                    </TableBody> */
}
