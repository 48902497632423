import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { Typography } from "@mui/material";
import gear from "../../assets/img/gear.png";
import { useState, useEffect, useContext } from "react";
import circleRight from "../../assets/img/circleRight.png";
import yellowWarning from "../../assets/img/yellowWarning@2x.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import alertred from "../../assets/img/alert_red.png";
import "./style.css";
import { Api } from "../../MyNewUtils/Api";
import SettingModel from "../../screens/CloudInfo/settingModel";
import SubLoader from "../Spinner/subLoader";
import RadioButtonDotIcon from "../../assets/img/Dot.svg";
import myContext from "../../screens/MyContext/MyContext";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  console.log("arraysacava", array);
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    arrayColumns,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ border: "0px" }}>
        {arrayColumns?.map((arrayColumns) =>
          arrayColumns.show ? (
            <TableCell
              key={arrayColumns?.id}
              // align={headCell.numeric ? 'right' : 'left'}
              padding={"none"}
              sortDirection={orderBy === arrayColumns?.id ? order : false}
              style={{ color: "#fff", border: "0px", textAlign: "left" }}
            >
              {arrayColumns?.label}
              <TableSortLabel
                active={true}
                direction={orderBy === arrayColumns?.id ? order : "asc"}
                onClick={createSortHandler(arrayColumns?.id)}
                style={{ color: "#fff" }}
                IconComponent={ArrowDropDownIcon}
              ></TableSortLabel>
            </TableCell>
          ) : null
        )}
      </TableRow>
    </TableHead>
  );
}

const AnalysisThirdBody = () => {
  const [rowLimitEnd, setRowLimitEnd] = useState(5);

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("Groups");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowLimitEnd);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(rowLimitEnd);
  const [pageCount, setPageCount] = useState(1);
  const [arrayColumns, setArrayColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [allRowsData, setAllRowsData] = useState([]);
  const [allColumns, setAllColumns] = useState([]);
  const [modelSetting, setModelSetting] = useState(false);

  const [showerror3, setShowError3] = useState(false);
  const [tabledata, setTableData] = useState();
  const [tableTitle, setTableTitle] = useState([]);
  const [loader, setLoader] = useState(false);

  const { cloudDetails } = useContext(myContext);

  const getData2 = async () => {
    setLoader(true);
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
      region: JSON.stringify(cloudDetails?.selectedCloudRegionsID),
    };
    const { data } = await Api.postRequest(
      "/users/get-all-iam-users_table",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      if (res.data?.IamUserTable?.data[0]?.value.length != 0) {
        setTableData(res.data?.IamUserTable?.data[0]?.value);
        setTableTitle(Object.keys(res?.data?.IamUserTable?.data[0]?.value[0]));
        setShowError3(false);
      } else {
        setShowError3(true);
      }
      setLoader(false);
    } else {
      setShowError3(true);
      setLoader(false);
    }
  };

  useEffect(() => {
    if (
      cloudDetails?.selectedCloudAccountID !== undefined &&
      cloudDetails?.selectedCloudAccountID.length !== 0
    )
      getData2();
  }, [
    cloudDetails?.selectedCloudServers,
    cloudDetails?.selectedCloudAccountID,
  ]);

  useEffect(() => {
    let array = [];
    tableTitle?.map((item, i) => {
      console.log("item", item);
      array[i] = { ...array[i], label: item, id: item, show: true };
    });
    setArrayColumns(array);
    setRows(tabledata);
    setAllRowsData(tabledata);
    console.log("dwegerhrtrntynbrt");
  }, [tableTitle, tabledata]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows?.map((n) => n?.UserName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, UserName) => {
    const selectedIndex = selected.indexOf(UserName);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, UserName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (UserName) => selected.indexOf(UserName) !== -1;

  const Previous = () => {
    if (paginationStart > 0) {
      setPaginationStart(paginationStart - rowsPerPage);
      setPaginationEnd(paginationEnd - rowsPerPage);
      setPageCount(pageCount - 1);
    }
  };
  const Next = () => {
    if (paginationEnd < rows.length) {
      setPaginationStart(paginationStart + rowsPerPage);
      setPaginationEnd(paginationEnd + rowsPerPage);
      setPageCount(pageCount + 1);
    }
  };
  useEffect(() => {
    setPaginationStart(0);
    setPaginationEnd(rowsPerPage);
    setPageCount(1);
  }, [rows]);

  useEffect(() => {
    setRowsPerPage(rowLimitEnd);
    setPaginationEnd(rowLimitEnd);
  }, [rowLimitEnd]);

  const requestSearch = (searchedVal) => {
    console.log("searchedVal", searchedVal);
    console.log("rowaRfsdgs", tabledata);
    if (searchedVal?.length != 0) {
      const filteredRows = tabledata.filter((row) => {
        for (let key in row) {
          if (key === "Users") {
            console.log("rowaRfsdgs1231213", row[key]);
            return row[key]?.toLowerCase().includes(searchedVal?.toLowerCase());
          }
        }
      });
      console.log("filteredRows", tabledata);
      setRows(filteredRows);
    } else {
      setRows(allRowsData);
    }
  };
  const getopenSetting = () => {
    setModelSetting(true);
  };

  return (
    <>
      {modelSetting ? (
        <SettingModel
          rows={rows}
          tableTitle={tableTitle}
          setModelSetting={setModelSetting}
          modelSetting={modelSetting}
          arrayColumns={arrayColumns}
          setRowLimitEnd={setRowLimitEnd}
          setArrayColumns={setArrayColumns}
          allColumns={allColumns}
        />
      ) : null}
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          margin: "50px 0px 20px 0px",
        }}
      >
        <Box>
          <Typography
            style={{ fontSize: "24px", color: "white", fontWeight: "700" }}
          >
            IAM USERS
          </Typography>
        </Box>
      </Box>
      <Box
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
          borderRadius: "30px",
          display: "flex",
          padding: "30px",
          flexDirection: "column",
          padding: "4% 6% 2% 6%",
          position: "relative",
        }}
      >
        <SubLoader isLoading={loader} />
        <Box style={{ display: "flex", width: "100%" }}>
          <Box style={{ width: "30%" }}>
            <Typography
              style={{ color: "#FF9900", fontSize: "24px", fontWeight: "600" }}
            >
              Users
              {rows?.length != undefined ? "(" + rows?.length + ")" : null}
            </Typography>
          </Box>
          <Box style={{ display: "flex", alignItems: "center", width: "70%" }}>
            <Box
              style={{
                display: "flex",
                backgroundColor: "#272E42",
                borderRadius: "10px",
                padding: "5px 30px",
                flex: 1,
              }}
            >
              <SearchIcon style={{ color: "white", margin: "5px" }} />
              <input
                placeholder={"Search user"}
                onChange={(e) => requestSearch(e.target.value)}
                style={{
                  border: "none",
                  outline: "none",
                  padding: "5px",
                  width: "100%",
                  backgroundColor: "transparent",
                  color: "white",
                }}
              />
            </Box>
            <Box style={{ marginLeft: "60px" }}>
              <img
                src={gear}
                width={"35px"}
                height={"35px"}
                style={{ cursor: "pointer" }}
                onClick={showerror3 ? null : () => getopenSetting()}
              />
            </Box>
          </Box>
        </Box>

        <Box style={{ display: "flex", width: "100%", marginTop: "30px" }}>
          <Paper sx={{ width: "100%", mb: 2, background: "rgb(0,0,0,0)" }}>
            {showerror3 ? (
              <Typography
                variant="h5"
                sx={{
                  margin: "50px",
                }}
                className="no_data_text_box"
              >
                No Data Available
              </Typography>
            ) : (
              <TableContainer>
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected?.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={rows?.length}
                    arrayColumns={arrayColumns}
                  />

                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      ?.slice(paginationStart, paginationEnd)
                      ?.map((row, index) => {
                        const isItemSelected = isSelected(row?.Name);
                        console.log("isItemSelected", isItemSelected);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            hover
                            onClick={(event) => handleClick(event, row?.Name)}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row?.Name}
                            selected={isItemSelected}
                          >
                            {console.log(
                              "arrayColumnsarrayColumns",
                              arrayColumns
                            )}
                            {arrayColumns?.map((item, i) => {
                              return (
                                <>
                                  {item?.show ? (
                                    <TableCell
                                      sx={{
                                        color: "rgb(255,255,255,0.4)",
                                        textAlign: "left",
                                        border: "0px solid #0000",
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        {console.log(
                                          'row[item?.label].split(" ")[0]',
                                          row[item?.label].split(" ")[0]
                                        )}
                                        <img
                                          src={
                                            row[item?.label].split(" ")[0] <
                                              100 && item.id === "ActiveKeyAge"
                                              ? circleRight
                                              : row[item?.label].split(" ")[0] <
                                                  200 &&
                                                item.id === "ActiveKeyAge"
                                              ? yellowWarning
                                              : alertred
                                          }
                                          style={
                                            item.id === "ActiveKeyAge"
                                              ? {
                                                  paddingRight: "5px",
                                                }
                                              : { display: "none" }
                                          }
                                          alt=""
                                        />
                                        <Box
                                          sx={
                                            row[item?.label].split(" ")[0] <
                                              100 && item.id === "ActiveKeyAge"
                                              ? {
                                                  color: "green",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }
                                              : row[item?.label].split(" ")[0] <
                                                  200 &&
                                                item.id === "ActiveKeyAge"
                                              ? {
                                                  color: "#E86C01",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }
                                              : item.id === "ActiveKeyAge"
                                              ? {
                                                  color: "red",
                                                  display: "flex",
                                                  alignItems: "center",
                                                }
                                              : {
                                                  display: "flex",
                                                  alignItems: "center",
                                                }
                                          }
                                        >
                                          {item?.id === "Users" ? (
                                            <img
                                              src={RadioButtonDotIcon}
                                              style={{ marginRight: "10px" }}
                                              alt=""
                                            />
                                          ) : null}
                                          {item?.label === "Groups" &&
                                          row[item?.label] === ""
                                            ? "None"
                                            : row[item?.label]}
                                        </Box>
                                      </Box>
                                    </TableCell>
                                  ) : null}
                                </>
                              );
                            })}
                          </TableRow>
                        );

                        // return (
                        // <TableRow
                        //   hover
                        //   onClick={(event) => handleClick(event, row.Name)}
                        //   role="checkbox"
                        //   aria-checked={isItemSelected}
                        //   tabIndex={-1}
                        //   key={row.Name}
                        //   selected={isItemSelected}
                        // >
                        //     <TableCell sx={{ color: 'rgb(255,255,255,0.4)', textAlign: 'left', border: '0px solid #0000' }}>{row.UserName}</TableCell>
                        //     <TableCell sx={{ color: '#fff', textAlign: 'left', border: '0px solid #0000' }}>{row.Groups}</TableCell>
                        //     <TableCell sx={row.LastActivitytrue === 'true' ? { color: '#CE6007', display: 'flex', alignItems: 'center', textAlign: 'left', border: '0px solid #0000' } : { color: '#F60001', display: 'flex', alignItems: 'center', textAlign: 'left', border: '0px solid #0000' }}>
                        //       <img src={row.LastActivitytrue === 'true' ? circleRight : yellowWarning} style={{ paddingRight: '5px' }} />
                        //       {row.LastActivity}
                        //     </TableCell>
                        //     <TableCell sx={{ color: 'rgb(255,255,255,0.4)', textAlign: 'left', border: '0px solid #0000' }}>
                        //       <img src={row.proteintrue === 'true' ? circleRight : yellowWarning} style={{ paddingRight: '5px' }} />
                        //       {row.protein}
                        //     </TableCell>
                        //   </TableRow>
                        // );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <Box
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                borderTop: "1px solid #666C7C",
                margin: "10px 20px 0px 20px",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Box
                  onClick={() => Previous()}
                  style={
                    pageCount != 1
                      ? {
                          marginRight: "30px",
                          color: "#666C7C",
                          cursor: "pointer",
                        }
                      : { display: "none" }
                  }
                >
                  Previous
                </Box>
                <Box
                  style={{
                    marginRight: "30px",
                    color: "#fff",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    background: "#666C7C",
                    textAlign: "center",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {pageCount}
                </Box>
                <Box
                  onClick={() => Next()}
                  style={
                    paginationEnd < rows?.length
                      ? {
                          marginRight: "30px",
                          color: "#666C7C",
                          cursor: "pointer",
                        }
                      : { display: "none" }
                  }
                >
                  Next
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default AnalysisThirdBody;
