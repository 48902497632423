import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Api } from "../../../../MyNewUtils/Api";
import SupportCaseTableSettingModel from "./model/ supportCaseTableSettingModel";
import TableWithPagination from "../table/TableWithPagination";
import DashboardHeader from "../../../../Component/DashboardHeader/DashboardHeader";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/system/Unstable_Grid/Grid";
import fillterIcon from "../../../../assets/img/fillter2x.png";
import settingIcon from "../../../../assets/img/gear@2x.png";
const ViewAllCases = () => {
  const [settingModel, setSettingModel] = useState(false);
  const [rowLimitEnd, setRowLimitEnd] = useState(10);
  const [searchCase, setSearchCase] = useState();
  const [tableTitle, setTableTitle] = useState([
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "Create",
      show: true,
      date: true,
    },
    {
      id: "subject",
      numeric: true,
      disablePadding: false,
      label: "Subject",
      show: true,
      date: false,
    },
    {
      id: "severity",
      numeric: true,
      disablePadding: false,
      label: "Severity",
      show: true,
      date: false,
    },
    {
      id: "caseid",
      numeric: true,
      disablePadding: false,
      label: "Case ID",
      show: true,
      date: false,
    },
    {
      id: "issuetype",
      numeric: true,
      disablePadding: false,
      label: "Case Type",
      show: true,
      date: false,
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      show: true,
      date: false,
    },
  ]);
  const [userSupportTicketsDetails, setUserSupportTicketsDetails] = useState(
    []
  );
  const [userSupportTicketsSearchDetails, setUserSupportTicketsSearchDetails] =
    useState([]);

  const cls = useMemo(() => "SectionPageOne", []);
  let navigate = useNavigate();

  useEffect(() => {
    getUserSupportTicketsDetails();
  }, []);
  useEffect(() => {
    let fillterData = userSupportTicketsDetails?.filter((name) =>
      name?.subject?.includes(searchCase)
    );
    setUserSupportTicketsSearchDetails(fillterData);
  }, [searchCase]);
  const getUserSupportTicketsDetails = async () => {
    let payload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    };
    const data = await Api.postRequest("/users/support-tickets", payload);
    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      setUserSupportTicketsDetails(res?.data);
      setUserSupportTicketsSearchDetails(res?.data);
    }
  };
  return (
    <Box className={`${cls}__background`} style={{}}>
      <DashboardHeader
        activeTab={"SETTINGS"}
        activeSettingSubTab={"Supports"}
      />
      {settingModel && (
        <SupportCaseTableSettingModel
          settingModel={settingModel}
          setSettingModel={setSettingModel}
          rowLimitEnd={rowLimitEnd}
          setRowLimitEnd={setRowLimitEnd}
          tableTitle={tableTitle}
          setTableTitle={setTableTitle}
        />
      )}
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box className="SupportCenter_Box ">
          <Box className="d-flex">
            <Typography
              className="SupportCenter_Cases_heading_text text-bold cursor-pointer"
              onClick={() => {
                navigate("/supports");
              }}
            >
              CLEVRONE Support Center
            </Typography>
            <Typography className="SupportCenter_Cases_heading_text margin-left-20px">
              <ArrowForwardIosIcon className="SupportCenter_Cases_heading_text" />
            </Typography>
            <Typography className="SupportCenter_Cases_heading_text margin-left-20px">
              Your support cases
            </Typography>
          </Box>
          <Box className="TableWithPagination_box_margin_right_left ">
            <Box className="d-flex justify-between margin-top-85 create_case_data">
              <Box className="d-flex items-center">
                <Typography className="SupportCenter_Case_History_heading_text">
                  Case History
                </Typography>
                <Typography className="SupportCenter_Info_heading_text margin-left-20px">
                  Info
                </Typography>
              </Box>
              <Box
                className="SupportCenter_btn_background_color SupportCenter_btn d-flex items-center margin-left-20px cursor_pointer"
                onClick={() => {
                  navigate("/createCase");
                }}
              >
                Create case
              </Box>
            </Box>

            <Box className="d-flex justify-between items-center margin-top-10 create_case_search" >
              <Box className="width_60_percentage" >
                <Box className="SupportCenter_Search_Box" >
                  <SearchIcon style={{ color: "white", margin: "5px" }} />
                  <input
                    placeholder={"Search cases by subject"}
                    onChange={(e) => setSearchCase(e.target.value)}
                    className="SupportCenter_Case_History_Search_Input_Box"
                  />
                </Box>
              </Box>
              <Box>
                {/* <img
                  src={fillterIcon}
                  alt=""
                  width="25px"
                  className="SupportCenter_fillterIco"
                /> */}
                <img
                  src={settingIcon}
                  alt=""
                  width="25px"
                  style={{ cursor: "pointer" }}
                  onClick={() => setSettingModel(true)}
                />
              </Box>
            </Box>
            <TableWithPagination
              headCells={tableTitle}
              rows={userSupportTicketsSearchDetails}
              rowLimitEnd={rowLimitEnd}
              setRowLimitEnd={setRowLimitEnd}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewAllCases;
