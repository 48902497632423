import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import DashboardDetails from "../dashboard/EC2Status/DashboardDetails";
import React from "react";

const Dashboard = () => {
  return (
    <>
      <DashboardHeader activeTab={"DASHBOARD"} accountSelectHeaderShow={true} />

      {/* ------------ Dashboard Functionality -------------- */}
      <DashboardDetails />
    </>
  );
};
export default Dashboard;
