import axios from "axios";

const base_api_url = 'http://44.196.70.103:8080'


const allInstanceUrl = [
    {
      base_url: base_api_url,
      instanceName: 'api',
    },
  ]
  export let axiosInstances = {}

  allInstanceUrl.map(item => {
    /*
     * create axios instance
     */
    let instance = axios.create({
      baseURL: `${item.base_url}`,
      headers: {
        'Authorization':'a eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2MTY2Y2Y2ZTk5MGUyMjhiNDBlYjcxYjgiLCJuYW1lIjoiamsiLCJlbWFpbCI6InNhbWlyLmphbmkxMjMzMDAxQGdtYWlsLmNvbSIsInBob25lIjoiNzQwNTMwMjYwMCIsInBhc3N3b3JkIjoiMTIzIiwiY29tcGFueSI6ImphbmFrIiwibGFzdExvZ2luVGltZSI6IjIwMjEtMTAtMjdUMTI6Mjc6NDIuMDAwWiIsInZlcmlmaWNhdGlvbkNvZGUiOiJleUpoYkdjaU9pSklVekkxTmlJc0luUjVjQ0k2SWtwWFZDSjkuZXlKbGJXRnBiQ0k2SW5OaGJXbHlMbXBoYm1reE1qTXpNREF4UUdkdFlXbHNMbU52YlNJc0ltbGhkQ0k2TVRZek5ERXlOemN5Tm4wLnBXbjA1ZmpBT2ZXYm1YS3g4LUtMaTRlVzhBLWVpRjlhaFdrNmpMa1VrRmciLCJpZCI6IjlvMlNHc1lRIiwic3RyaXBlQ3VzdG9tZXJJRCI6ImN1c19LT3VISGd4bjZvNHZPNiIsImlzZW1haWx2ZXJpZmllZCI6dHJ1ZSwiaXNtb2JpbGV2ZXJpZmllZCI6dHJ1ZSwicHJvZmlsZXBpYyI6Imh0dHA6Ly9sb2NhbGhvc3Q6ODA4MC9pbWFnZXMvZGVmYXVsdC5wbmciLCJ0d29mYWN0b3JlbmFibGVkIjpmYWxzZSwiY3JlYXRlZEF0IjoiMjAyMS0xMC0xM1QxMjoyMjowNi44NThaIiwidXBkYXRlZEF0IjoiMjAyMS0xMC0yN1QxMjoyNzo0Mi4zOTlaIiwiX192IjowLCJzdWJzY3JpcHRpb25FbmREYXRlIjoiMjAyMC0xMC0yNlQwMDowMDowMC4wMDBaIiwiaWF0IjoxNjM1MzM3Nzg3LCJleHAiOjE2MzUzMzgwODd9.PKEfa73Tq2B979xv_DE9QsURAT1S2pBC9aQ9qAK8JZA',
        'Content-Type': 'application/json'
      },
    })
    /*
     * handle request interceptor
     */
    instance.interceptors.request.use(
      config => handleConfig(config),
      error => Promise.reject(error)
    )
    /*
     * handle response interceptor
     */
    instance.interceptors.response.use(
      function(response) {
        return response
      },
      function(error) {
        return Promise.reject(error)
      }
    )
    /*
     * assign correspondance instance
     */
    axiosInstances[item.instanceName] = instance
  })
  /*
   * logput on getting status 401 from any api
   */
  const logout = () => {
    //removeAllFromLocalStorage();
    window.location.replace('/')
  }
  /**
   * handle config for get/post
   */
  const handleConfig = config => {
    // let token = getCurrentUser().userSession.idToken.jwtToken
    // if (token) {
    //   config.headers['Authorization'] = token
    // }
    // return config
  }