/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { Api } from "../../MyNewUtils/Api";
import ic_calendar from "../../assets/img/ic_calendar.png";
import MainLoader from "../Spinner/MainLoader";
import { useState, useEffect, useRef, useContext } from "react";
import Chart from "react-apexcharts";
import "./CloudInfoBodyTwo.css";
import "./styles.css";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { toast } from "react-toastify";
import SubLoader from "../Spinner/subLoader";
import { addDays, subDays } from "date-fns";
// import Grid from "@mui/system/Unstable_Grid/Grid";
import myContext from "../../screens/MyContext/MyContext";
const CloudInfoTwo = ({ allData }) => {
  const [subLoaderStartInstances, setSubLoaderStartInstances] = useState(false);
  const [subLoaderStopInstances, setSubLoaderStopInstances] = useState(false);
  const [subLoaderEC2StopInstances, setSubLoaderEC2StopInstances] =
    useState(false);
  const [subLoaderEC2StartInstances, setSubLoaderEC2StartInstances] =
    useState(false);
  const [startInstancesSeries, setStartInstancesSeries] = useState([]);
  const [startInstancesCategories, setStartInstancesCategories] = useState([]);
  const [startInstancesCount, setStartInstancesCount] = useState(0);
  const [stopInstancesSeries, setStopInstancesSeries] = useState([]);
  const [ec2StopInstancesSeries, setEc2StopInstancesSeries] = useState([]);
  const [ec2StartInstancesSeries, setEc2StartInstancesSeries] = useState([]);
  const [stopInstancesCategories, setStopInstancesCategories] = useState([]);
  const [ec2stopInstancesCategories, setEc2stopInstancesCategories] = useState(
    []
  );
  const [ec2StartInstancesCategories, setEc2StartInstancesCategories] =
    useState([]);

  const [stopInstancesCount, setStopInstancesCount] = useState(0);
  const [ec2stopInstancesCount, setEc2stopInstancesCount] = useState(0);
  const [ec2startInstancesCount, setEc2startInstancesCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [rdsStatistics, setRdsStatistics] = useState([
    subDays(new Date(), 6),
    addDays(new Date(), 0),
  ]);
  const [stopInstancesTime, setStopInstancesTime] = useState([
    subDays(new Date(), 6),
    addDays(new Date(), 0),
  ]);
  const [ec2StartStatistics, setEc2StartStatistics] = useState([
    subDays(new Date(), 6),
    addDays(new Date(), 0),
  ]);
  const [ec2EndStatistics, setEc2EndStatistics] = useState([
    subDays(new Date(), 6),
    addDays(new Date(), 0),
  ]);
  const { cloudDetails } = useContext(myContext);
  const defaultEndDate = new Date();
  defaultEndDate.setDate(defaultEndDate.getDate() + 7);

  const StartDateopencalendar = useRef(null);
  const EndDateopencalendar = useRef(null);
  const EC2StartDateopencalendar = useRef(null);
  const EC2EndDateopencalendar = useRef(null);

  // --------------RDS Change Start Date ----------------
  const onChangeStartDate = (date) => {
    setRdsStatistics(date);
  };

  // --------------EC2 Change Start Date ----------------
  const onChangeEC2StartDate = (date) => {
    setEc2StartStatistics(date);
  };

  // --------------RDS Change End Date ----------------
  const onChangeEndDate = (date) => {
    setStopInstancesTime(date);
  };

  // --------------EC2 Change End Date ----------------
  const onChangeEC2EndDate = (date) => {
    setEc2EndStatistics(date);
  };

  const openstartdatecalendar = () => {
    StartDateopencalendar.current.openCalendar(true);
  };

  const openenddatecalendar = () => {
    EndDateopencalendar.current.openCalendar(true);
  };

  const EC2Startopenenddatecalendar = () => {
    EC2StartDateopencalendar.current.openCalendar(true);
  };

  const EC2Endopenenddatecalendar = () => {
    EC2EndDateopencalendar.current.openCalendar(true);
  };

  // ----------------------EC2 Start Chart Data ---------------
  const chartData3 = {
    series: [
      {
        name: "",
        // data: [80, 10, 50, 60, 1, 0, 80],
        data: startInstancesSeries,
      },
    ],
    options: {
      chart: {
        height: 500,
        type: "area",
        id: "support-chart",
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: "",
        align: "left",
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: "#E07E51",
      },
      stroke: {
        colors: "#E07E51",
      },
      xaxis: {
        categories: startInstancesCategories,
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: "left",
      },
    },
  };

  // ----------------------EC2 Stop Chart Data ---------------
  const stopInstancesChart = {
    series: [
      {
        name: "",
        // data: [14, 20, 30, 5, 10, 60, 60],
        data: stopInstancesSeries,
      },
    ],
    options: {
      chart: {
        height: 500,
        type: "area",
        id: "support-chart",
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: "",
        align: "left",
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: "#F4B406",
      },
      stroke: {
        colors: "#F4B406",
      },
      xaxis: {
        categories: stopInstancesCategories,
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: "left",
      },
    },
  };

  // ----------------------EC2 Stop Chart Data ---------------

  const EC2StopInstancesChart = {
    series: [
      {
        name: "",
        // data: [10, 20, 30, 40, 50, 60, 70],
        data: ec2StopInstancesSeries,
      },
    ],
    options: {
      chart: {
        height: 500,
        type: "area",
        id: "support-chart",
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: "",
        align: "left",
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: "#1FD286",
      },
      stroke: {
        colors: "#1FD286",
      },
      xaxis: {
        categories: ec2stopInstancesCategories,
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: "left",
      },
    },
  };

  // ----------------------EC2 Start Chart Data ---------------
  const EC2StartInstancesChart = {
    series: [
      {
        name: "",
        // data: [5, 15, 20, 25, 30, 5, 80],
        data: ec2StartInstancesSeries,
      },
    ],
    options: {
      chart: {
        height: 500,
        type: "area",
        id: "support-chart",
        sparkline: {
          enabled: true,
        },
        zoom: {
          enabled: true,
        },
      },
      title: {
        text: "",
        align: "left",
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        colors: "#8702F9",
      },
      stroke: {
        colors: "#8702F9",
      },
      xaxis: {
        categories: ec2StartInstancesCategories,
      },
      yaxis: {
        opposite: true,
      },
      legend: {
        horizontalAlign: "left",
      },
    },
  };

  const startInstances = async () => {
    setSubLoaderStartInstances(true);
    let final = {
      from_date: rdsStatistics[0].toISOString(),
      to_date: rdsStatistics[1].toISOString(),
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      accountId: cloudDetails?.selectedCloudAccountID,
      region: JSON.stringify(cloudDetails?.selectedCloudRegionsID),
      account_type: cloudDetails?.selectedCloudServers,
    };
    const { statusCode, data } = await Api.postRequest(
      "/accounts/get-rds-instance-statistics",
      final
    );
    const res = JSON.parse(data);
    if (res?.status == 1) {
      let startInstances = [];
      res?.data["startRdsInstances"]?.data?.map((item, index) => {
        startInstances[index] = item;
        let time = item?.eventtime?.split("T")?.[0];
        startInstances[index] = { ...startInstances[index], time };
      });

      const StartInstancesGroupByDate = startInstances?.reduce(
        (catsSoFar, { time }, index) => {
          if (!catsSoFar[time]) catsSoFar[time] = [];
          catsSoFar[time].push(startInstances[index]);
          return catsSoFar;
        },
        {}
      );

      let StartInstancesGroupByDateSplit = [];
      for (let key in StartInstancesGroupByDate) {
        StartInstancesGroupByDateSplit[StartInstancesGroupByDateSplit.length] =
          StartInstancesGroupByDate[key];
      }
      console.log("totalDay-totalDay-totalDay-rdsStatistics", rdsStatistics);
      let startDate = new Date(rdsStatistics[0]);
      let EndDate = new Date(rdsStatistics[1]);
      const totalDay = Math.floor(
        (Date.UTC(
          EndDate.getFullYear(),
          EndDate.getMonth(),
          EndDate.getDate()
        ) -
          Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          )) /
        (1000 * 60 * 60 * 24) +
        1
      );
      console.log("totalDay-totalDay-totalDay", totalDay);
      let startingDate = new Date(rdsStatistics[0]);
      if (startingDate.getHours() != 0) {
        startingDate.setDate(startingDate.getDate() - 1);
      } else {
        startingDate.setDate(startingDate.getDate());
      }

      let graphData = new Array(totalDay).fill(0).map((item, index) => {
        let dataDate = startingDate;
        dataDate.setDate(dataDate.getDate() + 1);
        let y = startingDate.toISOString().split("T")[0];
        for (let key in StartInstancesGroupByDate) {
          if (key == y) {
            return StartInstancesGroupByDate[key]?.length;
          }
        }
        return 0;
      });

      let categoriesStartingDate = new Date(rdsStatistics[0]);
      if (categoriesStartingDate.getHours() != 0) {
        categoriesStartingDate.setDate(categoriesStartingDate.getDate() - 1);
      } else {
        categoriesStartingDate.setDate(categoriesStartingDate.getDate());
      }

      let categories = new Array(totalDay).fill(0).map((item, index) => {
        let dataDate = categoriesStartingDate;
        dataDate.setDate(dataDate.getDate() + 1);
        let y = categoriesStartingDate.toISOString().split("T")[0];
        return y;
      });
      let totalCount = 0;
      graphData?.map((item, index) => {
        totalCount = totalCount + item;
      });
      setStartInstancesCount(totalCount);
      setStartInstancesCategories(categories);
      setStartInstancesSeries(graphData);
      setSubLoaderStartInstances(false);
    } else {
      setSubLoaderStartInstances(false);
      toast.error(res?.message, { autoClose: 1000 });
    }
  };

  const stopInstances = async () => {
    setSubLoaderStopInstances(true);
    let final = {
      from_date: stopInstancesTime[0].toISOString(),
      to_date: stopInstancesTime[1].toISOString(),
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      accountId: cloudDetails?.selectedCloudAccountID,
      region: JSON.stringify(cloudDetails?.selectedCloudRegionsID),
      account_type: cloudDetails?.selectedCloudServers,
    };
    const { statusCode, data } = await Api.postRequest(
      "/accounts/get-rds-instance-statistics",
      final
    );
    const res = JSON.parse(data);

    if (res?.status == 1) {
      let stopInstances = [];
      res?.data["stopRdsInstances"]?.data?.map((item, index) => {
        stopInstances[index] = item;
        let time = item?.eventtime?.split("T")?.[0];
        stopInstances[index] = { ...stopInstances[index], time };
      });

      const StopInstancesGroupByDate = stopInstances?.reduce(
        (catsSoFar, { time }, index) => {
          if (!catsSoFar[time]) catsSoFar[time] = [];
          catsSoFar[time].push(stopInstances[index]);
          return catsSoFar;
        },
        {}
      );
      let StopInstancesGroupByDateSplit = [];
      for (let key in StopInstancesGroupByDate) {
        StopInstancesGroupByDateSplit[StopInstancesGroupByDateSplit.length] =
          StopInstancesGroupByDate[key];
      }

      let startDate = new Date(stopInstancesTime[0]);
      let EndDate = new Date(stopInstancesTime[1]);
      const totalDay = Math.floor(
        (Date.UTC(
          EndDate.getFullYear(),
          EndDate.getMonth(),
          EndDate.getDate()
        ) -
          Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          )) /
        (1000 * 60 * 60 * 24) +
        1
      );

      let startingDate = new Date(stopInstancesTime[0]);

      if (startingDate.getHours() != 0) {
        startingDate.setDate(startingDate.getDate() - 1);
      } else {
        startingDate.setDate(startingDate.getDate());
      }
      let graphData = new Array(totalDay).fill(0).map((item, index) => {
        let dataDate = startingDate;
        dataDate.setDate(dataDate.getDate() + 1);
        let y = startingDate.toISOString().split("T")[0];
        for (let key in StopInstancesGroupByDate) {
          if (key == y) {
            return StopInstancesGroupByDate[key]?.length;
          }
        }
        return 0;
      });

      let categoriesStartingDate = new Date(stopInstancesTime[0]);

      if (categoriesStartingDate.getHours() != 0) {
        categoriesStartingDate.setDate(categoriesStartingDate.getDate() - 1);
      } else {
        categoriesStartingDate.setDate(categoriesStartingDate.getDate());
      }
      let categories = new Array(totalDay).fill(0).map((item, index) => {
        let dataDate = categoriesStartingDate;
        dataDate.setDate(dataDate.getDate() + 1);
        let y = categoriesStartingDate.toISOString().split("T")[0];
        return y;
      });
      let totalCount = 0;
      graphData?.map((item, index) => {
        totalCount = totalCount + item;
      });
      setStopInstancesCount(totalCount);
      setStopInstancesSeries(graphData);
      setStopInstancesCategories(categories);
      setSubLoaderStopInstances(false);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setSubLoaderStopInstances(false);
    }
  };

  const ec2startInstances = async () => {
    setSubLoaderEC2StartInstances(true);
    let final = {
      from_date: ec2StartStatistics[0].toISOString(),
      to_date: ec2StartStatistics[1].toISOString(),
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      accountId: cloudDetails?.selectedCloudAccountID,
      region: JSON.stringify(cloudDetails?.selectedCloudRegionsID),
      account_type: cloudDetails?.selectedCloudServers,
    };
    const { statusCode, data } = await Api.postRequest(
      "/accounts/get-ec2-statistics",
      final
    );
    const res = JSON.parse(data);
    console.log("res-res-res-res", res);
    if (res?.status == 1) {
      let startInstances = [];
      res?.data["startInstances"]?.data?.map((item, index) => {
        startInstances[index] = item;
        let time = item?.eventtime.split("T")[0];
        startInstances[index] = { ...startInstances[index], time };
      });

      const StopInstancesGroupByDate = startInstances?.reduce(
        (catsSoFar, { time }, index) => {
          if (!catsSoFar[time]) catsSoFar[time] = [];
          catsSoFar[time].push(startInstances[index]);
          return catsSoFar;
        },
        {}
      );
      let StopInstancesGroupByDateSplit = [];
      for (let key in StopInstancesGroupByDate) {
        StopInstancesGroupByDateSplit[StopInstancesGroupByDateSplit.length] =
          StopInstancesGroupByDate[key];
      }

      let startDate = new Date(ec2StartStatistics[0]);
      let EndDate = new Date(ec2StartStatistics[1]);
      const totalDay = Math.floor(
        (Date.UTC(
          EndDate.getFullYear(),
          EndDate.getMonth(),
          EndDate.getDate()
        ) -
          Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          )) /
        (1000 * 60 * 60 * 24) +
        1
      );

      let startingDate = new Date(ec2StartStatistics[0]);

      if (startingDate.getHours() != 0) {
        startingDate.setDate(startingDate.getDate() - 1);
      } else {
        startingDate.setDate(startingDate.getDate());
      }
      let graphData = new Array(totalDay).fill(0).map((item, index) => {
        let dataDate = startingDate;
        dataDate.setDate(dataDate.getDate() + 1);
        let y = startingDate.toISOString().split("T")[0];
        for (let key in StopInstancesGroupByDate) {
          if (key == y) {
            return StopInstancesGroupByDate[key]?.length;
          }
        }
        return 0;
      });

      let categoriesStartingDate = new Date(ec2StartStatistics[0]);

      if (categoriesStartingDate.getHours() != 0) {
        categoriesStartingDate.setDate(categoriesStartingDate.getDate() - 1);
      } else {
        categoriesStartingDate.setDate(categoriesStartingDate.getDate());
      }
      let categories = new Array(totalDay).fill(0).map((item, index) => {
        let dataDate = categoriesStartingDate;
        dataDate.setDate(dataDate.getDate() + 1);
        let y = categoriesStartingDate.toISOString().split("T")[0];
        return y;
      });
      let totalCount = 0;
      graphData?.map((item, index) => {
        totalCount = totalCount + item;
      });
      setEc2startInstancesCount(totalCount);
      setEc2StartInstancesSeries(graphData);
      setEc2StartInstancesCategories(categories);
      setSubLoaderEC2StartInstances(false);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setSubLoaderEC2StartInstances(false);
    }
  };

  const ec2stopInstances = async () => {
    setSubLoaderEC2StopInstances(true);
    let final = {
      from_date: ec2EndStatistics[0].toISOString(),
      to_date: ec2EndStatistics[1].toISOString(),
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      accountId: cloudDetails?.selectedCloudAccountID,
      region: JSON.stringify(cloudDetails?.selectedCloudRegionsID),
      account_type: cloudDetails?.selectedCloudServers,
    };
    const { statusCode, data } = await Api.postRequest(
      "/accounts/get-ec2-statistics",
      final
    );
    const res = JSON.parse(data);
    console.log("res-res-res-res", res);
    if (res?.status == 1) {
      let stopInstances = [];
      res?.data["stopInstances"]?.data?.map((item, index) => {
        stopInstances[index] = item;
        let time = item?.eventtime?.split("T")?.[0];
        stopInstances[index] = { ...stopInstances[index], time };
      });

      const StopInstancesGroupByDate = stopInstances?.reduce(
        (catsSoFar, { time }, index) => {
          if (!catsSoFar[time]) catsSoFar[time] = [];
          catsSoFar[time].push(stopInstances[index]);
          return catsSoFar;
        },
        {}
      );
      let StopInstancesGroupByDateSplit = [];
      for (let key in StopInstancesGroupByDate) {
        StopInstancesGroupByDateSplit[StopInstancesGroupByDateSplit.length] =
          StopInstancesGroupByDate[key];
      }

      let startDate = new Date(ec2EndStatistics[0]);
      let EndDate = new Date(ec2EndStatistics[1]);
      const totalDay = Math.floor(
        (Date.UTC(
          EndDate.getFullYear(),
          EndDate.getMonth(),
          EndDate.getDate()
        ) -
          Date.UTC(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
          )) /
        (1000 * 60 * 60 * 24) +
        1
      );

      let startingDate = new Date(ec2EndStatistics[0]);

      if (startingDate.getHours() != 0) {
        startingDate.setDate(startingDate.getDate() - 1);
      } else {
        startingDate.setDate(startingDate.getDate());
      }
      let graphData = new Array(totalDay).fill(0).map((item, index) => {
        let dataDate = startingDate;
        dataDate.setDate(dataDate.getDate() + 1);
        let y = startingDate.toISOString().split("T")[0];
        for (let key in StopInstancesGroupByDate) {
          if (key == y) {
            return StopInstancesGroupByDate[key]?.length;
          }
        }
        return 0;
      });

      let categoriesStartingDate = new Date(ec2EndStatistics[0]);

      if (categoriesStartingDate.getHours() != 0) {
        categoriesStartingDate.setDate(categoriesStartingDate.getDate() - 1);
      } else {
        categoriesStartingDate.setDate(categoriesStartingDate.getDate());
      }
      let categories = new Array(totalDay).fill(0).map((item, index) => {
        let dataDate = categoriesStartingDate;
        dataDate.setDate(dataDate.getDate() + 1);
        let y = categoriesStartingDate.toISOString().split("T")[0];
        return y;
      });
      let totalCount = 0;
      graphData?.map((item, index) => {
        totalCount = totalCount + item;
      });
      setEc2stopInstancesCount(totalCount);
      setEc2StopInstancesSeries(graphData);
      setEc2stopInstancesCategories(categories);
      setSubLoaderEC2StopInstances(false);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setSubLoaderEC2StopInstances(false);
    }
  };

  useEffect(() => {
    if (
      cloudDetails?.selectedCloudAccountID !== undefined &&
      cloudDetails?.selectedCloudAccountID?.length !== 0
    )
      startInstances();
  }, [
    rdsStatistics,
    cloudDetails?.selectedCloudRegionsID,
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudServers,
  ]);

  useEffect(() => {
    if (
      cloudDetails?.selectedCloudAccountID !== undefined &&
      cloudDetails?.selectedCloudAccountID?.length !== 0
    )
      stopInstances();
  }, [
    stopInstancesTime,
    cloudDetails?.selectedCloudRegionsID,
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudServers,
  ]);

  useEffect(() => {
    if (
      cloudDetails?.selectedCloudAccountID !== undefined &&
      cloudDetails?.selectedCloudAccountID?.length !== 0
    )
      ec2startInstances();
  }, [
    ec2StartStatistics,
    cloudDetails?.selectedCloudRegionsID,
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudServers,
  ]);

  useEffect(() => {
    if (
      cloudDetails?.selectedCloudAccountID !== undefined &&
      cloudDetails?.selectedCloudAccountID?.length !== 0
    )
      ec2stopInstances();
  }, [
    ec2EndStatistics,
    cloudDetails?.selectedCloudRegionsID,
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudServers,
  ]);

  // useEffect(() => {
  //   let today = new Date();
  //   let last7Day = new Date();
  //   last7Day.setDate(last7Day.getDate() - 6);
  //   setRdsStatistics([last7Day, today]);
  //   setStopInstancesTime([last7Day, today]);
  //   setEc2StartStatistics([last7Day, today]);
  //   setEc2EndStatistics([last7Day, today]);
  // }, []);
  return (
    <Box
      style={{
        width: "100%",
        display: "flex",
        marginTop: "80px",
      }}
    >
      <MainLoader isLoading={isLoading} />
      <Box
        // sx={{ flexGrow: 2 }}
        style={{
          backgroundColor: "rgba(0,0,0,0.5)",
          padding: "20px 40px",
          width: "100%",
          borderRadius: "20px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* <Grid
          container
          columnSpacing={{ xs: 1, sm: 2, md: 23 }}
          colums={{ xs: 4, sm: 8, md: 12 }}
        > */}

        {/* ------------------RDS Start Instances----------------------------- */}
        {/* <Grid item xs={3} md={3}> */}
        <Box style={{ width: "20%", position: "relative", zIndex: "5" }}>
          <Box
            style={{
              // backgroundColor: 'rgba(0,0,0,0.5)',
              // border-radius: 30px;
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              position: "absolute",
              height: "100%",
            }}
          >
            <SubLoader isLoading={subLoaderStartInstances} />
          </Box>
          <Typography
            style={{ color: "rgba(255,255,255,0.7)", fontSize: "12px" }}
          >
            RDS Start Instances
          </Typography>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "8px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ color: "white", fontWeight: "600", fontSize: "30px" }}
            >
              {startInstancesCount}
            </Typography>
            <Chart
              options={chartData3.options}
              series={chartData3.series}
              type="area"
              height={100}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "8px",
              backgroundColor: "rgb(52,56,73)",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
            className="date_label_resp"
          >
            <Button
              style={{ padding: "0px", margin: "0px", width: "10px" }}
              onClick={openstartdatecalendar}
              className="img_but_resp"
            >
              <img
                src={ic_calendar}
                style={{ width: "25px", height: "17px" }}
                className="cal_img_resp"
              />
            </Button>

            <DateRangePicker
              ref={StartDateopencalendar}
              onChange={onChangeStartDate}
              value={rdsStatistics}
              className="date-resp1"
            />
          </Box>
        </Box>
        {/* </Grid> */}

        {/* ------------------- RDS Stop Instances---------------------------- */}
        {/* <Grid item xs={3} md={3}> */}
        <Box style={{ width: "20%", position: "relative", zIndex: "0" }}>
          <Box
            style={{
              // backgroundColor: 'rgba(0,0,0,0.5)',
              // border-radius: 30px;
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              position: "absolute",
              height: "100%",
            }}
          >
            <SubLoader isLoading={subLoaderStopInstances} />
          </Box>
          <Typography
            style={{ color: "rgba(255,255,255,0.7)", fontSize: "12px" }}
          >
            RDS Stop Instances
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "8px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ color: "white", fontWeight: "600", fontSize: "30px" }}
            >
              {stopInstancesCount}
            </Typography>
            <Chart
              options={stopInstancesChart.options}
              series={stopInstancesChart.series}
              type="area"
              height={100}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "8px",
              backgroundColor: "rgb(52,56,73)",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
            className="date_label_resp2"
          >
            <Button
              style={{ padding: "0px", margin: "0px", width: "10px" }}
              onClick={openenddatecalendar}
              className="img_but_resp"
            >
              <img
                src={ic_calendar}
                style={{ width: "25px", height: "17px" }}
                className="cal_img_resp"
              />
            </Button>

            <DateRangePicker
              ref={EndDateopencalendar}
              onChange={onChangeEndDate}
              value={stopInstancesTime}
              className="date-resp2"
            />
          </Box>
        </Box>
        {/* </Grid> */}

        {/* ------------------EC2 Start Instances----------------------------- */}
        <Box style={{ width: "20%", position: "relative", zIndex: "5" }}>
          <Box
            style={{
              // backgroundColor: 'rgba(0,0,0,0.5)',
              // border-radius: 30px;
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              position: "absolute",
              height: "100%",
            }}
          >
            <SubLoader isLoading={subLoaderEC2StartInstances} />
          </Box>
          <Typography
            style={{ color: "rgba(255,255,255,0.7)", fontSize: "12px" }}
          >
            EC2 Start Instances
          </Typography>

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "8px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ color: "white", fontWeight: "600", fontSize: "30px" }}
            >
              {ec2startInstancesCount}
            </Typography>
            <Chart
              options={EC2StartInstancesChart.options}
              series={EC2StartInstancesChart.series}
              type="area"
              height={100}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "8px",
              backgroundColor: "rgb(52,56,73)",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
            className="date_label_resp3"
          >
            <Button
              style={{ padding: "0px", margin: "0px", width: "10px" }}
              onClick={EC2Startopenenddatecalendar}
              className="img_but_resp"
            >
              <img
                src={ic_calendar}
                style={{ width: "25px", height: "17px" }}
                className="cal_img_resp"
              />
            </Button>

            <DateRangePicker
              ref={EC2StartDateopencalendar}
              onChange={onChangeEC2StartDate}
              value={ec2StartStatistics}
              className="date-resp3"
            />
          </Box>
        </Box>
        {/* </Grid> */}

        {/* ------------------- ec2 Stop Instances---------------------------- */}
        {/* <Grid item xs={3} md={3}> */}
        <Box style={{ width: "20%", position: "relative", zIndex: "0" }}>
          <Box
            style={{
              // backgroundColor: 'rgba(0,0,0,0.5)',
              // border-radius: 30px;
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              width: "100%",
              position: "absolute",
              height: "100%",
            }}
          >
            <SubLoader isLoading={subLoaderEC2StopInstances} />
          </Box>
          <Typography
            style={{ color: "rgba(255,255,255,0.7)", fontSize: "12px" }}
          >
            EC2 Stop Instances
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "8px",
              justifyContent: "space-between",
            }}
          >
            <Typography
              style={{ color: "white", fontWeight: "600", fontSize: "30px" }}
            >
              {ec2stopInstancesCount}
            </Typography>
            <Chart
              options={EC2StopInstancesChart.options}
              series={EC2StopInstancesChart.series}
              type="area"
              height={100}
            />
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "8px",
              backgroundColor: "rgb(52,56,73)",
              padding: "4px 8px",
              borderRadius: "4px",
            }}
            className="date_label_resp4"
          >
            <Button
              style={{ padding: "0px", margin: "0px", width: "10px" }}
              onClick={EC2Endopenenddatecalendar}
              className="img_but_resp"
            >
              <img
                src={ic_calendar}
                style={{ width: "25px", height: "17px" }}
                className="cal_img_resp"
              />
            </Button>

            <DateRangePicker
              ref={EC2EndDateopencalendar}
              onChange={onChangeEC2EndDate}
              value={ec2EndStatistics}
              className="date-resp4"
            />
          </Box>
        </Box>
        {/* </Grid> */}

        {/* </Grid> */}
      </Box>
    </Box>
  );
};
export default CloudInfoTwo;
