import { Box } from "@mui/system";
import { useState } from "react";
import SearchBar from "../DashboardSettingUserBody/SearchBar";
import RolesTable from "./RolesTable";
import SearchBarRoles from "./SearchBarRoles";

const DashboardSettingRolesBody = () => {
  const [search, setSearch] = useState();
  const [refreshRoleData, setRefreshRoleData] = useState(false);
  return (
    <Box
      style={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          width: "90%",
          backgroundColor: "rgba(0,0,0,0.5)",
          marginTop: "30px",
          padding: "30px",
          border: "1px solid #707070",
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SearchBarRoles
          setSearch={setSearch}
          refreshRoleData={refreshRoleData}
          setRefreshRoleData={setRefreshRoleData}
        />
        <RolesTable
          search={search}
          refreshRoleData={refreshRoleData}
          setRefreshRoleData={setRefreshRoleData}
        />
      </Box>
    </Box>
  );
};
export default DashboardSettingRolesBody;
