import axios from "axios";
import { BASE_URL } from "../utils/api.routes";
import { getToken } from "../MyNewUtils/localstorage";

const instance = new axios.create({
  baseURL: BASE_URL,
  headers: {
    'Authorization': `token ${getToken()}`
  }

});

export default instance;
