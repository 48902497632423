import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import DashboardHeader from "../../../Component/DashboardHeader/DashboardHeader";
import "./style.css";
import SupportCenter from "./supportCenter";
const Supports = ({ ConfigureCloudModel }) => {
  const cls = useMemo(() => "SectionPageOne", []);
  return (
    <Box className={`${cls}__background`} style={{}}>
      <DashboardHeader
        activeTab={"SETTINGS"}
        activeSettingSubTab={"Supports"}
      />
      <SupportCenter />
    </Box>
  );
};
export default Supports;
