import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import activateStartup from "../../../assets/img/activateStartup.png"
import morebtnicn from "../../../assets/img/more_btn_icn.png"
import cancelicn from "../../../assets/img/cancel_round_icn.png"
import penicn from "../../../assets/img/pen_icn@2x.png"
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ReactLoading from "react-loading";
const Setupwindows = () => {
    return (
        <>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center', width: '66.66%', justifyContent: 'space-between' }}>
                    <Typography style={{ color: '#FF8800', fontSize: '36px', fontWeight: 'bold', marginLeft: '2%' }}>EC2 Status</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <img src={activateStartup} style={{ width: '33px', height: '33px' }} />
                        <Button style={{ marginLeft: '16px', fontWeight: 'normal', fontSize: '20px', background: '#008CF2', color: '#fff', borderRadius: '20px' }}>Done Editing</Button>
                        <FilterAltIcon sx={{ marginLeft: '16px', width: '33px', height: '33px' }} />
                        <ShareIcon sx={{ marginLeft: '16px', width: '33px', height: '33px' }} />
                        <img src={morebtnicn} style={{ marginLeft: '16px', height: '33px' }} />
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '66.66%', marginTop: '25px' }}>
                    <Box sx={{ padding: '10px', borderRadius: '20px', width: '70%', height: '400px', background: 'rgba(0 ,0 ,0 ,0.3)', display: 'flex', flexDirection: 'column', alignItems: ' flex-start' }}>
                        <Box style={{ display: 'flex', paddingLeft: '90%' }}><img src={cancelicn} style={{ width: '25px', height: '25px' }} /></Box>
                        <Box sx={{ padding: '3%' }}>
                            <Typography sx={{ fontSize: '26px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>Overview <SettingsIcon style={{ marginLeft: '20px' }} /> </Typography>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'normal' }}>This dashboard presents key IAM Analysis indicators, including</Typography>
                            <Typography sx={{ fontSize: '20px', fontWeight: 'normal', display: 'flex', alignItems: 'center' }}><FiberManualRecordIcon style={{ marginRight: '20px', width: '15px' }} />Access codes  </Typography>
                        </Box>
                    </Box>
                    <Box sx={{ marginLeft: '50px', padding: '10px', borderRadius: '20px', width: '70%', height: '400px', background: 'rgba(0 ,0 ,0 ,0.3)', display: 'flex', flexDirection: 'column' }}>
                        <Box style={{ display: 'flex', paddingLeft: '90%' }}><img src={cancelicn} style={{ width: '25px', height: '25px' }} /></Box>
                        <Box sx={{ padding: '3%', display: 'flex', alignItems: 'center', justifyContent: 'space-around' }}>
                            <Typography sx={{ fontSize: '26px', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>User vs Services <SettingsIcon style={{ marginLeft: '20px' }} /> </Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography>Last 60 Minutes</Typography>
                                <img src={penicn} style={{ width: '15px', height: '15px', marginLeft: '5px' }} />
                                <FilterAltIcon sx={{ width: '15px', height: '15px', marginLeft: '5px' }} />
                                <ShareIcon sx={{ width: '15px', height: '15px', marginLeft: '5px' }} />
                                <img src={morebtnicn} style={{ height: '15px', marginLeft: '5px' }} />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }}>
                            <ReactLoading type="spokes" color="#fff" />
                        </Box>
                    </Box>
                </Box>

            </Box>
        </>
    )
}
export default Setupwindows;