import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { toast } from "react-toastify";
import { Api } from "../../../MyNewUtils/Api";
import { useNavigate } from "react-router-dom";
import { conutryList } from "../../../utils/country";
const style = {
  position: "absolute",
  width: "45vw",
  bgcolor: "#01081D",
  border: " 1px solid #707070",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const ChildModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "#01081D",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const UpdateNumber = ({ disable, setUpdateNumber }) => {
  const countryCode = conutryList;
  const [number, setNumber] = useState(
    JSON.parse(localStorage.getItem("user")).data.phone
  );
  const [phoneNumber, setPhoneNumber] = useState("");
  const [selectCountryCode, setSelectCountryCode] = useState("+93");
  const [otp, setOtp] = useState("");
  const [open, setOpen] = useState(true);
  const [otpModel, setOtpModel] = useState(false);

  const handleClose = () => setUpdateNumber(false);
  React.useEffect(() => { }, [disable]);

  const handleSendOTP = async () => {
    if (phoneNumber.length === 0) {
      toast.error("Enter valid Number", { autoClose: 1000 });
    } else {
      let final = {
        id: JSON.parse(localStorage.getItem("user")).data.id,
        phone: selectCountryCode + phoneNumber,
      };
      const { statusCode, data } = await Api.postRequest(
        "/users/send-otp-for-change-mobile",
        final
      );
      const res = JSON.parse(data);
      if (res.status == 1) {
        setOtpModel(true);
        toast.success(res?.message, { autoClose: 1000 });
      } else {
        toast.error(res?.message, { autoClose: 1000 });
      }
    }
  };

  const handleSubmit = async () => {
    if (otp.length == 0) {
      toast.error("Enter OTP Number", { autoClose: 1000 });
    } else {
      let final = {
        id: JSON.parse(localStorage.getItem("user")).data.id,
        phone: selectCountryCode + phoneNumber,
        mobileOtp: otp,
      };
      const { statusCode, data } = await Api.postRequest(
        "/users/change-mobile",
        final
      );
      const res = JSON.parse(data);
      if (res.status == 1) {
        let userData = JSON.parse(localStorage.getItem("user"));
        userData.data = {
          ...userData.data,
          phone: selectCountryCode + phoneNumber,
        };
        localStorage.setItem("user", JSON.stringify(userData));
        setOtpModel(true);
        setUpdateNumber(false);
        toast.success(res?.message, { autoClose: 1000 });
      } else {
        toast.error(res?.message, { autoClose: 1000 });
      }
    }
  };
  const handleCloseOtpModel = () => {
    setOtp("");
    setOtpModel(false);
  };

  const onCountryoregionSet = (e) => {
    setSelectCountryCode(e.target.value);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "30px", textAlign: "center", color: "#008CF2" }}
          >
            Update Number
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              fontSize: "22px",
              textAlign: "center",
              color: "#FFFFFF",
              marginTop: "32px",
            }}
          >
            Do you want to Update Number:-{" "}
            <span style={{ color: "#7CECF7" }}>{number}</span>
          </Typography>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: "22px", color: "#008CF2", marginTop: "32px" }}
            >
              Country or Region
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <select
                onChange={onCountryoregionSet}
                style={{
                  width: "300px",
                  backgroundColor: "#2A3144",
                  border: "1px solid #363636",
                  padding: "4px",
                  color: "#FFF",
                }}
              >
                {countryCode.map((item) => (
                  <option
                    value={item.dial_code}
                  >{`${item.name}  (${item.dial_code})`}</option>
                ))}
              </select>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: "22px", color: "#008CF2", marginTop: "32px" }}
            >
              Enter New Number
            </Typography>
            <Typography>
              <input
                type="Number"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                style={{
                  width: "300px",
                  height: "40px",
                  background: "#0000",
                  color: "#fff",
                  border: "1px solid #363636",
                  padding: "5%",
                }}
                placeholder=" Enter New Number"
              />
            </Typography>
          </Box>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              marginTop: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={handleClose}>
                No, cancel
              </Button>
              <Button variant="contained" onClick={handleSendOTP}>
                Send OTP
              </Button>
            </Stack>
          </Typography>

          {/* ------------------ OTP Section Model ---------------- */}
          <Modal
            hideBackdrop
            open={otpModel}
            onClose={handleClose}
            aria-labelledby="child-modal-title"
            aria-describedby="child-modal-description"
            style={{
              background: "rgb(225, 225, 225, 0.4)",
              backdropFilter: "blur(5px)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box sx={{ ...ChildModalStyle }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography
                  style={{
                    fontSize: "22px",
                    color: "#008CF2",
                    marginTop: "32px",
                  }}
                >
                  Enter OTP
                </Typography>
                <Typography>
                  <input
                    type="Number"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    style={{
                      width: "300px",
                      height: "40px",
                      background: "#0000",
                      color: "#fff",
                      border: "1px solid #363636",
                      padding: "5%",
                    }}
                    placeholder="Enter OTP"
                  />
                </Typography>
              </Box>
              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                style={{
                  marginTop: "32px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Stack spacing={2} direction="row">
                  <Button variant="outlined" onClick={handleCloseOtpModel}>
                    No, cancel
                  </Button>
                  <Button variant="contained" onClick={handleSubmit}>
                    Yes, Update
                  </Button>
                </Stack>
              </Typography>
            </Box>
          </Modal>
        </Box>
      </Modal>
    </div>
  );
};
export default UpdateNumber;
