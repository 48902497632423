import { useContext, useEffect } from "react";
import { Box, width } from "@mui/system";
import { Badge, IconButton, Typography } from "@mui/material";
import logo from "../../assets/img/logo_header.png";
import home from "../../assets/img/home@2x.png";
import discover from "../../assets/img/discover.svg";
import globe from "../../assets/img/globe@2x.png";
import lock from "../../assets/img/lock@2x.png";
import cloudComputing from "../../assets/img/cloud-computing@2x.png";
import settings from "../../assets/img/settings_1_icn@2x.png";
import defaultimg from "../../assets/img/default.png";
import money from "../../assets/img/money.png";
import LogoutIcon from "@mui/icons-material/Logout";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import DashBoardSettingHeader from "./DashBoardSettingHeader";
import CloudinfoHeader from "../../screens/dashboard/CLOUD INFO/CloudinfoHeader";
import LogoutModel from "../../screens/dashboard/deshboardhome/LogoutModel";
import myContext from "../../screens/MyContext/MyContext";
import AccountInfo from "./accountSelectHeader";
import "./Header.scss";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';


const DashboardHeader = ({
  activeTab,
  activeSettingSubTab,
  accountSelectHeaderShow,
  showRegionPart,
}) => {
  const [logoutmodel, setLogOutModel] = useState(false);
  const [isClosed, setIsClosed] = useState(true);

  const { profileImageUrl, timeZone, getprofileImageUrl } =
    useContext(myContext);

  const [hoveredNavigation, setHoveredNavigation] = useState('');

  const navigate = useNavigate();

  const navItems = useMemo(
    () => [
      {
        logo: home,
        text: "DASHBOARD",
        navigation: "/dashboard",
        hover: false,
      },
      {
        logo: discover,
        text: "DISCOVER",
        navigation: "/discover",
        hover: false,
        new: true,
      },
      {
        logo: globe,
        text: "NETWORK MAP",
        navigation: "/network-map",
        hover: false,
      },
      {
        logo: lock,
        text: "ACCESS ANALYSIS",
        navigation: "/access-analysis",
        hover: false,
      },
      {
        logo: cloudComputing,
        text: "CLOUD INFO",
        hover: true,
        hoverName: "CLOUD INFO",
      },
      {
        logo: settings,
        text: "SETTINGS",
        hover: true,
        hoverName: "SETTINGS",
      },
    ],
    []
  );

  const logout = () => {
    setLogOutModel(true);
  };

  const profile = () => {
    navigate("/Profile");
  };

  const profileBilling = () => {
    navigate("/Profile-Billing");
  };

  const Month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const DayName = ["Sun", "Mon", "Tue", "Wed", "Thurs", "Fri", "Sat"];


  const Day = DayName[new Date().getUTCDay()];
  const time = new Date().getHours() + ":" + new Date().getMinutes();
  const hours = new Date().getHours();
  const ampm = hours >= 12 ? "pm" : "am";
  const { LiveTime } = useContext(myContext);
  const [datetime, setdateTime] = useState(LiveTime(new Date()));
  useEffect(() => {
    setdateTime(LiveTime(new Date()));
  }, [timeZone]);

  useEffect(() => {
    getprofileImageUrl(
      JSON.parse(localStorage.getItem("userData"))?.profilepic
    );
  }, []);

  useEffect(() => {
    const closeStatus = localStorage.getItem('discoverClosed');
    setIsClosed(closeStatus === 'true');
  }, []);

  const handleClose = (event) => {
    event.stopPropagation();
    setIsClosed(true);
    localStorage.setItem('discoverClosed', 'true');
  };

  return (
    <>
      {logoutmodel ? <LogoutModel setLogOutModel={setLogOutModel} /> : null}
      <Box
        className="flex justify-between bg-[rgb(5,1,46)] relative z-13"
      >
        <Box className="flex">
          <Box
            className="w-3/10 flex items-center"
          >
            <img src={logo} className="header-logo" />
          </Box>

          <Box className="flex flex-grow">
            {navItems.map((item, i) => (
              <Box
                className={`px-4 py-3 flex flex-col items-center text-center ${activeTab && activeTab === item.text ? 'bg-[rgb(1,4,22)]' : 'bg-[rgb(5,1,46)]'} justify-center w-full cursor-pointer`}
              >
                <Badge
                  badgeContent={item.new ? "New" : undefined}
                  className="text-white"
                  sx={{
                    '& .MuiBadge-badge': {
                      color: 'white',
                      bgcolor: 'red'
                    }
                  }}
                >
                  <Box
                    className="flex flex-col items-center nav-item"
                    onClick={() => item.navigation && navigate(item.navigation)}
                    onMouseEnter={() => setHoveredNavigation(item.navigation ? "" : item.text)}
                  >
                    <img src={item.logo} className="img" />
                    <div className="mt-1 text-[14px] text-[#81F5FF] tracking-wide">
                      {
                        item.text === "DISCOVER" && !isClosed ? (
                          <div className="relative flex justify-center">
                            <span className="nav-label">
                              {item.text}
                            </span>
                            <div className="absolute w-[240px] bg-[#46faff] p-4 text-left z-[999] top-[24px] flex justify-center rounded-lg">
                              <IconButton className="badge-icon">
                                <ArrowDropUpIcon fontSize="40px" />
                              </IconButton>
                              <IconButton className="close-icon" onClick={handleClose}>
                                <HighlightOffIcon />
                              </IconButton>
                              <div>
                                <h2 className="text-lg font-bold text-black">See what's new</h2>
                                <p className="text-black">Click to explore newest feature we've added!</p>
                              </div>
                            </div>
                          </div>
                        ) : <span className="text-nowrap">{item.text}</span>
                      }
                    </div>
                  </Box>
                </Badge>
              </Box>
            ))}
          </Box>
        </Box>

        {/* header right part */}
        <Box className="flex flex-col w-auto self-center">
          <Box className="flex w-full justify-evenly items-center self-end">
            {/* first item right header */}
            {/* <Box className="flex-grow header-time-section">
              <Typography className="text-white text-base">
                {datetime[0]}
              </Typography>
              <Typography className="text-[0.8rem] text-[rgb(103,108,130)]">
                {datetime[1]?.split(",")[0]}
                {datetime[1]?.split(",")[1]}
              </Typography>
            </Box> */}

            <Box
              className={`flex items-center ${activeTab === "Profile" ? "flex-grow bg-black w-[248px] h-full p-2.5" : "flex-grow mt-2.5"} header-profile-details-section`}
            >
              <Box onClick={profile} className="flex gap-2.5 items-center">
                {/* <img
                  src={defaultimg}
                  className="w-[65px] h-[50px] bg-contain rounded-[40%] bg-red-500 cursor-pointer"
                /> */}
                {/* <div>
                  <Typography className="text-white text-base">
                    {JSON.parse(localStorage.getItem("user"))?.data?.name}
                  </Typography>
                  <Typography className="text-[rgb(103,108,130)] text-[0.8rem] lowercase">
                    {JSON.parse(localStorage.getItem("user"))?.data?.email}
                  </Typography>
                </div> */}
              </Box>
            </Box>
            <Box className="flex flex-grow justify-evenly items-center">
              <Box
                className={`mx-[0.3125rem] cursor-pointer ${activeTab === "Profile-Billing" ? "bg-black rounded-full" : ""}`}
                onClick={profileBilling}
              >
                <img src={money} className="w-12" />
              </Box>
              <Box>
                <Button onClick={logout}>
                  <LogoutIcon className="text-white" />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

      </Box>
      <Box>
        <DashBoardSettingHeader
          settingTabOpen={hoveredNavigation === "SETTINGS" || (activeTab === "SETTINGS" && hoveredNavigation === "")}
          handleOpenSelect={() => setHoveredNavigation("SETTINGS")}
          handleCloseSelect={() => setHoveredNavigation("")}
          activeSettingSubTab={activeSettingSubTab}
        />
        <CloudinfoHeader
          cloudinfoManu={hoveredNavigation === "CLOUD INFO" || (activeTab === "CLOUD INFO" && hoveredNavigation === "")}
          cloudinfoManuOpenSelect={() => setHoveredNavigation("CLOUD INFO")}
          cloudinfoManuCloseSelect={() => setHoveredNavigation("")}
        />
      </Box>
      {accountSelectHeaderShow && (
        <AccountInfo showRegionPart={showRegionPart} />
      )}
    </>
  );
};
export default DashboardHeader;
