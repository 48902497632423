const devConfig = {
  // baseURL: "http://54.156.11.54:8080",
  baseURL: "http://localhost:8080",
};

const prodConfig = {
  baseURL: "https://api.clevroneidentity.com",
};

export const config = prodConfig;
