import { Box } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import { Button, Typography } from "@mui/material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import DashBoardTable from "../DashBoardTable/DashBoardTable";
import { useState, useEffect } from "react";
import SearchBar from "./SearchBar";
import { Api } from "../../MyNewUtils/Api";
import DeleteUsersModel from "../DashBoardTable/DeleteUsersModel";
const DashboardSettingUserBody = ({ setIsLoading }) => {
  const [selectedUserCount, setSelectedUserCount] = useState(0);
  const [UserDataError, setUserDataError] = useState(false);
  const [AllUserData, setAllUserData] = useState([]);
  const [Usertabletitle, setUsertabletitle] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [searched, setSearched] = useState("");
  const [deleteUsersModelOpen, setDeleteUsersModelOpen] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);

  const getAllUserData = async () => {
    setIsLoading(true);
    let lapdata = JSON.parse(localStorage.getItem("user")).data.ldapcred;
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      uid: lapdata?.uid,
      uidW: lapdata?.uidW,
      secret: lapdata?.secret,
      url: lapdata?.url,
    };

    const data = await Api.postRequest("/ldap/search-user", final);
    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      setAllUserData(res?.data);

      setIsLoading(false);
      setUsertabletitle(Object?.keys(res?.data?.[0]));
    } else {
      setUserDataError(true);
      setIsLoading(false);
    }
  };

  const _handleDeleteUser = (row) => {
    setDeleteUsersModelOpen(true);
  };
  useEffect(() => {
    getAllUserData();
  }, [refresh]);

  return (
    <Box
      style={{
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {deleteUsersModelOpen ? (
        <DeleteUsersModel
          editDashboardModelOpen={deleteUsersModelOpen}
          seteditDashboardModelOpen={setDeleteUsersModelOpen}
          resetChildpasswordModelValue={selectedUserList}
          setSelectedUserList={setSelectedUserList}
          refreshList={refresh}
          setRefresh={setRefresh}
          // setResetChildpasswordModelOpenValue={
          //   setResetChildpasswordModelOpenValue
          // }
        />
      ) : null}
      <Box
        style={{
          width: "90%",
          backgroundColor: "rgba(0,0,0,0.5)",
          marginTop: "30px",
          padding: "30px",
          border: "1px solid #707070",
          borderRadius: "30px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* search bar */}
        <SearchBar
          setRefresh={setRefresh}
          refresh={refresh}
          setSearched={setSearched}
        />

        {/* filter */}
        {selectedUserList.length > 0 && (
          <Box
            style={{
              display: "flex",
              // backgroundColor: 'red',
              width: "70%",
              alignSelf: "center",
              justifyContent: "space-between",
            }}
          >
            <Box style={{ flexGrow: 1 }}>
              <Typography style={{ color: "white" }}>
                {selectedUserList.length === 0 ? (
                  <>
                    Users
                    <span style={{ color: "rgb(103,108,130)" }}>
                      | Showing all users
                    </span>{" "}
                  </>
                ) : (
                  <span style={{ color: "rgb(103,108,130)" }}>
                    {selectedUserList.length} Users selected
                  </span>
                )}
              </Typography>
            </Box>
            <Box
              sx={{ flexGrow: 1, cursor: "pointer" }}
              onClick={_handleDeleteUser}
            >
              <Typography style={{ color: "#008CF2" }}>
                Delete selected users
              </Typography>
            </Box>
            <Box sx={{ flexGrow: 1, cursor: "pointer" }}>
              <Typography style={{ color: "#008CF2" }}>
                Add selected users to group
              </Typography>
            </Box>
          </Box>
        )}

        {/* table */}

        <DashBoardTable
          UserDataError={UserDataError}
          searched={searched}
          setSelectedUserCount={setSelectedUserCount}
          AllUserData={AllUserData}
          Usertabletitle={Usertabletitle}
          refreshList={refresh}
          setRefresh={setRefresh}
          selectedUserList={selectedUserList}
          setSelectedUserList={setSelectedUserList}
        />
      </Box>
    </Box>
  );
};
export default DashboardSettingUserBody;
