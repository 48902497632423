import { Box } from "@mui/system";
import RegistrationLayout from "../../Component/Layout/index";
import SignupStep4Col1 from "../../Component/verificationCleveronDetails/Logo";
import SignupStep4Col2 from "../../Component/verificationCleveronDetails/SecureVerification";
import SignupStep4Col3 from "../../Component/verificationCleveronDetails/VerificationOnlyCleveron";
import { useLocation } from "react-router-dom";

const SignupStepThree = () => {
  const location = useLocation();

  return (
    <RegistrationLayout isHeaderShow={false}>
      <Box style={{ flexGrow: 1, display: "flex" }}>
        <Box
          style={{
            width: "35%",

            display: "flex",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <SignupStep4Col1 />
        </Box>
        <Box
          style={{
            // flexGrow: 4,
            width: "25%",
            // backgroundColor: 'green',
            display: "flex",
            alignItems: "center",
            padding: "25px",
          }}
        >
          <SignupStep4Col2 />
        </Box>
        <Box
          style={{
            flexGrow: 1,
            display: "flex",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <SignupStep4Col3 MyData={location?.state} />
        </Box>
      </Box>
    </RegistrationLayout>
  );
};
export default SignupStepThree;
