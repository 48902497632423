import "./Lambda.css";
import { Button, Box, Grid, Typography } from '@mui/material'
import Modal from '@mui/material/Modal';
import { useState } from "react";
import cancel from "../../../assets/img/cancel_icn.png";
import setting from "../../../assets/img/settings_icn.png"
import search from "../../../assets/img/search_icn.png"
import component from "../../../assets/img/component_55.png"
import component1 from "../../../assets/img/component_56.png"
import dot from "../../../assets/img/ellipse_icn.png"


const Lambda = () => {

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="main">
          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", marginTop: "20px", marginRight: "20px" }}><img width="30px" height="30px" src={cancel} /></Box>
          <Box className="header1">
            <Box ><Typography className="heading1" >ES2 (5)</Typography></Box>
            <Box className="input" style={{ marginLeft: "50px" }}>
              <Box><img style={{ height: "60%", width: "65%", marginTop: "10px", marginLeft: "15px" }} src={search} /></Box>
              <Box><input style={{ width: "650px", height: "100%", background: '#0000', fontSize: "18px", outline: "none", border: "none", color: "white", paddingLeft: "10px" }} type="search" placeholder="Search.." /></Box>
            </Box>
            <Box ><img className="setting1" src={setting} /></Box>
          </Box>
          <Box className="bo" style={{ display: "flex !important", flexDirection: "row" }} >
            <Box className="bo1">
              <Box style={{ display: "flex", flexDirection: "row", paddingTop: "10px", justifyContent: "space-between" }}>
                <Typography style={{ color: "white", marginLeft: "25px", fontSize: "22px" }}>Function Name</Typography>
                <img style={{ height: "20px", width: "20px", marginTop: "5px" }} src={component} />
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", marginLeft: "10px" }}>
                <img style={{ height: "17px", width: "17px", color: "white", marginTop: "3px", marginLeft: "15px" }} src={dot} />
                <Typography style={{ color: "white", marginLeft: "15px", fontSize: "18px" }}>CHECKING</Typography>
              </Box>
            </Box>
            <Box className="bo2" style={{ marginLeft: "20px" }}>
              <Box style={{ display: "flex", flexDirection: "row", paddingTop: "10px", justifyContent: "space-between" }}>
                <Typography style={{ color: "white", marginLeft: "25px", fontSize: "22px" }}>Description</Typography>
                <img style={{ height: "20px", width: "20px", marginTop: "5px" }} src={component1} />
              </Box>
              <Box style={{ display: "flex", flexDirection: "row", marginLeft: "10px", marginTop: "20px" }}>
                <Typography style={{ color: "white", marginLeft: "15px", fontSize: "18px" }}>An Amazon S3 trigger that retrieves metadata for the object that has been updated</Typography>
              </Box>
            </Box>
            <Box className="bo3">
              <Box style={{ display: "flex", flexDirection: "row", paddingTop: "10px", justifyContent: "space-between" }}>
                <Typography style={{ color: "white", marginLeft: "25px", fontSize: "22px" }}>Runtime</Typography>
                <img style={{ height: "20px", width: "20px", marginTop: "5px" }} src={component1} />
              </Box>
              <Box style={{ marginLeft: "10px", marginTop: "20px" }}>
                <Typography style={{ color: "white", marginLeft: "15px", fontSize: "18px" }}>Python 3.7</Typography>
              </Box>
            </Box>
            <Box className="bo4">
              <Box style={{ display: "flex", flexDirection: "row", paddingTop: "10px" }}>
                <Typography style={{ color: "white", marginLeft: "25px", fontSize: "22px" }}>Code size</Typography>
                <img style={{ height: "20px", width: "20px", marginTop: "5px" }} src={component1} />
              </Box>
              <Box style={{ marginLeft: "10px", marginTop: "20px" }}>
                <Typography style={{ color: "white", marginLeft: "15px", fontSize: "18px" }}>566.0 byte</Typography>
              </Box>
            </Box>
            <Box className="bo5">
              <Box style={{ display: "flex", flexDirection: "row", paddingTop: "10px", justifyContent: "space-between" }}>
                <Typography style={{ color: "white", marginLeft: "25px", fontSize: "22px" }}>Last modified</Typography>
                <img style={{ height: "20px", width: "20px", marginLeft: "50px", marginTop: "5px" }} src={component1} />
              </Box>
              <Box style={{ marginLeft: "10px", marginTop: "20px" }}>
                <Typography style={{ color: "white", marginLeft: "15px", fontSize: "18px" }}>2 minutes ago</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ marginTop: "-50px" }} >
            <hr style={{ color: "white", width: "90%", marginLeft: "60px", height: "20%" }} />
          </Box>
          <Box style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", paddingTop: "-35px", paddingBottom: "20px", width: "90%" }}>
            <Typography style={{ color: "#666C7C", fontSize: "22px", marginRight: "20px" }}>Previous</Typography>
            <Box style={{ height: "35px", width: "35px", borderRadius: "50%", backgroundColor: "#666C7C", marginRight: "20px" }}><Typography style={{ color: "white", textAlign: "center", fontSize: "22px" }}>1</Typography></Box>
            <Typography style={{ color: "#666C7C", fontSize: "22px", marginRight: "5px" }}>Next</Typography>
          </Box>
        </Box>
      </Modal>

    </div>
  )
}

export default Lambda