import React from 'react'
import Modal from '@mui/material/Modal';
import { useEffect, useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Select } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControl from '@mui/material/FormControl';
import { Api } from '../../MyNewUtils/Api';
import { toast } from 'react-toastify'

const AccountIdList = ({ setAccountIdListModel, setRadioBttonValue }) => {
    const [open, setOpen] = useState(true);
    const handleClose = () => setAccountIdListModel(false);
    const [A_id_List, setA_id_List] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const handleChange = (e) => {
        setRadioBttonValue(e.target.value)
        handleClose()
    }

    const getAllDashboard = async () => {
        setIsLoading(true)
        const data = await Api.postRequest('/users/get-all-cloud-accounts', { userid: JSON.parse(localStorage.getItem('user'))?.data?.id })

        const res = JSON.parse(data?.data)
        console.log("awgdhgrdyhtfdehryfu", data)
        if (res?.status == 1) {
            res?.data?.slice(0, 1)?.map((row) => {
                console.log("csxgdxeyhtfsxgdf", row?.accountId)
                let aid = row?.accountId
                // setRadioBttonValue(aid)
            })
            setA_id_List(res?.data)
            setIsLoading(false)
        } else {
            toast.error(res?.message, { autoClose: 1000 })
            setIsLoading(false)

        }

    }

    useEffect(() => {
        getAllDashboard()
    }, [])
    // console.log("cbsgedhr", A_id_List)
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ background: 'rgb(225, 225, 225, 0.4)', backdropFilter: 'blur(5px)', display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <Box sx={{
                    background: '#010A26', padding: '40px', width: '20%', borderRadius: '0px', maxHeight: '90%', overflow: 'auto',
                    "&::-webkit-scrollbar": {
                        width: 10,
                        height: 10
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#0000",

                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ADD8E6",
                        borderRadius: 2,
                        border: '0px solid #0000'
                    }
                }}>
                    <Typography variant="h6" style={{ color: "#5A95A9", marginBottom: "20px" }}>
                        Select Account ID
                    </Typography>
                    <FormControl>

                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group"
                        >
                            {A_id_List?.map((row) => (
                                < FormControlLabel value={row?.accountId} onChange={handleChange}
                                    control={< Radio size="small" style={{ color: "white" }} />} label={row?.accountId} style={{ color: "rgba(255,255,255,0.6)" }} />
                            ))}
                            {/* < FormControlLabel value="987654321" onChange={handleChange}
                                control={< Radio size="small" style={{ color: "white" }} />} label="987654321" style={{ color: "rgba(255,255,255,0.6)" }} />
                            < FormControlLabel value="147852369" onChange={handleChange}
                                control={< Radio size="small" style={{ color: "white" }} />} label="147852369" style={{ color: "rgba(255,255,255,0.6)" }} /> */}


                        </RadioGroup>

                    </FormControl>
                </Box>

            </Modal>
        </div >
    )
}

export default AccountIdList