import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
const style = {
  position: 'absolute',
  top: '50%',
  left: '60%',
  transform: 'translate(-50%, -50%)',
  width: '50vw',
  bgcolor: '#01081D',
  border: ' 1px solid #707070',
  boxShadow: 24,
  p: 4,
};

const Removeemail = ({ disable, setEmailmodel }) => {
  const [email, setEmail] = useState(JSON.parse(localStorage.getItem('user')).data.email)
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setEmailmodel(false);
  React.useEffect(() => {

  }, [disable])
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontSize: '30px', textAlign: 'center', color: '#008CF2' }}>
            Remove Email Conatct?
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ fontSize: '22px', textAlign: 'center', color: '#FFFFFF', marginTop: '32px' }}>
            Do you want to remove <span style={{ color: '#7CECF7' }}>{email}</span>
            <Typography style={{ fontSize: '22px', textAlign: 'center', color: '#FFFFFF' }}>
              as your email contact address ?
            </Typography>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ fontSize: '22px', textAlign: 'center', color: '#FFFFFF', marginTop: '32px' }}>
            This action removed the email contact address for this Region Immediately.
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ marginTop: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={handleClose}>No, cancel</Button>
              <Button variant="contained">Yes, remove</Button>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}
export default Removeemail;