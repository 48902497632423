import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { Captcha, captchaSettings } from "reactjs-captcha";
import logo from "../../assets/img/CO5_FINAL.png";
import roundClose from "../../assets/img/cancel_icn.png";
import { toast } from "react-toastify";
import RegistrationLayout from "../../Component/Layout/index";
import security_code from "../../assets/img/security_code.png";
import "./PasswordRecovery.css";
import { style } from "./style";
import CustomModel from "../../Component/Model/index";
import SentEmailModel from "../../Component/SentEmailModel/index";
import { useNavigate } from "react-router-dom";
import ForgotEmailModel from "../../Component/ForgotEmailModel/index";
import { Api } from "../../MyNewUtils/Api";

const PasswordRecoveryOtp = () => {
  const cls = useMemo(() => "passwordRecovery", []);
  const navigate = useNavigate();
  const [otp, setOtp] = useState("");
  const [isNumberFild, setIsNumberFild] = useState("");
  const [isEmailModelOpen, setIsEmailModelOpen] = useState(false);
  const [isForgetEmailModelOpen, setIsForgetEmailModelOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isTextMessageSent, setIsTextMessageSent] = useState(false);
  const [isTextMessageSentAgain, setIsTextMessageSentAgain] = useState(false);
  const _handleSubmit = useCallback(async () => {
    if (otp.length == "0") {
      toast.error("Enter Valid OTP", { autoClose: 1000 });
    } else {
    }

    // if (isMobile) {
    //     if (isNumberFild.length == '0') {
    //         toast.error("Please enter valid Mobile Number")
    //     } else {
    //         setIsEmailModelOpen(false)
    //         setIsTextMessageSent(true)
    //     }
    // } else {
    //     // that means it email
    //     if (isEmailFild.length == '0') {
    //         toast.error("Please enter valid Email address")
    //     } else {
    //         let final = { email: isEmailFild }
    //         const { statusCode, data } = await Api.postRequest('/users/forgot-password', final)
    //         const res = JSON.parse(data)
    //         if (res == null) {
    //             toast.error("Please enter valid Email address")
    //         } else {
    //             toast.error(res.message)
    //             setIsTextMessageSent(false)
    //             setIsEmailModelOpen(true)
    //         }

    //     }
    // }
  });

  const _handleCloseEmailModel = () => setIsEmailModelOpen(false);
  const _handleCloseForgetEmailModel = () => setIsForgetEmailModelOpen(false);

  const _handleResend = useCallback(() => {
    if (isMobile) {
      setIsTextMessageSent(false);
      setIsTextMessageSentAgain(true);
    }
  }, [isMobile]);

  const _handleClickLink = useCallback(() => {
    setIsForgetEmailModelOpen(true);
  }, []);

  return (
    <RegistrationLayout>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} className={`${cls}__gridItem`}>
            <img src={logo} alt="logo" className="img-fluid signup_logo" />
          </Grid>
          <Grid item xs={6} className={`${cls}__gridContentItem`}>
            <Box className={`${cls}__gridContentBox`}>
              <Box>
                <Typography style={style.contentTitle}>Enter OTP</Typography>
              </Box>
              <Box>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="type"
                    // defaultValue="mobile"
                    value={isMobile ? "mobile" : "email"}
                    name="radio-buttons-group"
                    onChange={(e) => {
                      if (
                        isMobile &&
                        !(isTextMessageSent || isTextMessageSentAgain)
                      )
                        setIsMobile(false);
                      else setIsMobile(true);
                    }}
                  ></RadioGroup>
                </FormControl>
              </Box>

              <Box className={`${cls}__d-flex`}>
                <Box>
                  {/* <label for="verfication" className={`${cls}__label`}>
                                        OTP
                                    </label> */}

                  <input
                    type="number"
                    // name="verfication"
                    className={`${cls}__input`}
                    placeholder="Enter otp"
                    value={otp}
                    onChange={(e) => {
                      setOtp(e.target.value);
                    }}
                  />
                </Box>
              </Box>
              <Box
                className={`${cls}__margin30 ${cls}__width350 ${cls}__d-flex ${cls}__justifyBetween`}
              >
                <Button style={style.btn} onClick={_handleSubmit}>
                  Submit OTP
                </Button>
                <Button style={style.btnOutLine}>Cancel</Button>
              </Box>
              <Box></Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </RegistrationLayout>
  );
};
export default PasswordRecoveryOtp;
