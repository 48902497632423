import * as React from "react";
import Checkbox from "@mui/material/Checkbox";
import "./BucketList.css";
import { Button, Box, Grid, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import { useEffect, useState, useContext } from "react";
import cancel from "../../assets/img/cancel_icn.png";
import setting from "../../assets/img/settings_icn.png";
import search from "../../assets/img/search_icn.png";
import component from "../../assets/img/component_55.png";
import component1 from "../../assets/img/component_56.png";
import dot from "../../assets/img/ellipse_icn.png";
import alert from "../../assets/img/alert_icn.png";
import alertred from "../../assets/img/alert_red.png";
// -------------------------------------------------
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import MaterialTable from "material-table";
import SearchBar from "material-ui-search-bar";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import gear from "../../assets/img/gear.png";
import circleRight from "../../assets/img/circleRight@2x.png";
import yellowWarning from "../../assets/img/yellowWarning@2x.png";
import SearchIcon from "@mui/icons-material/Search";
import SettingModel from "./settingModel";
import myContext from "../MyContext/MyContext";

const ShortingTable = ({
  arrayColumns,
  rows,
  tableTitle,
  rowLimitEnd,
  DateTime,
}) => {
  // console.log("esdhfvijygi", datetime)

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // This method is created for cross-browser compatibility, if you don't
  // need to support IE11, you can use Array.prototype.sort() directly
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const headCells = arrayColumns;
  console.log("arrayColumns", arrayColumns);

  function EnhancedTableHead(props) {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      onRequestSort,
    } = props;
    const createSortHandler = (property) => (event) => {
      console.log("property", property, event);
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) =>
            headCell.show ? (
              <TableCell
                key={headCell.id}
                // align={headCell.numeric ? 'right' : 'left'}
                // padding={headCell.disablePadding ? 'none' : 'normal'}
                sortDirection={orderBy === headCell.id ? order : false}
                style={{
                  color: "#fff",
                  borderBottom: "0px solid #fff",
                  fontSize: "22px",
                }}
              >
                <TableSortLabel
                  active={true}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={createSortHandler(headCell.id)}
                  style={{ color: "#fff" }}
                  IconComponent={ArrowDropDownIcon}
                  style={{
                    color: "#fff",
                    borderBottom: "0px solid #fff",
                    fontSize: "22px",
                  }}
                >
                  {headCell.label}
                  {orderBy === headCell.id ? (
                    <Box component="span" sx={visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              </TableCell>
            ) : null
          )}
        </TableRow>
      </TableHead>
    );
  }

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(rowLimitEnd);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationEnd, setPaginationEnd] = useState(rowLimitEnd);
  const [pageCount, setPageCount] = useState(1);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const Previous = () => {
    if (paginationStart >= rowsPerPage) {
      setPaginationStart(paginationStart - rowsPerPage);
      setPaginationEnd(paginationEnd - rowsPerPage);
      setPageCount(pageCount - 1);
    }
  };
  const Next = () => {
    if (paginationEnd <= rows.length) {
      setPaginationStart(paginationStart + rowsPerPage);
      setPaginationEnd(paginationEnd + rowsPerPage);
      setPageCount(pageCount + 1);
    }
  };
  useEffect(() => {
    setPaginationStart(0);
    setPaginationEnd(rowsPerPage);
    setPageCount(1);
  }, [rows]);

  useEffect(() => {
    setRowsPerPage(rowLimitEnd);
    setPaginationEnd(rowLimitEnd);
  }, [rowLimitEnd]);

  console.log("rowLimitEnd", rowLimitEnd);
  console.log("rowsPerPage", rowsPerPage);
  console.log("paginationStart", paginationStart);
  console.log("paginationEnd", paginationEnd);
  console.log("pageCount", pageCount);
  return (
    <>
      <Box sx={{ width: "100%", background: "#0000" }}>
        <Paper sx={{ width: "100%", mb: 2, background: "#0000" }}>
          <TableContainer
            sx={{
              background: "#0000",
              "&::-webkit-scrollbar": {
                width: 3,
                height: 2,
              },
              "&::-webkit-scrollbar-track": {
                backgroundColor: "#0000",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "white",
                borderRadius: 2,
                border: "0px solid #0000",
              },
            }}
          >
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
                {stableSort(rows, getComparator(order, orderBy))
                  .slice(paginationStart, paginationEnd)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.Name);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row.Name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.Name}
                        selected={isItemSelected}
                      >
                        {arrayColumns.map((item, i) => {
                          return (
                            <>
                              {item.show ? (
                                <TableCell
                                  align="left"
                                  style={
                                    item.label === "Instance state"
                                      ? row[item.label] === "available"
                                        ? {
                                          color: "#CE6007",
                                          background: "#010A26",
                                          borderBottom: "0px solid #fff",
                                          fontSize: "18px",
                                          paddingBottom: "0px",
                                        }
                                        : row[item.label] === "stopped"
                                          ? {
                                            color: "red",
                                            background: "#010A26",
                                            borderBottom: "0px solid #fff",
                                            fontSize: "18px",
                                            paddingBottom: "0px",
                                          }
                                          : row[item.label] === "running"
                                            ? {
                                              color: "green",
                                              background: "#010A26",
                                              borderBottom: "0px solid #fff",
                                              fontSize: "18px",
                                              paddingBottom: "0px",
                                            }
                                            : {
                                              color: "#CE6007",
                                              background: "#010A26",
                                              borderBottom: "0px solid #fff",
                                              fontSize: "18px",
                                              paddingBottom: "0px",
                                            }
                                      : item.label === "Access"
                                        ? row[item.label] === "Private"
                                          ? {
                                            color: "#F60001",
                                            background: "#010A26",
                                            borderBottom: "0px solid #fff",
                                            fontSize: "18px",
                                            paddingBottom: "0px",
                                          }
                                          : {
                                            color: "#CE6007",
                                            background: "#010A26",
                                            borderBottom: "0px solid #fff",
                                            fontSize: "18px",
                                            paddingBottom: "0px",
                                          }
                                        : {
                                          color: "#3f455b",
                                          background: "#010A26",
                                          borderBottom: "0px solid #fff",
                                          fontSize: "18px",
                                          paddingBottom: "0px",
                                        }
                                  }
                                >
                                  <img
                                    src={
                                      row[item.label] === "available" &&
                                        item.label === "Instance state"
                                        ? yellowWarning
                                        : row[item.label] === "stopped" &&
                                          item.label === "Instance state"
                                          ? alertred
                                          : row[item.label] === "running" &&
                                            item.label === "Instance state"
                                            ? circleRight
                                            : alert
                                    }
                                    style={
                                      item.label === "Instance state"
                                        ? { marginRight: "5px", width: "15px" }
                                        : { display: "none" }
                                    }
                                    alt=""
                                  />
                                  <img
                                    src={
                                      row[item.label] === "Private"
                                        ? alertred
                                        : alert
                                    }
                                    style={
                                      item.label === "Access"
                                        ? { marginRight: "5px" }
                                        : { display: "none" }
                                    }
                                    alt=""
                                  />
                                  {row[item.label] === true
                                    ? "True"
                                    : row[item.label] === false
                                      ? "False"
                                      : item.label === "Launch Date"
                                        ? DateTime(row[item.label])
                                        : item.label === "Creation Date"
                                          ? DateTime(row[item.label])
                                          : item.label === "Created Date"
                                            ? DateTime(row[item.label])
                                            : row[item.label]}
                                </TableCell>
                              ) : null}
                            </>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              borderTop: "1px solid #666C7C",
              margin: "10px 20px 0px 20px",
            }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Box
                onClick={() => Previous()}
                style={{
                  marginRight: "30px",
                  color: "#666C7C",
                  cursor: "pointer",
                }}
              >
                Previous
              </Box>
              <Box
                style={{
                  marginRight: "30px",
                  color: "#fff",
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  background: "#666C7C",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {pageCount}
              </Box>
              <Box
                onClick={() => Next()}
                style={{
                  marginRight: "30px",
                  color: "#666C7C",
                  cursor: "pointer",
                }}
              >
                Next
              </Box>
            </Box>
          </Box>
        </Paper>
      </Box>
    </>
  );
};

const BucketList = ({ setModel, allChartdata, tableTitle }) => {
  const [modelSetting, setModelSetting] = useState(false);
  const [open, setOpen] = useState(true);
  const handleClose = () => setModel(false);
  const [arrayColumns, setArrayColumns] = useState([]);
  const [rowLimitEnd, setRowLimitEnd] = useState(5);
  const [allColumns, setAllColumns] = useState([]);
  const originalRows = allChartdata?.data;
  const { DateTime } = useContext(myContext);
  // const datetime = DateTime(JSON.parse(localStorage.getItem('user'))?.data?.lastLoginTime);
  // const [datatimeFormat, setDatatimeFormat] = useState(datetime)

  useEffect(() => {
    let array = [];
    tableTitle.map((item, i) => {
      console.log("item", item);
      let number = item == "Creation Date" ? true : false;
      console.log("number", number);
      array[i] = {
        ...array[i],
        label: item,
        field: item,
        id: item,
        numeric: true,
        show: true,
      };
    });
    setArrayColumns(array);
    setAllColumns(array);
  }, []);
  const columns = arrayColumns;

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  });

  const [rows, setRows] = useState(originalRows);
  const [searched, setSearched] = useState("");
  console.log("searched###", searched);
  const classes = useStyles();
  console.log("rows", rows);
  const requestSearch = (searchedVal) => {
    console.log("searchedVal", searchedVal);
    const filteredRows = originalRows.filter((row) => {
      console.log("originalRows", originalRows);
      console.log("originalRows", row.Name);

      return row.Name.toLowerCase().includes(searchedVal.toLowerCase());
    });
    setRows(filteredRows);
  };
  const getopenSetting = () => {
    setModelSetting(true);
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          className="wrapper"
          sx={{
            maxHeight: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          {modelSetting ? (
            <SettingModel
              rows={rows}
              tableTitle={tableTitle}
              setModelSetting={setModelSetting}
              modelSetting={modelSetting}
              arrayColumns={arrayColumns}
              setRowLimitEnd={setRowLimitEnd}
              setArrayColumns={setArrayColumns}
              allColumns={allColumns}
            />
          ) : null}
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              marginTop: "20px",
              cursor: "pointer",
            }}
            onClick={() => {
              handleClose();
            }}
          >
            <img src={cancel} />
          </Box>

          <Box style={{ display: "flex", width: "100%" }}>
            <Box style={{ width: "49%" }}>
              <Box>
                <Typography className="headingpadding">
                  {allChartdata?.label} ({allChartdata?.data?.length})
                </Typography>
              </Box>
            </Box>
            <Box
              style={{ display: "flex", alignItems: "center", width: "43%" }}
            >
              <Box
                style={{
                  display: "flex",
                  backgroundColor: "#272E42",
                  borderRadius: "10px",
                  padding: "0px 16px",
                  flex: 1,
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <img style={{ margin: "10px" }} src={search} />
                </Box>
                <input
                  placeholder={"Search..."}
                  onChange={(e) => requestSearch(e.target.value)}
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "5px",
                    width: "100%",
                    backgroundColor: "transparent",
                    color: "white",
                    fontSize: "20px",
                  }}
                />
              </Box>

              <Box>
                <img
                  style={{
                    marginTop: "5px",
                    paddingLeft: "25px",
                    cursor: "pointer",
                  }}
                  src={setting}
                  onClick={() => getopenSetting()}
                />
              </Box>
            </Box>
          </Box>

          <ShortingTable
            DateTime={DateTime}
            rows={rows}
            tableTitle={tableTitle}
            arrayColumns={arrayColumns}
            rowLimitEnd={rowLimitEnd}
          />
        </Box>
      </Modal>
    </div>
  );
};

export default BucketList;
