import React, { useEffect, useState, useContext } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import sild from "../../../assets/img/sild.png";
import { Button } from "@mui/material";
import IamTaskList from "./components/IamTaskList";
import { Iamdata } from "./Constants";
import { Api } from "../../../MyNewUtils/Api";
import { toast } from "react-toastify";
import MainLoader from "../../../Component/Spinner/MainLoader";
import myContext from "../../MyContext/MyContext";
import { useDispatch } from "react-redux";
import { activateTabIndex } from "../../../redux/action";
const Iam = ({
  iamOpen,
  setIamOpen,
  setAddNewDashboardData,
  slide,
  doneEditing,
  setDoneEditing,
  activateSlider,
  index,
  dashid,
}) => {
  const [dashboardData, setDashboardData] = useState({});
  const [next, setNext] = useState(iamOpen);
  const [done, setDone] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showTaskItem, setShowTaskItem] = useState(false);
  const [dashboard, setDashboard] = useState({
    dashboardName: "",
  });
  const [newdoneData, setNewDoneData] = useState([]);
  const [dashboadFeatureKeys, setDashboadFeatureKeys] = useState([]);
  const inputEvent = (e) => {
    if (e.target.value.length <= 10) {
      setDashboard({ ...dashboard, dashboardName: e.target.value });
    }
  };
  const { cloudDetails } = useContext(myContext);
  let newData = [];
  const dispatch = useDispatch();
  const getdata = async () => {
    setIsLoading(true);
    let payload = {
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
    };
    const data = await Api.getRequest("/dashboard/get-iam-features", payload);
    const res = JSON.parse(data?.data);
    const iamdata = res?.data;
    if (res?.status === 1) {
      iamdata.map((item, index) => {
        newData[index] = {
          ...newData[index],
          id: item?._id,
          name: item?.featureName,
          featureKey: item?.featureKey,
          done: false,
        };
      });
      setDashboardData(newData);
      setIsLoading(false);
      // setShowTaskItem(true)
    } else {
      toast.error(res?.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // console.log("seryhreujtyifsyhrj", activateSlider, index, dashid)
    if (activateSlider == index + 1 && dashid === undefined) {
      getdata();
    }
  }, [activateSlider, index]);

  let FeatureKeys = [{}];

  useEffect(() => {
    if (done.length != undefined) {
      let donedata = done.filter((t) => t.done);
      setNewDoneData(donedata);
    }
  }, [done]);
  useEffect(() => {
    newdoneData.map((item, index) => {
      FeatureKeys[index] = item.featureKey;
      setDashboadFeatureKeys(FeatureKeys);
    });
  }, [newdoneData]);
  useEffect(() => {
    setIamOpen(next);
  }, [next]);

  useEffect(() => {
    // dispatch(activateTabIndex(activateSlider - 1));
  }, []);
  const CreateDashboard = async () => {
    if (dashboard.dashboardName.length === 0) {
      toast.error("Please Enter Dashboard Name", { autoClose: 1000 });
    } else {
      if (newdoneData.length === 0) {
        toast.error("Please Select Features", { autoClose: 1000 });
      } else {
        let param = {
          userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
          dashboadName: dashboard.dashboardName,
          dashboadFeatureKeys: dashboadFeatureKeys,
          account_type: cloudDetails?.selectedCloudServers,
          accountId: cloudDetails?.selectedCloudAccountID,
        };
        console.log("paramaddbrhrtnew", param);
        const data = await Api.postRequest(
          "/dashboard/add-new-dashboard",
          param
        );
        const res = JSON.parse(data?.data);
        console.log("resgvdhfcjgk", res);

        if (res.status === 1) {
          toast.success(res.message, { autoClose: 1000 });
          setAddNewDashboardData(dashboadFeatureKeys);
          // setDoneEditing(false);
          // setIamOpen(2)
        } else {
          toast.error(res.message, { autoClose: 1000 });
        }
      }
    }
  };

  return (
    <>
      <MainLoader isLoading={isLoading} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img src={sild} style={{ width: "5%", height: "5%" }} alt="" />
        <Typography
          style={{
            color: "#FF8800",
            fontSize: "36px",
            fontWeight: "bold",
            marginLeft: "2%",
          }}
        >
          IAM
        </Typography>
      </Box>
      <Box sx={{ marginTop: "2%", padding: "0% 3% 3% 3%" }}>
        <Typography style={{ fontWeight: "Semilight", fontSize: "20px" }}>
          The CLEVRONE App for IAM Analysis and Dashboards to help you stay
          aware of cloud operations, information, and errors.
        </Typography>
        {/* <Typography style={{ marginTop: '1%', color: '#008CF2', fontWeight: 'Semilbold', fontSize: '14px' }}>
                    Preview Dashbaords
                </Typography> */}
        <Box sx={{ marginTop: "1%", display: "flex" }}>
          <input
            onChange={inputEvent}
            value={dashboard?.dashboardName}
            placeholder="Name of Dashboard"
            type="text"
            style={{
              marginLeft: "2%",
              textTransform: "capitalize",
              border: "0  solid #fff",
              borderBottom: "1px  solid #fff",
              background: "#0000",
              padding: "5px",
              color: "#fff",
              fontWeight: "bold",
              fontSize: "20px",
              display: "flex",
              textAlign: "center",
            }}
          />
          <Button
            onClick={() => CreateDashboard()}
            style={{
              textTransform: "capitalize",
              border: "1px solid #008CF2",
              background: "#008CF2",
              padding: "5px 20px 5px 20px",
              borderRadius: "20px",
              color: "#fff",
              fontWeight: "normal",
              fontSize: "20px",
              marginLeft: "10px",
            }}
          >
            Create Dashboard
          </Button>
        </Box>
      </Box>
      <Box sx={{ padding: "4px 0 4px 4px" }}>
        {!isLoading ? (
          <IamTaskList tasks={dashboardData} setDone={setDone} />
        ) : null}
      </Box>
    </>
  );
};
export default Iam;
