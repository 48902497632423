import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import "../cloudAssessment.css";
import { Document, Page, pdfjs } from "react-pdf";
import DashboardHeader from "../../../../Component/DashboardHeader/DashboardHeader";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useLocation } from "react-router-dom";
const LogPdfViewer = ({ }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  const location = useLocation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [nextPageBtn, setNextPageBtn] = useState({
    nextBtn: true,
    backBtn: true,
  });

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }
  const url = "https://cdn.filestackcontent.com/wcrjf9qPTCKXV3hMXDwK";
  return (
    <>
      {/* <MainLoader isLoading={isLoading} /> */}
      <DashboardHeader activeTab={"DASHBOARD"} accountSelectHeaderShow={true} />

      <Box className="EC2Status__background">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CloudDownloadIcon style={{ width: "5%", height: "5%" }} alt="" />
          <Typography
            style={{
              color: "#FF8800",
              fontSize: "36px",
              fontWeight: "bold",
              marginLeft: "2%",
            }}
          >
            Cloud Assessment
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Document
            file={url}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={(e) => console.log("e-----", e)}
            renderAnnotationLayer={false}
            renderForms={true}
            renderTextLayer={false}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          {pageNumber > 1 && (
            <Box sx={{ cursor: "pointer" }} onClick={previousPage}>
              <ArrowLeftIcon />
            </Box>
          )}
          <Box>
            <p>
              Page {pageNumber} of {numPages}
            </p>
          </Box>
          {pageNumber < numPages && (
            <Box sx={{ cursor: "pointer" }} onClick={nextPage}>
              <ArrowRightIcon />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
export default LogPdfViewer;
