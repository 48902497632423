import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import RegistrationLayout from "../../Component/Layout/index";

import exportImg from "../../assets/img/export.png";
import logo from "../../assets/img/logo_header.png";
import wallet from "../../assets/img/wallet.png";
import building from "../../assets/img/building.png";

import briefcase from "../../assets/img/briefcase.png";
import { Api } from "../../MyNewUtils/Api";
import laptop from "../../assets/img/laptop.png";
import { useState, useEffect } from "react";
import SignupStep3Header from "../../Component/upgradePlanDetails/upgradePlanHeader";
import FeturesCard from "../../Component/upgradePlanDetails/Card/FeturesCard";
import CustomButton from "../../Component/CustomButton/index";
import SignupStep3Footer from "../../Component/upgradePlanDetails/upgradePlanFooter";
import "./SignupStepThree.css";
import { map } from "highcharts";

const SignupStepThree = () => {
  const [selectedPlan, setSelectedPlan] = useState({
    planid: "",
    isannualplan: false,
    transactionAmount: "",
    noofusers: 1,
  });

  const [select, setSelect] = useState(true);
  const [plans, setPlans] = useState([]);

  const plandetails = async () => {
    const { statusCode, data } = await Api.getRequest("/plans/get-all-plans");
    const res = JSON.parse(data)["plans"];

    let data1 = res.map((item) => {
      let icon = wallet;
      let price = item.planPricePerMonth;
      let time = "Month";
      if (select == true) {
        price = item.planPricePerMonth;
        time = "Month";
      } else {
        price = item.planPricePerYear;
        time = "Year";
      }

      if (item?.planName == "Basic") {
        icon = laptop;
      }
      if (item?.planName == "Premium") {
        icon = briefcase;
      }
      if (item?.planName == "Enterprise") {
        icon = laptop;
      }
      let rest = {
        name: item?.planName,
        title: `${item?.planName}`,
        price: `${price == "0" ? "Free" : price}`,
        annualplan: `${price == "0" ? "" : "/" + time}`,
        planid: `${item?.id}`,
        fetures: JSON.parse(item?.planHtml),
        icon: icon,
        isenterprise: item?.isenterprise,
      };
      return rest;
    });
    setPlans(data1);
  };

  useEffect(() => {
    plandetails();
  }, [select]);

  return (
    <RegistrationLayout isHeaderShow={false}>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",

          justifyContent: "center",
        }}
      >
        <Box
          style={{
            width: "80%",
            display: "flex",
            // backgroundColor: 'black',
            marginTop: "20px",
            flexDirection: "column",
          }}
        >
          {/* Header */}
          <SignupStep3Header select={select} setSelect={setSelect} />

          {/* Body */}
          <Box
            style={{
              // backgroundColor: 'pink',
              flexGrow: 1,
              display: "flex",
              padding: "20px",
              justifyContent: "space-between",
            }}
            className="plan_box_card"
          >
            <RadioGroup
              name="radio-buttons-group"
              row
              defaultValue="Trial - Free"
            >
              {plans.map(
                (items) =>
                  !items.isenterprise && (
                    <FeturesCard
                      select={select}
                      setSelectedPlan={setSelectedPlan}
                      selectedPlan={selectedPlan}
                      title={items.title}
                      price={items.price}
                      annual={items.annualplan}
                      fetures={items.fetures}
                      planid={items.planid}
                      icon={items.icon}
                      backgroundColor={false}
                    />
                  )
              )}

              <Box
                style={{
                  width: "25%",
                  // backgroundColor: 'red',
                  paddingRight: "20px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  style={{
                    flexGrow: 1,
                    borderRadius: "30px",
                    backgroundColor: "rgb(0,0,0,0.40)",
                    padding: "20px",
                    border: "3px solid #008CF2",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  className="plan_box_card1"
                >
                  <Box
                    style={{
                      marginTop: "20px",
                      flexDirection: "column",
                    }}
                  >
                    <Box style={{ marginLeft: "20px" }}>
                      <Typography
                        style={{ fontSize: "22px" }}
                        className="box_responsive_font"
                      >
                        Need Enterprise level support ?
                      </Typography>
                      <Typography
                        style={{ fontSize: "14px" }}
                        className="box_responsive_font2"
                      >
                        From $ 15,000 a month you will receive 15 - minute
                        response times and concierge-style experience with an
                        assigned Technical Account Manager.{" "}
                        {/* <span style={{ color: '#008CF2' }}>
                        Learn More &nbsp;
                        <img src={exportImg} />
                      </span> */}
                      </Typography>
                    </Box>
                  </Box>

                  <CustomButton
                    text={"Contact Us"}
                    styles={{
                      backgroundColor: "rgb(255,131,0)",
                      color: "#FFF",
                      marginTop: "20px",
                      width: "auto",
                      borderRadius: "2px",
                      padding: "10px 18px",
                    }}
                  />
                  <Box style={{ display: "flex", justifyContent: "center" }}>
                    <img src={building} width="80%" />
                  </Box>
                </Box>
              </Box>
            </RadioGroup>
          </Box>
          {/* Footer */}

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              margin: "20px 0px",
              flexDirection: "column",
            }}
          >
            <SignupStep3Footer selectedPlan={selectedPlan} select={select} />
          </Box>
        </Box>
      </Box>
    </RegistrationLayout>
  );
};
export default SignupStepThree;
