import { useEffect, useMemo, useState } from "react";
import logo from "../../assets/img/logo.png";

export const DefaultDiscover = (props) => {

  const { handleQuentionClick } = props;

  const defaultQuestions = [
    // "What are the current EC2 instance types being used in our instances? share me the name and their instance type.",
    "What are the current EC2 instance types being used in our instances?",
    "Which EC2 instances are currently running?",
    "What is CIDR",
    "Can we keep everything open in the CIRD for financial workloads",
    // "What is the status of the S3 bucket?",
    "Provide the last 5 events for EC2 instances?",
    // "What are the current EC2 instance types available?",
    // "How many EC2 instances are in the 'running' state?",
    // "What is the current billing status for EC2 instances?",
    "List all EC2 instances with their respective tags.",
    // "What is the CPU utilization for the EC2 instance?",
    // "Describe the security groups attached to the EC2 instance.",
    // "What is the current status of RDS instances?",
    // "List all RDS instances along with their endpoint URLs.",
    // "What are the backup retention settings for the RDS instance?",
    "What is the current status of CloudFront distributions?",
    "List all CloudFront distributions along with their domain names.",
    // "What are the edge locations associated with my CloudFront distribution?",
    // "Provide the last 5 CloudWatch events for RDS instances.",
    // "What is the VPC CIDR for VPC?",
    // "List all subnets in VPC with their CIDR blocks.",
    // "What is the internet gateway status for VPC?",
    // "Describe the route tables associated with VPC.",
    // "What are the current settings for my S3 bucket?",
    // "List all objects in S3 bucket.",
    // "What are the lifecycle policies set on S3 bucket?"
  ];

  const getRandomQuestions = (questions, num) => {
    const shuffled = [...questions].sort(() => 0.5 - Math.random());
    return shuffled.slice(0, num);
  };

  const [displayedQuestions, setDisplayedQuestions] = useState([]);

  useEffect(() => {
    setDisplayedQuestions(getRandomQuestions(defaultQuestions, 4));
  }, []);

  return (
    <div className='default-discover-container'>
      <img src={logo} className="header-logo" alt="logo" />
      <div className='question-card'>
        {displayedQuestions.map((question, index) => (
          <div key={index} onClick={() => handleQuentionClick(question)}>{question}</div>
        ))}
      </div>
    </div>
  )
};