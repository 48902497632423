import { useMemo } from "react";
import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Rating from "@mui/material/Rating";
import UploadIcon from "../../../../assets/img/uploadIcon.png";
import exportIcon from "../../../../assets/img/export@2.png";
import ReactFileReader from "react-file-reader";
import { styled } from "@mui/material/styles";
import Radio, { RadioProps } from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import DashboardHeader from "../../../../Component/DashboardHeader/DashboardHeader";
import myContext from "../../../MyContext/MyContext";
import { Api } from "../../../../MyNewUtils/Api";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useLocation } from "react-router";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "./viewCasesDetails.css";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const ViewCasesDetails = () => {
  const location = useLocation();
  const cls = useMemo(() => "SectionPageOne", []);
  const [textdata, setTextData] = useState();
  const [loadConversation, setLoadConversation] = useState();
  const [searchparams] = useSearchParams();
  const activateSlider = useSelector((state) => state.reducer)?.supportData[0];
  const [supportTicketByIdData, setSupportTicketByIdData] = useState();
  const [support, setSupport] = useState(location?.state?.supportData);

  const { cloudDetails, DateTime } = useContext(myContext);
  let navigate = useNavigate();
  const handleChange = (event) => {
    setTextData(event.target.value);
  };
  console.log("location", support);
  const AddMessageToTicket = async () => {
    let payload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      ticketid: support?.id,
      caseid: support?.caseid,
      text: textdata,
    };
    const data = await Api.postRequest(
      "/users/add-msg-to-conversation",
      payload
    );
    const res = JSON.parse(data?.data);

    console.log("res-dataatat", res);
    if (res?.status === 1) {
      setTextData("");
      LoadConversation();
      toast.success(res?.message, { autoClose: 1000 });
    } else {
      toast.error(res?.message, { autoClose: 1000 });
    }
  };
  console.log("search", location.state);

  const getSupportTicketById = async () => {
    let payload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      caseid: support?.caseid,
    };
    const suportdataId = await Api.postRequest(
      "/users/support-ticket-by-id",
      payload
    );
    const res = JSON.parse(suportdataId?.data);
    if (res?.status === 1) {
      setSupportTicketByIdData(res?.data);
    }
  };
  const LoadConversation = async () => {
    let payload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      ticketid: support?.id,
      caseid: support?.caseid,
    };
    const data = await Api.postRequest("/users/load-conversation", payload);
    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      console.log("dataaa", res?.data);
      setLoadConversation(res?.data);
    }
  };

  useEffect(() => {
    LoadConversation();
    getSupportTicketById();
  }, []);
  return (
    <Box className={`${cls}__background`} style={{}}>
      <DashboardHeader
        activeTab={"SETTINGS"}
        activeSettingSubTab={"Supports"}
      />
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box className="SupportCenter_Box ">
          <Box className="d-flex">
            <Typography
              className="SupportCenter_Cases_heading_text text-bold cursor-pointer"
              onClick={() => {
                navigate("/supports");
              }}
            >
              CLEVRONE Support Center
            </Typography>
            <Typography className="SupportCenter_Cases_heading_text margin-left-20px">
              <ArrowForwardIosIcon className="SupportCenter_Cases_heading_text" />
            </Typography>
            <Typography
              className="SupportCenter_Cases_heading_text margin-left-20px cursor-pointer"
              onClick={() => {
                navigate("/allCases");
              }}
            >
              Your support cases
            </Typography>
            <Typography className="SupportCenter_Cases_heading_text margin-left-20px">
              <ArrowForwardIosIcon className="SupportCenter_Cases_heading_text" />
            </Typography>
            <Typography className="SupportCenter_Cases_heading_text margin-left-20px">
              Details
            </Typography>
          </Box>
          <Box className="case-details-box">
            <Box className="TableWithPagination_box_margin_right_left">
              <Box className="d-flex justify-between case_reopen_case_info">
                <Box className="d-flex items-center">
                  <Typography className="SupportCenter_Case_History_heading_text">
                    Case ID {supportTicketByIdData?.["Case ID"]}
                  </Typography>
                  <Typography className="SupportCenter_Info_heading_text margin-left-20px">
                    Info
                  </Typography>
                </Box>
                <Box
                  className="SupportCenter_btn_background_color SupportCenter_btn d-flex items-center margin-left-20px cursor_pointer case_btn_resp"
                  onClick={() => {
                    navigate("/createCase");
                  }}
                >
                  Reopen case
                </Box>
              </Box>
            </Box>
            <Box className="case-details-sub-box">
              <Grid container>
                <Grid xs={12} className="case-details-header-title">
                  Case details
                </Grid>
                <Grid xs={12} className="padding-left-right-15">
                  <Grid container>
                    <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Subject
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {supportTicketByIdData?.Subject ?? "-"}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Status
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {supportTicketByIdData?.Status ?? "-"}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Case ID
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {supportTicketByIdData?.["Case ID"]}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Severity
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {supportTicketByIdData?.Severity ?? "-"}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Created
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {moment(supportTicketByIdData?.Created ?? " ").format(
                            "Do MMM YY"
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Category
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {supportTicketByIdData?.Category ?? "-"}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Case type
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {supportTicketByIdData?.["Case Type"]}
                        </Grid>
                      </Grid>
                    </Grid>
                    {/* <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Case type
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {supportTicketByIdData?.["Case Type"]}
                        </Grid>
                      </Grid>
                    </Grid> */}
                    {/* <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Account
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          {cloudDetails?.selectedCloudAccountID ?? "-"}
                        </Grid>
                      </Grid>
                    </Grid> */}
                    {/* <Grid xs={6} className="case-details-text-grid">
                      <Grid container>
                        <Grid
                          xs={12}
                          className="case-details-sub-text-title opacity-05"
                        >
                          Opened by
                        </Grid>
                        <Grid xs={12} className="case-details-sub-text-title">
                          hpd@gmail.com
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box>
              <Grid container className="case-details-content-box">
                <Grid
                  xs={12}
                  className="case-details-content-header padding-top-20"
                >
                  Correspondence
                </Grid>
                <Box className="reply-box">
                  <Grid container>
                    <Grid className="reply-details">
                      <Grid
                        xs={12}
                        className="reply-text-title reply-font-size"
                      >
                        Reply
                      </Grid>
                      <Grid xs={12} className="reply-do-not-share opacity-05">
                        Do not share any sensitive information in case
                        correspondences,such as credentials, credit cards,
                        signed URLs, or personally identifiable information.
                        Find more information
                      </Grid>
                      <Grid
                        xs={12}
                        className="d-flex reply-here-text reply-link-color items-center"
                      >
                        here
                        <Grid>
                          <img
                            src={exportIcon}
                            alt="exportIcon"
                            className="reply-export-icon"
                          />
                        </Grid>
                      </Grid>
                      <Grid xs={12} className="reply-textarea-filed">
                        <textarea
                          rows={10}
                          cols={165}
                          className="reply-textarea"
                          value={textdata}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid xs={12} className="reply-maximum-char opacity-05">
                        Maximum 6000 characters (6000 remaining)
                      </Grid>
                      {/* <Grid
                        xs={12}
                        className="reply-attachment-file reply-font-size"
                      >
                        Attachments
                      </Grid> */}
                      {/* <Grid xs={12} >
                    <Box className="reply-upload-box">
                      <ReactFileReader
                        fileTypes={[".png", ".jpg", ".jpeg"]}
                        base64={true}
                        handleFiles={handleFiles}
                      >
                        <Box className="reply-upload-button">
                          <img
                            src={UploadIcon}
                            alt=""
                            width={17}
                            style={{ marginRight: "5px" }}
                          />
                          Choose files
                        </Box>
                      </ReactFileReader>
                    </Box>
                  </Grid> */}
                      {/* <Grid
                        xs={12}
                        className="reply-up-to-attachment opacity-05"
                      >
                        Up to 3 attachments, each less than 5MB.
                      </Grid> */}
                      {/* <Grid className="d-flex items-center reply-contact-info">
                        <Grid
                          xs={12}
                          className="reply-contact-method reply-font-size"
                        >
                          Contact methods{" "}
                          <span className="reply-link-color">info</span>
                        </Grid>
                      </Grid>
                      <Box className="reply-contactct-box">
                        <Grid className="d-flex">
                          <Grid xs={12} className="reply-web-text">
                            Web
                          </Grid>
                          <Grid xs={12} className="reply-status-button">
                            <RadioGroup
                              defaultValue="female"
                              className="reply-radio-status"
                            >
                              <FormControlLabel
                                value="female"
                                control={<BpRadio />}
                              />
                            </RadioGroup>
                          </Grid>
                        </Grid>
                        <Grid
                          xs={12}
                          className="reply-via-email-support-center opacity-05"
                        >
                          Via email and Support Center
                        </Grid>
                      </Box> */}
                      <Grid xs={12} className="d-flex">
                        <Grid
                          xs={12}
                          className="reply-cancel-submit-button"
                        ></Grid>
                        <Grid className="d-flex">
                          <Box
                            className="reply-submit-button reply-cancel-button"
                            onClick={AddMessageToTicket}
                          >
                            Submit
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>

                <Grid
                  xs={11}
                  className="d-flex justify-content-end padding-top-20"
                >
                  {/* <Box className="case-details-ratings-details">
                    <Typography>
                      Was this response helpful? Click here to rate:
                    </Typography>
                    <Rating name="read-only" value={2} readOnly />
                  </Box> */}
                </Grid>
                {/* {loadConversation?.map((rows, index) => {
                              return (

                              )}} */}
                {loadConversation?.map((item, index) => {
                  console.log("table", item);
                  return (
                    <>
                      <Grid
                        xs={2}
                        className="case-details-content-sub-header padding-top-60"
                      >
                        CLEVRONE Web Services Mon{" "}
                        {DateTime(item?.createdAt ?? " ")}
                      </Grid>
                      <Grid
                        xs={10}
                        className="d-flex justify-content-end padding-top-60 flex-column submit_data_resp"
                      >
                        <Typography className="case-details-content-description">
                          {item?.text ?? " "}
                        </Typography>
                      </Grid>
                    </>
                  );
                })}
              </Grid>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ViewCasesDetails;
