import {Button} from '@mui/material'

const CustomButton = ({text, styles, onButtonClick}) => {
  return (
    <Button style={styles} onClick={onButtonClick}>
      {text}
    </Button>
  )
}
export default CustomButton
