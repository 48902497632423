import { generateAnswer } from '../../api/discover';
import { DISCOVER_REQUEST, DISCOVER_SUCCESS, DISCOVER_FAILURE, ADD_MESSAGE, RESET_MESSAGE } from '../constants/discoverActionTypes';

export const discoverRequest = () => ({ type: DISCOVER_REQUEST });
export const discoverSuccess = (answer) => ({ type: DISCOVER_SUCCESS, payload: answer });
export const discoverFailure = (error) => ({ type: DISCOVER_FAILURE, payload: error });
export const discoverClear = () => ({ type: DISCOVER_FAILURE, payload: null });

export const discoverAnswer = (question) => {
	return async (dispatch) => {
		dispatch(discoverRequest());
		try {
			const answer = await generateAnswer(question);
			dispatch(discoverSuccess(answer));
		} catch (error) {
			dispatch(discoverFailure(error || "Something went wrong!"));
		}
	};
};

export const addMessageAction = (message) => ({
	type: ADD_MESSAGE,
	payload: message,
});

export const resetMessageAction = () => ({
	type: RESET_MESSAGE,
	payload: [],
});