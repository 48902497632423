export const BASE_URL = "http://localhost:8080";

// USERS API ENDPOINTS
export const Users = {
  REGISTER_USER: "users/register",
  POST_AUTHENTICATE: "users/authenticate",
  POST_TOKEN: "/users/token",
  POST_LOGOUT: "/users/logout",
  GET_TOKEN: "/users",
  GET_ALL_USERS: "users/all",
  VERIFY_EMAIL:"/users/verifyemail",
  FORGOT_PASSWORD:"/users/forgot-password",
  RESET_PASSWORD:"/users/reset-password",
  SEND_MOBILE_VERIFICATION:"/users/send-mobile-verification",
  VERIFY_MOBILE_OTP:"/users/verify-mobile-otp",
  ENABLE_MFA:"/users/enable-second-factor-authentication",
  SEND_MFA_CODE:"/users/send-otp-two-factor",
  VERIFY_MFA_CODE:"/users/verify-two-factor-otp",
  CHANGE_PASSWORD:"/users/change-password",
  CHANGE_MOBILE:"/users/change-mobile",
  CHANGE_EMAIL:"/users/change-email",
  SEND_OTP4CHANGE_MOBILE:"/users/send-otp-for-change-mobile",
  SEND_OTP4CHANGE_EMAIL:"/users/send-otp-for-change-email",
  UPDATE_PROFILE:"/users/update-profile",
  GET_USER_PROFILE:"users/user/"

};
// IAM ACCOUNT API ENDPOINTS
export const IAM_Account = {
  ADD_IAM_ACCOUNT: "accounts/add",
  GET_ALL_IAM_ACCOUNTS: "accounts/all",
};
export const S3_ROUTES = {
  GET_FROM_AWS: "s3/get-from-aws",
  GET_FROM_DB: "s3",
};
export const EC2_ROUTES = {
  GET_FROM_AWS: "ec2/get-from-aws",
  GET_VPC_FROM_AWS: "ec2/get-vpc-from-aws",
  GET_VPC_SG_FROM_AWS: "ec2/getvpcsecgroupfromaws",
  GET_OVERLAPPED_SUBNET_FROM_AWS: "ec2/getoverlappedsubnetfromaws",
  GET_FROM_DB: "ec2",
};
export const IAM_ROLES_ROUTES = {
  GET_FROM_AWS: "iam-role/get-from-aws",
  GET_FROM_DB: "iam-role",
  GET_ALL_ROLLS: "iam-role/get-all-roles",
  GET_NOT_LOGGED_IN_USERS: "iam-role/get-not-logged-in-users",
  GET_NON_MFA_USERS: "iam-role/get-non-mfa-device-users",
  GET_ALL_ADMIN_ROLES: "iam-role/get-all-administrative-roles",
  GET_ALL_EXCESSIVE_ROLES: "iam-role/get-all-excessive-roles",
  GET_PASSWORD_COMPLEXITY: "iam-role/get-password-complexity",
};
export const LAMBDA_ROUTES = {
  GET_FROM_AWS: "lambda/get-from-aws",
  GET_FROM_DB: "lambda",
};
export const RDS_ROUTES = {
  GET_FROM_AWS: "rds/get-from-aws",
  GET_FROM_DB: "rds",
};

export const NETWORK_ROUTES = {
  GET_NETWORK_MAP: "network/generate-map",
  GET_NETWORK_RESOURCE: "network/resource-network",
  GET_NETWORK_PUBLIC_RESOURCE: "network/public-resource-network",
};
export const DASHBOARD_ROUTES = {
  GET_DASHBOARD: "dashboard",
  GET_DASHBOARD_RECO_VPC_SEC:"dashboard/vpc-secgrp-subnet-recommendation"

};

