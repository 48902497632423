import { Button, Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/img/CO5_FINAL.png'
import RegistrationLayout from '../../Component/Layout/index'
import './VerifyNumber.css'
import { style } from './style'
const VerifyNumber = () => {
  const cls = useMemo(() => 'verifyNumber', [])
  const [isShowError, setIsShowError] = useState(false)

  return (
    <RegistrationLayout>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} className={`${cls}__gridItem`}>
            <img src={logo} alt="logo" className="img-fluid signup_logo" />
          </Grid>
          <Grid item xs={6} className={`${cls}__gridContentItem`}>
            <Box className={`${cls}__gridContentBox`}>
              <Box>
                <Typography style={style.contentTitle}>
                  Verify your number
                </Typography>
              </Box>
              <Box>
                <Typography style={style.contentDesc}>
                  <p>We sent a text message to</p>
                  <p> +91 00X XXX X000 with your verification code.</p>
                </Typography>
              </Box>
              <Box className={`${cls}__resentSms `}>
                <Box>
                  <Typography style={style.otpText}>
                    Didn't receive OTP?
                  </Typography>
                </Box>
                <Box>
                  <Link to="/" className={`${cls}__resentLink `}>
                    <a>Resend</a>
                  </Link>
                </Box>
              </Box>
              <Box className={`${cls}__d-flex`}>
                <Box>
                  <label for="verfication" className={`${cls}__label`}>
                    Verification code
                  </label>

                  <input
                    type="password"
                    name="verfication"
                    className={`${cls}__input`}
                  />
                </Box>
              </Box>

              <Box className={`${cls}__margin50 ${cls}__width350`}>
                <Button style={style.btn}>SIGN IN</Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </RegistrationLayout>
  )
}
export default VerifyNumber
