import "./aceessanalysisS3buckets.css";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import refreshicn from "../../../assets/img/refresh_icn.png";
import cancel from "../../../assets/img/cancel_icn.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DashboardHeader from "../../../Component/DashboardHeader/DashboardHeader";
import { useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { Api } from "../../../MyNewUtils/Api";
import MainLoader from "../../../Component/Spinner/MainLoader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import AccountIdList from "../../CloudInfo/AccountIdList";
import AwsServicesList from "../../CloudInfo/AwsServicesList";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import myContext from "../../MyContext/MyContext";
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    tableTitle,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow sx={{ border: "0px" }}>
        {tableTitle?.map((item) => (
          <TableCell
            key={item}
            // align={headCell.numeric ? 'right' : 'left'}
            padding={"none"}
            sortDirection={orderBy === item ? order : false}
            style={{
              color: "#fff",
              border: "0px",
              textAlign: "left",
              fontSize: "22px",
              textTransform: "capitalize",
            }}
            className="acc_analysis_s3bucket_table_font"
          >
            {item}
            <TableSortLabel
              active={true}
              direction={orderBy === item ? order : "asc"}
              onClick={createSortHandler(item)}
              style={{ color: "#fff" }}
              IconComponent={ArrowDropDownIcon}
              className="acc_table_sort_label_icon"
            ></TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const AccessAnalysisS3Bucket = () => {
  const location = useLocation();
  const [accountID, setAccountID] = useState("123456789");
  const [regionType, setRegionType] = useState("ap-south-1");
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [allData, setAllData] = useState([]);
  const [defaultValue, setDefaultValue] = useState(null);
  const [tabledata, setTableData] = useState(null);
  const [tableTitle, setTableTitle] = useState([]);
  const [dense, setDense] = useState(false);
  const [selected, setSelected] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Groups");
  const [title, setTitle] = useState(location?.state?.title);
  const [key, setKey] = useState(location?.state?.key?.split());
  const [RadioBttonValue, setRadioBttonValue] = useState("");
  const [accountIdListmodel, setAccountIdListModel] = useState(false);
  const [A_id_List, setA_id_List] = useState([]);
  const [model2, setModel2] = useState(false);
  const [UserDataError, setUserDataError] = useState(false);

  const R_Type = location?.state?.r_type;
  const AllTitle = location?.state?.AllTitle;
  const { cloudDetails } = useContext(myContext);
  const getData = async () => {
    setIsLoading(true);
    let final = {
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
      region: JSON.stringify(cloudDetails?.selectedCloudRegionsID),
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      filter: key,
    };
    if (R_Type === "ec2") {
      console.log("WQGWEGHWEH", location?.state?.r_type);
      const { data } = await Api.postRequest(
        "/ec2/get-all-ec2-instances",
        final
      );
      const res = JSON.parse(data);
      console.log("WQGWEGHWEHsfsg", JSON.parse(data));
      if (res?.status === 1) {
        if (res?.data?.length != 0 && res?.data != null) {
          setTableData(res?.data);
          setTableTitle(Object.keys(res?.data[0]));
          setIsLoading(false);
          setUserDataError(false);
        } else {
          setTableData(null);
          setUserDataError(true);
          setIsLoading(false);
        }
      } else {
        toast.error(res?.message, { autoClose: 1000 });
      }
    } else if (R_Type === "rds") {
      const { data } = await Api.postRequest(
        "/rds/get-all-rds-instances",
        final
      );
      const res = JSON.parse(data);
      if (res?.status === 1) {
        if (res?.data?.length != 0 && res?.data != null) {
          setTableData(res?.data);
          setTableTitle(Object.keys(res?.data[0]));
          setIsLoading(false);
          setUserDataError(false);
        } else {
          setTableData(null);
          setUserDataError(true);
          setIsLoading(false);
        }
      } else {
        toast.error(res?.message, { autoClose: 1000 });
      }
    } else if (R_Type === "s3") {
      const { data } = await Api.postRequest("/s3/get-all-buckets", final);
      const res = JSON.parse(data);
      console.log(
        "res?.data?.data?.finaldata?.data",
        res?.data?.data?.finaldata[0]?.data
      );
      if (res?.status === 1) {
        if (
          res?.data?.data?.finaldata[0]?.data?.length != 0 &&
          res?.data?.data?.finaldata[0]?.data != null
        ) {
          setTableData(res?.data?.data?.finaldata[0]?.data);
          setTableTitle(Object.keys(res?.data?.data?.finaldata[0]?.data[0]));
          setIsLoading(false);
          setUserDataError(false);
        } else {
          setTableData(null);
          setUserDataError(true);
          setIsLoading(false);
        }
      } else {
        toast.error(res?.message, { autoClose: 1000 });
        setIsLoading(false);
      }
    }
  };
  useEffect(() => {
    let AllDataArray = [];
    AllTitle?.data?.map((item, index) => {
      if (item?.title === title) {
        AllDataArray[AllDataArray.length] = {
          ...AllDataArray[AllDataArray.length],
          item: item,
          selected: true,
        };
      } else {
        AllDataArray[AllDataArray.length] = {
          ...AllDataArray[AllDataArray.length],
          item: item,
          selected: false,
        };
      }
    });
    setAllData(AllDataArray);
  }, []);
  useEffect(() => {
    if (
      cloudDetails?.selectedCloudAccountID !== undefined &&
      cloudDetails?.selectedCloudAccountID.length !== 0
    )
      getData();
  }, [
    key,
    refresh,
    cloudDetails?.selectedCloudServers,
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudRegionsID,
  ]);

  const isSelected = (UserName) => selected.indexOf(UserName) !== -1;

  const handleClick = (event, UserName) => {
    const selectedIndex = selected.indexOf(UserName);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, UserName);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected?.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected?.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected?.slice(0, selectedIndex),
        selected?.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tabledata?.map((n) => n?.UserName);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };
  const handleChange = (event) => {
    let AllDataArray = [];

    allData?.map((item, index) => {
      if (item?.item?.title === event.target.value) {
        AllDataArray[AllDataArray.length] = {
          ...AllDataArray[AllDataArray.length],
          item: item?.item,
          selected: true,
        };
      } else {
        AllDataArray[AllDataArray.length] = {
          ...AllDataArray[AllDataArray.length],
          item: item?.item,
          selected: item?.selected,
        };
      }
    });
    setAllData(AllDataArray);
    let keyData = [];
    AllDataArray?.map((item, index) => {
      if (item?.selected) {
        keyData[keyData.length] = item?.item?.key;
      }
    });
    setKey(keyData);
  };
  const removeItem = (item) => {
    let AllDataArray = [];
    let event = item;
    allData?.map((item, index) => {
      if (item?.item?.title === event?.item?.title) {
        AllDataArray[AllDataArray.length] = {
          ...AllDataArray[AllDataArray.length],
          item: item?.item,
          selected: false,
        };
      } else {
        AllDataArray[AllDataArray.length] = {
          ...AllDataArray[AllDataArray.length],
          item: item?.item,
          selected: item?.selected,
        };
      }
    });
    setAllData(AllDataArray);
    let keyData = [];
    AllDataArray?.map((item, index) => {
      if (item?.selected) {
        keyData[keyData.length] = item?.item?.key;
      }
    });
    setKey(keyData);
  };

  console.log("safasgfsfdyh", tabledata?.length);
  return (
    <>
      <MainLoader isLoading={isLoading} />

      {accountIdListmodel ? (
        <AccountIdList
          A_id_List={A_id_List}
          setAccountIdListModel={setAccountIdListModel}
          setRadioBttonValue={setRadioBttonValue}
        />
      ) : null}

      {model2 ? <AwsServicesList setModel2={setModel2} /> : null}

      <DashboardHeader
        activeTab={"ACCESS ANALYSIS"}
        accountSelectHeaderShow={true}
        showRegionPart={true}
      />

      <Box className="AceessanalysisS3__background">
        <Box
          sx={{
            backgroundColor: "rgb(1,4,22)",
            padding: "19px 0 19px 108px",
            width: "100%",
          }}
        >
          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Link to="/access-analysis">
                <Typography style={{ fontSize: "20", fontWeight: "bold" }}>
                  Access Analysis
                </Typography>
              </Link>
              <Typography style={{ fontSize: "20", fontWeight: "bold" }}>
                <ChevronRightIcon />
              </Typography>
              <Typography style={{ fontSize: "20", fontWeight: "bold" }}>
                {R_Type.toUpperCase()} {R_Type === "s3" ? "Buckets" : null}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "row" }}></Box>
          </Grid>
        </Box>

        <Grid container>
          <Grid item md={12} className="AceessanalysisS3leftbox">
            <Box
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                margin: "50px 50px 50px 50px",
                padding: "46px 51px 52px 57px ",
                borderRadius: "20px",
              }}
            >
              <Grid style={{ display: "flex", flexDirection: "row" }}>
                <Grid
                  md="11"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "left",
                    color: "#FF8800",
                    fontSize: "25px",
                    fontWeight: "bold",
                  }}
                >
                  {R_Type.toUpperCase()} {R_Type === "s3" ? "Buckets" : null}{" "}
                  {tabledata?.length != undefined
                    ? "(" + tabledata?.length + ")"
                    : null}
                </Grid>
                <Grid
                  md="1"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "right",
                    justifyContent: "flex-end",
                    cursor: "pointer",
                  }}
                  className="s3_bucket_reficon"
                  onClick={() => setRefresh(!refresh)}
                >
                  <img src={refreshicn} className="s3_refresh_img" />
                </Grid>
              </Grid>

              <Grid>
                <Box
                  className="s2bucket_filter_sections"
                >
                  <FilterAltIcon style={{ color: "rgb(143, 143, 143)" }} />
                  {/* <Box> */}
                  {allData?.map((item, index) => {
                    if (item?.selected) {
                      return (
                        <Box
                          sx={{
                            margin: "0 5px 0 5px",
                            background: "#8A8E99",
                            letterSpacing: "1px",
                            color: "#fff",
                            borderRadius: "10px",
                            padding: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "auto",
                          }}
                          className="acc_filter_label_title"
                        >
                          {item?.item?.title}
                          <Box
                            style={{
                              marginLeft: "5px",
                              display: "flex",
                              alignItems: "center",
                              cursor: "pointer",
                            }}
                            onClick={() => removeItem(item)}
                          >
                            <img src={cancel} width={18} height={18} />
                          </Box>
                        </Box>
                      );
                    }
                  })}
                  {/* </Box> */}
                  {/* <input type="Text" placeholder='Add Filter criteria' style={{ height: '100%', background: '#0000', border: '0px solid #fff', marginLeft: '5px', fontSize: '20px', color: '#fff' }}></input> */}
                  <select
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{
                      "aria-label": "Without label",
                      style: {
                        padding: "0px",
                        margin: "0px",
                        background: "red",
                        border: "1px solid #000 !important",
                      },
                    }}
                    InputProps={{
                      "aria-label": "Without label",
                      style: {
                        padding: "0px",
                        margin: "0px",
                        background: "red",
                        border: "1px solid #000 !important",
                      },
                    }}
                    style={{
                      border: "0px solid #0000",
                      backgroundColor: "#272E42",
                      width: "170px",
                      color: "#7C84AA",
                      fontSize: "16px",
                      "&:before": {
                        borderColor: "red",
                      },
                      "&:after": {
                        borderColor: "blue",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "red",
                      },
                    }}
                    className="acc_ana_s3bucket_select"
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          background: "#010A26",
                        },
                      },
                    }}
                    IconComponent={KeyboardArrowDownIcon}
                    defaultValue="Add Filter criteria"
                    label="Add Filter criteria"
                  >
                    <option
                      select
                      style={{ color: "#fff", background: "#010A26" }}
                    >
                      Add Filter criteria
                    </option>
                    {allData?.map((item, index) => {
                      if (!item?.selected) {
                        return (
                          <option
                            value={item?.item?.title}
                            style={{ color: "#fff", background: "#010A26" }}
                          >
                            {item?.item?.title}
                          </option>
                        );
                      }
                    })}
                  </select>
                </Box>
              </Grid>
              {UserDataError ? (
                <Typography
                  variant="h5"
                  sx={{
                    margin: "70px",
                  }}
                  className="no_data_text_box"
                >
                  No Data Available
                </Typography>
              ) : (
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                  >
                    <EnhancedTableHead
                      numSelected={selected?.length}
                      order={order}
                      orderBy={orderBy}
                      onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={tabledata?.length}
                      tableTitle={tableTitle}
                    />

                    <TableBody>
                      {stableSort(
                        tabledata,
                        getComparator(order, orderBy)
                      )?.map((row, index) => {
                        const isItemSelected = isSelected(row?.InstanceId);
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                          <TableRow
                            className="AceessanalysisS3leftboxtext"
                            onClick={(event) =>
                              handleClick(event, row?.InstanceId)
                            }
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row?.InstanceId}
                            selected={isItemSelected}
                          >
                            {tableTitle?.map((item, i) => {
                              return (
                                <>
                                  <TableCell
                                    sx={{
                                      padding: "10px 0px",
                                      color: "rgb(255,255,255,0.4)",
                                      textAlign: "left",
                                      border: "0px solid #0000",
                                    }}
                                  >
                                    {/* {i === 0 ? <Checkbox
                                                                            color="primary"
                                                                            checked={isItemSelected}
                                                                            inputProps={{
                                                                                'aria-labelledby': labelId,
                                                                            }}
                                                                        /> : null} */}
                                    {row[item]}
                                  </TableCell>
                                </>
                              );
                            })}
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default AccessAnalysisS3Bucket;
