import { Typography } from '@mui/material'
import { Box } from '@mui/system'
// import building from '../../assets/img/building.png'
import building from '../../assets/img/building.png'
import exportImg from '../../assets/img/export.png'
import CustomButton from "../CustomButton/index";
import { useNavigate } from 'react-router-dom'
import { set } from 'reactjs-captcha/dist/captcha-settings'
import { useState, useEffect } from 'react'
import { Api } from '../../MyNewUtils/Api'
import { toast } from 'react-toastify'
import { path } from '../../MyNewUtils/screenPath'
const SignupStep3Footer = ({ selectedPlan, select }) => {
  const [isSendData, setIsSendData] = useState(true)
  const navigate = useNavigate()
  const onSubmit = async () => {
    console.log("sd", JSON.stringify(selectedPlan))
    if (selectedPlan.planid) {
      let final = { id: JSON.parse(localStorage.getItem('user')).data.id, step: '5', data: JSON.stringify(selectedPlan) }
      console.log("final", final)
      const { statusCode, data } = await Api.postRequest('/users/user-account-setup', final)
      const res = JSON.parse(data)
      toast.success(res.message, { autoClose: 1000 })
      navigate(path.step7)
    } else {
      toast.error("Select Plan", { autoClose: 1000 })
    }
  }
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '60%',
        alignItems: 'center',
      }}
    >
      {/* <Box style={{ display: 'flex' }}>
        <Box>
          <img src={building} />
        </Box>
        <Box style={{ marginLeft: '20px' }}>
          <Typography style={{ fontSize: '22px' }}>
            Need Enterprise level support ?
          </Typography>
          <Typography style={{ fontSize: '14px' }}>
            From $ 15,000 a month you will receive 15 - minute response times
            and concierge-style experience with an assigned Technical Account
            Manager.{' '}
            <span style={{ color: '#008CF2' }}>
              Learn More &nbsp;
              <img src={exportImg} />
            </span>
          </Typography>
        </Box>
      </Box> */}
      <CustomButton
        text={'Complete sign up'}
        styles={{
          backgroundColor: 'rgb(255,131,0)',
          color: '#FFF',
          marginTop: '20px',
          width: 'auto',
          borderRadius: '2px',
          padding: '10px 18px',
        }}
        onButtonClick={onSubmit}
      />
    </Box>
  )
}
export default SignupStep3Footer
