import { Box } from "@mui/system";
import { useMemo, useState } from "react";
import DashboardSettingRolesBody from "../../Component/Dashboard2/DashboardSettingRolesBody";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import DashBoardSettingHeader from "../../Component/DashboardHeader/DashBoardSettingHeader";
import DashBoardSctionPage1Body from "../../Component/selectCloudInfrastructureDetails/index";
import DashBoardSctionPage2Body from "../../Component/SetupSourceDetails/index";
import DashboardSettingPasswordPolicyBody from "../../Component/DashboardSettingPasswordPolicyBody/index";
import Header from "../dashboard/Header/header";
import "./style.css";
import MainLoader from "../../Component/Spinner/MainLoader";
const SectionPageTwo = () => {
  const [isLoading, setIsLoading] = useState(false);

  const cls = useMemo(() => "SectionPageTwo", []);
  return (
    <Box className={`${cls}__background`} style={{}}>
      <MainLoader isLoading={isLoading} />

      {/* <Header/> */}
      <DashboardHeader
        activeTab={"SETTINGS"}
        activeSettingSubTab={"Cloud Setup Wizard"}
      />
      {/* Setting inner header*/}

      <DashBoardSctionPage2Body setIsLoading={setIsLoading} />
    </Box>
  );
};
export default SectionPageTwo;
