import {
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'

import CustomButton from '../../Component/CustomButton/index'
import RegistrationLayout from '../../Component/Layout/index'

import SignupStep2Col1 from '../../Component/SignupForCleveronDetails/Logo'
import SignupStep2Col2 from '../../Component/SignupForCleveronDetails/FreeTierOffice'
import SignupStep2Col3 from '../../Component/SignupForCleveronDetails/SignupForClevron'
const SignupStepTwo = () => {
  return (
    <RegistrationLayout isHeaderShow={false}>
      <Box style={{ flexGrow: 1, display: 'flex' }}>
        <Box
          style={{
            width: '35%',

            display: 'flex',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <SignupStep2Col1 />
        </Box>
        <Box
          style={{
            // flexGrow: 4,
            width: '25%',
            // backgroundColor: 'green',
            display: 'flex',
            alignItems: 'center',
            padding: '25px',
          }}
        >
          <SignupStep2Col2 />
        </Box>
        <Box
          style={{
            flexGrow: 1,
            display: 'flex',
            alignItems: 'center',
            padding: '20px',
          }}
        >
          <SignupStep2Col3 />
        </Box>
      </Box>
    </RegistrationLayout>
  )
}
export default SignupStepTwo
