import { config } from "./config";
import { getToken } from "./localstorage";
import axios from "axios";
const getRequest = async (path) => {
  try {
    const params = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
    const res = await fetch(config.baseURL + path, params);
    const data = await res.text();
    if (
      JSON.parse(data).message === "Invalid token" ||
      JSON.parse(data).message === "Token expired"
    ) {
      window.location.href = "/";
    }
    return { statusCode: res.status, data };
  } catch (e) {
    console.log(`error in get Request (${path}) :- `, e);
  }
};

const postRequest = async (path, body) => {
  try {
    const params = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify(body),
    };

    const res = await fetch(config.baseURL + path, params);
    const data = await res.text();
    if (
      JSON.parse(data).message === "Invalid token" ||
      JSON.parse(data).message === "Token expired"
    ) {
      localStorage.removeItem("user");
      localStorage.removeItem("userData");
      window.location.href = "/";
    }

    return { statusCode: res.status, data };
  } catch (e) {
    console.log(`error in post Request (${path}) :- `, e);
    const data = null;
    return { statusCode: null, data };
  }
};

const DeleteRequest = async (path) => {
  try {
    const params = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
    };

    const res = await fetch(config.baseURL + path, params);

    const data = await res.text();
    if (
      JSON.parse(data).message === "Invalid token" ||
      JSON.parse(data).message === "Token expired"
    ) {
      localStorage.removeItem("user");
      localStorage.removeItem("userData");
      window.location.href = "/";
    }
    return { statusCode: res.status, data };
  } catch (e) {
    console.log(`error in Delete Request (${path}) :- `, e);
  }
};

const putRequest = async (path, body) => {
  try {
    const params = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + getToken(),
      },
      body: JSON.stringify(body),
    };

    const res = await fetch(config.baseURL + path, params);

    const data = await res.text();
    if (
      JSON.parse(data).message === "Invalid token" ||
      JSON.parse(data).message === "Token expired"
    ) {
      localStorage.removeItem("user");
      localStorage.removeItem("userData");
      window.location.href = "/";
    }
    return { statusCode: res.status, data };
  } catch (e) {
    console.log(`error in PUT Request (${path}) :- `, e);
  }
};

const axiospostRequest = async (path, body) => {
  const res = await axios({
    // Endpoint to send files
    url: config.baseURL + path,
    method: "POST",
    headers: {
      // Add any auth token here
      authorization: "Bearer " + getToken(),
    },

    // Attaching the form data
    data: body,
  });
  // if (
  //   JSON.parse(res?.data).message === "Invalid token" ||
  //   JSON.parse(res?.data).message === "Token expired"
  // ) {
  //   localStorage.removeItem("user");
  //   localStorage.removeItem("userData");
  //   window.location.href = "/";
  // }
  return res?.data;
};

const customRequest = async (uri, method, header, body) => {
  try {
    const params = {
      method: method,
      headers: header,
      body: JSON.stringify(body),
    };

    const res = await fetch(uri, params);

    const data = await res.text();
    // console.log({data})
    if (JSON.parse(data).message === "Invalid token") {
      localStorage.removeItem("user");
      localStorage.removeItem("userData");
      window.location.href = "/";
    }
    return { statusCode: res.status, data };
  } catch (e) {
    console.log(`error in post Request (${uri}) :- `, e);
    const data = null;
    return { statusCode: null, data };
  }
};

export const Api = {
  getRequest,
  postRequest,
  DeleteRequest,
  putRequest,
  axiospostRequest,
  customRequest,
};
