import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";

import logo from "../../assets/img/logo_header.png";
import home from "../../assets/img/home.png";
import globe from "../../assets/img/globe.png";
import lock from "../../assets/img/lock_lock.png";
import cloudComputing from "../../assets/img/cloud-computing.png";
import settings from "../../assets/img/settings_icn.png";
import oUser from "../../assets/img/319946.webp";
import money from "../../assets/img/money.png";

import notification from "../../assets/img/notification.png";
import { useEffect, useMemo, useState } from "react";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import DashBoardSettingHeader from "../../Component/DashboardHeader/DashBoardSettingHeader";
import { styled, alpha } from "@mui/material/styles";

import InputBase from "@mui/material/InputBase";

import "./style.css";
import { DataGrid } from "@mui/x-data-grid";
import DashBoardTable from "../../Component/DashBoardTable/DashBoardTable";
import DashboardSettingUserBody from "../../Component/DashboardSettingUserBody/DashboardSettingUserBody";
import DashboardAnalysisOneHeader from "../../Component/DashboardAnalysisHeader/index";
import DashboardAnalisisOneBody from "../../Component/SecurityAnalysisDetails/SecurityAnalysisDropDown";
import NetworkMapBody from "../../Component/NetworkMapBody/NetworkMapBody";
import NewChartModel from "../../Component/NetworkMapBody/NewChartModel";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import mapInit from "highcharts/modules/map";
import MainLoader from "../../Component/Spinner/MainLoader";
import { Api } from "../../MyNewUtils/Api";
import { useLocation } from "react-router-dom";
require("highcharts/modules/map")(Highcharts);
require("highcharts/modules/networkgraph")(Highcharts);
require("highcharts/modules/exporting")(Highcharts);
const NetWorkMapTwo = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [public_rows, setPublicRows] = useState([]);
  const [private_rows, setPrivateRows] = useState([]);
  const [selectedItem, setSelectedItem] = useState(
    useLocation().state.selectedItem
  );
  const [networkGraphOptions, setNetworkGraphOptions] = useState({
    chart: {
      type: "networkgraph",
      height: "58%",
      backgroundColor: "#0000",
    },
    title: {
      text: "The Indo-European Language Tree",
      style: { color: "white" },
    },
    subtitle: {
      text: "A Force-Directed Network Graph in Highcharts",
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          color: "red",
        },
      },
    },
    series: [
      {
        dataLabels: {
          enabled: true,
          color: "red",
          fillColor: "red",
          linkFormat: "",
        },
        id: "lang-tree",
        data: [],
      },
    ],
    exporting: {
      buttons: {
        contextButton: {
          enabled: false,
        },
        exportButton: {
          text: "Download",
          // Use only the download related menu items from the default
          // context button
          menuItems: ["downloadPNG"],
        },
        printButton: {
          text: "Print",
          onclick: function () {
            this.print();
          },
        },
      },
    },
  });

  const mapData = async () => {
    setIsLoading(true);
    const final = { regionName: selectedItem.name };
    const { networkdata, data } = await Api.postRequest(
      "/network/world-network-map",
      final
    );
    const res = JSON.parse(data);
    // setMapnetworkdata(res)res["networkmapdata"]
    const series = [
      {
        dataLabels: {
          enabled: true,
          linkFormat: "",
        },
        id: "lang-tree",
        data: res["networkmapdata"],
      },
    ];
    setNetworkGraphOptions({ ...networkGraphOptions, series: series });
    setIsLoading(false);
  };
  useEffect(() => {
    mapData();
  }, []);
  const cls = useMemo(() => "NetWorkMapTwo", []);

  return (
    <Box className={`${cls}__background`}>
      <MainLoader isLoading={isLoading} />
      {/* Header */}
      {/* <DashboardHeader activeTab={'NETWORK MAP'} /> */}
      <Box style={{ flexGrow: 1, width: "90%" }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={networkGraphOptions}
        />
      </Box>
    </Box>
  );
};
export default NetWorkMapTwo;
// 2,7,41
