import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "../CustomButton/index";
import exportImg from "../../assets/img/export.png";
import visa from "../../assets/img/visa.png";
import master from "../../assets/img/master.png";
import express from "../../assets/img/express.png";
import discovry from "../../assets/img/dicovery.png";
import { useContext, useState } from "react";
import myContext from "../../screens/MyContext/MyContext";
import { useNavigate } from "react-router-dom";
import { path } from "../../MyNewUtils/screenPath";
import MainLoader from "../Spinner/MainLoader";
import { Api } from "../../MyNewUtils/Api";
import { toast } from "react-toastify";
const SignupStep7Col3 = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [numberOtpError, setNumberOtpError] = useState(false);
  const { userId, myFunc } = useContext(myContext);

  const [signupData, setSignupData] = useState({
    id: JSON.parse(localStorage.getItem("user")).data.id,
    step: "4",
    mobileOtp: null,
  });
  const onMobileOtpSet = (e) => {
    setSignupData({ ...signupData, mobileOtp: e.target.value });
  };
  const Back = () => {
    navigate(path.step4);
  };
  const onSubmit = async (e) => {
    if (signupData.mobileOtp != null && signupData.mobileOtp.length != null) {
      setNumberOtpError(false);
      setIsLoading(true);
      let params = {
        mobileOtp: signupData.mobileOtp,
        countryCode: JSON.parse(localStorage.getItem("user")).data.countryCode,
        phone: JSON.parse(localStorage.getItem("user")).data.phone,
      };
      let final = {
        id: signupData.id,
        step: "4",
        data: JSON.stringify(params),
      };
      const { statusCode, data } = await Api.postRequest(
        "/users/user-account-setup",
        final
      );
      const res = JSON.parse(data);
      if (data) {
        setIsLoading(false);
        console.log("data", final);
        if (res.status == 1) {
          console.log("data", res);
          toast.success(res.message, { autoClose: 1000 });
          navigate(path.step6);
        } else {
          console.log("data", res);
          toast.error(res.message, { autoClose: 1000 });
        }
      } else {
        setIsLoading(false);
        toast.error("Some error occured", { autoClose: 1000 });
      }
      setIsLoading(false);
    } else {
      setNumberOtpError(true);
    }
  };
  const navigate = useNavigate();

  const resendCode = async () => {
    const userData = JSON.parse(localStorage.getItem("userData"));
    let params = {
      userid: userData?.id,
      phone: `${userData?.countryCode}${userData?.phone}`,
    };

    const { statusCode, data } = await Api.postRequest(
      "/users/send-mobile-verification",
      params
    );
    const res = JSON.parse(data);

    if (res?.status === 1) {
      toast.success(res?.message, { autoClose: 1000 });
    } else if (res?.status === 0) {
      toast.error(res?.message, { autoClose: 1000 });
    } else {
      toast.error("validation error", { autoClose: 1000 });
    }
  };

  return (
    <Box
      style={{
        width: "90%",
        border: "1px solid gray",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderRadius: "30px",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <MainLoader isLoading={isLoading} />
      <Box style={{ margin: "20px" }}>
        <Typography style={{ color: "#FF8800", fontSize: "36px" }}>
          Sign up for Clevrone
        </Typography>
        <Typography style={{ fontSize: "22px", marginTop: "20px" }}>
          Confirm your identity
        </Typography>
        <Box style={{ marginTop: "20px" }}>
          <lable
            style={{
              display: "block",
              fontSize: "18px",
              color: "#008CF2",
            }}
          >
            Verify code
          </lable>
          <Box style={{ display: "flex" }}>
            <input
              style={{
                width: "60%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              onChange={onMobileOtpSet}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />

            <Typography
              style={{
                fontSize: "16px",
                color: "#008CF2",
                flexGrow: 1,
                textAlign: "right",
                cursor: "pointer",
              }}
              onClick={resendCode}
            >
              Resend the code
            </Typography>
          </Box>
          <Typography
            style={
              numberOtpError == false
                ? { display: "none" }
                : { color: "red", fontSize: "10px" }
            }
          >
            Enter valid OTP
          </Typography>
        </Box>

        <Box style={{ marginTop: "10px" }}>
          <CustomButton
            text={"Verify and Continue (Step 5 of 5)"}
            styles={{
              backgroundColor: "rgb(255,131,0)",
              color: "#FFF",
              marginTop: "20px",
              width: "100%",
              borderRadius: "2px",
              padding: "10px 18px",
            }}
            onButtonClick={onSubmit}
          />
        </Box>
        <Box style={{ marginTop: "30px" }}>
          <Typography>
            Having trouble? Sometimes it takes up to 10 minutes to retrieve a
            verification code. if it's been longer than that,{" "}
            <span
              style={{ color: "#008CF2", cursor: "pointer" }}
              onClick={Back}
            >
              return to previous page
            </span>{" "}
            and try again.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default SignupStep7Col3;
