import {Typography} from '@mui/material'
import {Box} from '@mui/system'
import icon1 from '../../assets/img/Component99.png'

import icon2 from '../../assets/img/Component100.png'

import icon3 from '../../assets/img/Component101.png'
const SignupStep2Col2 = () => {
  return (
    <Box
      style={{
        border: '1px solid grey',
        padding: '20px 30px',
        borderRadius: '30px',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <Typography
        style={{
          color: '#008CF2',
          fontSize: '20px',
          fontWeight: '600',
        }}
      >
        Free Tier Offers
      </Typography>
      <Typography style={{fontStyle: '14px', marginTop: '20px'}}>
        All Clevrone accounts can explore 3 different types of free offers,
        depending on the product used.
      </Typography>

      <Box style={{display: 'flex', marginTop: '10px'}}>
        <Box>
          <img src={icon1} />
        </Box>
        <Box style={{marginLeft: '10px'}}>
          <Typography style={{color: '#008CF2'}}>Always free</Typography>
          <Typography style={{fontSize: '12px'}}>Never expires</Typography>
        </Box>
      </Box>
      <Box style={{display: 'flex', marginTop: '10px'}}>
        <Box>
          <img src={icon2} />
        </Box>
        <Box style={{marginLeft: '10px'}}>
          <Typography style={{color: '#008CF2'}}>12 months free</Typography>
          <Typography style={{fontSize: '12px'}}>
            Start from initial sign-up date
          </Typography>
        </Box>
      </Box>
      <Box style={{display: 'flex', marginTop: '10px'}}>
        <Box>
          <img src={icon3} />
        </Box>
        <Box style={{marginLeft: '10px'}}>
          <Typography style={{color: '#008CF2'}}>Trials</Typography>
          <Typography style={{fontSize: '12px'}}>
            Start from service activation date
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
export default SignupStep2Col2
