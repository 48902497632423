import React from "react";
import Modal from "@mui/material/Modal";
import { useEffect, useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import myContext from "../../../../screens/MyContext/MyContext";
import './style.css';
const SelectAccountIdListModel = ({
  accountIDListModel,
  setAccountIDListModel,
}) => {
  const handleClose = () => setAccountIDListModel(false);
  const { cloudDetails, setCloudDetails } = useContext(myContext);
  const handleChange = (e) => {
    setCloudDetails({
      ...cloudDetails,
      selectedCloudAccountID: e.target.value,
    });
    handleClose();
  };

  return (
    <div>
      <Modal
        open={accountIDListModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
          className="select_account_id_model"
        >
          <Typography
            variant="h6"
            style={{ color: "#5A95A9", marginBottom: "20px" }}
          >
            Select Account ID
          </Typography>
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
            >
              {cloudDetails?.cloudAccountIDList?.map((row) => (
                <FormControlLabel
                  value={row?.accountId}
                  onChange={handleChange}
                  control={<Radio size="small" style={{ color: "white" }} />}
                  label={row?.accountId}
                  style={{ color: "rgba(255,255,255,0.6)" }}
                />
              ))}
              {/* < FormControlLabel value="987654321" onChange={handleChange}
                                control={< Radio size="small" style={{ color: "white" }} />} label="987654321" style={{ color: "rgba(255,255,255,0.6)" }} />
                            < FormControlLabel value="147852369" onChange={handleChange}
                                control={< Radio size="small" style={{ color: "white" }} />} label="147852369" style={{ color: "rgba(255,255,255,0.6)" }} /> */}
            </RadioGroup>
          </FormControl>
        </Box>
      </Modal>
    </div>
  );
};

export default SelectAccountIdListModel;
