import React from "react";
import Modal from "@mui/material/Modal";
import { useEffect, useState, useContext } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import myContext from "../../../../screens/MyContext/MyContext";
import './style.css';

const RegionListModel = ({
  regionListSelectModel,
  setRegionListSelectModel,
  setSelectedRegionList,
  regionsList,
}) => {
  const [selectAll, setSelectAll] = useState(false);
  const { cloudDetails, setCloudDetails } = useContext(myContext);
  const handleClose = () => setRegionListSelectModel(false);

  const handleChangeCheckbox = (e) => {
    let selectRegion = [];
    selectRegion[0] = e.target.value;
    setCloudDetails({ ...cloudDetails, selectedCloudRegionsID: selectRegion });
    setRegionListSelectModel(false);
  };

  const getSelectAll = (event) => {
    let selectAllRegionarray = [];
    cloudDetails?.cloudRegionsList?.map((itm, idx) => {
      selectAllRegionarray[idx] = itm?.region_key;
    });
    setRegionListSelectModel(false);
    setSelectAll(true);
    setCloudDetails({
      ...cloudDetails,
      selectedCloudRegionsID: selectAllRegionarray,
    });
  };
  const getRemoveAll = (event) => {
    let tmpArr = cloudDetails?.cloudRegionsList?.map((itm, idx) => {
      return { ...itm, select: false };
    });
    setSelectAll(false);
    setCloudDetails({
      ...cloudDetails,
      selectedCloudRegionsID: tmpArr,
    });
  };
  return (
    <div>
      <Modal
        open={regionListSelectModel}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
          className="aws_region_select_model"
        >
          <Typography
            variant="h6"
            style={{ color: "#5A95A9", marginBottom: "20px" }}
          >
            Regions
          </Typography>
          <FormGroup>
            <FormControlLabel
              style={{ color: "white" }}
              label={selectAll ? "Remove All" : "Select All"}
              control={
                <Checkbox
                  checked={selectAll}
                  name="checkbox"
                  sx={{ color: "white" }}
                  onChange={selectAll ? getRemoveAll : getSelectAll}
                />
              }
            />
            {cloudDetails?.cloudRegionsList?.map((item, index) => {
              return (
                <FormControlLabel
                  style={{ color: "white" }}
                  label={item?.region_key}
                  control={
                    <Checkbox
                      sx={{ color: "white" }}
                      value={item?.region_key}
                      onChange={handleChangeCheckbox}
                      checked={selectAll}
                    />
                  }
                />
              );
            })}
          </FormGroup>
        </Box>
      </Modal>
    </div>
  );
};

export default RegionListModel;
