export const timezonelist = [
  {
    DisplayNameofTimeZoneOption: "International Date Line West",
    "ID of Time Zone": "Etc/GMT+12",
  },
  {
    DisplayNameofTimeZoneOption: "Coordinated Universal Time-11",
    "ID of Time Zone": "Etc/GMT+11",
  },
  {
    DisplayNameofTimeZoneOption: "Hawaii",
    "ID of Time Zone": "Pacific/Honolulu",
  },
  {
    DisplayNameofTimeZoneOption: "Alaska",
    "ID of Time Zone": "America/Anchorage",
  },
  {
    DisplayNameofTimeZoneOption: "Baja California",
    "ID of Time Zone": "America/Santa_Isabel",
  },
  {
    DisplayNameofTimeZoneOption: "Pacific Time (US and Canada)",
    "ID of Time Zone": "America/Los_Angeles",
  },
  {
    DisplayNameofTimeZoneOption: "Chihuahua, La Paz, Mazatlan",
    "ID of Time Zone": "America/Chihuahua",
  },
  {
    DisplayNameofTimeZoneOption: "Arizona",
    "ID of Time Zone": "America/Phoenix",
  },
  {
    DisplayNameofTimeZoneOption: "Mountain Time (US and Canada)",
    "ID of Time Zone": "America/Denver",
  },
  {
    DisplayNameofTimeZoneOption: "Central America",
    "ID of Time Zone": "America/Guatemala",
  },
  {
    DisplayNameofTimeZoneOption: "Central Time (US and Canada)",
    "ID of Time Zone": "America/Chicago",
  },
  {
    DisplayNameofTimeZoneOption: "Saskatchewan",
    "ID of Time Zone": "America/Regina",
  },
  {
    DisplayNameofTimeZoneOption: "Guadalajara, Mexico City, Monterey",
    "ID of Time Zone": "America/Mexico_City",
  },
  {
    DisplayNameofTimeZoneOption: "Bogota, Lima, Quito",
    "ID of Time Zone": "America/Bogota",
  },
  {
    DisplayNameofTimeZoneOption: "Indiana (East)",
    "ID of Time Zone": "Asia/Kolkata",
  },
  {
    DisplayNameofTimeZoneOption: "Eastern Time (US and Canada)",
    "ID of Time Zone": "America/New_York",
  },
  {
    DisplayNameofTimeZoneOption: "Caracas",
    "ID of Time Zone": "America/Caracas",
  },
  {
    DisplayNameofTimeZoneOption: "Atlantic Time (Canada)",
    "ID of Time Zone": "America/Halifax",
  },
  {
    DisplayNameofTimeZoneOption: "Asuncion",
    "ID of Time Zone": "America/Asuncion",
  },
  {
    DisplayNameofTimeZoneOption: "Georgetown, La Paz, Manaus, San Juan",
    "ID of Time Zone": "America/La_Paz",
  },
  {
    DisplayNameofTimeZoneOption: "Cuiaba",
    "ID of Time Zone": "America/Cuiaba",
  },
  {
    DisplayNameofTimeZoneOption: "Santiago",
    "ID of Time Zone": "America/Santiago",
  },
  {
    DisplayNameofTimeZoneOption: "Newfoundland",
    "ID of Time Zone": "America/St_Johns",
  },
  {
    DisplayNameofTimeZoneOption: "Brasilia",
    "ID of Time Zone": "America/Sao_Paulo",
  },
  {
    DisplayNameofTimeZoneOption: "Greenland",
    "ID of Time Zone": "America/Godthab",
  },
  {
    DisplayNameofTimeZoneOption: "Cayenne, Fortaleza",
    "ID of Time Zone": "America/Cayenne",
  },
  {
    DisplayNameofTimeZoneOption: "Buenos Aires",
    "ID of Time Zone": "America/Argentina/Buenos_Aires",
  },
  {
    DisplayNameofTimeZoneOption: "Montevideo",
    "ID of Time Zone": "America/Montevideo",
  },
  {
    DisplayNameofTimeZoneOption: "Coordinated Universal Time-2",
    "ID of Time Zone": "Etc/GMT+2",
  },
  {
    DisplayNameofTimeZoneOption: "Cape Verde",
    "ID of Time Zone": "Atlantic/Cape_Verde",
  },
  {
    DisplayNameofTimeZoneOption: "Azores",
    "ID of Time Zone": "Atlantic/Azores",
  },
  {
    DisplayNameofTimeZoneOption: "Casablanca",
    "ID of Time Zone": "Africa/Casablanca",
  },
  {
    DisplayNameofTimeZoneOption: "Monrovia, Reykjavik",
    "ID of Time Zone": "Atlantic/Reykjavik",
  },
  {
    DisplayNameofTimeZoneOption: "Dublin, Edinburgh, Lisbon, London",
    "ID of Time Zone": "Europe/London",
  },
  {
    DisplayNameofTimeZoneOption: "Coordinated Universal Time",
    "ID of Time Zone": "Etc/GMT",
  },
  {
    DisplayNameofTimeZoneOption:
      "Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    "ID of Time Zone": "Europe/Berlin",
  },
  {
    DisplayNameofTimeZoneOption: "Brussels, Copenhagen, Madrid, Paris",
    "ID of Time Zone": "Europe/Paris",
  },
  {
    DisplayNameofTimeZoneOption: "West Central Africa",
    "ID of Time Zone": "Africa/Lagos",
  },
  {
    DisplayNameofTimeZoneOption:
      "Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    "ID of Time Zone": "Europe/Budapest",
  },
  {
    DisplayNameofTimeZoneOption: "Sarajevo, Skopje, Warsaw, Zagreb",
    "ID of Time Zone": "Europe/Warsaw",
  },
  {
    DisplayNameofTimeZoneOption: "Windhoek",
    "ID of Time Zone": "Africa/Windhoek",
  },
  {
    DisplayNameofTimeZoneOption: "Athens, Bucharest, Istanbul",
    "ID of Time Zone": "Europe/Istanbul",
  },
  {
    DisplayNameofTimeZoneOption:
      "Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    "ID of Time Zone": "Europe/Kiev",
  },
  {
    DisplayNameofTimeZoneOption: "Cairo",
    "ID of Time Zone": "Africa/Cairo",
  },
  {
    DisplayNameofTimeZoneOption: "Damascus",
    "ID of Time Zone": "Asia/Damascus",
  },
  {
    DisplayNameofTimeZoneOption: "Amman",
    "ID of Time Zone": "Asia/Amman",
  },
  {
    DisplayNameofTimeZoneOption: "Harare, Pretoria",
    "ID of Time Zone": "Africa/Johannesburg",
  },
  {
    DisplayNameofTimeZoneOption: "Jerusalem",
    "ID of Time Zone": "Asia/Jerusalem",
  },
  {
    DisplayNameofTimeZoneOption: "Beirut",
    "ID of Time Zone": "Asia/Beirut",
  },
  {
    DisplayNameofTimeZoneOption: "Baghdad",
    "ID of Time Zone": "Asia/Baghdad",
  },
  {
    DisplayNameofTimeZoneOption: "Minsk",
    "ID of Time Zone": "Europe/Minsk",
  },
  {
    DisplayNameofTimeZoneOption: "Kuwait, Riyadh",
    "ID of Time Zone": "Asia/Riyadh",
  },
  {
    DisplayNameofTimeZoneOption: "Nairobi",
    "ID of Time Zone": "Africa/Nairobi",
  },
  {
    DisplayNameofTimeZoneOption: "Tehran",
    "ID of Time Zone": "Asia/Tehran",
  },
  {
    DisplayNameofTimeZoneOption: "Moscow, St. Petersburg, Volgograd",
    "ID of Time Zone": "Europe/Moscow",
  },
  {
    DisplayNameofTimeZoneOption: "Tbilisi",
    "ID of Time Zone": "Asia/Tbilisi",
  },
  {
    DisplayNameofTimeZoneOption: "Yerevan",
    "ID of Time Zone": "Asia/Yerevan",
  },
  {
    DisplayNameofTimeZoneOption: "Abu Dhabi, Muscat",
    "ID of Time Zone": "Asia/Dubai",
  },
  {
    DisplayNameofTimeZoneOption: "Baku",
    "ID of Time Zone": "Asia/Baku",
  },
  {
    DisplayNameofTimeZoneOption: "Port Louis",
    "ID of Time Zone": "Indian/Mauritius",
  },
  {
    DisplayNameofTimeZoneOption: "Kabul",
    "ID of Time Zone": "Asia/Kabul",
  },
  {
    DisplayNameofTimeZoneOption: "Tashkent",
    "ID of Time Zone": "Asia/Tashkent",
  },
  {
    DisplayNameofTimeZoneOption: "Islamabad, Karachi",
    "ID of Time Zone": "Asia/Karachi",
  },
  {
    DisplayNameofTimeZoneOption: "Sri Jayewardenepura Kotte",
    "ID of Time Zone": "Asia/Colombo",
  },
  {
    DisplayNameofTimeZoneOption: "Kathmandu",
    "ID of Time Zone": "Asia/Kathmandu",
  },
  {
    DisplayNameofTimeZoneOption: "Astana",
    "ID of Time Zone": "Asia/Almaty",
  },
  {
    DisplayNameofTimeZoneOption: "Dhaka",
    "ID of Time Zone": "Asia/Dhaka",
  },
  {
    DisplayNameofTimeZoneOption: "Yekaterinburg",
    "ID of Time Zone": "Asia/Yekaterinburg",
  },
  {
    DisplayNameofTimeZoneOption: "Yangon",
    "ID of Time Zone": "Asia/Yangon",
  },
  {
    DisplayNameofTimeZoneOption: "Bangkok, Hanoi, Jakarta",
    "ID of Time Zone": "Asia/Bangkok",
  },
  {
    DisplayNameofTimeZoneOption: "Novosibirsk",
    "ID of Time Zone": "Asia/Novosibirsk",
  },
  {
    DisplayNameofTimeZoneOption: "Krasnoyarsk",
    "ID of Time Zone": "Asia/Krasnoyarsk",
  },
  {
    DisplayNameofTimeZoneOption: "Ulaanbaatar",
    "ID of Time Zone": "Asia/Ulaanbaatar",
  },
  {
    DisplayNameofTimeZoneOption: "Beijing, Chongqing, Hong Kong, Urumqi",
    "ID of Time Zone": "Asia/Shanghai",
  },
  {
    DisplayNameofTimeZoneOption: "Perth",
    "ID of Time Zone": "Australia/Perth",
  },
  {
    DisplayNameofTimeZoneOption: "Kuala Lumpur, Singapore",
    "ID of Time Zone": "Asia/Singapore",
  },
  {
    DisplayNameofTimeZoneOption: "Taipei",
    "ID of Time Zone": "Asia/Taipei",
  },
  {
    DisplayNameofTimeZoneOption: "Irkutsk",
    "ID of Time Zone": "Asia/Irkutsk",
  },
  {
    DisplayNameofTimeZoneOption: "Seoul",
    "ID of Time Zone": "Asia/Seoul",
  },
  {
    DisplayNameofTimeZoneOption: "Osaka, Sapporo, Tokyo",
    "ID of Time Zone": "Asia/Tokyo",
  },
  {
    DisplayNameofTimeZoneOption: "Darwin",
    "ID of Time Zone": "Australia/Darwin",
  },
  {
    DisplayNameofTimeZoneOption: "Adelaide",
    "ID of Time Zone": "Australia/Adelaide",
  },
  {
    DisplayNameofTimeZoneOption: "Hobart",
    "ID of Time Zone": "Australia/Hobart",
  },
  {
    DisplayNameofTimeZoneOption: "Yakutsk",
    "ID of Time Zone": "Asia/Yakutsk",
  },
  {
    DisplayNameofTimeZoneOption: "Brisbane",
    "ID of Time Zone": "Australia/Brisbane",
  },
  {
    DisplayNameofTimeZoneOption: "Guam, Port Moresby",
    "ID of Time Zone": "Pacific/Port_Moresby",
  },
  {
    DisplayNameofTimeZoneOption: "Canberra, Melbourne, Sydney",
    "ID of Time Zone": "Australia/Sydney",
  },
  {
    DisplayNameofTimeZoneOption: "Vladivostok",
    "ID of Time Zone": "Asia/Vladivostok",
  },
  {
    DisplayNameofTimeZoneOption: "Solomon Islands, New Caledonia",
    "ID of Time Zone": "Pacific/Guadalcanal",
  },
  {
    DisplayNameofTimeZoneOption: "Coordinated Universal Time+12",
    "ID of Time Zone": "Etc/GMT-12",
  },
  {
    DisplayNameofTimeZoneOption: "Fiji, Marshall Islands",
    "ID of Time Zone": "Pacific/Fiji",
  },
  {
    DisplayNameofTimeZoneOption: "Magadan",
    "ID of Time Zone": "Asia/Magadan",
  },
  {
    DisplayNameofTimeZoneOption: "Auckland, Wellington",
    "ID of Time Zone": "Pacific/Auckland",
  },
  {
    DisplayNameofTimeZoneOption: "Nuku'alofa",
    "ID of Time Zone": "Pacific/Tongatapu",
  },
  {
    DisplayNameofTimeZoneOption: "Samoa",
    "ID of Time Zone": "Pacific/Apia",
  },
];
