import { Box } from '@mui/system'
import CustomModel from '../Model/index'
import roundClose from '../../assets/img/cancel_icn.png'
import refresh from '../../assets/img/refresh_icn.png'
import warningIcon from '../../assets/img/triangle_icn.png'

import checkRound from '../../assets/img/checkrefresh.png'
import { Typography } from '@mui/material'
import CustomButton from "../CustomButton/index";

const SentEmailModel = ({
  isEmailModelOpen,
  _handleCloseEmailModel,
  onButtonClick,
  onResendVerification,
  isResendVerification,
}) => {
  return (
    <CustomModel
      open={isEmailModelOpen}
      handleClose={_handleCloseEmailModel}
      styles={{
        backgroundColor: '#01081D',
        color: 'white',
        border: '1px solid #707070',
        left: '65%',
        padding: '10px',
      }}
    >
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {/* close icon */}
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <Box style={{ cursor: 'pointer' }} onClick={_handleCloseEmailModel}>
            <img src={roundClose} />
          </Box>
        </Box>

        {/* 1st Header */}
        <Box
          style={{
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{ fontSize: '30px', color: '#008CF2', fontWeight: '600' }}
          >
            Check your email...
          </Typography>
          <Typography style={{ fontSize: '20px', marginTop: '20px' }}>
            We sent a verification email to{' '}
            <span style={{ color: '#008CF2' }}>hardik.desaiaim@gmail.com.</span>
          </Typography>
          <Box style={{ display: 'flex', marginTop: '10px' }}>
            <img src={warningIcon} />
            <Typography
              style={{
                fontSize: '24px',
                color: '#FF7700',
                marginLeft: '10px',
              }}
            >
              You must verify your email address within 24 hours.
            </Typography>
          </Box>
          <Typography style={{ fontSize: '20px', marginTop: '10px' }}>
            after verify your email address, you will begin receiving
            notifications
          </Typography>
        </Box>

        {/* Devider */}
        <Box
          style={{
            width: '85%',
            height: '2px',
            backgroundColor: 'grey',
            marginTop: '20px',
          }}
        ></Box>

        {/* 2nd Header */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          <Box style={{ display: 'flex', marginTop: '10px' }}>
            <img src={warningIcon} />
            <Typography
              style={{
                fontSize: '22px',
                color: '#FFF',
                marginLeft: '10px',
              }}
            >
              Not seeing the verification email in your inbox?
            </Typography>
          </Box>
          <Typography style={{ fontSize: '18px', marginTop: '10px' }}>
            Make sure to check your spam folder.
          </Typography>
          <Box
            style={{ display: 'flex', marginTop: '20px', cursor: 'pointer' }}
            onClick={onResendVerification}
          >
            <img src={!isResendVerification ? refresh : checkRound} />
            <Typography
              style={{
                fontSize: '24px',
                color: isResendVerification ? '#00AB5E' : '#FF7700',
                marginLeft: '10px',
              }}
            >
              {!isResendVerification ? 'Resend Verification' : 'Sent'}
            </Typography>
          </Box>
        </Box>

        {/* Devider */}
        <Box
          style={{
            width: '70%',
            height: '2px',
            backgroundColor: 'grey',
            marginTop: '20px',
          }}
        ></Box>

        {/* Button */}
        <CustomButton
          text={'I underStand'}
          styles={{
            backgroundColor: '#008CF2',
            color: '#FFF',
            marginTop: '20px',
            borderRadius: '20px',
            padding: '10px 18px',
          }}
          onButtonClick={onButtonClick}
        />
      </Box>
    </CustomModel>
  )
}
export default SentEmailModel
