const key = 'TRALLO_CLONE'
export const token_key = 'user'

export const setToken = token => {
  window.localStorage.setItem(token_key, JSON.stringify(token))
}

export const getToken = () => {
  let token = window.localStorage.getItem(token_key)
  if (!!token) return JSON.parse(token).data.accessToken
  return false
}

export const isLogin = () => {
  if (!!getToken()) {
    return true
  }
  return false
}

export const logout = () => {
  window.localStorage.clear()
}
