import * as React from "react";
import { useContext } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Photo from "../../../assets/img/pro_pic.png";
import defaultimg from "../../../assets/img/default.png";
import filter_icn from "../../../assets/img/filter_icn.png";
import Component from "../../../assets/img/Component 34 – 1.png";
import aws_cloud_icn from "../../../assets/img/aws_cloud_icn.png";
import blue_down_arrow from "../../../assets/img/blue_down_arrow.png";
import Component1 from "../../../assets/img/Component 45 – 1.png";
import azure_icn from "../../../assets/img/azure_icn.png";
import firebase_icn from "../../../assets/img/firebase_icn.png";
import Avatar from "@mui/material/Avatar";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import "./home.css";
import AddEmail from "../AddEmail/AddEmail";
import SentEmailModel from "../sentEmailModel/sentEmailModel";
import { IconButton, Select } from "@mui/material";
import { fontSize } from "@mui/system";
import Removeemail from "../RemoveEmailModel/RemoveEmailModel";
import RemoveNumber from "../RemoveNumber/removenumber";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { Api } from "../../../MyNewUtils/Api";
import EditIcon from "@mui/icons-material/Edit";
import UpdateNumber from "../update/numberupdatemodel";
import UpdateEmail from "../update/emailupdatemodel";
import Edit from "../../../assets/img/pen_icn@2x.png";
import ReactFileReader from "react-file-reader";
import { Axios } from "axios";
import myContext from "../../MyContext/MyContext";
import moment from "moment-timezone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { timezonelist } from "./timezone";

const UserDetails = ({ setIsLoading }) => {
  // const [timezonelist, setTimeZoneList] = useState(timezonelist)
  var timeZoneList = timezonelist;

  const [emailmodel, setEmailmodel] = useState(false);
  const [numbermodel, setNumbermodel] = useState(false);
  const [updateNumber, setUpdateNumber] = useState(false);
  const [updateEmail, setUpdateEmail] = useState(false);
  const [modelName, setModelName] = useState();
  const dateTime = JSON.parse(localStorage.getItem("user"))?.data
    ?.lastLoginTime;
  const date = JSON.parse(localStorage.getItem("user"))?.data?.lastLoginTime;
  const time = new Date(date).toLocaleTimeString("en", {
    timeStyle: "medium",
    hour12: true,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [url, setUrl] = useState("");
  const {
    profileImageUrl,
    getprofileImageUrl,
    timeZone,
    setTimeZone,
    setProfileImageUrl,
  } = useContext(myContext);
  console.log("profileImageUrl", profileImageUrl);
  const open = Boolean(anchorEl);
  const { DateTime } = useContext(myContext);
  const datetime = DateTime(
    JSON.parse(localStorage.getItem("user"))?.data?.lastLoginTime
  );
  const [datatimeFormat, setDatatimeFormat] = useState(datetime);
  const [SelectTimeZone, setSelectTimeZone] = useState(timeZone);
  const handleChange = (event) => {
    setSelectTimeZone(event.target.value);
  };

  const strAscending = [...timeZoneList].sort((a, b) =>
    a.DisplayNameofTimeZoneOption > b.DisplayNameofTimeZoneOption ? 1 : -1
  );
  console.log("fdsfdsf", strAscending);
  // var moment = require('moment-timezone');
  // var newYork = moment(datetime).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MMMM Do YYYY, h:mm:ss a');
  // newYork.format();
  // var d = new Date(newYork);

  // var usaTime = date.toLocaleString("en-US", { timeZone: "America/New_York" });

  // var date2 = new Date(newYork);
  // var date.toString()

  // console.log("daAGFVSDHB======", newYork)

  // console.log("daAGFVSDHB", Intl.DateTimeFormat().resolvedOptions().timeZone)

  const onChangePassword = async () => {
    if (currentPassword?.length != "0") {
      if (newPassword.length != "0") {
        if (confirmPassword.length != "0") {
          if (newPassword == confirmPassword) {
            setIsLoading(true);
            let final = {
              id: JSON.parse(localStorage.getItem("user")).data.id,
              oldpassword: currentPassword,
              newpassword: newPassword,
              confpassword: confirmPassword,
            };
            const { statusCode, data } = await Api.postRequest(
              "/users/change-password",
              final
            );
            const res = JSON.parse(data);
            if (res?.status === 1) {
              toast.success(res.message, { autoClose: 1000 });
              setCurrentPassword("");
              setNewPassword("");
              setConfirmPassword("");
              setIsLoading(false);
            } else {
              toast.error(res.message, { autoClose: 1000 });
              setIsLoading(false);
            }
          } else {
            toast.error("New Password and Confirm Password are not match", {
              autoClose: 1000,
            });
          }
        } else {
          toast.error("Enter Confirm Password", { autoClose: 1000 });
        }
      } else {
        toast.error("Enter New Password", { autoClose: 1000 });
      }
    } else {
      toast.error("Enter current Password", { autoClose: 1000 });
    }
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function createData(month, year, amount) {
    return { month, year, amount };
  }

  const rows = [
    createData("April", 2021, "xxxx $"),
    createData("May", 2021, "xxxx $"),
    createData("June", 2021, "xxxx $"),
  ];

  function EmailMobileNumberData(emali, Verified, Action) {
    return { emali, Verified, Action };
  }

  const Data = [
    EmailMobileNumberData(
      JSON.parse(localStorage.getItem("user"))?.data?.email,
      "Yes",
      "Email"
    ),
    EmailMobileNumberData(
      JSON.parse(localStorage.getItem("user"))?.data?.phone,
      "Yes",
      "Number"
    ),
  ];

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    background: "#000",
  }));

  const handleFiles = async (files) => {
    setUrl(files.base64);
    var updateProfileForm = new FormData();
    updateProfileForm.append(
      "userid",
      JSON.parse(localStorage.getItem("user")).data.id
    );
    updateProfileForm.append("profilePic", files[0]);

    let user = JSON.parse(localStorage.getItem("user"));

    const data = await Api.axiospostRequest(
      "/users/update-profile-picture",
      updateProfileForm
    );
    const req = data;
    if (req?.status === 1) {
      toast.success(req?.message, { autoClose: 1000 });
      setProfileImageUrl(req?.data?.profilePic);
      let userdate = { ...user.data, profilepic: req?.data?.profilePic };
      user = { ...user, data: userdate };
      getprofileImageUrl(req?.data?.profilePic);
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      toast.error(req?.message, { autoClose: 1000 });
    }
  };

  const ChangeTimeZone = async () => {
    console.log("zsxtgdxyh", SelectTimeZone);
    if (SelectTimeZone != "") {
      let final = {
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        timeZone: SelectTimeZone,
      };
      const data = await Api.postRequest("/users/update-profile", final);
      const res = JSON.parse(data?.data);
      console.log("data-0", res);
      if (data?.statusCode === 200) {
        let localData = JSON.parse(localStorage.getItem("userData"));
        localData = { ...localData, timeZone: SelectTimeZone };
        localStorage.setItem("userData", JSON.stringify(localData));
        getTime();
        toast.success(res?.message, { autoClose: 1000 });
        setTimeZone(SelectTimeZone);
      } else {
        toast.error(res?.message, { autoClose: 1000 });
      }
    }
  };

  const getTime = () => {
    const Newdatetime = DateTime(
      JSON.parse(localStorage.getItem("user"))?.data?.lastLoginTime
    );
    setDatatimeFormat(Newdatetime);
  };
  return (
    <>
      {/* <Removeemail/>
        <AddEmail/>
        <SentEmailModel/> */}
      {emailmodel ? <Removeemail setEmailmodel={setEmailmodel} /> : null}
      {numbermodel ? <RemoveNumber setNumbermodel={setNumbermodel} /> : null}
      {updateNumber ? <UpdateNumber setUpdateNumber={setUpdateNumber} /> : null}
      {updateEmail ? <UpdateEmail setUpdateEmail={setUpdateEmail} /> : null}
      <section className="background">
        <Box
          component="span"
          sx={{
            paddingLeft: "109px",
            paddingRight: "109px",
            paddingBottom: "50px",
          }}
        >
          <Grid
            container
            spacing={2}
            rowSpacing={1}
            style={{ marginTop: "47px", background: "rgba(0,0,0,0)" }}
          >
            <Grid
              item
              xs={12}
              md={4}
              className="homegrid"
              style={{
                background: "rgba(0,0,0,0.3)",
                width: "100%",
                padding: "40px 10px 50px 10px",
                borderRadius: "20px",
              }}
            >
              <Box>
                <ReactFileReader
                  fileTypes={[".png", ".jpg"]}
                  // base64={true}
                  handleFiles={handleFiles}
                >
                  <img
                    src={Edit}
                    style={{
                      width: "35px",
                      marginLeft: "90%",
                      cursor: "pointer",
                      marginBottom: "30px",
                    }}
                    alt="profile"
                    className="pr_edit_resp"
                  />
                </ReactFileReader>
                {profileImageUrl === "" ? (
                  <img
                    src={defaultimg}
                    className="photo homegrid"
                    width={310}
                    height={310}
                    alt="profile"
                  />
                ) : (
                  <img
                    src={profileImageUrl}
                    className="photo homegrid"
                    width={310}
                    height={310}
                    alt="profile"
                    className="pro_profile_img"
                    style={{ marginLeft: "30px" }}
                  />
                )}
                <Box className="photoname homegrid">
                  {JSON.parse(localStorage.getItem("user"))?.data?.name}
                </Box>
                <Box className="photosubdis homegrid">
                  Last Loging - {datatimeFormat}
                </Box>
                {/* {new Date(date).toLocaleDateString()} - {time} */}
              </Box>
            </Grid>
            <Grid item xs={12} md={8}>
              <Grid
                item
                xs={12}
                md={12}
                className="ditels"
                style={{ borderRadius: "20px", background: "rgba(0,0,0,0.3)" }}
              >
                <Box>
                  <Box className="hedingdetels">Email & Mobile number</Box>

                  <TableContainer
                    component={Paper}
                    style={{
                      background: "rgba(0,0,0,0)",
                      padding: "0 8% 0 5%",
                      marginTop: "2%",
                    }}
                  >
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow
                          style={{
                            background: "#2a3144",
                            borderRadius: "20px",
                          }}
                        >
                          <TableCell
                            style={{
                              border: "0px",
                              color: "#fff",
                              fontSize: "20px",
                              fontWeight: "normal",
                              paddingLeft: "5%",
                            }}
                            align="left"
                          >
                            Email address/ Mobile number
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                              color: "#fff",
                              fontSize: "20px",
                              fontWeight: "normal",
                            }}
                            align="center"
                          >
                            Verified
                          </TableCell>
                          <TableCell
                            style={{
                              border: "0px",
                              color: "#fff",
                              fontSize: "20px",
                              fontWeight: "normal",
                            }}
                            align="center"
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Data.map((data) => (
                          <TableRow>
                            <TableCell
                              align="left"
                              style={{
                                border: "0px",
                                color: "#fff",
                                fontSize: "18px",
                                fontWeight: "normal",
                                paddingLeft: "5%",
                              }}
                            >
                              {data.emali}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                border: "0px",
                                color: "#fff",
                                fontSize: "18px",
                                fontWeight: "normal",
                              }}
                            >
                              {data.Verified}
                            </TableCell>
                            <TableCell
                              align="center"
                              style={{
                                border: "0px",
                                color: "#fff",
                                fontSize: "18px",
                                fontWeight: "normal",
                              }}
                            >
                              <IconButton sx={{ p: 0 }}>
                                <EditIcon
                                  style={{
                                    color: "#fff",
                                    background: "#328cf2",
                                    borderRadius: "50%",
                                    width: "11%",
                                    height: "11%",
                                    padding: "5px",
                                  }}
                                  className="resp_edit_btn"
                                  onClick={() => {
                                    data.Action == "Email"
                                      ? setUpdateEmail(true)
                                      : setUpdateNumber(true);
                                  }}
                                />
                                {/* <Avatar alt="Remy Sharp" src={delete_icn} style={{ width: '12%', height: '12%' }} onClick={() => { data.Action == 'Email' ? setEmailmodel(true) : setNumbermodel(true) }} /> */}
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>

                  <Box className="hedingdetels">Change Password</Box>
                  <Box className="hedingdetels" style={{ paddingRight: "8%" }}>
                    <Grid
                      container
                      spacing={0}
                      style={{ marginTop: "20px", paddingBottom: "23px" }}
                    >
                      <Grid item xs={4} md={4}>
                        <input
                          type="password"
                          name="Password"
                          placeholder="Current Password*"
                          value={currentPassword}
                          onChange={(e) => {
                            setCurrentPassword(e.target.value);
                          }}
                          style={{
                            background: "#2A3144",
                            marginTop: "1%",
                            color: "rgba(255,255,255,0.3)",
                            width: "98%",
                            height: "44px",
                            borderRadius: "20px",
                            border: "0px",
                            fontSize: "22px",
                            padding: "15px",
                          }}
                          className="textbox_font"
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      spacing={0}
                      style={{ marginTop: "20px", paddingBottom: "23px" }}
                    >
                      <Grid item xs={4} md={4}>
                        <input
                          type="password"
                          name="password"
                          value={newPassword}
                          onChange={(e) => {
                            setNewPassword(e.target.value);
                          }}
                          placeholder="New Password*"
                          className="textbox"
                          style={{
                            background: "#2A3144",
                            color: "rgba(255,255,255,0.3)",
                            width: "98%",
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} md={4}>
                        <input
                          type="password"
                          value={confirmPassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value);
                          }}
                          name="password"
                          placeholder="Confirm Password*"
                          className="textbox"
                          style={{
                            background: "#2A3144",
                            color: "rgba(255,255,255,0.3)",
                            width: "98%",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={4}
                        md={4}
                        sx={{ display: "flex", justifyContent: "flex-end" }}
                      >
                        <Button
                          onClick={onChangePassword}
                          style={{
                            padding: "20px",
                            background: "#008CF2",
                            color: "#fff",
                            height: "43px",
                            borderRadius: "20px",
                          }}
                          className="btn_change_pass"
                        >
                          Change Password
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box className="hedingdetels">Change Time Zone</Box>
                  <Box className="hedingdetels" style={{ paddingRight: "8%" }}>
                    <Grid
                      container
                      spacing={0}
                      style={{ marginTop: "20px", paddingBottom: "23px" }}
                    >
                      <Grid item xs={9} md={9}>
                        <select
                          onChange={handleChange}
                          IconButton={KeyboardArrowDownIcon}
                          style={{
                            width: "60%",
                            backgroundColor: "#2A3144",
                            border: "1px solid #363636",
                            padding: "4px",
                            color: "#FFF",
                            height: "100%",
                          }}
                          value={SelectTimeZone}
                        >
                          <option value="" disabled>
                            Select Time Zone
                          </option>
                          {strAscending.map((item) => (
                            <option value={item["ID of Time Zone"]}>
                              {item["DisplayNameofTimeZoneOption"]}
                            </option>
                          ))}
                        </select>
                      </Grid>
                      <Grid item xs={3} md={3}>
                        <Button
                          onClick={ChangeTimeZone}
                          style={{
                            padding: "20px",
                            background: "#008CF2",
                            color: "#fff",
                            height: "43px",
                            borderRadius: "20px",
                            width: "200px",
                          }}
                          className="btn_change_timezone"
                        >
                          Change Time Zone
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </section>
    </>
  );
};
export default UserDetails;
