import { Box } from '@mui/system'
import CustomModel from '../Model/index'
import roundClose from '../../assets/img/cancel_icn.png'
import refresh from '../../assets/img/refresh_icn.png'
import warningIcon from '../../assets/img/triangle_icn.png'

import checkRound from '../../assets/img/checkrefresh.png'
import { Typography } from '@mui/material'
import CustomButton from "../CustomButton/index";

const ForgotEmailModel = ({
  isForgetEmailModelOpen,
  _handleCloseForgetEmailModel,
}) => {
  return (
    <CustomModel
      open={isForgetEmailModelOpen}
      handleClose={_handleCloseForgetEmailModel}
      styles={{
        backgroundColor: '#01081D',
        color: 'white',
        border: '1px solid #707070',
        left: '65%',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      {/* close icon */}
      <Box
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <Box style={{ cursor: 'pointer' }} onClick={_handleCloseForgetEmailModel}>
          <img src={roundClose} />
        </Box>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '60%',
        }}
      >
        {/* 1st Header */}
        <Box
          style={{
            // backgroundColor: 'red',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            style={{
              fontSize: '30px',
              color: '#008CF2',
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            Forgot Email & Phone number
          </Typography>
          <Typography
            style={{ fontSize: '20px', marginTop: '20px', textAlign: 'center' }}
          >
            If you need more help, mail us at below email ID with First name &
            Last name.
          </Typography>

          <Typography style={{ fontSize: '20px', marginTop: '20px' }}>
            Email to{' '}
            <span style={{ color: '#FF8800' }}>support@clevrone.com</span>
          </Typography>
        </Box>

        {/* 2nd Header */}
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: '10px',
          }}
        >
          {/* Button */}
          <CustomButton
            text={'I underStand'}
            styles={{
              backgroundColor: '#008CF2',
              color: '#FFF',
              margin: '20px 0px',
              borderRadius: '20px',
              padding: '10px 18px',
            }}
            onButtonClick={_handleCloseForgetEmailModel}
          />
        </Box>
      </Box>
    </CustomModel>
  )
}
export default ForgotEmailModel
