import React, { useState, useRef, useEffect, useMemo } from 'react';
import './Discover.scss';
import { TextareaAutosize } from '@mui/base';
import { CircularProgress, IconButton, Skeleton } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { addMessageAction, discoverAnswer, discoverClear, resetMessageAction } from '../../redux/action/discoverActions';
import { useSelector, useDispatch } from 'react-redux';
import logo from "../../assets/img/logo.png";
import { Message } from './Message';
import { DefaultDiscover } from './DefaultDiscover';
import DashboardHeader from '../../Component/DashboardHeader/DashboardHeader';
import AddIcon from '@mui/icons-material/Add';


const Discover = () => {
  const [inputText, setInputText] = useState('');
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const chatboatTextareaRef = useRef(null);

  const { loading: isDiscoverLoading, error: discoverError, answer: discoverSuccess } = useSelector(state => state.discover);
  const conversation = useSelector((state) => state.chat.conversation);
  const [isQuestion, setIsQuestion] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      // block: "start" 
    });
  };

  useEffect(() => {
    chatboatTextareaRef.current.focus();
    return () => {
      dispatch(discoverClear());
    }
  }, [])

  useEffect(() => {
    let botResponse = {}
    if (!isDiscoverLoading && discoverSuccess) {
      setIsQuestion(false);
      botResponse = { text: discoverSuccess, sender: 'bot', timestamp: new Date().toISOString() };
      dispatch(addMessageAction(botResponse));
    } else if (!isDiscoverLoading && discoverError) {
      setIsQuestion(false);
      botResponse = { text: discoverError, sender: 'bot', timestamp: new Date().toISOString() };
      dispatch(addMessageAction(botResponse));
    }
    scrollToBottom();
  }, [discoverSuccess, isDiscoverLoading, discoverError]);

  const handleMessageSend = async () => {
    setIsQuestion(true);
    if (inputText.trim()) {
      const newQuestion = { text: inputText, sender: 'user', timestamp: new Date().toISOString() };
      dispatch(addMessageAction(newQuestion));
      setInputText('');
      scrollToBottom();
      dispatch(discoverAnswer({ data: inputText, endpoint: "chat" }));
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !(e.ctrlKey || e.shiftKey || e.altKey || e.metaKey)) {
      e.preventDefault();
      handleMessageSend();
    }
  };

  const handleQuentionClick = (question) => {
    setInputText(question);
    chatboatTextareaRef.current.focus();
  }

  const onChatClearClick = () => {
    dispatch(resetMessageAction());
  }


  return (

    <div className="discover-container">
      <DashboardHeader activeTab={"DISCOVER"} />

      <div className="discover-messages">
        <div className='messages-container relative'>
          {conversation.length ? (
            <>
              {
                conversation.map((message, index) => (
                  <Message
                    key={index}
                    message={message}
                    index={index}
                    isLastMessage={conversation.length === index + 1}
                    messagesEndRef={messagesEndRef}
                    isQuestion={isQuestion}
                  />
                ))
              }
              {
                isQuestion ? (
                  <div className='loader-answer'>
                    <img src={logo} alt="bot-logo" />
                    <div>
                      <Skeleton />
                      <Skeleton />
                      <Skeleton width={400} />
                    </div>
                  </div>
                ) : null
              }
            </>
          ) : (
            <DefaultDiscover handleQuentionClick={handleQuentionClick} />
          )}
        </div>
      </div>
      <div className='discover-footer'>
        {isDiscoverLoading && <div>Please wait...</div>}
        <div className="discover-input relative">
          {!isDiscoverLoading && <div onClick={onChatClearClick} className='absolute top-0 -left-[50px] cursor-pointer text-white'>
            <AddIcon />
          </div>}
          <TextareaAutosize
            onChange={(e) => setInputText(e.target.value)}
            className='chatboat-textarea'
            placeholder='Enter a prompt here'
            value={inputText}
            onKeyDown={handleKeyDown}
            ref={chatboatTextareaRef}
          />
          {
            isDiscoverLoading ? (
              <div className='loader-icon'>
                <CircularProgress color="inherit" />
              </div>
            ) : (
              <IconButton aria-label="send" onClick={handleMessageSend} disabled={isDiscoverLoading}>
                <ArrowForwardIosIcon />
              </IconButton>
            )
          }
        </div>
      </div>
    </div>
  );
};

export default Discover;
