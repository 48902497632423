import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/img/CO5_FINAL.png";
import cross from "../../assets/img/cross.png";
import check from "../../assets/img/Checkbox.png";
import eye from "../../assets/img/eye.png";
import RegistrationLayout from "../../Component/Layout/index";
import "./Signup.css";
import { style } from "./style";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Component/Spinner/Spinner";
import { Api } from "../../MyNewUtils/Api";
import MainLoader from "../../Component/Spinner/MainLoader";
import { toast } from "react-toastify";
import { path } from "../../MyNewUtils/screenPath";
import myContext from "../MyContext/MyContext";
import { useContext } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

const Signup = () => {
  const { userId, setUserID } = useContext(myContext);
  const cls = useMemo(() => "signup", []);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [againPassword, setAgainPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isShowError, setIsShowError] = useState(false);
  const [isEmailShowError, setIsEmailShowError] = useState(false);
  const [isConfirmPasswordError, setConfirmPasswordError] = useState(false);
  const [remember, setRemember] = useState(false);
  const [passwordLengthError, setPasswordLengthError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showC_Password, setShowC_Password] = useState(false);
  const [passwordError, setPasswordError] = useState({
    isBlank: true,
    isError: false,
  });
  const [againPasswordError, setAgainPasswordError] = useState({
    isBlank: true,
    isError: false,
  });
  // const isValidated = useCallback(() => {
  //   if (password.length < 8) {
  //     setPasswordError({isError: true, isBlank: false})
  //     return false
  //   } else if (password !== againPassword) {
  //     setPasswordError({isError: false, isBlank: false})
  //     setAgainPasswordError({isError: true, isBlank: false})
  //     setIsShowError(true)
  //     return false
  //   }
  //   setPasswordError({isError: false, isBlank: false})
  //   setAgainPasswordError({isError: false, isBlank: false})
  //   setIsShowError(false)
  //   return true
  // }, [againPassword, password])

  // const _handleSignUp = useCallback(async () => {
  //   if (!isValidated()) {
  //     return
  //   }

  //   setIsLoading(true)
  //   const {statusCode, data} = await Api.postRequest('/users/register', {
  //     conf_password: againPassword,
  //     email: email,
  //     password,
  //   })
  //   const res = JSON.parse(data)
  //   if (statusCode !== 200) {
  //     setIsLoading(false)
  //     toast.error(res.message)
  //     return
  //   }
  //   setIsLoading(false)
  //   toast.success('Verification link send in email.')
  //   myFunc(res.data.userId)
  //   navigate(path.login)
  //   // navigate('/Sign-up-for-cleveron')
  // }, [againPassword, email, isValidated, navigate, password])

  const [signupData, setSignupData] = useState({
    email: null,
    password: "",
    conf_password: null,
  });

  const onEmailSet = (e) => {
    setSignupData({ ...signupData, email: e.target.value });
  };

  const onPasswordSet = (e) => {
    setSignupData({ ...signupData, password: e.target.value });
  };

  const onAPasswordSet = (e) => {
    setSignupData({ ...signupData, conf_password: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    var emailregex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    const checkValidObj = {};
    const newVal = signupData.password;

    // check 8 cherecter
    checkValidObj.charecterLong = newVal.length >= 8;

    // checkLowerCase
    checkValidObj.lowerCase = /[a-z]/.test(newVal);

    // checkUpperCase
    checkValidObj.upperCase = /[A-Z]/.test(newVal);

    // check number
    checkValidObj.number = /[0-9]/.test(newVal);

    // check special
    checkValidObj.special = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
      newVal
    );

    checkValidObj.isStrong =
      checkValidObj.charecterLong &&
      checkValidObj.lowerCase &&
      checkValidObj.upperCase &&
      checkValidObj.number &&
      checkValidObj.special;

    if (emailregex.test(signupData.email)) {
      if (
        checkValidObj.charecterLong &&
        checkValidObj.lowerCase &&
        checkValidObj.upperCase &&
        checkValidObj.number &&
        checkValidObj.special
      ) {
        if (signupData.password == signupData.conf_password) {
          setIsEmailShowError(false);
          setPasswordError({ ...passwordError, isBlank: true, isError: false });
          setAgainPasswordError({
            ...againPasswordError,
            isBlank: false,
            isError: false,
          });
          setIsLoading(true);
          let body = {
            email: signupData?.email.toLowerCase(),
            password: signupData?.password,
            conf_password: signupData?.conf_password,
          };
          const { statusCode, data } = await Api.postRequest(
            "/users/register",
            body
          );

          const res = JSON.parse(data);
          if (res?.status == 0) {
            setIsLoading(false);
            setPasswordLengthError(false);
            toast.error(res.message, { autoClose: 1000 });
          } else {
            if (remember === true) {
              let rememberdata = {
                email: signupData.email.toLowerCase(),
                password: signupData.password,
              };
              localStorage.setItem(
                "rememberemail",
                JSON.stringify(rememberdata)
              );
            }
            setIsLoading(false);
            setPasswordLengthError(false);
            toast.success("Verification link send in email.", {
              autoClose: 1000,
            });
            // setUserID(res?.data?.userId)
            navigate(path.login);
          }
        } else {
          toast.error("Password and confirm password must be same", {
            autoClose: 1000,
          });
          setIsEmailShowError(false);
          setPasswordLengthError(false);
          setPasswordError({ ...passwordError, isBlank: true, isError: false });
          setAgainPasswordError({
            ...againPasswordError,
            isBlank: false,
            isError: true,
          });
        }
      } else {
        setPasswordLengthError(true);
        // setAgainPasswordError({ ...againPasswordError, isBlank: true, isError: false })
        // toast.error('Password must have minimum 8 character of Uppercase,Lowercase,Special character and Numbers.');
        setIsEmailShowError(false);
        setPasswordError({ ...passwordError, isBlank: true, isError: true });
        setAgainPasswordError({
          ...againPasswordError,
          isBlank: true,
          isError: false,
        });
      }
    } else {
      if (signupData.password.length >= 8) {
        if (signupData.password == signupData.conf_password) {
          setIsEmailShowError(true);
          setPasswordError({ ...passwordError, isBlank: true, isError: false });
          setAgainPasswordError({
            ...againPasswordError,
            isBlank: false,
            isError: false,
          });
        } else {
          setIsEmailShowError(true);
          setPasswordError({ ...passwordError, isBlank: true, isError: false });
          setAgainPasswordError({
            ...againPasswordError,
            isBlank: false,
            isError: true,
          });
        }
      } else {
        setIsEmailShowError(true);
        setPasswordError({ ...passwordError, isBlank: false, isError: true });
        setAgainPasswordError({
          ...againPasswordError,
          isBlank: false,
          isError: true,
        });
      }
    }
  };

  useEffect(() => {}, [passwordLengthError]);

  return (
    <RegistrationLayout>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={6} className={`${cls}__gridItem`}>
            <img src={logo} alt="logo" className="img-fluid signup_logo" />
          </Grid>
          <Grid item xs={6} className={`${cls}__gridContentItem`}>
            <Box className={`${cls}__gridContentBox`}>
              <Box>
                <Typography style={style.contentTitle}>
                  Get Free Access Now
                </Typography>
              </Box>
              <form onSubmit={onSubmit}>
                <Box className={`${cls}__d-flex`}>
                  <Box
                    style={{
                      position: "relative",
                      displat: "flex",
                      alignItem: "center",
                    }}
                  >
                    <label for="Password" className={`${cls}__label`}>
                      Email
                    </label>

                    <input
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      className={`${cls}__input`}
                      onChange={onEmailSet}
                      value={signupData?.email}
                      style={{ textTransform: "lowercase" }}
                      required
                    />
                    {isEmailShowError && (
                      <img
                        src={cross}
                        alt={"wrong"}
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "15px",
                        }}
                      />
                    )}
                  </Box>
                  <Box style={{ position: "relative" }}>
                    {isEmailShowError && (
                      <div className={`${cls}__error`}>
                        <div className={`${cls}__error_msgBody`}>
                          <p className={`${cls}__error_text`}>
                            Sorry, wrong email
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                </Box>
                <Box className={`${cls}__d-flex`}>
                  <Box
                    style={{
                      position: "relative",
                      alignItem: "center",
                    }}
                  >
                    <label for="Password" className={`${cls}__label`}>
                      Password
                    </label>

                    <input
                      type={showPassword ? "text" : "password"}
                      name="Password"
                      placeholder="Enter Password"
                      className={`${cls}__input`}
                      value={signupData?.password}
                      onChange={onPasswordSet}
                      required
                    />
                    {/* <img
                      src={eye}
                      alt={'wrong'}
                      width={20}
                      style={{
                        position: 'absolute',
                        right: '13px',
                        bottom: '16px',
                        cursor: 'pointer'
                      }}
                      onClick={() => { setShowPassword(!showPassword) }}
                    /> */}
                    <Box
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon
                          sx={{
                            position: "absolute",
                            right: "13px",
                            bottom: "13px",
                            color: "#020922",
                          }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          sx={{
                            position: "absolute",
                            right: "13px",
                            bottom: "13px",
                            color: "#020922",
                          }}
                        />
                      )}
                    </Box>
                    {!passwordError.isBlank && passwordError.isError && (
                      <img
                        src={cross}
                        alt={"wrong"}
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "15px",
                        }}
                      />
                    )}
                    {!passwordError.isBlank && !passwordError.isError && (
                      <img
                        src={check}
                        alt={"right"}
                        style={{
                          position: "absolute",
                          right: "10px",
                          bottom: "15px",
                        }}
                      />
                    )}
                  </Box>
                </Box>
                <Typography
                  style={
                    passwordLengthError
                      ? { color: "red", fontSize: "14px" }
                      : { display: "none" }
                  }
                >
                  Password must have minimum 8 character of
                  Uppercase,Lowercase,Special character and Numbers.
                </Typography>
                <Box className={`${cls}__d-flex`}>
                  <Box
                    style={{
                      position: "relative",
                      alignItem: "center",
                    }}
                  >
                    <label for="Password" className={`${cls}__label`}>
                      Confirm Password
                    </label>

                    <input
                      type={showC_Password ? "text" : "password"}
                      name="Password"
                      placeholder="Enter Confirm Password"
                      className={`${cls}__input`}
                      onChange={onAPasswordSet}
                      value={signupData?.conf_password}
                      required
                    />
                    {/* <img
                      src={eye}
                      alt={'wrong'}
                      width={20}
                      style={{
                        position: 'absolute',
                        right: '13px',
                        bottom: '16px',
                        cursor: 'pointer'
                      }}
                      onClick={() => { setShowC_Password(!showC_Password) }}
                    /> */}
                    {againPasswordError.isBlank &&
                    !againPasswordError.isError ? (
                      <Box
                        onClick={() => {
                          setShowC_Password(!showC_Password);
                        }}
                      >
                        {showC_Password ? (
                          <VisibilityOutlinedIcon
                            sx={{
                              position: "absolute",
                              right: "13px",
                              bottom: "13px",
                              color: "#020922",
                            }}
                          />
                        ) : (
                          <VisibilityOffOutlinedIcon
                            sx={{
                              position: "absolute",
                              right: "13px",
                              bottom: "13px",
                              color: "#020922",
                            }}
                          />
                        )}
                      </Box>
                    ) : null}
                    {!againPasswordError.isBlank && againPasswordError.isError && (
                      <img
                        src={cross}
                        alt={"wrong"}
                        style={{
                          position: "absolute",
                          right: "13px",
                          bottom: "16px",
                        }}
                      />
                    )}
                    {!againPasswordError.isBlank &&
                      !againPasswordError.isError && (
                        <img
                          src={check}
                          alt={"right"}
                          style={{
                            position: "absolute",
                            right: "10px",
                            bottom: "15px",
                          }}
                        />
                      )}
                  </Box>
                  <Box style={{ position: "relative" }}>
                    {isConfirmPasswordError && (
                      <div className={`${cls}__error`}>
                        <div className={`${cls}__error_msgBody`}>
                          <p className={`${cls}__error_text`}>
                            Sorry, wrong password
                          </p>
                        </div>
                      </div>
                    )}
                  </Box>
                </Box>

                <Box className={`${cls}__alignItems`}>
                  {/* <Box className={`${cls}__alignCheckbox`}>
                    <Box className={`${cls}__round`}>
                      <input type="checkbox" id="checkbox" onChange={(e) => setRemember(!remember)} />
                      <label for="checkbox"></label>
                    </Box>
                    <span className={`${cls}__cheackbox_text`}>Remember me</span>
                  </Box> */}
                  <Box className={`${cls}__alignCheckbox`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name="checkbox"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 30 },
                            color: "white",
                          }}
                          onChange={(e) => setRemember(!remember)}
                        />
                      }
                      label="Remember me"
                    />
                  </Box>
                </Box>

                <Box className={`${cls}__margin30 ${cls}__width350`}>
                  <Button style={style.btn} type="submit">
                    SIGN UP
                  </Button>
                </Box>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </RegistrationLayout>
  );
};
export default Signup;
