import React from 'react'
import Modal from '@mui/material/Modal';
import { useEffect, useState, useContext } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Select } from '@mui/material';
import { Button } from '@mui/material'
import roundClose from '../../../assets/img/cancel_icn.png'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import myContext from "../../../screens/MyContext/MyContext"
import './home.css';
const LogoutModel = ({ setLogOutModel }) => {
    const [open, setOpen] = useState(true);

    const [cloudAccountIDList, setCloudAccountIDList] = useState([]);
    const [cloudAccountRegionsList, setCloudAccountRegionsList] = useState([]);
    const { cloudDetails, setCloudDetails } = useContext(myContext);
    const handleClose = () => setLogOutModel(false);
    const navigate = useNavigate()

    const Logout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('userData');
        toast.success("Successfully Logout", { autoClose: 1000 })
        navigate('/')
    }
    useEffect(() => {
        if (
            cloudAccountIDList.length === 0 &&
            cloudAccountRegionsList.length === 0
        ) {
            setCloudDetails({
                ...cloudDetails,
                cloudAccountIDList: '',
                selectedCloudAccountID: '',
                cloudRegionsList: '',
            });
        }
    }, [cloudAccountIDList, cloudAccountRegionsList]);
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ background: 'rgb(225, 225, 225, 0.4)', backdropFilter: 'blur(5px)', display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Box sx={{
                    background: '#010A26', padding: '20px', width: '28%', borderRadius: '0px', maxHeight: '90%', overflow: 'auto', position: "absolute",
                    "&::-webkit-scrollbar": {
                        width: 10,
                        height: 10
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#0000",

                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ADD8E6",
                        borderRadius: 2,
                        border: '0px solid #0000'
                    }
                }}
                    className="Logout-model-container"
                >
                    <Box style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        cursor: 'text',
                        padding: "0px 0px 0px 0px"
                    }}>
                        <img
                            src={roundClose}
                            style={{ cursor: 'pointer' }}
                            onClick={handleClose}
                        />
                    </Box>
                    <Typography variant="h5" align="center" style={{ marginTop: "30px", color: "white" }}>
                        Are sure for logout?
                    </Typography>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "40px 0px 20px 0px" }}>
                        <Button onClick={handleClose} style={{ padding: "10px 20px 10px 20px", marginRight: "5px", background: "green", color: "white" }}>
                            Close
                        </Button>
                        <Button onClick={Logout} style={{ background: "red", color: "white", marginLeft: "5px", padding: "10px 20px 10px 20px" }}>
                            LogOut
                            {/* <ExitToAppIcon style={{ marginLeft: "5px" }} /> */}
                        </Button>
                    </Box>

                </Box>

            </Modal>
        </div>
    )
}


export default LogoutModel

