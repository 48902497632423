import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DonutChart from 'react-donut-chart'
import { Box } from "@mui/system"
import { Typography } from "@mui/material"
import more_btn_icn from '../../assets/img/more_btn_icn.png'
import information from '../../assets/img/information.png'
import ExitfullScreen from '../../assets/img/Exit full Screen@2x.png'
import orangealert from '../../assets/img/alert_icn.png'
import redalert from '../../assets/img/alert_red.png'
import searchicon from '../../assets/img/glass.png'
import rds from '../../assets/img/rds.png'
import { useMemo, useState } from 'react';
import DashboardHeader from '../../Component/DashboardHeader/DashboardHeader';
import DashboardAnalysisOneHeader from '../../Component/DashboardAnalysisHeader/index';
import './style.css'
import { useNavigate } from 'react-router-dom'
import { Api } from '../../MyNewUtils/Api';
import { useEffect } from 'react';
import BucketList from '../CloudInfo/BucketList';
const Zoomchart = () => {
    const [model, setModel] = useState(false)
    const [chartData, setChartData] = useState();
    const [showTable, setShowTable] = useState(false)
    console.log('chartData', chartData)


    const cls = useMemo(() => 'CloudInfoChart', [])
    const navigate = useNavigate()
    const [awsServicesData, setAwsServicesData] = useState()
    const [awsChartData, setAwsChartData] = useState()
    const data = [
        {
            label: 'S3',
            value: 20,
            color: '#FF5774',
        },
        {
            label: 'Lambda',
            value: 25,
            color: '#8A00FF',
        },
        {
            label: 'CloudFront',
            value: 8,
            color: '#00B1FF',
        },
        {
            label: 'Route 53',
            value: 12,
            color: '#64D600',
        },
        {
            label: 'Lightsail',
            value: 23,
            color: '#FFBB00',
        },
        {
            label: 'ES2',
            value: 10,
            color: '#F9874D',
        },
    ]
    const [selected, setSelected] = useState(0)
    const [hovered, setHovered] = useState(undefined)

    const lineWidth = 60

    // new parameter

    const reactDonutChartdata = [
        {
            label: 'S3Buckets',
            value: awsChartData?.numberOfS3Buckets,
            color: '#FF5774',
            data: awsChartData?.S3BucketsArr
        },
        {
            label: 'EC2Instances',
            value: awsChartData?.numberOfEC2Instances,
            color: '#8A00FF',
            data: awsChartData?.EC2InstancesArr
        },
        {
            label: 'RDSInstances',
            value: awsChartData?.numberOfRDSInstances,
            color: '#00B1FF',
            data: awsChartData?.RDSInstancesArr
        },
        {
            label: 'Route 53',
            value: awsChartData?.numberOfVPCs,
            color: '#64D600',
            data: awsChartData?.VPCsArr
        },
        {
            label: 'Lightsail',
            value: awsChartData?.numberOfSecGrps,
            color: '#FFBB00',
            data: awsChartData?.SecGrpsArr
        },
        {
            label: 'ES2',
            value: awsChartData?.numberOfSubnets,
            color: '#F9874D',
            data: awsChartData?.SubnetsArr
        },
    ]
    const reactDonutChartBackgroundColor = [
        '#FF5774',
        '#8A00FF',
        '#00B1FF',
        '#64D600',
        '#FFBB00',
        '#F9874D',
    ]

    const reactDonutChartSelectedOffset = 0.04
    const reactDonutChartHandleClick = (item, toggled) => {
        if (toggled) {
            console.log(item)
        }
    }
    let reactDonutChartStrokeColor = '#FFFFFF'
    const reactDonutChartOnMouseEnter = item => {
        setChartData(item)
        let color = reactDonutChartdata.find(q => q.label === item.label).color
        reactDonutChartStrokeColor = color
        console.log('item', item)
        setShowTable(true)
    }
    function createData(name, icon, date) {
        return { name, icon, date };
    }

    const rows = [
        createData('Frozen yoghurt', orangealert, 159),
        createData('Ice cream sandwich', orangealert, 237),
        createData('Ice cream sandwich', orangealert, 237),
        createData('Eclair', redalert, 262),
    ];
    const getData = async () => {
        let final = { account_type: 'aws', id: JSON.parse(localStorage.getItem('user'))?.data?.id }
        const { statusCode, data } = await ('/accounts/get-account-statistics', final)
        const res = JSON.parse(data)
        console.log('res', res)
        setAwsServicesData(res?.data.servicelist)
        setAwsChartData(res?.data.servicedata)

    }
    const modelOpen = () => {
        setModel(true);
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            {model ? <BucketList setModel={setModel} chartData={chartData} /> : null}
            <DashboardHeader activeTab={'CLOUD INFO'} />
            <DashboardAnalysisOneHeader />
            <Box className={`${cls}__background`} style={{ padding: '50px 100px 50px 100px' }}>
                <Box
                    style={{
                        width: '100%',
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        padding: '20px 40px',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '20px',
                    }}
                >

                    <Box
                        style={{
                            display: 'flex',
                            marginTop: '10px',
                            justifyContent: 'space-between',
                        }}
                    >

                        <Box>
                            <Box>
                                <Typography style={{ color: 'white', fontSize: '14px' }}>
                                    AWS Services
                                </Typography>
                            </Box>
                            <Box>

                                {reactDonutChartdata.map((row, index) => (
                                    <Box
                                        style={{
                                            display: 'flex',
                                            marginTop: '10px',
                                            color: 'white',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                width: '17px',
                                                height: '11px',
                                                background: row?.color,
                                                marginRight: '5px',
                                            }}
                                        ></Box>
                                        <Typography style={{ fontSize: '12px' }}>{row.label}</Typography>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        <Box className="App" style={{ background: '#0000', width: '600px', height: '600px' }}>
                            <Box sx={{ paddiing: 'auto', display: 'flex', position: 'absolute', flexDirection: 'column', justifyContent: 'flex-start', width: '600px', height: '600px', textAlign: 'center' }}>
                                <DonutChart
                                    onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                                    strokeColor={reactDonutChartStrokeColor}
                                    data={reactDonutChartdata}
                                    colors={reactDonutChartBackgroundColor}
                                    selectedOffset={reactDonutChartSelectedOffset}
                                    onClick={(item, toggled) => reactDonutChartHandleClick(item, toggled)}
                                />
                            </Box>
                            <Box sx={{ marginLeft: '150px', marginTop: '110px', position: 'absolute', color: '#fff', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center', width: 'auto', height: 'auto', textAlign: 'center' }}>
                                <Typography style={showTable ? { fontSize: '28px' } : { display: 'none' }}>{chartData?.label}</Typography>
                                <TableContainer component={Paper} style={showTable ? { width: '200px', height: '150px', background: 'rgba(232, 84, 114,0.25)', borderRadius: '30px', padding: '0 15px 0 15px' } : { display: 'none' }}>
                                    <Table aria-label="simple table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ border: 'none', padding: '20px 0 0 0', color: '#fff' }}>Name</TableCell>
                                                <TableCell sx={{ border: 'none', padding: '20px 0 0 0', color: '#fff' }}>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {chartData?.data?.slice(0, 3).map((row, index) => (
                                                <TableRow
                                                    key={row[index]?.name}
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >
                                                    <TableCell component="th" scope="row" sx={{ border: 'none', color: '#796473', fontSize: '12px', padding: '0px' }}>{row[index]?.title}</TableCell>
                                                    <TableCell align="right" sx={{ border: 'none', color: '#796473', fontSize: '12px', padding: '0px' }}>{row[index]?.value}</TableCell>
                                                </TableRow>
                                            ))}

                                        </TableBody>
                                        {/* <Typography style={{ fontSize: '12px', color: '#fff', color: '#fff', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>View in Details <img style={{ width: '13px', height: '13px' }} src={searchicon} /></Typography> */}
                                    </Table>
                                    <Typography style={{ fontSize: '12px', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }} onClick={() => { modelOpen() }}>View in Details <img style={{ width: '13px', height: '13px' }} src={searchicon} /></Typography>
                                </TableContainer>
                                <Typography style={showTable ? { fontSize: '50px', fontWeight: 'bold' } : { fontSize: '100px', fontWeight: 'bold' }}>{awsChartData?.totalservices}</Typography>
                                <Typography style={showTable ? { fontSize: '26px', color: 'rgba(255, 255, 255, 0.25)' } : { fontSize: '56px', color: 'rgba(255, 255, 255, 0.25)' }}>Services</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>

                                <Box>
                                    <img src={ExitfullScreen} onClick={() => { navigate('/cloudinfo-chart') }} style={{ width: '20px', height: '20px' }} />
                                    <img src={information} style={{ margin: '0px 20px', width: '20px', height: '20px' }} />
                                    <img src={more_btn_icn} style={{ height: '20px' }} />
                                </Box>
                            </Box>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default Zoomchart;