import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import RadioGroup from '@mui/material/RadioGroup';
import { FormControlLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import { Api } from '../../../MyNewUtils/Api';
import wallet from '../../../assets/img/wallet.png'
import laptop from '../../../assets/img/laptop.png'
import briefcase from '../../../assets/img/briefcase.png'
import FeturesCard from '../../../Component/upgradePlanDetails/Card/FeturesCard';
import CustomButton from "../../../Component/CustomButton/index"
import building from '../../../assets/img/building.png';
import './Upgrademodel.css';
import { toast } from 'react-toastify'
import { height } from '@mui/system';
const style = {
    position: 'absolute',
    bgcolor: '#01081D',
    border: '1px solid #707070',
    boxShadow: 24,
    p: 4,
    paddingLeft: '5%',
    paddingRight: '5%',
    // width: '80%',
    width: '93%',
    height: '80vh',
    overflow: 'auto'
};

const Upgradeplan = ({ disable, setUpgradeplanmodel }) => {
    const [selectedPlan, setSelectedPlan] = useState(
        {
            planid: "",
            isannualplan: false,
            transactionAmount: "",
            annualplan: '',
            noofusers: 1
        }
    );
    const [select, setSelect] = useState(true);
    const [open, setOpen] = React.useState(true);
    const [plans, setPlans] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setUpgradeplanmodel(false);


    const plandetails = async () => {
        const { statusCode, data } = await Api.getRequest('/plans/get-all-plans')
        const res = JSON.parse(data)["plans"]

        let data1 = res.map((item) => {
            let icon = wallet
            let price = item.planPricePerMonth
            let time = 'Month'
            if (select == true) {
                price = item.planPricePerMonth
                time = 'Month'
            } else {
                price = item.planPricePerYear
                time = 'Year'
            }

            if (item?.planName == "Basic") {
                icon = laptop
            }
            if (item?.planName == "Premium") {
                icon = briefcase
            }
            if (item?.planName == "Enterprise") {
                icon = laptop
            }
            let rest = {
                name: item?.planName,
                title: `${item?.planName}`,
                price: `${(price == "0" ? "Free" : price)}`,
                annualplan: `${(price == "0" ? "" : ("/" + time))}`,
                planid: `${item?.id}`,
                fetures: JSON.parse(item?.planHtml),
                icon: icon,
                isenterprise: item?.isenterprise
            }
            return rest
        })
        setPlans(data1)
    }

    const getUpgradePlan = async () => {
        // "userid", "planid", "isannualplan"
        // /users/upgrade-subscription
        let body = {
            userid: JSON.parse(localStorage.getItem('user'))?.data?.id,
            planid: selectedPlan?.planid,
            isannualplan: selectedPlan?.isannualplan
        }
        if (selectedPlan?.planid?.length === 0) {
            toast.error('Select Plan', { autoClose: 1000 })
        } else {
            const { statusCode, data } = await Api.postRequest('/users/upgrade-subscription', body)
            const res = JSON.parse(data)
            if (res?.status === 1) {
                toast.success(res?.message, { autoClose: 1000 })
                handleClose()
            } else {
                toast.error(res?.message, { autoClose: 1000 })
            }
        }
    }
    React.useEffect(() => {
        plandetails()
        if (select == true) {
            setSelect(true)
        } else {
            setSelect(false)
        }
    }, [select])

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                className="outer_border"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ color: '#FF8800', fontSize: '36px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-around' }}>
                        Upgrade the plan
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }} style={{ color: '#fff', fontSize: '22px', fontWeight: 'bold', display: 'flex', justifyContent: 'space-around' }}>
                        <Typography style={{ color: '#fff', fontSize: '22px', fontWeight: 'bold' }}>If you need more info, please check <span style={{ color: '#008CF2', fontSize: '22px', fontWeight: 'bold' }}>Pricing Guidelines.</span></Typography>
                    </Typography>
                    <Typography style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Box
                            style={{
                                padding: '5px',
                                borderRadius: '20px',
                                backgroundColor: '#000',
                                border: 'solid 1px #707070'
                            }}
                        >
                            <Button
                                style={{
                                    backgroundColor: select && 'white',
                                    borderRadius: '20px',
                                    width: '100px',
                                }}
                                onClick={() => setSelect(!select)}
                            >
                                Monthly
                            </Button>
                            <Button
                                style={{
                                    backgroundColor: !select && 'white',
                                    borderRadius: '20px',
                                    width: '100px',
                                }}
                                onClick={() => setSelect(!select)}
                            >
                                Yearly
                            </Button>
                        </Box>

                    </Typography>
                    <Typography style={{ marginTop: '56px', display: 'flex' }}>
                        <RadioGroup row aria-label="gender" name="row-radio-buttons-group"
                            style={{ display: 'flex', justifyContent: 'space-between' }}
                            className="plan_detail_content"
                        >
                            {
                                plans.map((items) => (
                                    // { console.log("DS", items) }
                                    !items.isenterprise &&
                                    <FeturesCard
                                        select={select}
                                        setSelectedPlan={setSelectedPlan}
                                        selectedPlan={selectedPlan}
                                        title={items.title}
                                        price={items.price}
                                        annual={items.annualplan}
                                        fetures={items.fetures}
                                        planid={items.planid}
                                        annualplan={items.annualplan}
                                        icon={items.icon}
                                        backgroundColor={true}

                                    />
                                ))
                            }

                            <Box
                                style={{
                                    width: '25%',
                                    // backgroundColor: 'red',
                                    paddingRight: '20px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box
                                    style={{
                                        flexGrow: 1,
                                        borderRadius: '30px',
                                        backgroundColor: "rgb(0,0,0,0.40)",
                                        padding: '20px',
                                        border: '3px solid #008CF2',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >

                                    <Box
                                        style={{
                                            marginTop: '20px',
                                            flexDirection: 'column'
                                        }}
                                    >
                                        <Box style={{ marginLeft: '20px' }}>
                                            <Typography style={{ fontSize: '22px', color: '#fff' }} className="box_responsive_fontsize">
                                                Need Enterprise level support ?
                                            </Typography>
                                            <Typography style={{ fontSize: '14px', color: '#fff' }} className="box_responsive_fontsize2">
                                                From $ 15,000 a month you will receive 15 - minute response times
                                                and concierge-style experience with an assigned Technical Account
                                                Manager.{' '}
                                                {/* <span style={{ color: '#008CF2' }}>
                        Learn More &nbsp;
                        <img src={exportImg} />
                      </span> */}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <CustomButton
                                        text={'Contact Us'}
                                        styles={{
                                            backgroundColor: 'rgb(255,131,0)',
                                            color: '#FFF',
                                            marginTop: '20px',
                                            width: 'auto',
                                            borderRadius: '2px',
                                            padding: '10px 18px',
                                        }}
                                    />
                                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img src={building} width='80%' className="contact_img" />
                                    </Box>

                                </Box>
                            </Box>
                        </RadioGroup>
                    </Typography>
                    <Typography style={{ display: 'flex', justifyContent: 'center', marginTop: '5%' }}>
                        <Button onClick={getUpgradePlan} style={{ background: '#f68903', color: '#fff', fontSize: '20px', fontWeight: 'bold', paddingLeft: '10%', paddingRight: '10%' }}>Upgrade</Button>
                    </Typography>
                </Box>
            </Modal>
        </div >
    );
}
export default Upgradeplan;