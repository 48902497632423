import { Api } from "../../MyNewUtils/Api";
import { FETCH_CHART_DATA, RESET_CHART_DATA, SET_CHART_DATA, SET_CHART_LOADING } from "../constants/chartsActionType";

export const fetchChartData = (chartId, filters) => ({
    type: FETCH_CHART_DATA,
    payload: { chartId, filters }
});

export const setChartData = (chartId, data) => ({
    type: SET_CHART_DATA,
    payload: { chartId, data }
});

export const resetChartData = (chartId, data) => ({
    type: RESET_CHART_DATA,
    payload: { chartId, data }
});

export const setChartLoading = (chartId, isLoading) => ({
    type: SET_CHART_LOADING,
    payload: { chartId, isLoading }
});


export const fetchChartDataAction = (chartId, filters) => async (dispatch) => {
    dispatch(setChartLoading(chartId, true));

    try {
        const response = await Api.axiospostRequest(chartId, filters);
        const data = await response;
        dispatch(setChartData(chartId, data));
    } catch (error) {
        console.error('Failed to fetch chart data:', error);
        dispatch(setChartLoading(chartId, false));
    }
};