import { Box } from '@mui/system'
import RegistrationLayout from '../../Component/Layout/index'
import logo from '../../assets/img/logo_headerr.png'

import rocket from '../../assets/img/rocket.png'
import { Typography } from '@mui/material'
import CustomButton from "../../Component/CustomButton/index";
import Spinner from '../../Component/Spinner/Spinner'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SignupStepSix = () => {
  const [isLoading, setLoading] = useState(true)
  const navigate = useNavigate()
  return (
    <RegistrationLayout isHeaderShow={false}>
      <Box
        style={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          style={{
            backgroundColor: 'rgba(0,0,0,0.5)',
            border: '1px solid grey',
            borderRadius: '30px',
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '40px 0',
          }}
        >
          {/* logo */}
          <Box>
            <img src={logo} style={{ width: '500px', height: 'auto' }} />
          </Box>

          {/* loader */}
          <Box>
            <Spinner
              isLoading={isLoading}
              color={'white'}
              styles={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '40px',
                marginBottom: '30px',
              }}
            />
          </Box>

          {/* connecting payment */}
          <Box>
            <Typography
              style={{ color: '#FF8800', fontSize: '36px', textAlign: 'center' }}
            >
              Connecting payment with your bank
            </Typography>
            <Typography
              style={{
                color: '#008CF2',
                fontSize: '36px',
                textAlign: 'center',
                marginTop: '20px',
              }}
              onClick={() => {
                navigate('/cloud-setup-wizard')
              }}
            >
              Please wait
            </Typography>
          </Box>
        </Box>
      </Box>
    </RegistrationLayout>
  )
}
export default SignupStepSix
