export const path = {
  signup: "/signup",
  login: "/",
  dashBoardHome: "/dashboard",
  step2: "/Sign-up-for-cleveron",
  step3: "/verification-cleveron",
  step4: "/confirm-identity",
  step5: "/confirm-identity-verification",
  step6: "/upgrade-plan",
  step7: "/congratulation-message",
};
