import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Api } from "../../../MyNewUtils/Api";
import './style.css';
const style = {
  position: "absolute",
  // transform: 'translate(-50%, -50%)',
  width: "50vw",
  bgcolor: "#01081D",
  border: " 1px solid #707070",
  boxShadow: 24,
  p: 4,
  borderRadius: "20px",
};

const UpdateEmail = ({ disable, setUpdateEmail }) => {
  const [email, setEmail] = useState(
    JSON.parse(localStorage.getItem("user"))?.data?.email
  );
  const [emailAddress, setEmailAddress] = useState();
  const [open, setOpen] = React.useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setUpdateEmail(false);

  React.useEffect(() => { }, [disable]);
  const navigate = useNavigate();
  const update = async () => {
    if (emailAddress.length !== 0) {
      let final = {
        id: JSON.parse(localStorage.getItem("user")).data.id,
        email: emailAddress,
      };
      const { statusCode, data } = await Api.postRequest(
        "/users/send-otp-for-change-email",
        final
      );
      const res = JSON.parse(data);
      if (res.status == 1) {
        handleClose();
        toast.success(res?.message, { autoClose: 1000 });
      } else {
        toast.error(res?.message, { autoClose: 1000 });
      }
    } else {
      toast.error("Enter valid Email", { autoClose: 1000 });
    }
  };
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ fontSize: "30px", textAlign: "center", color: "#008CF2" }}
          >
            Update Email
          </Typography>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{
              fontSize: "22px",
              textAlign: "center",
              color: "#FFFFFF",
              marginTop: "32px",
            }}
          >
            Do you want to Update Email:-{" "}
            <span style={{ color: "#7CECF7" }}>{email}</span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              style={{ fontSize: "22px", color: "#008CF2", marginTop: "32px" }}
            >
              Enter new Email address
            </Typography>
            <Typography>
              <input
                type="email"
                style={{
                  width: "400px",
                  height: "40px",
                  background: "#0000",
                  color: "#fff",
                  border: "1px solid #363636",
                  padding: "5%",
                }}
                className="enter_email_input_email"
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                placeholder="Enter New Email"
              />
            </Typography>
          </Box>
          <Typography
            id="modal-modal-description"
            sx={{ mt: 2 }}
            style={{
              marginTop: "32px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Stack spacing={2} direction="row">
              <Button variant="outlined" onClick={handleClose}>
                No, cancel
              </Button>
              <Button variant="contained" onClick={update}>
                Yes, Update
              </Button>
            </Stack>
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};
export default UpdateEmail;
