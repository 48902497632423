import { Box } from "@mui/system";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import { Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import roundClose from "../../assets/img/cancel_icn.png";
import { toast } from "react-toastify";
import SearchBarMenu from "./SearchBarMenu";

const SearchBar = ({ refresh, setRefresh, setSearched }) => {
  const [SearchBarMenumodel, setSearchBarMenuModel] = useState(false);

  const handleClick = (event) => {
    setSearchBarMenuModel(true);
  };

  const requestSearch = (searchedVal) => {
    setSearched(searchedVal);
  };

  return (
    <>
      {SearchBarMenumodel ? (
        <SearchBarMenu
          setSearchBarMenuModel={setSearchBarMenuModel}
          setRefresh={setRefresh}
          refresh={refresh}
        />
      ) : null}

      <Box style={{ padding: "10px", display: "flex", alignItems: "center" }}>
        <Box
          style={{
            display: "flex",
            backgroundColor: "#272E42",
            borderRadius: "10px",
            padding: "5px 30px",
            flex: 1,
          }}
        >
          <SearchIcon style={{ color: "white", margin: "5px" }} />
          <input
            placeholder={"Search user"}
            onChange={(e) => requestSearch(e.target.value)}
            style={{
              border: "none",
              outline: "none",
              padding: "5px",
              width: "100%",
              backgroundColor: "transparent",
              color: "white",
            }}
          />
        </Box>
        <Box style={{ marginLeft: "20px" }}>
          <Button
            variant="contained"
            style={{ borderRadius: "20px" }}
            startIcon={<AddCircleRoundedIcon />}
            onClick={handleClick}
          >
            Add User
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SearchBar;
