import reducer from "./dashboardData";
import { combineReducers } from "redux"
import { chatReducer, discoverReducer } from "./discoverReducer";
import { chartsReducer } from './chartsReducer';


const rootReducer = combineReducers({
  reducer,
  discover: discoverReducer,
  chat: chatReducer,
  charts: chartsReducer,
})

export default rootReducer