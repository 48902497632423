import React from 'react'
import Modal from '@mui/material/Modal';
import { useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button } from '@mui/material'
// import roundClose from '../../assets/img/cancel_icn.png'

const ConfigureCloudMessageModel = ({ setConfigureCloudModel }) => {
    const [open, setOpen] = useState(true);
    // const handleClose = () => setConfigureCloudModel(false);

    const handleClose = () => {
        // navigate('/cloud-setup-wizard')
        // setConfigureCloudModel(false)
        setOpen(false)
    }

    return (
        <div>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{ background: 'rgb(225, 225, 225, 0.4)', backdropFilter: 'blur(5px)', display: "flex", justifyContent: "center", alignItems: "center" }}
            >
                <Box sx={{
                    background: '#010A26', padding: '20px', width: '28%', borderRadius: '20px', maxHeight: '90%', overflow: 'auto', position: "absolute",
                    "&::-webkit-scrollbar": {
                        width: 10,
                        height: 10
                    },
                    "&::-webkit-scrollbar-track": {
                        backgroundColor: "#0000",

                    },
                    "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ADD8E6",
                        borderRadius: 2,
                        border: '0px solid #0000'
                    }
                }}>
                    {/* <Box style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        cursor: 'text',
                        padding: "0px 0px 0px 0px"
                    }}>
                        <img
                            src={roundClose}
                            style={{ cursor: 'pointer' }}
                            onClick={handleClose}
                        />
                    </Box> */}
                    <Typography variant="h5" align="center" style={{ marginTop: "30px", color: "white" }}>
                        Please Configure Cloud Account First
                    </Typography>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "40px 0px 20px 0px" }}>
                        <Button onClick={handleClose} style={{ padding: "10px 20px 10px 20px", marginRight: "5px", background: "green", color: "white" }}>
                            Ok
                        </Button>
                    </Box>

                </Box>

            </Modal>
        </div>
    )
}


export default ConfigureCloudMessageModel

