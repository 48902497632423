import instance from '../utils/axios';

export const generateAnswer = async (question) => {
  try {
    const response = await instance.post('/api/discover', question);

    if (!response?.data?.answer) {
      throw new Error('Invalid response from server');
    }
    return response.data.answer;

  } catch (error) {
    if (error.response.status === 400) {
      throw error.response.data.error;
    }
    if (error.response.status >= 300) {
      throw "Oops! Something went wrong. Please try again";
    }
    console.error('Error occurred while discovering:', error.message);
    throw error;
  }
};
