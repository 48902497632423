import { Box } from '@mui/system'
import logo from '../../assets/img/logo_headerr.png'
const SignupStep8Col1 = () => {
  return (
    <Box style={{ width: '100%' }}>
      <img
        src={logo}
        style={{
          width: '100%',
          height: 'auto',
          backgroundSize: 'contain',
          display: 'inline-block',
        }}
        alt={'logo'}
      />
    </Box>
  )
}
export default SignupStep8Col1
