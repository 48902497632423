import { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import TextField from "@mui/material/TextField";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ReactFileReader from "react-file-reader";
import CloseIcon from "@mui/icons-material/Close";
import ClearPageModel from "./model/clearPageModel";
import Upgradeplan from "../../../dashboard/Profile/Upgrademodel";
import UploadIcon from "../../../../assets/img/uploadIcon.png";
import SubLoader from "../../../../Component/Spinner/MainLoader";
import { Api } from "../../../../MyNewUtils/Api";
import DashboardHeader from "../../../../Component/DashboardHeader/DashboardHeader";
import DeskTable from "../../../../assets/img/DeskTable.png";
import exportIcon from "../../../../assets/img/export@2.png";
import "./createCase.css";
const CreateCase = () => {
  const [activeStep, setaAtiveStep] = useState(1);
  const [clearModel, setClearModel] = useState(false);
  const [selectedIssueValue, setSelectedIssueValue] = useState();
  const [upgradeSupportPlanModel, setUpgradeSupportPlanModel] = useState(false);
  const [listSupportTicketsOptions, setListSupportTicketsOptions] = useState(
    []
  );
  const [loader, setLoader] = useState(false);
  const [accountAndBillingSelectItem, setAccountAndBillingSelectItem] =
    useState({
      Service: "",
      Category: "",
      Severity: "",
    });
  const [technicalSelectItem, setTechnicalSelectItem] = useState({
    Service: "",
    Category: "",
    Severity: "",
  });

  const [additionalInformation, setAdditionalInformation] = useState({
    Subject: "",
    Description: "",
    file: [],
  });

  let navigate = useNavigate();
  const cls = useMemo(() => "SectionPageOne", []);

  useEffect(() => {
    getListSupportTicketsOptions();
  }, []);

  const handleChange = (event) => {
    setSelectedIssueValue(event.target.value);
  };

  const handleChangeAccountAndBillingSeverity = (event) => {
    console.log("event.target.name", event.target.name);
    if (selectedIssueValue === "Technical") {
      if (event.target.name === "Service") {
        setTechnicalSelectItem({
          ...technicalSelectItem,
          [event.target.name]: event.target.value,
          Category: "",
        });
      } else {
        setTechnicalSelectItem({
          ...technicalSelectItem,
          [event.target.name]: event.target.value,
        });
      }
    } else if (selectedIssueValue === "Account and billing") {
      if (event.target.name === "Service") {
        setAccountAndBillingSelectItem({
          ...accountAndBillingSelectItem,
          [event.target.name]: event.target.value,
          Category: "",
        });
      } else {
        setAccountAndBillingSelectItem({
          ...accountAndBillingSelectItem,
          [event.target.name]: event.target.value,
        });
      }
    }
  };
  const getListSupportTicketsOptions = async () => {
    setLoader(true);
    let payload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    };
    const data = await Api.postRequest(
      "/users/list-support-tickets-options",
      payload
    );
    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      setLoader(false);
      setListSupportTicketsOptions(res?.data);
    }
    setLoader(false);
  };
  const handleFiles = (files) => {
    if (files?.fileList[0]?.size <= 4908407.5) {
      if (additionalInformation?.file?.length < 3) {
        let additionalInformationFiles = additionalInformation?.file;
        additionalInformationFiles[additionalInformationFiles?.length] =
          files?.base64;
        setAdditionalInformation({
          ...additionalInformation,
          file: additionalInformationFiles,
        });
      } else {
        toast.error("Attach Only 3 File", {
          autoClose: 1000,
        });
      }
    } else {
      toast.error("Each file can be up to 5 MB", {
        autoClose: 1000,
      });
    }
  };
  const removeAdditionalInformation = (e) => {
    let removeFile = additionalInformation?.file.filter(
      (file, index) => index !== e
    );
    setAdditionalInformation({ ...additionalInformation, file: removeFile });
  };
  const handleNextStepAdditionalInformation = () => {
    if (selectedIssueValue) {
      if (selectedIssueValue === "Account and billing") {
        if (
          accountAndBillingSelectItem?.Service &&
          accountAndBillingSelectItem?.Service.length != 0
        ) {
          if (
            accountAndBillingSelectItem?.Category &&
            accountAndBillingSelectItem?.Category.length != 0
          ) {
            if (
              accountAndBillingSelectItem?.Severity &&
              accountAndBillingSelectItem?.Severity.length != 0
            ) {
              setaAtiveStep(2);
            } else {
              toast.error("Please Select Severity", {
                autoClose: 1000,
              });
            }
          } else {
            toast.error("Please Select Category", {
              autoClose: 1000,
            });
          }
        } else {
          toast.error("Please Select Service", { autoClose: 1000 });
        }
      } else if (selectedIssueValue === "Technical") {
        if (
          technicalSelectItem?.Service &&
          technicalSelectItem?.Service.length != 0
        ) {
          if (
            technicalSelectItem?.Category &&
            technicalSelectItem?.Category.length != 0
          ) {
            if (
              technicalSelectItem?.Severity &&
              technicalSelectItem?.Severity.length != 0
            ) {
              setaAtiveStep(2);
            } else {
              toast.error("Please Select Severity", {
                autoClose: 1000,
              });
            }
          } else {
            toast.error("Please Select Category", {
              autoClose: 1000,
            });
          }
        } else {
          toast.error("Please Select Service", { autoClose: 1000 });
        }
      }
    } else {
      toast.error("Please Choose Related Issue Type", { autoClose: 1000 });
    }
  };
  const handleSupportCase = () => {
    navigate("/allCases");
  };
  const handleSupportCenter = () => {
    navigate("/supports");
  };
  const submitTicket = async () => {
    if (
      additionalInformation?.Subject &&
      additionalInformation?.Subject?.length
    ) {
      if (
        additionalInformation?.Description &&
        additionalInformation?.Description?.length
      ) {
        setLoader(true);
        let payload = {};
        if (selectedIssueValue === "Account and billing") {
          payload = {
            userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
            issuetype: selectedIssueValue,
            service: accountAndBillingSelectItem?.Service,
            servicecategory: accountAndBillingSelectItem?.Category,
            severity: accountAndBillingSelectItem?.Severity,
            subject: additionalInformation?.Subject,
            description: additionalInformation?.Description,
            problemfiles: additionalInformation?.file,
          };
        } else if (selectedIssueValue === "Technical") {
          payload = {
            userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
            issuetype: selectedIssueValue,
            service: technicalSelectItem?.Service,
            servicecategory: technicalSelectItem?.Category,
            severity: technicalSelectItem?.Severity,
            subject: additionalInformation?.Subject,
            description: additionalInformation?.Description,
          };
        } else {
          payload = {
            userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
            issuetype: selectedIssueValue,
            service: "",
            servicecategory: "",
            severity: "",
            subject: additionalInformation?.Subject,
            description: additionalInformation?.Description,
          };
        }
        const { data } = await Api.postRequest(
          "/users/add-support-ticket",
          payload
        );
        const res = JSON.parse(data);

        if (res?.status === 1) {
          setLoader(false);
          setaAtiveStep(3);
          toast.success(res?.message, { autoClose: 1000 });
        } else {
          setLoader(false);
          toast.error(res?.message, { autoClose: 1000 });
        }
      } else {
        toast.error("Please Enter Description", { autoClose: 1000 });
      }
    } else {
      toast.error("Please Enter Subject", { autoClose: 1000 });
    }
  };
  return (
    <Box
      className={`${cls}__background`}
    >
      <SubLoader isLoading={loader} />
      {clearModel && (
        <ClearPageModel clearModel={clearModel} setClearModel={setClearModel} />
      )}
      {upgradeSupportPlanModel ? (
        <Upgradeplan setUpgradeplanmodel={setUpgradeSupportPlanModel} />
      ) : null}
      <DashboardHeader
        activeTab={"SETTINGS"}
        activeSettingSubTab={"Supports"}
      />
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box className="SupportCenter_Box ">
          <Box className="d-flex">
            <Typography
              className="SupportCenter_Cases_heading_text text-bold"
              onClick={handleSupportCenter}
              style={{ cursor: "pointer" }}
            >
              CLEVRONE Support Center
            </Typography>
            <Typography className="SupportCenter_Cases_heading_text margin-left-20px">
              <ArrowForwardIosIcon className="SupportCenter_Cases_heading_text" />
            </Typography>
            <Typography
              className="SupportCenter_Cases_heading_text margin-left-20px"
              onClick={handleSupportCase}
              style={{ cursor: "pointer" }}
            >
              Your support cases
            </Typography>
            <Typography className="SupportCenter_Cases_heading_text margin-left-20px">
              <ArrowForwardIosIcon className="SupportCenter_Cases_heading_text" />
            </Typography>
            <Typography className="SupportCenter_Cases_heading_text margin-left-20px">
              Create case
            </Typography>
          </Box>

          <Box className="create_case_sub_box">
            <Box className="d-flex justify-content-center create_case_hello_here_text">
              <Box sx={{ marginRight: "20px" }}>
                <img src={DeskTable} alt="" />
              </Box>
              <Box>
                <Box>
                  <Typography className="SupportCenter_heading_text">
                    Hello ! <Box>We're here to help.</Box>
                  </Typography>
                </Box>
                <Box className="d-flex  justify-between account_support_pan_detail">
                  <Typography
                    className="Create_Case_sub_heding_text"
                  >
                    Account:
                    {JSON.parse(localStorage.getItem("user"))?.data?.email}
                  </Typography>
                  <Typography className="Create_Case_sub_heding_text">
                    Support plan : Basic
                  </Typography>
                  <Typography
                    className="Create_Case_sub_heding_text_color_primary cursor-pointer"
                    onClick={() => setUpgradeSupportPlanModel(true)}
                  >
                    Change
                    <img
                      src={exportIcon}
                      alt=""
                      width="15px"
                      style={{ marginLeft: "5px" }}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className="SupportCenter_Sub_Box">
              <Box className="d-flex justify-between">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box>
                            <Typography
                              className={`SupportCenter_Sub_Box_step_text ${activeStep === 1
                                ? "SupportCenter_Sub_select_color"
                                : "SupportCenter_Sub_unselect_color"
                                }`}
                            >
                              Step 1
                            </Typography>
                            <Typography
                              className={`SupportCenter_Sub_Box_text p-bottom-30 ${activeStep === 1
                                ? "SupportCenter_Sub_select_color"
                                : "SupportCenter_Sub_unselect_color"
                                }`}
                            >
                              How can we help?
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography
                              className={`SupportCenter_Sub_Box_step_text ${activeStep === 2
                                ? "SupportCenter_Sub_select_color"
                                : "SupportCenter_Sub_unselect_color"
                                }`}
                            >
                              Step 2
                            </Typography>
                            <Typography
                              className={`create_Case_Sub_Box_Dactive_text p-bottom-30 ${activeStep === 2
                                ? "SupportCenter_Sub_select_color"
                                : "SupportCenter_Sub_unselect_color"
                                }`}
                            >
                              Additional information
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box>
                            <Typography
                              className={`SupportCenter_Sub_Box_step_text ${activeStep === 3
                                ? "SupportCenter_Sub_select_color"
                                : "SupportCenter_Sub_unselect_color"
                                }`}
                            >
                              Step 3
                            </Typography>
                            <Typography
                              className={`border_none create_Case_Sub_Box_Dactive_text p-bottom-30 ${activeStep === 3
                                ? "SupportCenter_Sub_select_color"
                                : "SupportCenter_Sub_unselect_color"
                                }`}
                            >
                              Contact us
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                    {activeStep === 1 && (
                      <Grid item xs={9}>
                        <Box className="SupportCenter_heading_text">
                          How can we help?
                        </Box>
                        <Typography className="font-size-24 margin-top-30">
                          Choose the related issue for your case.
                        </Typography>

                        <Box sx={{ flexGrow: 1 }} className="margin-top-30">
                          <Grid container spacing={0} className="radio-opt-box">
                            <Grid
                              item
                              xs={3}
                              className="d-flex items-center full-box-border box-fit-width"
                              style={{ marginBottom: "12px" }}
                            >
                              <Radio
                                checked={
                                  selectedIssueValue === "Account and billing"
                                }
                                onChange={handleChange}
                                value="Account and billing"
                                name="radio-buttons"
                                inputProps={{
                                  "aria-label": "Account and billing",
                                }}
                                sx={{
                                  color: "#fff",
                                  "&.Mui-checked": {
                                    color: "#fff",
                                  },
                                }}
                              />
                              <Typography>Account and billing</Typography>
                            </Grid>
                            <Grid xs={1} />
                            <Grid
                              item
                              xs={3}
                              className="d-flex items-center full-box-border box-fit-width"
                              style={{ marginBottom: "12px" }}
                            >
                              <Radio
                                checked={selectedIssueValue === "Technical"}
                                onChange={handleChange}
                                value="Technical"
                                name="radio-buttons"
                                inputProps={{ "aria-label": "Technical" }}
                                sx={{
                                  color: "#fff",
                                  "&.Mui-checked": {
                                    color: "#fff",
                                  },
                                }}
                              />
                              <Typography>Technical</Typography>
                            </Grid>
                            <Grid xs={1} />
                            {/* <Grid item xs={4} className="d-flex items-center">
                            <Typography className="text_color_primary d-flex items-center">
                              Looking for service limit increases?
                            </Typography>
                          </Grid> */}
                          </Grid>
                        </Box>
                        {selectedIssueValue === "Account and billing" && (
                          <Grid
                            container
                            spacing={0}
                            className="Account_and_billing_box margin-top-30"
                          >
                            <Grid item xs={12} className="margin-top-30">
                              <Typography className="text_color_primary">
                                Service
                              </Typography>
                              <Box className="margin-top-10">
                                <Grid container spacing={0}>
                                  <Grid item xs={12} md={12} lg={6}>
                                    <Select
                                      name="Service"
                                      onChange={
                                        handleChangeAccountAndBillingSeverity
                                      }
                                      value={
                                        accountAndBillingSelectItem?.Service
                                      }
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                        style: {
                                          padding: "0px",
                                          margin: "0px",
                                          border: "1px solid #707070",
                                        },
                                      }}
                                      style={{
                                        backgroundColor: "#0000",
                                        width: "100%",
                                        color: "white",
                                        border: "1px solid #707070",
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            background: "#010A26",
                                            border: "1px solid #707070",
                                          },
                                        },
                                      }}
                                      IconComponent={KeyboardArrowDownIcon}
                                    >
                                      <MenuItem
                                        value=""
                                        className="text-color-white"
                                      >
                                        Select Service
                                      </MenuItem>
                                      {`${listSupportTicketsOptions}?."Account and billing"` &&
                                        listSupportTicketsOptions?.[
                                          "Account and billing"
                                        ].map((option) => {
                                          return (
                                            <MenuItem
                                              className="text-color-white"
                                              value={option?.service}
                                            >
                                              {option?.service}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item xs={12} className="margin-top-30">
                              <Typography className="text_color_primary">
                                Category
                              </Typography>
                              <Box className="margin-top-10">
                                <Grid container spacing={0}>
                                  <Grid item xs={12} md={12} lg={6}>
                                    <Select
                                      name="Category"
                                      onChange={
                                        handleChangeAccountAndBillingSeverity
                                      }
                                      value={
                                        accountAndBillingSelectItem?.Category
                                      }
                                      defaultValue=""
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                        style: {
                                          padding: "0px",
                                          margin: "0px",
                                          border: "1px solid #707070",
                                        },
                                      }}
                                      style={{
                                        backgroundColor: "#0000",
                                        width: "100%",
                                        color: "white",
                                        border: "1px solid #707070",
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            background: "#010A26",
                                            border: "1px solid #707070",
                                          },
                                        },
                                      }}
                                      IconComponent={KeyboardArrowDownIcon}
                                    >
                                      <MenuItem
                                        value=""
                                        className="text-color-white"
                                      >
                                        Select Category
                                      </MenuItem>
                                      {`${listSupportTicketsOptions}?."Account and billing"` &&
                                        listSupportTicketsOptions?.[
                                          "Account and billing"
                                        ]?.map((option) => {
                                          return option?.service ===
                                            accountAndBillingSelectItem?.Service
                                            ? option?.category?.map(
                                              (categoryOption) => {
                                                return (
                                                  <MenuItem
                                                    className="text-color-white"
                                                    value={categoryOption}
                                                  >
                                                    {categoryOption}
                                                  </MenuItem>
                                                );
                                              }
                                            )
                                            : null;
                                        })}
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item xs={12} className="margin-top-30">
                              <Typography className="text_color_primary">
                                Severity
                              </Typography>
                              <Box className="margin-top-10">
                                <Grid container spacing={0}>
                                  <Grid item xs={12} md={12} lg={6}>
                                    <Select
                                      name="Severity"
                                      onChange={
                                        handleChangeAccountAndBillingSeverity
                                      }
                                      value={
                                        accountAndBillingSelectItem?.Severity
                                      }
                                      defaultValue=""
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                        style: {
                                          padding: "0px",
                                          margin: "0px",
                                          border: "1px solid #707070",
                                        },
                                      }}
                                      style={{
                                        backgroundColor: "#0000",
                                        width: "100%",
                                        color: "white",
                                        border: "1px solid #707070",
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            background: "#010A26",
                                            border: "1px solid #707070",
                                          },
                                        },
                                      }}
                                      IconComponent={KeyboardArrowDownIcon}
                                    >
                                      <MenuItem
                                        value=""
                                        className="text-color-white"
                                      >
                                        Select Severity
                                      </MenuItem>
                                      <MenuItem
                                        className="text-color-white"
                                        value="LOW"
                                      >
                                        LOW
                                      </MenuItem>
                                      <MenuItem
                                        className="text-color-white"
                                        value="MEDIUM"
                                      >
                                        MEDIUM
                                      </MenuItem>
                                      <MenuItem
                                        className="text-color-white"
                                        value="HIGH"
                                      >
                                        HIGH
                                      </MenuItem>
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                        {selectedIssueValue === "Technical" && (
                          <Grid
                            container
                            spacing={0}
                            className="Account_and_billing_box margin-top-30"
                          >
                            <Grid item xs={12} className="margin-top-30">
                              <Typography className="text_color_primary">
                                Service
                              </Typography>
                              <Box className="margin-top-10">
                                <Grid container spacing={0}>
                                  <Grid item xs={12} md={12} lg={6}>
                                    <Select
                                      name="Service"
                                      onChange={
                                        handleChangeAccountAndBillingSeverity
                                      }
                                      value={technicalSelectItem?.Service}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                        style: {
                                          padding: "0px",
                                          margin: "0px",
                                          border: "1px solid #707070",
                                        },
                                      }}
                                      style={{
                                        backgroundColor: "#0000",
                                        width: "100%",
                                        color: "white",
                                        border: "1px solid #707070",
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            background: "#010A26",
                                            border: "1px solid #707070",
                                          },
                                        },
                                      }}
                                      IconComponent={KeyboardArrowDownIcon}
                                    >
                                      <MenuItem
                                        value=""
                                        className="text-color-white"
                                      >
                                        Select Service
                                      </MenuItem>
                                      {listSupportTicketsOptions?.Technical &&
                                        listSupportTicketsOptions?.[
                                          "Technical"
                                        ]?.map((option) => {
                                          return (
                                            <MenuItem
                                              className="text-color-white"
                                              value={option?.service}
                                            >
                                              {option?.service}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item xs={12} className="margin-top-30">
                              <Typography className="text_color_primary">
                                Category
                              </Typography>
                              <Box className="margin-top-10">
                                <Grid container spacing={0}>
                                  <Grid item xs={12} md={12} lg={6}>
                                    <Select
                                      name="Category"
                                      onChange={
                                        handleChangeAccountAndBillingSeverity
                                      }
                                      value={technicalSelectItem?.Category}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                        style: {
                                          padding: "0px",
                                          margin: "0px",
                                          border: "1px solid #707070",
                                        },
                                      }}
                                      style={{
                                        backgroundColor: "#0000",
                                        width: "100%",
                                        color: "white",
                                        border: "1px solid #707070",
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            background: "#010A26",
                                            border: "1px solid #707070",
                                          },
                                        },
                                      }}
                                      IconComponent={KeyboardArrowDownIcon}
                                    >
                                      <MenuItem
                                        value=""
                                        className="text-color-white"
                                      >
                                        Select Category
                                      </MenuItem>
                                      {listSupportTicketsOptions?.Technical &&
                                        listSupportTicketsOptions?.[
                                          "Technical"
                                        ]?.map((option) => {
                                          return option?.service ===
                                            technicalSelectItem?.Service
                                            ? option?.category?.map(
                                              (categoryOption) => {
                                                return (
                                                  <MenuItem
                                                    className="text-color-white"
                                                    value={categoryOption}
                                                  >
                                                    {categoryOption}
                                                  </MenuItem>
                                                );
                                              }
                                            )
                                            : null;
                                        })}
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                            <Grid item xs={12} className="margin-top-30">
                              <Typography className="text_color_primary">
                                Severity
                              </Typography>
                              <Box className="margin-top-10">
                                <Grid container spacing={0}>
                                  <Grid item xs={12} md={12} lg={6}>
                                    <Select
                                      name="Severity"
                                      onChange={
                                        handleChangeAccountAndBillingSeverity
                                      }
                                      value={technicalSelectItem?.Severity}
                                      displayEmpty
                                      inputProps={{
                                        "aria-label": "Without label",
                                        style: {
                                          padding: "0px",
                                          margin: "0px",
                                          border: "1px solid #707070",
                                        },
                                      }}
                                      style={{
                                        backgroundColor: "#0000",
                                        width: "100%",
                                        color: "white",
                                        border: "1px solid #707070",
                                      }}
                                      MenuProps={{
                                        PaperProps: {
                                          sx: {
                                            background: "#010A26",
                                            border: "1px solid #707070",
                                          },
                                        },
                                      }}
                                      IconComponent={KeyboardArrowDownIcon}
                                    >
                                      <MenuItem
                                        value=""
                                        className="text-color-white"
                                      >
                                        Select Severity
                                      </MenuItem>
                                      <MenuItem
                                        className="text-color-white"
                                        value="LOW"
                                      >
                                        LOW
                                      </MenuItem>
                                      <MenuItem
                                        className="text-color-white"
                                        value="MEDIUM"
                                      >
                                        MEDIUM
                                      </MenuItem>
                                      <MenuItem
                                        className="text-color-white"
                                        value="HIGH"
                                      >
                                        HIGH
                                      </MenuItem>
                                    </Select>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Grid>
                          </Grid>
                        )}
                        <Box className="d-flex justify-content-end margin-top-30">
                          <Box
                            className="SupportCenter_btn d-flex items-center cursor_pointer padding_top_bottom_10_15 resp_width"
                            onClick={() => setClearModel(true)}
                          >
                            Cancel
                          </Box>
                          <Box
                            onClick={handleNextStepAdditionalInformation}
                            className="SupportCenter_btn_background_color SupportCenter_btn d-flex resp_width_next items-center margin-left-20px cursor_pointer padding_top_bottom_10_15 "
                          >
                            Next step : Additional information
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {activeStep === 2 && (
                      <Grid item xs={9}>
                        <Box className="SupportCenter_heading_text">
                          Additional information
                        </Box>
                        <Typography className="font-size-24 margin-top-30">
                          Describe your question or issue
                        </Typography>

                        <Box sx={{ flexGrow: 1 }} className="margin-top-30">
                          <Typography className="font-size-24 margin-top-55">
                            Subject
                          </Typography>
                          <Typography className="subject-input-text">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="Brief summarize your questions or issue"
                              inputProps={{
                                style: {
                                  color: "white",
                                  fontSize: "22px",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& > fieldset": { borderColor: "#008CF2" },
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                  "& > fieldset": { borderColor: "#008CF2" },
                                },
                              }}
                              value={additionalInformation?.Subject}
                              onChange={(e) =>
                                setAdditionalInformation({
                                  ...additionalInformation,
                                  Subject: e.target.value,
                                })
                              }
                              fullWidth
                            />
                          </Typography>
                          <Typography className="subject-input-suggestion-text">
                            Maximum 250 characters (250 remaining)
                          </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 1 }} className="margin-top-30">
                          <Typography className="font-size-24 margin-top-55">
                            Description
                          </Typography>

                          <Typography className="font-size-24 description-text">
                            Don't share any sensitive information in case
                            correspondences, such as credentials, credit cards,
                            signed URLs, or personally identifiable information.
                          </Typography>

                          <Typography className="description-input-box">
                            <TextField
                              id="outlined-basic"
                              variant="outlined"
                              placeholder="Describe your questions or issue in details"
                              InputProps={{
                                style: {
                                  color: "white",
                                  fontSize: "22px",
                                  borderColor: "#008CF2",
                                },
                              }}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  "& > fieldset": { borderColor: "#008CF2" },
                                },
                                "& .MuiOutlinedInput-root:hover": {
                                  "& > fieldset": { borderColor: "#008CF2" },
                                },
                              }}
                              fullWidth
                              multiline
                              value={additionalInformation?.Description}
                              onChange={(e) =>
                                setAdditionalInformation({
                                  ...additionalInformation,
                                  Description: e.target.value,
                                })
                              }
                              rows={5}
                            />
                          </Typography>

                          <Typography className="subject-input-suggestion-text">
                            Maximum 5000 characters (5000 remaining)
                          </Typography>
                        </Box>
                        <Box
                          sx={{ flexGrow: 1 }}
                          className="margin-top-30 d-flex"
                        >
                          {additionalInformation?.file.map((item, index) => {
                            return (
                              <Box className="margin-right-25 position-relative">
                                <Box
                                  className="cursor-pointer position-absolute Badge"
                                  onClick={() =>
                                    removeAdditionalInformation(index)
                                  }
                                >
                                  <CloseIcon sx={{ width: "17px" }} />
                                </Box>
                                <img
                                  src={item}
                                  style={{
                                    width: "150px",
                                    maxHeight: "150px",
                                  }}
                                  alt=""
                                />
                              </Box>
                            );
                          })}
                        </Box>
                        <Box sx={{ flexGrow: 1 }} className="margin-top-30">
                          <Box className="upload-btn-box">
                            <ReactFileReader
                              fileTypes={[".png", ".jpg", ".jpeg"]}
                              base64={true}
                              handleFiles={handleFiles}
                            >
                              <Box className="upload-btn">
                                <img
                                  src={UploadIcon}
                                  alt=""
                                  width={17}
                                  style={{ marginRight: "5px" }}
                                />
                                Attach files
                              </Box>
                            </ReactFileReader>
                          </Box>
                          <Typography className="subject-input-suggestion-text">
                            you can attach up to 3 files. Each file can be up to
                            5 MB.
                          </Typography>
                        </Box>
                        <Box className="d-flex justify-content-end margin-top-30">
                          <Box
                            onClick={() => setClearModel(true)}
                            className="SupportCenter_btn d-flex items-center cursor_pointer padding_top_bottom_10_15 "
                          >
                            Cancel
                          </Box>
                          <Box
                            onClick={() => setaAtiveStep(1)}
                            className="previous-btn SupportCenter_btn d-flex items-center cursor_pointer padding_top_bottom_10_15 "
                          >
                            Previous
                          </Box>
                          <Box
                            onClick={submitTicket}
                            className="SupportCenter_btn_background_color SupportCenter_btn d-flex items-center margin-left-20px cursor_pointer padding_top_bottom_10_15 "
                          >
                            Next step : Solve now or contact us
                          </Box>
                        </Box>
                      </Grid>
                    )}
                    {activeStep === 3 && (
                      <Grid
                        item
                        xs={9}
                        className="d-flex flex-column justify-content-center items-center"
                      >
                        <Box className="SupportCenter_heading_text d-flex justify-content-center items-center text-center">
                          Thank you for reaching out to us. We are working on
                          your issue and will get back to you soon.
                        </Box>
                        <Box className="d-flex justify-content-end margin-top-30">
                          <Box
                            onClick={() => navigate("/allCases")}
                            className="previous-btn SupportCenter_btn d-flex items-center cursor_pointer padding_top_bottom_10_15 "
                          >
                            View all cases
                          </Box>
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateCase;
