export const style = {
  contentTitle: {
    fontSize: '32px',
  },
  btn: {
    width: '100%',
    height: '100%',
    borderRadius: '100px',
    backgroundColor: 'rgb(255,131,0)',
    color: 'white',
    fontWeight: 'normal',
  },
}
