import { Box, display } from "@mui/system";
import { Typography } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import logo from "../../assets/img/logo_header.png";
import home from "../../assets/img/home.png";
import globe from "../../assets/img/globe.png";
import lock from "../../assets/img/lock_lock.png";
import cloudComputing from "../../assets/img/cloud-computing.png";
import settings from "../../assets/img/settings_icn.png";
import oUser from "../../assets/img/319946.webp";
import money from "../../assets/img/money.png";
import notification from "../../assets/img/notification.png";
import { useMemo, useState } from "react";
import aws from "../../assets/img/aws_cloud_icn.png";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { MenuItem, Select } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

const DashboardAnalysisOneHeader = ({
  awsServicesData,
  selectItem,
  setModel2,
  setAccountIdListModel,
  RadioBttonValue,
  setAccountNameListModel,
  A_N_RadioBttonValue,
}) => {
  const [serviseName, setServiseName] = useState("AWS");
  const [accountID, setAccountID] = useState("123456789");

  const [area, setArea] = useState("ap-south-1");
  const navigate = useNavigate();
  const handleChange = (event) => {
    setServiseName(event.target.value);
  };
  const handleChangeAccountID = (event) => {
    setAccountID(event.target.value);
  };
  const handleChangeArea = (event) => {
    setArea(event.target.value);
  };

  console.log("Fsedhdh", awsServicesData);
  return (
    <Box
      style={{
        width: "100%",
        backgroundColor: "rgb(1,4,22)",
        height: "60px",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box style={{ padding: "20px", display: "flex", flexGrow: 1 }}>
        <Box style={{ display: "flex", flexGrow: 1 }}>
          {/* <Box style={{ display: 'flex', alignItems: 'center' }}>
            <img src={aws} />
            <Typography
              style={{
                fontSize: '18px',
                fontWeight: '700',
                color: 'white',
                marginLeft: '10px',
              }}
            >
              AWS
            </Typography>
            <KeyboardArrowDownIcon
              fontSize={'large'}
              style={{ color: 'white' }}
            />
          </Box> */}

          {/* <Box style={{ marginLeft: '20px', display: 'flex', alignItems: 'center' }}>
            <Select
              value={serviseName}
              onChange={handleChange}
              displayEmpty
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
              autoWidth
              inputProps={{
                'aria-label': 'Without label',
                style: { padding: '0px', margin: '0px', background: 'red' },
              }}

              variant='filled'
              style={{
                backgroundColor: '#0000',
                border: '0px solid #0000',
                color: 'white',
                fontSize: '18px',
                fontWeight: '700',
                display: 'flex',
                alignItems: 'center',

              }}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value="rds" style={{ background: '#010A26', color: '#fff', fontSize: '18px', fontWeight: '700', color: 'white', display: 'flex', paddingRight: '10px' }}> <Box sx={{ display: 'flex', alignItems: 'center' }}><img src={aws} style={{ marginRight: '10px' }} /> RDS &nbsp;</Box></MenuItem>
              <MenuItem value="ec2" style={{ background: '#010A26', color: '#fff', fontSize: '18px', fontWeight: '700', color: 'white', display: 'flex', paddingRight: '10px' }}> <Box sx={{ display: 'flex', alignItems: 'center' }}><img src={aws} style={{ marginRight: '10px' }} /> EC2 &nbsp;</Box></MenuItem>
              <MenuItem value="AWS" style={{ background: '#010A26', color: '#fff', fontSize: '18px', fontWeight: '700', color: 'white', display: 'flex', paddingRight: '10px' }}> <Box sx={{ display: 'flex', alignItems: 'center' }}><img src={aws} style={{ marginRight: '10px' }} /> AWS &nbsp;</Box></MenuItem>
            </Select>
          </Box> */}

          <Box
            style={{ margin: "20px", display: "flex", alignItems: "center" }}
          >
            <img src={aws} />

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setAccountNameListModel(true)}
            >
              <Typography
                style={{
                  fontSize: "17px",
                  fontWeight: "700",
                  color: "white",
                  marginLeft: "10px",
                  marginTop: "5px",
                  // display: 'flex',
                  // alignItems: 'center'
                }}
              >
                {A_N_RadioBttonValue}
                <KeyboardArrowDownIcon
                  fontSize={"large"}
                  style={{ color: "white", paddingBottom: "2px" }}
                />
              </Typography>
            </Box>
          </Box>

          <Box
            style={{
              marginLeft: "20px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "18px",
                fontWeight: "700",
                color: "white",
                // marginLeft: '10px',
                // display: 'flex',
                // alignItems: 'center'
              }}
            >
              Account ID:
            </Typography>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setAccountIdListModel(true)}
            >
              <Typography
                style={{
                  fontSize: "17px",
                  fontWeight: "700",
                  color: "white",
                  marginLeft: "10px",
                  marginTop: "3px",
                  // display: 'flex',
                  // alignItems: 'center'
                }}
              >
                {RadioBttonValue}
                <KeyboardArrowDownIcon
                  fontSize={"large"}
                  style={{ color: "white", paddingBottom: "2px" }}
                />
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box style={{ display: "flex", alignItems: "center" }}>
          {/* <Typography
            sx={{
              fontSize: '18px',
              fontWeight: '700',
              color: 'white',
              marginLeft: '10px',
              pointer: 'cursor'
            }}

          >
             <Box style={{ marginLeft: '0px', display: 'flex', alignItems: 'center' }}>
              <Select
                value={area}
                onChange={handleChangeArea}
                displayEmpty
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                autoWidth
                inputProps={{
                  'aria-label': 'Without label',
                  style: { padding: '0px', margin: '0px', background: 'red' },
                }}
                variant='filled'

                style={{
                  backgroundColor: '#0000',
                  border: '0px solid #0000',
                  color: 'white',
                  fontSize: '18px',
                  fontWeight: '700',
                  display: 'flex',
                  alignItems: 'center'

                }}
                IconComponent={KeyboardArrowDownIcon}
              >
                <MenuItem value="ap-south-1" style={{ background: '#010A26', color: '#fff', fontSize: '18px', fontWeight: '700', color: 'white', display: 'flex', paddingRight: '10px' }}><Box sx={{ paddingRight: '10px' }}>ap-south-1</Box></MenuItem>
                <MenuItem value="ap-south-2" style={{ background: '#010A26', color: '#fff', fontSize: '18px', fontWeight: '700', color: 'white', display: 'flex', paddingRight: '10px' }}><Box sx={{ paddingRight: '10px' }}>ap-south-2</Box></MenuItem>
                <MenuItem value="ap-south-3" style={{ background: '#010A26', color: '#fff', fontSize: '18px', fontWeight: '700', color: 'white', display: 'flex', paddingRight: '10px' }}><Box sx={{ paddingRight: '10px' }}>ap-south-3</Box></MenuItem>
              </Select>
            </Box> 
          </Typography> */}
          <Box
            onClick={() => setModel2(true)}
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          >
            {/* {awsServicesData?.map((row, index) => (
              <Typography style={{ fontSize: '12px' }}>{row.label}</Typography>
            ))} */}

            <Typography
              style={{
                background: "#010A26",
                color: "#fff",
                fontSize: "18px",
                fontWeight: "700",
                color: "white",
                display: "flex",
                paddingRight: "10px",
              }}
            >
              {console.log("awsServicesData.length", awsServicesData)}
              {awsServicesData.length === 1 ? awsServicesData : "All"}
              {/* {awsServicesData?.map((row, index) => (
                awsServicesData?.length - 1 === index ?
                  row :
                  row + ` / `
              ))} */}
            </Typography>
            <KeyboardArrowDownIcon
              fontSize={"large"}
              style={{ color: "white" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default DashboardAnalysisOneHeader;
