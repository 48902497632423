import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "../CustomButton/index";
import exportImg from "../../assets/img/export.png";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import MainLoader from "../Spinner/MainLoader";
import { Api } from "../../MyNewUtils/Api";
import { toast } from "react-toastify";
import { conutryList } from "../../utils/country";
import { path } from "../../MyNewUtils/screenPath";
import { Input } from "@mui/material";
const SignupStep2Col3 = () => {
  const [countryCode, setCountryCode] = useState(conutryList);
  const [isLoading, setIsLoading] = useState(false);
  const [addressNumber, setAddressNumber] = useState("");
  const [addressName, setAddressName] = useState("");
  const [signupData, setSignupData] = useState({
    id: JSON.parse(localStorage.getItem("user")).data.id,
    accountType: "Personal",
    fullName: "",
    companyname: "",
    phone: "",
    country: "Afghanistan",
    countryCode: "+91",
    address: "",
    city: "",
    state: "",
    zipCode: " ",
  });
  console.log("awtesyerdh0", signupData);

  const onPlanSet = (e) => {
    setSignupData({ ...signupData, accountType: e.target.value });
  };
  const onNameSet = (e) => {
    setSignupData({ ...signupData, fullName: e.target.value });
  };
  const onCompanyNameSet = (e) => {
    setSignupData({ ...signupData, companyname: e.target.value });
  };
  const onPhoneNumberSet = (e) => {
    if (e.target.value.length >= 8) {
      setSignupData({ ...signupData, phone: e.target.value });
    }
  };
  const onCountryoregionSet = (e) => {
    let countrycodeFilter = countryCode?.filter(
      (row) => row?.name === e.target.value
    );
    setSignupData({
      ...signupData,
      country: e.target.value,
      countryCode: countrycodeFilter[0]?.dial_code,
    });
  };
  const onAddressNoSet = (e) => {
    setAddressNumber(e.target.value);
  };
  const onAddressSet = (e) => {
    setAddressName(e.target.value);
  };
  const onCitySet = (e) => {
    setSignupData({ ...signupData, city: e.target.value });
  };
  const onStateSet = (e) => {
    setSignupData({ ...signupData, state: e.target.value });
  };
  const onZipSet = (e) => {
    if (e.target.value.length < 7) {
      setSignupData({ ...signupData, zipCode: e.target.value });
    }
  };

  const [nameError, setnameError] = useState(false);
  const [companynameError, setCompanynameError] = useState(false);
  const [phonenumberError, setPhonenumberError] = useState(false);
  const [addressnoError, setAddressnoError] = useState(false);
  const [cityError, setCityError] = useState(false);
  const [stateError, setStateError] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const [checkboxvalidation, setCheckboxvalidation] = useState(false);
  const [checkboxvalidationError, setCheckboxvalidationError] = useState(false);
  console.log(checkboxvalidation);
  const navigate = useNavigate();

  const onSubmit = async (e) => {
    if (signupData.fullName.length != "") {
      if (signupData.fullName.length != "") {
        if (signupData.phone.length != "") {
          if (addressNumber.length != "" && addressName.length != "") {
            if (signupData.city.length != "") {
              if (signupData.state.length != "") {
                if (signupData.zipCode.length != "") {
                  if (checkboxvalidation != false) {
                    {
                      // let address = addressNumber + " " + addressName;
                      setnameError(false);
                      setCompanynameError(false);
                      setPhonenumberError(false);
                      setAddressnoError(false);
                      setCityError(false);
                      setStateError(false);
                      setZipcodeError(false);
                      let params = {
                        accountType: signupData.accountType,
                        fullName: signupData.fullName,
                        companyname: signupData.companyname,
                        phone: signupData.phone,
                        country: signupData.country,
                        countryCode: signupData?.countryCode,
                        address: `${addressNumber} ${addressName}`,
                        city: signupData.city,
                        state: signupData.state,
                        zipCode: signupData.zipCode,
                      };

                      console.log("params", signupData, params);
                      setIsLoading(true);
                      let final = {
                        id: signupData.id,
                        step: "2",
                        data: JSON.stringify(params),
                      };
                      console.log("Final DATA", final);
                      const { statusCode, data } = await Api.postRequest(
                        "/users/user-account-setup",
                        final
                      );
                      const res = JSON.parse(data);
                      if (res?.status === 1) {
                        let localData = JSON.parse(
                          localStorage.getItem("userData")
                        );
                        localData = res["data"]["userdata"]
                          ? res["data"]["userdata"]
                          : localData;
                        localStorage.setItem(
                          "userData",
                          JSON.stringify(localData)
                        );
                        toast.success(res.message, { autoClose: 1000 });
                        setIsLoading(false);
                        navigate(path.step3, {
                          state: {
                            phone: signupData.phone,
                            country: signupData.country,
                          },
                        });
                      } else {
                        toast.error(res.message, { autoClose: 1000 });
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    if (signupData.fullName.length != "") {
      setnameError(false);
    } else {
      setnameError(true);
    }

    if (signupData.companyname.length != "") {
      setCompanynameError(false);
    } else {
      setCompanynameError(true);
    }

    if (signupData.phone.length != "" || e.target.value.length >= 8) {
      setPhonenumberError(false);
    } else {
      setPhonenumberError(true);
    }
    if (addressNumber.length != "" && addressName.length != "") {
      setAddressnoError(false);
    } else {
      setAddressnoError(true);
    }
    if (signupData.city.length != "") {
      setCityError(false);
    } else {
      setCityError(true);
    }
    if (signupData.state.length != "") {
      setStateError(false);
    } else {
      setStateError(true);
    }
    if (signupData.zipCode.length != "") {
      setZipcodeError(false);
    } else {
      setZipcodeError(true);
    }
    if (checkboxvalidation != false) {
      setCheckboxvalidationError(false);
    } else {
      setCheckboxvalidationError(true);
    }
  };
  // const data = await Authenticate(signupData)
  // console.log(data)

  return (
    <Box
      style={{
        width: "90%",
        border: "1px solid gray",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "20px",
        borderRadius: "30px",
        backgroundColor: "rgba(0,0,0,0.5)",
      }}
    >
      <MainLoader isLoading={isLoading} />
      <Box>
        <Typography style={{ color: "#FF8800", fontSize: "36px" }}>
          Sign up for Clevrone
        </Typography>
        <Typography style={{ fontSize: "18px" }}>
          Contact Information
        </Typography>
        <Box>
          <Typography style={{ color: "#008CF2", fontSize: "18px" }}>
            How do you plan to use?
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="type"
              defaultValue="Personal"
              name="radio-buttons-group"
              onChange={onPlanSet}
            >
              <FormControlLabel
                value="business"
                control={<Radio style={{ color: "#008fff" }} />}
                label="Business - for your work, school, or organization"
              />
              <FormControlLabel
                value="Personal"
                control={<Radio select style={{ color: "#008fff" }} />}
                label="Personal - for your work projects"
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <Box>
          <Typography style={{ fontSize: "14px" }}>
            Who should we contact about this account?
          </Typography>
          <Box>
            <lable
              style={{
                display: "block",
                fontSize: "18px",
                color: "#008CF2",
              }}
            >
              Full Name
            </lable>
            <input
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              placeholder="Enter your Full Name"
              onChange={onNameSet}
            />
            <Typography
              style={
                nameError == false
                  ? { display: "none" }
                  : { color: "red", fontSize: "10px" }
              }
            >
              Enter your Full Name{" "}
            </Typography>
          </Box>
          {signupData?.accountType != "Personal" ? (
            <Box>
              <lable
                style={{
                  display: "block",
                  fontSize: "18px",
                  color: "#008CF2",
                }}
                onChange={onCompanyNameSet}
              >
                Company Name
              </lable>
              <input
                onChange={onCompanyNameSet}
                style={{
                  width: "100%",
                  backgroundColor: "black",
                  border: "1px solid #363636",
                  padding: "4px",
                  color: "#FFF",
                }}
                placeholder="Enter your Company Name"
              />
              <Typography
                style={
                  companynameError == false
                    ? { display: "none" }
                    : { color: "red", fontSize: "10px" }
                }
              >
                Enter your Company Name{" "}
              </Typography>
            </Box>
          ) : null}
          <Box>
            <lable
              style={{
                display: "block",
                fontSize: "18px",
                color: "#008CF2",
              }}
            >
              Phone Number
            </lable>
            <Typography style={{ fontSize: "12px" }}>
              Enter your phone number.
            </Typography>
            <input
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              type="number"
              onChange={onPhoneNumberSet}
              placeholder={"222-333-4444"}
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
            <Typography
              style={
                phonenumberError == false
                  ? { display: "none" }
                  : { color: "red", fontSize: "10px" }
              }
            >
              Enter your Phone Number{" "}
            </Typography>
          </Box>
          <Box>
            <lable
              style={{
                display: "block",
                fontSize: "18px",
                color: "#008CF2",
              }}
            >
              Country or Region
            </lable>
            <select
              onChange={onCountryoregionSet}
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
            >
              {countryCode.map((item) => (
                <option
                  value={item.name}
                >{`${item.name}  (${item.dial_code})`}</option>
              ))}
            </select>
            {/* <input
          style={{
            width: '100%',
            backgroundColor: 'black',
            border: '1px solid #363636',
            padding: '4px',
          }}
        /> */}
          </Box>
          <Box>
            <lable
              style={{
                display: "block",
                fontSize: "18px",
                color: "#008CF2",
              }}
            >
              Address
            </lable>
            <input
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              placeholder="Enter your AddressNo"
              onChange={onAddressNoSet}
            />
            <input
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                marginTop: "5px",
                color: "#FFF",
              }}
              onChange={onAddressSet}
              placeholder={"Apartment, suite, unit, building, floor, etc."}
            />
            <Typography
              style={
                addressnoError == false
                  ? { display: "none" }
                  : { color: "red", fontSize: "10px" }
              }
            >
              Enter your Address
            </Typography>
          </Box>
          <Box>
            <lable
              style={{
                display: "block",
                fontSize: "18px",
                color: "#008CF2",
              }}
            >
              City
            </lable>
            <input
              onChange={onCitySet}
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              placeholder="Enter city Name"
            />
            <Typography
              style={
                cityError == false
                  ? { display: "none" }
                  : { color: "red", fontSize: "10px" }
              }
            >
              Enter your City name{" "}
            </Typography>
          </Box>
          <Box>
            <lable
              style={{
                display: "block",
                fontSize: "18px",
                color: "#008CF2",
              }}
            >
              State, Province, or Region
            </lable>
            <input
              onChange={onStateSet}
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              placeholder="Enter State Name"
            />
            <Typography
              style={
                stateError == false
                  ? { display: "none" }
                  : { color: "red", fontSize: "10px" }
              }
            >
              Enter your State Name
            </Typography>
          </Box>
          <Box>
            <lable
              style={{
                display: "block",
                fontSize: "18px",
                color: "#008CF2",
              }}
            >
              Zip Code
            </lable>
            <input
              onChange={(e) => onZipSet(e)}
              value={signupData.zipCode}
              type="number"
              style={{
                width: "100%",
                backgroundColor: "black",
                border: "1px solid #363636",
                padding: "4px",
                color: "#FFF",
              }}
              placeholder="Enter Zip Code"
              onKeyDown={(e) =>
                ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()
              }
            />
            <Typography
              style={
                zipcodeError == false
                  ? { display: "none" }
                  : { color: "red", fontSize: "10px" }
              }
            >
              Enter Zip Code
            </Typography>
          </Box>
          <Box style={{ marginTop: "10px" }}>
            <input
              type="checkbox"
              onChange={(e) => {
                setCheckboxvalidation(!checkboxvalidation);
              }}
              id="vehicle1"
              name="vehicle1"
              value="false"
              style={{ backgroundColor: "white", color: "black" }}
            />
            <label for="vehicle1">
              {" "}
              I have read and agree to the terms of the
            </label>
          </Box>
          <Typography
            style={
              checkboxvalidationError == false
                ? { display: "none" }
                : { color: "red", fontSize: "10px" }
            }
          >
            please accept terms and conditions
          </Typography>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            <Typography style={{ color: "#008CF2", fontSize: "14px" }}>
              Clevrone Customer Agreement.
            </Typography>
            <Box style={{ marginLeft: "10px" }}>
              <img src={exportImg} />
            </Box>
          </Box>
          <CustomButton
            text={"Continue (Step 2 of 5)"}
            styles={{
              backgroundColor: "rgb(255,131,0)",
              color: "#FFF",
              marginTop: "20px",
              width: "100%",
              borderRadius: "20px",
              padding: "10px 18px",
            }}
            // onClick={onSubmit}
            // onButtonClick={() => {
            //   navigate('/confirm-identity-verification')
            // }}
            onButtonClick={onSubmit}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default SignupStep2Col3;
