import { Box } from "@mui/system";
import { useEffect, useMemo, useState, useContext } from "react";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import "./style.css";
import DashboardAnalisisOneBody from "../SecurityAnalysisDetails/SecurityAnalysisDropDown";
import { Api } from "../../MyNewUtils/Api";
import { toast } from "react-toastify";
import MainLoader from "../Spinner/MainLoader";
import myContext from "../../screens/MyContext/MyContext";

const AcessAnalysisOne = () => {
  const [refresh, setRefresh] = useState(false);
  const cls = useMemo(() => "AcessAnalysisOne", []);
  const [allData, setAllData] = useState();
  const [rType, setRType] = useState("s3");
  const [subLoader, setSubLoader] = useState(false);
  const [showerror1, setShowError1] = useState(false);

  const [awsServicesData, setAwsServicesData] = useState([]);

  const [allS3BucketsKey, setAllS3BucketsKey] = useState([]);

  const { cloudDetails } = useContext(myContext);

  const getData = async () => {
    setSubLoader(true);
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      resource_type: rType,
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
      region: JSON.stringify(cloudDetails?.selectedCloudRegionsID),
    };

    const { data } = await Api.postRequest(
      "/users/get-access-analysis-data",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      let Bucketskey = [];
      res?.data?.finaldata.map((item, index) => {
        item?.data.map((keyItem, keyIndex) => {
          Bucketskey.push(keyItem);
        });
      });
      setAllS3BucketsKey({ data: Bucketskey });
      setAllData(res?.data);
      setSubLoader(false);
      if (
        res?.data?.finaldata?.length != 0 &&
        res?.data?.finaldata != undefined
      ) {
        setShowError1(false);
      } else {
        setShowError1(true);
      }
      console.log("finaldata", res?.data?.finaldata);
    } else {
      setShowError1(true);
      setSubLoader(false);
    }
  };

  useEffect(() => {
    if (rType === "") {
      setRType("s3");
      if (rType === "s3" && cloudDetails?.selectedCloudAccountID) {
        getData();
      }
    } else {
      if (
        cloudDetails?.selectedCloudAccountID !== undefined &&
        cloudDetails?.selectedCloudAccountID.length !== 0
      )
        getData();
    }
  }, [
    rType,
    refresh,
    cloudDetails?.selectedCloudServers,
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudRegionsID,
  ]);
  console.log("allData-allData", allData);
  return (
    <Box className={`${cls}__background`}>
      <MainLoader isLoading={false} />

      {/* Header */}
      <DashboardHeader
        activeTab={"ACCESS ANALYSIS"}
        accountSelectHeaderShow={true}
        showRegionPart={true}
      />

      <DashboardAnalisisOneBody
        showerror1={showerror1}
        subLoader={subLoader}
        setIsLoading={setSubLoader}
        allData={allData}
        rType={rType}
        setRType={setRType}
        refresh={refresh}
        setRefresh={setRefresh}
        allS3BucketsKey={allS3BucketsKey}
      />
    </Box>
  );
};
export default AcessAnalysisOne;
// 2,7,41
