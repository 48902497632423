import "./EC2Status.css";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useState, useEffect, useRef, useContext } from "react";
import { Button } from "@mui/material";
import PluseIcon from "../../../assets/img/Component 176 – 1@2x.png";
import Clevronefeatures from "../CLEVRONE-FEATURES/clevronefeatures";
import { Api } from "../../../MyNewUtils/Api";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useSelector, useDispatch } from "react-redux";
import {
  upadateDashboardTabData,
  firstActivateTabData,
  activateTabIndex,
} from "../../../redux/action";
import myContext from "../../MyContext/MyContext";
import { Navigate } from "react-router";

const DashboardDetails = () => {
  const [slide, setSlide] = useState();
  const [iamOpen, setIamOpen] = useState(0);
  const [refresh, setRefresh] = useState();
  const [tabManuOpen, setTabManuOpen] = useState(null);
  const [allTabManu, setAllTabManu] = useState(null);
  const [addNewDashboardData, setAddNewDashboardData] = useState([]);
  const [activOneTabManuIndex, setActivOneTabManuIndex] = useState();
  const [lastDeactivateTab, setLastDeactivateTab] = useState();
  const [showTotalTab, setShowTotalTab] = useState(0);
  const [openTab, setOpenTab] = useState(0);
  const [showTotalTabBar, setShowTotalTabBar] = useState(
    parseInt(window.innerWidth / 190) - 2
  );
  const { cloudDetails } = useContext(myContext);
  const allDashboard = useSelector((state) => state.reducer).data;
  const firstActivateTab = useSelector(
    (state) => state.reducer
  ).firstActivateTab;

  // --------- All Dhashboard Tab Data -----------
  const [allDhashboardTabData, setAllDhashboardTabData] = useState([]);
  
  const activateSlider = useSelector((state) => state.reducer).activateTabIndex;
  const ref = useRef(null);
  const refbox = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      cloudDetails?.selectedCloudServers &&
      cloudDetails?.selectedCloudAccountID.length !== 0 &&
      cloudDetails?.selectedCloudAccountID.length !== undefined
    )
      getAllDashboard();
  }, [
    addNewDashboardData,
    cloudDetails?.selectedCloudAccountID,
    cloudDetails?.selectedCloudServers,
  ]);

  useEffect(() => {
    if (parseInt(window.innerWidth / 190) - 2 <= 0) {
      setShowTotalTabBar(1);
    }
  }, [showTotalTabBar]);

  const getAllDashboard = async () => {
    let payload = {
      account_type: cloudDetails?.selectedCloudServers,
      accountId: cloudDetails?.selectedCloudAccountID,
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    };
    const data = await Api.postRequest(
      "/dashboard/get-all-user-dashboard",
      payload
    );
    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      if (res?.data?.length === 0) {
        dispatch(activateTabIndex(1));
        dispatch(upadateDashboardTabData(res?.data));
      } else {
        let totalShowTab = showTotalTabBar;
        if (res?.data?.length <= showTotalTabBar) {
          totalShowTab = res?.data?.length;
        }
        let tabData = res?.data;
        let tabDataShowLastNineTab = res?.data?.length;
        if (res?.data?.length - totalShowTab >= 0) {
          tabDataShowLastNineTab = res?.data?.length - totalShowTab;
        }
        if (res?.data?.length < showTotalTabBar) {
          setShowTotalTabBar(res?.data?.length);
        }
        let totalShowTabCount = 0;
        if (res?.data?.length - totalShowTab >= 0) {
          setLastDeactivateTab(tabDataShowLastNineTab - 1);
          dispatch(firstActivateTabData(tabDataShowLastNineTab));
        } else {
          dispatch(firstActivateTabData(0));
        }
        tabData.map((item, index) => {
          if (tabDataShowLastNineTab === index) {
            tabData[index] = { ...tabData[index], show: true };
            tabDataShowLastNineTab = tabDataShowLastNineTab + 1;
            totalShowTabCount = totalShowTabCount + 1;
          } else {
            tabData[index] = { ...tabData[index], show: false };
          }
        });
        if (allDashboard.length === 0) {
          dispatch(upadateDashboardTabData(tabData));
        } else {
          if (tabData.length > showTotalTabBar + 1) {
            let firstTabShowIndex = 0;
            let firstTabShowIndexLoop = true;
            tabData.map((item, index) => {
              if (item.show & firstTabShowIndexLoop) {
                firstTabShowIndex = index;
                firstTabShowIndexLoop = false;
              }
              if (allDashboard[index] !== undefined) {
                tabData[index] = {
                  ...tabData[index],
                  show: allDashboard[index].show,
                };
              } else {
                tabData[index] = { ...tabData[index], show: true };
              }
            });
            tabData[firstTabShowIndex] = {
              ...tabData[firstTabShowIndex],
              show: false,
            };
          } else {
            tabData.map((item, index) => {
              if (allDashboard[index] !== undefined) {
                tabData[index] = {
                  ...tabData[index],
                  show: allDashboard[index].show,
                };
              } else {
                tabData[index] = { ...tabData[index], show: true };
              }
            });
          }
          dispatch(upadateDashboardTabData(tabData));
        }
        setShowTotalTab(totalShowTabCount);
        dispatch(activateTabIndex(res?.data.length));
        ref.current.scrollLeft = refbox.current.clientWidth;
        setSlide(res?.data.length + 1);
      }
    }
  };

  const handleClickTabManu = (event, index) => {
    event.stopPropagation();
    setActivOneTabManuIndex(index);
    setTabManuOpen(event.currentTarget);
  };

  const handleClickTabManuClose = (event) => {
    event.stopPropagation();
    setActivOneTabManuIndex();
    setTabManuOpen(null);
  };

  const getShowTabBarItem = (item, index) => {
    let firstTabShowIndexCount = 0;
    let lastTabShowIndex = 0;
    let firstTabShow = true;
    let totalShowTabCount = 0;
    item?.map((tabItem, tabIndex) => {
      if (tabItem?.show) {
        if (firstTabShow) {
          firstTabShowIndexCount = tabIndex;
          firstTabShow = false;
        }
        totalShowTabCount = totalShowTabCount + 1;
        lastTabShowIndex = tabIndex;
      }
    });

    if (totalShowTabCount > showTotalTabBar - 1) {
      let showTab = item;
      if (index === firstTabShowIndexCount) {
        showTab[lastTabShowIndex] = {
          ...showTab[lastTabShowIndex],
          show: false,
        };
      } else {
        showTab[firstTabShowIndexCount] = {
          ...showTab[firstTabShowIndexCount],
          show: false,
        };
      }

      dispatch(upadateDashboardTabData(showTab));
    }
  };

  const onclickbox = (index) => {
    dispatch(activateTabIndex(index + 1));
  };

  const removeOneTab = (e) => {
    e.stopPropagation();
    let removeTab = allDashboard;
    let totalTabShowCount = 0;

    removeTab[activOneTabManuIndex] = {
      ...removeTab[activOneTabManuIndex],
      show: false,
    };
    removeTab.map((item, index) => {
      if (item?.show) {
        totalTabShowCount = totalTabShowCount + 1;
      }
    });
    if (lastDeactivateTab >= 0 && totalTabShowCount > 0) {
      removeTab[lastDeactivateTab] = {
        ...removeTab[lastDeactivateTab],
        show: true,
      };
      setLastDeactivateTab(lastDeactivateTab - 1);
    }
    onclickbox(allDashboard.length);

    dispatch(upadateDashboardTabData(removeTab));
    setTabManuOpen(null);
    setActivOneTabManuIndex();
  };

  const removeAllTab = (e) => {
    e.stopPropagation();
    let removeTab = allDashboard;
    removeTab.map((item, index) => {
      removeTab[index] = { ...removeTab[index], show: false };
    });
    onclickbox(allDashboard.length);
    dispatch(upadateDashboardTabData(removeTab));
    setTabManuOpen(null);
    setActivOneTabManuIndex();
  };

  const handleClickAllTab = (event) => {
    setAllTabManu(event.currentTarget);
  };
  const handleCloseAllTab = () => {
    setAllTabManu(null);
  };

  const openTabManManu = (index) => {
    let ShowTab = allDashboard;
    if (
      showTotalTab >= showTotalTabBar &&
      firstActivateTab >= 0 &&
      !ShowTab[index].show &&
      firstActivateTab <= allDashboard.length
    ) {
      ShowTab[firstActivateTab] = { ...ShowTab[firstActivateTab], show: false };
      dispatch(firstActivateTabData(firstActivateTab + 1));
    }

    ShowTab[index] = { ...ShowTab[index], show: true };
    getShowTabBarItem(ShowTab, index);
    onclickbox(index);
    dispatch(upadateDashboardTabData(ShowTab));
    handleCloseAllTab();
  };

  const handleOpen = (data) => {
    setOpenTab(data);
  };

  const openNewTab = () => {
    dispatch(activateTabIndex(slide));
    setIamOpen(0);
  };

  return (
    <>
      <Box className="EC2Status__background">
        <Box>
          {/* -------------------  DashBoard Header Tab Section ------------------ */}
          {allDashboard.length != 0 ? (
            <>
              <Box sx={{ position: "relative", zIndex: "13" }}>
                <Box sx={pageStyle.mainContainer} ref={ref}>
                  <Box sx={{ display: "flex" }} ref={refbox}>
                    {openTab == 1 ? (
                      <Button
                        style={{
                          color: "#80f4fe",
                          width: 190,
                          border: "1px solid #4e505c",
                          borderRadius: "0px",
                          fontWeight: "bold",
                          fontSize: "100%",
                          alignItems: "center",
                          padding: "10px",
                          paddingTop: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        IAM
                      </Button>
                    ) : null}
                    {openTab == 3 ? (
                      <Button
                        style={{
                          color: "#80f4fe",
                          width: 190,
                          border: "1px solid #4e505c",
                          borderRadius: "0px",
                          fontWeight: "bold",
                          fontSize: "93%",
                          alignItems: "center",
                          padding: "10px",
                          paddingTop: "14px",
                          marginLeft: "5px",
                        }}
                      >
                        Cloud Assessment
                      </Button>
                    ) : null}

                    {[...Array(slide)]?.map((elementInArray, index) => {
                      return (
                        <Box
                          onClick={() => onclickbox(index)}
                          style={
                            index + 1 == activateSlider
                              ? slide === activateSlider
                                ? { display: "none" }
                                : !allDashboard[index]?.show
                                ? { display: "none" }
                                : {
                                    position: "relative",
                                    color: "#80f4fe",
                                    cursor: "pointer",
                                    padding: "10px",
                                    width: 190,
                                    height: "55px",
                                    border: "1px solid #4e505c",
                                    marginLeft: "5px",
                                    fontSize: "100%",
                                    fontWeight: "bold",
                                    display: "flex",
                                    alignItems: "center",
                                  }
                              : index == slide - 1
                              ? {
                                  position: "relative",
                                  cursor: "pointer",
                                  padding: "10px",
                                  width: 190,
                                  height: "55px",
                                  border: "1px solid #4e505c",
                                  marginLeft: "5px",
                                  fontSize: "100%",
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                }
                              : !allDashboard[index]?.show
                              ? { display: "none" }
                              : {
                                  cursor: "pointer",
                                  padding: "10px",
                                  width: 190,
                                  height: "55px",
                                  border: "1px solid #4e505c",
                                  marginLeft: "5px",
                                  fontSize: "100%",
                                  fontWeight: "bold",
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative",
                                }
                          }
                        >
                          {allDashboard[index]?.dashboadName}
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={
                              tabManuOpen ? "long-menu" : undefined
                            }
                            aria-expanded={tabManuOpen ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={(e) => {
                              handleClickTabManu(e, index);
                            }}
                            style={{ position: "absolute", right: "0" }}
                          >
                            <MoreVertIcon
                              sx={
                                index != slide - 1
                                  ? { fill: "#fff" }
                                  : { display: "none" }
                              }
                            />
                          </IconButton>
                          <Menu
                            id="long-menu"
                            MenuListProps={{
                              "aria-labelledby": "long-button",
                            }}
                            anchorEl={tabManuOpen}
                            open={tabManuOpen}
                            onClose={handleClickTabManuClose}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                overflow: "visible",
                                mt: 1.5,
                                background: "#1A2B56",
                                color: "#fff",
                                "& .MuiAvatar-root": {
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                  background: "#1A2B56",
                                },
                                "&:before": {
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "background.paper",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                  background: "#1A2B56",
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem key={0} onClick={removeOneTab}>
                              <Typography>Close This Tab</Typography>
                            </MenuItem>
                            <MenuItem key={1} onClick={removeAllTab}>
                              <Typography>Close All Tabs</Typography>
                            </MenuItem>
                          </Menu>

                          {/* {index + 1 == activateSlider ? allDashboard[activateSlider - 1]?.dashboadName : null} */}
                          <Button
                            style={
                              index == slide - 1
                                ? { color: "white", width: "100%" }
                                : { display: "none" }
                            }
                          >
                            <img
                              src={PluseIcon}
                              width="25px"
                              height="25px"
                              style={{ marginRight: "15px" }}
                              alt=""
                            />
                            New
                          </Button>
                          {/* <Button style={index == slide - 1 ? { color: "white", width: "100%" } : { display: 'none' }}><img src={PluseIcon} width='25px' height='25px' style={{ marginRight: "15px" }} />Setup dashboard</Button> */}
                        </Box>
                      );
                    })}
                  </Box>

                  {openTab == 1 || openTab == 3 ? (
                    <Button
                      style={{
                        color: "white",
                        width: 190,
                        border: "1px solid #4e505c",
                        borderRadius: "0px",
                        fontWeight: "bold",
                        fontSize: "100%",
                        alignItems: "center",
                        padding: "10px",
                        paddingTop: "14px",
                        marginLeft: "5px",
                      }}
                      onClick={openNewTab}
                    >
                      <img
                        src={PluseIcon}
                        width="25px"
                        height="25px"
                        style={{ marginRight: "15px" }}
                        alt=""
                      />
                      New
                    </Button>
                  ) : null}
                  <Box
                    sx={{
                      position: "absolute",
                      background: "#0000",
                      cursor: "pointer",
                      borderRadius: "50px",
                      right: "0",
                    }}
                  >
                    <IconButton
                      onClick={handleClickAllTab}
                      size="small"
                      sx={{ ml: 2 }}
                      aria-controls={allTabManu ? "account-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={allTabManu ? "true" : undefined}
                    >
                      <MoreVertIcon sx={{ fill: "#fff" }} />
                    </IconButton>
                    <Menu
                      anchorEl={allTabManu}
                      id="account-menu"
                      open={allTabManu}
                      onClose={handleCloseAllTab}
                      onClick={handleCloseAllTab}
                      PaperProps={{
                        style: {
                          maxHeight: "60vh",
                          width: "20ch",
                          background: "#1A2B56",
                          color: "#fff",
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#0000",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#ADD8E6",
                            borderRadius: 2,
                            border: "0px solid #0000",
                          },
                        },
                      }}
                      transformOrigin={{
                        horizontal: "right",
                        vertical: "top",
                      }}
                      anchorOrigin={{
                        horizontal: "right",
                        vertical: "bottom",
                      }}
                    >
                      {allDashboard.map((item, index) => {
                        return (
                          <>
                            <MenuItem onClick={() => openTabManManu(index)}>
                              <Typography>{item?.dashboadName}</Typography>
                            </MenuItem>
                          </>
                        );
                      })}
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </>
          ) : null}

          {/* -------------------  DashBoard Tab Details Section ------------------ */}
          <Box sx={{ padding: "3%" }}>
            {[...Array(slide)]?.map((elementInArray, index) => {
              return (
                <Clevronefeatures
                  setRefresh={setRefresh}
                  allDashboard={allDashboard}
                  activateSlider={activateSlider}
                  index={index}
                  slide={slide}
                  dashid={allDashboard[activateSlider - 1]?.dashid}
                  setAddNewDashboardData={setAddNewDashboardData}
                  handleOpen={handleOpen}
                  iamOpen={iamOpen}
                  setIamOpen={setIamOpen}
                  allDhashboardTabData={allDhashboardTabData}
                  setAllDhashboardTabData={setAllDhashboardTabData}
                />
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default DashboardDetails;

const pageStyle = {
  mainContainer: {
    background: "#010515",
    display: "flex",
    marginTop: "13px",
    alignItems: "center",
    width: "100%",
    zIndex: 13,
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: 0,
      height: 0,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#0000",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "white",
      borderRadius: 2,
      border: "0px solid #0000",
    },
  },
};
