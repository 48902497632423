import { Box } from '@mui/system'
import { Button, Typography } from '@mui/material'

import logo from '../../assets/img/logo_header.png'
import home from '../../assets/img/home.png'
import globe from '../../assets/img/globe.png'
import lock from '../../assets/img/lock_lock.png'
import cloudComputing from '../../assets/img/cloud-computing.png'
import settings from '../../assets/img/settings_icn.png'
import oUser from '../../assets/img/319946.webp'
import money from '../../assets/img/money.png'

import notification from '../../assets/img/notification.png'
import { useMemo, useState } from 'react'
import DashboardHeader from '../../Component/DashboardHeader/DashboardHeader'
import DashBoardSettingHeader from '../../Component/DashboardHeader/DashBoardSettingHeader'
import { styled, alpha } from '@mui/material/styles'

import InputBase from '@mui/material/InputBase'

import './style.css'
import { DataGrid } from '@mui/x-data-grid'
import DashBoardTable from '../../Component/DashBoardTable/DashBoardTable'
import DashboardSettingUserBody from '../../Component/DashboardSettingUserBody/DashboardSettingUserBody'
import DashboardAnalysisOneHeader from '../../Component/DashboardAnalysisHeader/index'
import DashboardAnalisisOneBody from '../../Component/SecurityAnalysisDetails/SecurityAnalysisDropDown'
import NetworkMapBody from '../../Component/NetworkMapBody/NetworkMapBody'

const AcessAnalysisOne = () => {
  const cls = useMemo(() => 'NetWorkMap', [])
  return (
    <Box className={`${cls}__background`}>
      {/* Header */}
      <DashboardHeader activeTab={'NETWORK MAP'} />

      <div class="text-center flex flex-col items-center justify-center" style={{
        height: "calc(100vh - 100px)"
      }}>
        <h1 class="text-7xl font-bold text-white mb-4">Coming Soon</h1>
        <p class="text-2xl text-white">The Network Map page is under construction. We'll be here soon with our new awesome page.</p>
      </div>
      {/* <NetworkMapBody /> */}
    </Box>
  )
}
export default AcessAnalysisOne
// 2,7,41
