import { Box, Button, IconButton, MenuItem, Select, Stack, Tooltip, Typography } from "@mui/material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { CSVLink } from "react-csv";
import DownloadIcon from "@mui/icons-material/Download";
import Modal from "@mui/material/Modal";
import { DateRangePicker } from "react-date-range";
import { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import MyContext from "../../../MyContext/MyContext";
import { Api } from "../../../../MyNewUtils/Api";
import { toast } from "react-toastify";
import SubLoader from "../../../../Component/Spinner/subLoader";
import { useDispatch, useSelector } from "react-redux";
import { fetchChartDataAction, resetChartData } from "../../../../redux/action/chartsAction";

export const ChartHeader = (props) => {
  const {
    chartData,
    titleColor,
    elementRef,
    tableTitle,
    isPreview,
    itemData,
    setShowDoneData,
    reloadCharts,
    setReloadCharts,
    chartType, 
    setChartType,
  } = props;

  const dispatch = useDispatch();
  const chart = useSelector(state => state.charts);

  const [anchorElFilterManu, setAnchorElFilterManu] = useState(null);
  const { cloudDetails } = useContext(MyContext);
  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const fetchChartData = async () => {
    const filters = {
      acc_id: cloudDetails?.selectedCloudAccountID,
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      chartType: [chartType || itemData.accountResponseType[0]],
      from_date: datePickerState[0]?.startDate.toISOString(),
      to_date: datePickerState[0]?.endDate.toISOString(),
    };

    dispatch(fetchChartDataAction(itemData.uri, filters));
  }

  useEffect(() => {
    const chartD = chart?.charts?.[itemData.uri]?.data;
    if (chartD) {
      if (chartD?.status === 1) {
        let newData = itemData;
        let title = Object.keys(chartD?.data);
        newData = { ...newData, data: (chartD?.data?.[`${title[0]}`] || []) };
        setShowDoneData(newData);
      } else {
        toast.error(chartD?.message, { autoClose: 1000 });
      }
    }
  }, [chart])

  useEffect(() => {
    const chartD = chart?.charts?.[itemData.uri]?.data;
    if (!chartD || !chartD.data) {
      fetchChartData()
    }
  }, []);

  const handleDateRange = async () => {
    setAnchorElFilterManu(!anchorElFilterManu);
    fetchChartData();
  };

  useEffect(() => {
    if (reloadCharts) {
      dispatch(resetChartData(itemData.uri, undefined));
      fetchChartData();
      setReloadCharts(false);
    }
  }, [reloadCharts])

  useEffect(() => {
    if (chartType) {
      dispatch(resetChartData(itemData.uri, undefined));
      fetchChartData();
    }
  }, [chartType])

  return (
    <>
      <Modal
        open={anchorElFilterManu}
        // onClose={() => { setAnchorElFilterManu(!anchorElFilterManu) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            background: "#010A26",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #fff",
            borderRadius: "20px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ color: "#fff", fontSize: "1.5rem" }}
            >
              Select Date
            </Typography>
            <Box
              onClick={() => {
                setAnchorElFilterManu(!anchorElFilterManu);
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#000",
                fontSize: "1.5rem",
                background: "#fff",
                borderRadius: "100%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <CloseIcon style={{ fill: "#000" }} />
            </Box>
          </Box>
          <Box sx={{ position: "relative" }}>
            <DateRangePicker
              onChange={(ranges) => {
                const { selection } = ranges;
                setDatePickerState([selection]);
              }}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={datePickerState}
              direction="vertical"

            // rangeColors={["rgb(128, 244, 254)", "blue", "green"]}
            />
          </Box>
          <Box sx={{ marginTop: "15px", justifyContent: "flex-end" }}>
            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: "flex-end" }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setAnchorElFilterManu(!anchorElFilterManu);
                }}
              >
                cancel
              </Button>
              <Button variant="contained" onClick={handleDateRange}>
                Ok
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          width: "100%",
          padding: "10px 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          position: "sticky",
          top: 0,
          background: "black"
        }}
      >
        <Typography
          sx={{
            fontSize: "26px",
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            color: titleColor,
          }}
        >
          {chartData?.Title || itemData?.Title}
        </Typography>
        {
          chartData?.Title ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="flex"
              >
                <Tooltip title="Filter" placement="top">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-haspopup="true"
                    onClick={() => {
                      setAnchorElFilterManu(!anchorElFilterManu);
                    }}
                  >
                    <CalendarMonthOutlinedIcon />
                  </IconButton>
                </Tooltip>
              </Box>

              {isPreview ? (
                  (chartType || "Table") === "Table" ? (
                  <CSVLink
                    data={chartData ? Array.isArray(chartData?.data) ? chartData?.data : [chartData?.data] : chartData}
                    headers={tableTitle}
                    filename={"Data.csv"}
                  >
                    <DownloadIcon />
                  </CSVLink>
                ) : (
                  <Box
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      elementRef.current.chart.exportChart({
                        type: "application/pdf",
                      });
                    }}
                  >
                    <DownloadIcon />
                  </Box>
                )
              ) : null}

              {
                itemData?.accountResponseType?.length > 1 ? (
                  <Select
                    className="text-white chart-type"
                    id="chart-type"
                    value={chartType || itemData?.accountResponseType?.[0]}
                    label="Chart Type"
                    onChange={(event) => {
                      setChartType(event.target.value);
                    }}
                  >
                    {
                      chartData?.accountResponseType?.map((item) => (
                        <MenuItem value={item}>{item}</MenuItem>
                      ))
                    }
                  </Select>
                ) : null
              }
            </Box>
          ) : null
        }
      </Box>
    </>
  )
}