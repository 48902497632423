import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Checkbox from "@mui/material/Checkbox";
import search from "../../../assets/img/search_icn.png";
import sild from "../../../assets/img/sild.png";
import CloudIcon from "@mui/icons-material/Cloud";
import { useState, useEffect } from "react";
import Iam from "../IAM/iam";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import AnalysisDashboard from "../IAM Analysis/IAMAnalysis";
import CloudAssessment from "../CloudAssessment";
import "../CLEVRONE-FEATURES/style.css";
const Clevronefeatures = ({
  setRefresh,
  allDashboard,
  slide,
  activateSlider,
  index,
  dashid,
  setAddNewDashboardData,
  handleOpen,
  iamOpen,
  setIamOpen,
  allDhashboardTabData,
  setAllDhashboardTabData,
}) => {
  const [doneEditing, setDoneEditing] = useState(true);
  const [cloudFeaturesFilter, setCloudFeaturesFilter] = useState({
    amazonWebServices: true,
    cloudAssessment: true,
  });
  const category = [
    {
      name: "amazonWebServices",
    },
    {
      name: "cloudAssessment",
    },
    {
      name: "allcategories",
    },
  ];
  const [searchedData, setSearchdData] = useState();
  const [searchCategory, setSearchCategory] = useState();
  const [cateDate, setCataData] = useState(category);
  const allCloudFeaturesFilter = (event) => {
    setCloudFeaturesFilter({
      ...cloudFeaturesFilter,
      amazonWebServices: event.target.checked,
      cloudAssessment: event.target.checked,
    });
  };
  useEffect(() => {
    if (activateSlider === allDashboard?.length + 1 || dashid === undefined) {
      setIamOpen(0);
    } else {
      setIamOpen(2);
    }
    setSearchdData(cateDate);
  }, [dashid]);
  useEffect(() => {
    const filterElement = cateDate?.filter((data) =>
      data?.name?.includes(searchCategory)
    );
    setSearchdData(filterElement);
  }, [searchCategory]);
  if (activateSlider === index + 1) {
    return (
      <Box>
        {/* ------------------ Add New Features Tab Section ----------------*/}
        <Box sx={iamOpen == 0 ? { padding: "50px" } : { display: "none" }}>
          <Typography
            style={{ color: "#FF8800", fontWeight: "bold", fontSize: "36px" }}
          >
            CLEVRONE FEATURES
          </Typography>
          <Typography style={{ fontWeight: "normal", fontSize: "20px" }}>
            Apps help you get started gaining insights from your data source by
            providing example searches and dashboards
            <br /> for common use cases. Feel free to edit them as you need to
            get the results you want.
          </Typography>
          <Box sx={{ marginTop: "77px" }}>
            <Box sx={{ display: "flex" }}>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                <Box
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                    width: "100%",
                    height: "40px",
                    background: "#272E42",
                    border: "1px solid #000000",
                    borderRadius: "10px",
                  }}
                >
                  <img
                    src={search}
                    style={{
                      width: "20px",
                      height: "20px",
                      background: "#272E42",
                      border: "0px",
                      borderRadius: "10px 0 0 10px",
                      marginRight: "7px",
                    }}
                    alt=""
                  />
                  <input
                    type="text"
                    placeholder="Search..."
                    style={{
                      width: "100%",
                      height: "100%",
                      background: "#272E42",
                      border: "none",
                      outline: "none",
                      borderRadius: "0px 10px 10px 0",
                      color: "#fff",
                    }}
                    onChange={(e) => setSearchCategory(e.target.value)}
                  />
                </Box>
                {}
                <Box style={{ marginTop: "20px" }}>
                  {" "}
                  <Checkbox
                    checked={
                      cloudFeaturesFilter?.amazonWebServices &&
                      cloudFeaturesFilter?.cloudAssessment
                    }
                    indeterminate={
                      cloudFeaturesFilter?.amazonWebServices !==
                      cloudFeaturesFilter?.cloudAssessment
                    }
                    onChange={allCloudFeaturesFilter}
                    sx={{
                      color: "#fff",
                      "&.Mui-checked": { color: "gray" },
                    }}
                  />
                  All Categories
                </Box>
                {searchedData?.map((item, index) => (
                  <>
                    {item?.name === "amazonWebServices" ? (
                      <Box>
                        {" "}
                        <Checkbox
                          checked={cloudFeaturesFilter?.amazonWebServices}
                          onChange={() =>
                            setCloudFeaturesFilter({
                              ...cloudFeaturesFilter,
                              amazonWebServices:
                                !cloudFeaturesFilter?.amazonWebServices,
                            })
                          }
                          sx={{
                            color: "#fff",
                            "&.Mui-checked": { color: "gray" },
                          }}
                        />
                        Amazon Web Services
                      </Box>
                    ) : null}
                  </>
                ))}
                {searchedData?.map((item, index) => (
                  <>
                    {item?.name === "cloudAssessment" ? (
                      <Box
                      // style={
                      //   searchedData.name === "cloudAssessment" ?
                      //     { display: "visible" } : { display: "none" }
                      // }
                      >
                        {" "}
                        <Checkbox
                          checked={cloudFeaturesFilter?.cloudAssessment}
                          onChange={() =>
                            setCloudFeaturesFilter({
                              ...cloudFeaturesFilter,
                              cloudAssessment:
                                !cloudFeaturesFilter?.cloudAssessment,
                            })
                          }
                          sx={{
                            color: "#fff",
                            "&.Mui-checked": { color: "gray" },
                          }}
                        />
                        Cloud Assessment
                      </Box>
                    ) : null}
                  </>
                ))}
              </Box>
              {cloudFeaturesFilter?.amazonWebServices && (
                <Box
                  sx={{
                    marginLeft: "130px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "40px",
                  }}
                  className="iam_box_design"
                >
                  <Box
                    sx={{
                      background: "#393E4C",
                      width: "133px",
                      height: "133px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleOpen(iamOpen)}
                  >
                    <img
                      src={sild}
                      onClick={() => setIamOpen(1)}
                      style={{ width: "70%", height: "70%" }}
                      alt=""
                    />
                  </Box>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      color: "#fff",
                    }}
                    className="tab_iam_resp"
                  >
                    IAM
                  </Typography>
                </Box>
              )}
              {cloudFeaturesFilter?.cloudAssessment && (
                <Box
                  sx={{
                    marginLeft: "30px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginRight: "40px",
                  }}
                >
                  <Box
                    sx={{
                      background: "#393E4C",
                      width: "133px",
                      height: "133px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                    onClick={handleOpen(iamOpen)}
                  >
                    <CloudIcon
                      style={{ width: "70%", height: "70%" }}
                      onClick={() => setIamOpen(3)}
                    />
                  </Box>
                  <Typography
                    style={{
                      fontWeight: "bold",
                      fontSize: "30px",
                      color: "#fff",
                      // marginLeft: "0px"
                    }}
                    className="tab_cloudas_resp"
                  >
                    Cloud Assessment
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Box>

        {/* ------------------ Iam Features Selection  Section ----------------*/}
        {iamOpen == 1 ? (
          <Iam
            activateSlider={activateSlider}
            index={index}
            dashid={dashid}
            iamOpen={iamOpen}
            setIamOpen={setIamOpen}
            setAddNewDashboardData={setAddNewDashboardData}
            slide={slide}
            doneEditing={doneEditing}
            setDoneEditing={setDoneEditing}
          />
        ) : null}

        {/* ------------------ Show Dashboard Selection Details  Section ----------------*/}
        {iamOpen == 2 ? (
          <AnalysisDashboard
            setRefresh={setRefresh}
            dashid={dashid}
            doneEditing={doneEditing}
            setDoneEditing={setDoneEditing}
            index={index}
            allDhashboardTabData={allDhashboardTabData}
            setAllDhashboardTabData={setAllDhashboardTabData}
          />
        ) : null}

        {/* ------------------ Cloud Assessment Features Selection  Section ----------------*/}
        {iamOpen == 3 ? <CloudAssessment /> : null}
      </Box>
    );
  } else {
    return <></>;
  }
};
export default Clevronefeatures;
