import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import logo from "../../assets/img/logo_header.png";
import home from "../../assets/img/home.png";
import globe from "../../assets/img/globe.png";
import lock from "../../assets/img/lock_lock.png";
import cloudComputing from "../../assets/img/cloud-computing.png";
import settings from "../../assets/img/settings_icn.png";
import oUser from "../../assets/img/319946.webp";
import money from "../../assets/img/money.png";
import notification from "../../assets/img/notification.png";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
const DashBoardSettingHeader = ({
  settingTabOpen,
  handleOpenSelect,
  handleCloseSelect,
  activeSettingSubTab,
}) => {
  const items = useMemo(
    () => [
      "Users",
      "Roles",
      "Password Policy",
      "Cloud Setup Wizard",
      "Supports",
    ],
    []
  );
  const navigate = useNavigate();
  // 'Password Policy'
  const [selectItem, setSelectItem] = useState();

  const navigateItem = (item, index) => {
    navigate(
      index === 3
        ? "/cloud-setup-wizard"
        : index === 4
          ? "/supports"
          : "/dashboard-" + (index + 1)
    );
  };

  return (
    <Box
      onMouseOver={handleOpenSelect}
      onMouseLeave={handleCloseSelect}
      style={
        settingTabOpen
          ? {
            position: "relative",
            zIndex: 13,
            width: "100%",
            backgroundColor: "rgb(1,4,22)",
            height: "60px",
            display: "flex",
            justifyContent: "center",
          }
          : { display: "none" }
      }
    >
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          marginLeft: "19%",
        }}
      >
        {items.map((item, index) => (
          <Box
            className="borderBottom"
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0px 20px",
              cursor: "pointer",
              transition: "0.5s ease",
            }}
            style={
              item === activeSettingSubTab
                ? {
                  borderBottom: "2px solid #008CF2",
                  color: "rgb(129, 245, 255)",
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 20px",
                  cursor: "pointer",
                  transition: "0.5s ease",
                }
                : {
                  display: "flex",
                  alignItems: "center",
                  margin: "0px 20px",
                  cursor: "pointer",
                  transition: "0.5s ease",
                }
            }
            onClick={() => navigateItem(item, index)}
            className="subHeaderBorderBottomTitle subHeaderTitle"
          >
            {/* <Typography style={{ color: 'white', fontSize: '1rem', paddingTop: '1%', paddingBottom: '1%', }} className='subHeaderTitle'> */}
            {item}
            {/* </Typography> */}
          </Box>
        ))}
      </Box>
    </Box>
  );
};
export default DashBoardSettingHeader;
