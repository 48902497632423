import { Box } from "@mui/system";
import { useMemo } from "react";
import DashboardSettingRolesBody from "../../Component/Dashboard2/DashboardSettingRolesBody";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import DashBoardSettingHeader from "../../Component/DashboardHeader/DashBoardSettingHeader";
import DashBoardSctionPage1Body from "../../Component/selectCloudInfrastructureDetails/index";
import DashBoardSctionPage2Body from "../../Component/SetupSourceDetails/index";
import DashBoardSctionPage3Body from "../../Component/previewDoneDetails/index";
import DashboardSettingPasswordPolicyBody from "../../Component/DashboardSettingPasswordPolicyBody/index";
import Header from "../dashboard/Header/header";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import MainLoader from "../../Component/Spinner/MainLoader";
import "./style.css";
const SectionPageThree = (props) => {
  const [data, setData] = useState(useLocation().state.sourceData);
  const cls = useMemo(() => "SectionPageThree", []);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Box className={`${cls}__background`} style={{}}>
      {/* <Header/> */}
      <MainLoader isLoading={isLoading} />
      <DashboardHeader
        activeTab={"SETTINGS"}
        activeSettingSubTab={"Cloud Setup Wizard"}
      />
      {/* Setting inner header*/}

      <DashBoardSctionPage3Body data={data} setIsLoading={setIsLoading} />
    </Box>
  );
};
export default SectionPageThree;
