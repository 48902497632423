import { Box } from "@mui/system";
import { useMemo, useState, createContext, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./screens/Login/index";
import PasswordRecovery from "./screens/PasswordRecovery/PasswordRecovery";
import ResetPassword from "./screens/Resetpassword/index";
import SectionPageOne from "./screens/selectCloudInfrastructure";
import SectionPageThree from "./screens/PreviewDone/index";
import SectionPageTwo from "./screens/SetupSource/index";
import Signup from "./screens/Signup/index";
import SignupStepFive from "./screens/CongratulationMessage/index";
import SignupStepThree from "./screens/UpgradePlan/index";
import SignupStepSix from "./screens/connectingPayment/index";
import SignupStepTwo from "./screens/SignUpForCleveron/index";
import VerifyNumber from "./screens/VerifyNumber/index";
import CloudInfoa from "./screens/CloudInfo/CloudInfoa";
import Dashboard from "./screens/dashboard/index";
import Cloudinfo from "./screens/dashboard/CLOUD INFO/Cloudinfo";
import BucketList from "./screens/CloudInfo/BucketList";
import Es2 from "./screens/CloudInfo/ES2/es2";
import Lambda from "./screens/CloudInfo/Lambda/Lambda";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import Securitynalysis from './screens/dashboard/Securityanalysis/securitynalysis'
import ProtectedRoute from "./ProtectedRoute/index";
// ---------------------------------------------------------------------
import DashBoardOne from "./screens/settingUsers/index";
import DashBoardTwo from "./screens/SettingRoles/index";
import DashBoardThree from "./screens/SettingPasswordPolicy/index";
import ForgetEmailAndPhoneNumber from "./screens/ForgotEmailAndPhonenumber/index";
import ForgotEmailModel from "./Component/ForgotEmailModel/index";
import AddNumber from "./screens/dashboard/AddNumber/AddNumber";
import VerifyNumberModel from "./screens/dashboard/VerifyNumber/veriynumber";
import RemoveNumber from "./screens/dashboard/RemoveNumber/removenumber";
import AccessAnalysisS3Bucket from "./screens/dashboard/AceessAnalysisS3buckets/aceessanalysisS3buckets";
import Profile from "./screens/dashboard/Profile/Profile";
import myContext from "./screens/MyContext/MyContext";
import NetWorkMap from "./screens/NetWorkMap/NetWorkMap";
import PasswordRecoveryOtp from "./screens/PasswordRecovery/PasswordRecoveryOtp";
import CloudInfoChart from "./screens/CloudInfoChart/CloudInfoChart";
import Verifyemail from "./verifyemail/verifyemail";
import NetWorkMapTwo from "./screens/NetWorkMapTwo/NetWorkMapTwo";
import CloudInfoChart2 from "./screens/CloudInfoChart/CloudInfoChart2";
import Zoomchart from "./screens/CloudInfoChart/zoomCloudInfoChart";
import Iam from "./screens/dashboard/IAM/iam";
import Setupwindows from "./screens/dashboard/EC2Status/setupwindows";
import UserProfile from "./screens/dashboard/deshboardhome/userprofile";
import defaultimg from "./assets/img/default.png";
import moment from "moment";
import DashboardDetails from "./screens/dashboard/EC2Status/DashboardDetails";
import Supports from "./screens/SETTINGS/Supports";
import ViewAllCases from "./screens/SETTINGS/Supports/viewAllCases";
import CreateCase from "./screens/SETTINGS/Supports/createCase";
import ACCESSANALYSIS from "./screens/ACCESSANALYSIS/index";
import SignupStepFour from "./screens/verificationCleveron/index";
import SignupStepSeven from "./screens/confirmIdentityVerification/index";
import SignupStepEight from "./screens/confirmIdentity/index";
import ViewCasesDetails from "./screens/SETTINGS/Supports/viewCasesDetails/index";
import LogPdfViewer from "./screens/dashboard/CloudAssessment/cloudzapLogsPdf";
import Discover from "./screens/Discover";
export const UserContext = createContext();

function App() {
  const [uId, SetUId] = useState("");

  const [useDate, setUseData] = useState(localStorage.getItem("user"));

  const [profileImageUrl, setProfileImageUrl] = useState(defaultimg);

  const [timeZone, setTimeZone] = useState(
    JSON.parse(localStorage.getItem("userData"))?.timeZone
  );

  const [cloudDetails, setCloudDetails] = useState({
    cloudServersList: ["AWS"],
    selectedCloudServers: "AWS",
    cloudAccountIDList: [],
    selectedCloudAccountID: [],
    cloudRegionsList: [],
    selectedCloudRegionsID: [],
  });

  var userId = [uId];

  const cls = useMemo(() => "app", []);

  const myFunc = (arg) => {
    SetUId(arg);
  };

  const getprofileImageUrl = (props) => {
    let localData = JSON.parse(localStorage.getItem("userData"));
    localData = { ...localData, profilepic: props };

    localStorage.setItem("userData", JSON.stringify(localData));

    let profileImg = props;
    setProfileImageUrl(profileImg);
  };

  const DateTime = (getdatetime) => {
    let getTimeZone = JSON.parse(localStorage.getItem("userData"))?.timeZone;
    var jun2 = moment(getdatetime);
    let datetime = jun2
      .tz(getTimeZone ? getTimeZone : "india/kolkata")
      .format("llll");
    return datetime;
  };

  const LiveTime = (getdatetime) => {
    let getTimeZone = JSON.parse(localStorage.getItem("userData"))?.timeZone;
    var jun2 = moment(getdatetime);
    let datetime = [
      jun2.tz(getTimeZone ? getTimeZone : "india/kolkata").format("LT"),
      jun2.tz(getTimeZone ? getTimeZone : "india/kolkata").format("llll"),
    ];
    return datetime;
  };

  useEffect(() => {
    getprofileImageUrl(
      JSON.parse(localStorage.getItem("userData"))?.profilepic
    );
  }, []);
  return (
    <Box className={`${cls}__globalContainer`}>
      <Router>
        <myContext.Provider
          value={{
            userId,
            myFunc,
            profileImageUrl,
            setProfileImageUrl,
            getprofileImageUrl,
            DateTime,
            LiveTime,
            timeZone,
            setTimeZone,
            cloudDetails,
            setCloudDetails,
          }}
        >
          <Routes>
            <Route
              exact
              path="/discover"
              element={<ProtectedRoute Component={Discover} />}
            ></Route>
            <Route
              exact
              path="/dashboard"
              element={<ProtectedRoute Component={Dashboard} />}
            ></Route>
            <Route
              exact
              path="/verify-number"
              element={<ProtectedRoute Component={VerifyNumber} />}
            ></Route>
            <Route
              exact
              path="/reset-password"
              element={<ResetPassword />}
            ></Route>
            <Route
              exact
              path="/password-recovery-otp"
              element={<PasswordRecoveryOtp />}
            ></Route>
            <Route
              exact
              path="/verifyemail/:verifytoken"
              element={<Verifyemail />}
            ></Route>
            <Route
              exact
              path="/password-recovery"
              element={<PasswordRecovery />}
            ></Route>
            <Route
              exact
              path="/cloudinfoa"
              element={<ProtectedRoute Component={CloudInfoa} />}
            ></Route>
            <Route
              exact
              path="/bucketlist"
              element={<ProtectedRoute Component={BucketList} />}
            ></Route>
            <Route
              exact
              path="/es2"
              element={<ProtectedRoute Component={Es2} />}
            ></Route>
            <Route
              exact
              path="/lambda"
              element={<ProtectedRoute Component={Lambda} />}
            ></Route>
            <Route
              exact
              path="/iam"
              element={<ProtectedRoute Component={Iam} />}
            ></Route>
            <Route
              exact
              path="/setupwindows"
              element={<ProtectedRoute Component={Setupwindows} />}
            ></Route>
            <Route
              exact
              path="/cloudinfo"
              element={<ProtectedRoute Component={Cloudinfo} />}
            />
            <Route
              exact
              path="/forgetemailornumber"
              element={<ProtectedRoute Component={ForgetEmailAndPhoneNumber} />}
            />
            <Route
              exact
              path="/ForgotEmailModel"
              element={<ProtectedRoute Component={ForgotEmailModel} />}
            />
            <Route
              exact
              path="/addNumber"
              element={<ProtectedRoute Component={AddNumber} />}
            />
            <Route
              exact
              path="/verifynumbermodel"
              element={<ProtectedRoute Component={VerifyNumberModel} />}
            />
            <Route
              exact
              path="/removenumber"
              element={<ProtectedRoute Component={RemoveNumber} />}
            />
            <Route
              exact
              path="/AccessAnalysisS3Bucket"
              element={<ProtectedRoute Component={AccessAnalysisS3Bucket} />}
            />
            <Route
              exact
              path="/Profile-Billing"
              element={
                <ProtectedRoute Component={Profile} AddCloudComponent={true} />
              }
            />
            <Route
              exact
              path="/Profile"
              element={
                <ProtectedRoute
                  Component={UserProfile}
                  AddCloudComponent={true}
                />
              }
            />
            {/* ------------------------------- dashboard--header ------------------------------------------------------------- */}
            {/* End essential routes.... */}
            {/* End essential routes .... */}
            {/* Routes changed By mohit */}
            <Route
              exact
              path="/dashboard-1"
              element={
                <ProtectedRoute
                  Component={DashBoardOne}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/settings"
              element={<ProtectedRoute Component={DashBoardOne} />}
            ></Route>
            <Route
              exact
              path="/dashboard-2"
              element={
                <ProtectedRoute
                  Component={DashBoardTwo}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/dashboard-3"
              element={
                <ProtectedRoute
                  Component={DashBoardThree}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/cloud-setup-wizard"
              element={
                <ProtectedRoute
                  Component={SectionPageOne}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/setup-source"
              element={
                <ProtectedRoute
                  Component={SectionPageTwo}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/preview-done"
              element={
                <ProtectedRoute
                  Component={SectionPageThree}
                  AddCloudComponent={true}
                />
              }
            ></Route>

            {/* <Route
              exact
              path="/access-analysis"
              element={<ProtectedRoute Component={AccessAnalysisOne} />}
            ></Route>
            <Route
              exact
              path="/access-analysis"
              element={<ProtectedRoute Component={AccessAnalysisTwo} />}
            ></Route>
            <Route
              exact
              path="/access-analysis"
              element={<ProtectedRoute Component={AccessAnalysisThree} />}
            ></Route> */}
            <Route
              exact
              path="/access-analysis"
              element={<ProtectedRoute Component={ACCESSANALYSIS} />}
            ></Route>
            <Route
              exact
              path="/network-map"
              element={<ProtectedRoute Component={NetWorkMap} />}
            ></Route>
            <Route
              exact
              path="/cloudinfo-chart"
              element={<ProtectedRoute Component={CloudInfoChart} />}
            ></Route>
            <Route
              exact
              path="/cloudinfo-chart-2"
              element={<ProtectedRoute Component={CloudInfoChart2} />}
            ></Route>
            <Route
              exact
              path="/zoomchart"
              element={<ProtectedRoute Component={Zoomchart} />}
            ></Route>

            {/* Api Integrate routes */}
            <Route exact path="/" element={<Login />}></Route>

            <Route exact path="/signup" element={<Signup />}></Route>

            <Route
              exact
              path="/Sign-up-for-cleveron"
              element={
                <ProtectedRoute
                  Component={SignupStepTwo}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/upgrade-plan"
              element={
                <ProtectedRoute
                  Component={SignupStepThree}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/verification-cleveron"
              element={
                <ProtectedRoute
                  Component={SignupStepFour}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exactCloudInfoaS3
              path="/congratulation-message"
              element={
                <ProtectedRoute
                  Component={SignupStepFive}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            {/* <Route
              exact
              path="/upgrade-plan"
              element={< ProtectedRoute Component={SignupStepSix} />}
            ></Route> */}
            <Route
              exact
              path="/connecting-payment"
              element={
                <ProtectedRoute
                  Component={SignupStepSix}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/confirm-identity-verification"
              element={
                <ProtectedRoute
                  Component={SignupStepSeven}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/confirm-identity"
              element={
                <ProtectedRoute
                  Component={SignupStepEight}
                  AddCloudComponent={true}
                />
              }
            ></Route>

            {/* ------------------------------- dashboard--header ------------------------------------------------------------- */}
            {/* End essential routes.... */}
            {/* End essential routes.... */}
            {/* Routes changed By mohit */}
            <Route
              exact
              path="/network-map-2"
              element={<ProtectedRoute Component={NetWorkMapTwo} />}
            ></Route>
            <Route
              exact
              path="/ec2status"
              element={<ProtectedRoute Component={DashboardDetails} />}
            ></Route>
            <Route
              exact
              path="/supports"
              element={
                <ProtectedRoute Component={Supports} AddCloudComponent={true} />
              }
            ></Route>
            <Route
              exact
              path="/allCases"
              element={
                <ProtectedRoute
                  Component={ViewAllCases}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/createCase"
              element={
                <ProtectedRoute
                  Component={CreateCase}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/viewCasesDetails"
              element={
                <ProtectedRoute
                  Component={ViewCasesDetails}
                  AddCloudComponent={true}
                />
              }
            ></Route>
            <Route
              exact
              path="/LogPdfViewer"
              element={
                <ProtectedRoute
                  Component={LogPdfViewer}
                  AddCloudComponent={true}
                />
              }
            ></Route>
          </Routes>
        </myContext.Provider>
      </Router>
      <ToastContainer />
    </Box>
  );
}

export default App;
