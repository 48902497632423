import { Box } from "@mui/system";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import SearchIcon from "@mui/icons-material/Search";
import { Button, TextField, Typography } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import roundClose from "../../assets/img/cancel_icn.png";
import exp from "../../assets/img/export.png";
import plainLock from "../../assets/img/plainLock.png";
import { Api } from "../../MyNewUtils/Api";
import { toast } from "react-toastify";
import SubLoader from "../Spinner/subLoader";

const SearchBarRoles = ({ setSearch, refreshRoleData, setRefreshRoleData }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [rolesAllPermissions, setRolesAllPermissions] = useState([]);
  const [roleUserName, setRoleUserName] = useState();
  const [roleDescription, setRoleDescription] = useState();
  const [subLoader, setsubLoader] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setRolesAllPermissions([]);
    setRoleUserName();
    setRoleDescription();
    setAnchorEl(null);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    let selectRolePermissions = [];
    rolesAllPermissions.map((item, index) => {
      if (item?.select) {
        selectRolePermissions.push(item?.permissionValue);
      }
    });

    if (selectRolePermissions.length === 0) {
      toast.error("Please Select Permissions", { autoClose: 1000 });
    } else {
      let final = {
        Rolename: roleUserName,
        Roledesc: roleDescription,
        userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
        Rolepermissions: JSON.stringify(selectRolePermissions),
      };
      const { data, statusCode } = await Api.postRequest(
        "/users/add-new-role",
        final
      );
      const res = JSON.parse(data);
      if (res?.status === 1) {
        toast.success(res?.message, { autoClose: 1000 });
        setRefreshRoleData(!refreshRoleData);
        handleClose();
      } else {
        toast.error(res?.message, { autoClose: 1000 });
      }
    }
  };
  const getAllPermissions = async () => {
    setsubLoader(true);
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { data, statusCode } = await Api.postRequest(
      "/users/get-all-permissions",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      setsubLoader(false);
      setRolesAllPermissions(res?.data);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setsubLoader(false);
    }
    setsubLoader(false);
  };
  const items = useMemo(
    () => [
      {
        title: "Data Management",
        subItem: [
          "View Collectors",
          "Manage Collectors",
          "Manage Ingest Budgets",
          "Manage data volume feed",
          "View field extraction rules",
          "View Fields",
          "Manage Fields",
          "Manage field extraction rules",
          "Manage S3 data forwarding",
          "Manage Content",
          "Manage connections",
          "View Scheduled Views",
          "Manage Scheduled Views",
        ],
      },
      {
        title: "Entry Management",
        subItem: ["Manage Entity Configs"],
      },
      {
        title: "Matrics",
        subItem: [
          "Manage Metrics Transformation Rules",
          "Manage Metrics Rules",
        ],
      },
      {
        title: "Security",
        subItem: [
          "Manage password policy",
          "Allowlist IP addresses",
          "Create access keys",
          "Manage access keys",
          "Manage support account access",
          "Manage audit data feed",
          "Manage SAML",
          "Manage Share Dashboards outside of organization",
          "Manage organization settings",
          "Change Data Access Level",
        ],
      },
      {
        title: "Dashboards",
        subItem: [
          "Share dashboards with world",
          "Share dashboard with the allowkist",
        ],
      },
      {
        title: "User Management",
        subItem: ["Manage users and roles"],
      },
      {
        title: "Alerting",
        subItem: ["View Monitors", "Manage Monitors", "View Alerts"],
      },
    ],
    []
  );
  const getSelectedItem = (index) => {
    let newArrayofPermissions = rolesAllPermissions;

    newArrayofPermissions?.map((newArrayItem, newArrayitemIndex) => {
      if (newArrayitemIndex === index) {
        newArrayofPermissions[index] = {
          ...newArrayofPermissions[index],
          select: !newArrayItem?.select,
        };
      }
    });
    setRolesAllPermissions(newArrayofPermissions);
    console.log("newArrayofPermissions", newArrayofPermissions);
  };

  useEffect(() => {
    if (anchorEl != null) {
      getAllPermissions();
    }
  }, [anchorEl]);

  useEffect(() => {
    if (rolesAllPermissions.length != 0) {
      let rolesPermissionsArray = rolesAllPermissions;

      rolesPermissionsArray.map((arrayItem, arrayIndex) => {
        rolesPermissionsArray[arrayIndex] = {
          ...rolesPermissionsArray[arrayIndex],
          select: false,
        };
      });
      setRolesAllPermissions(rolesPermissionsArray);
    }
  }, [rolesAllPermissions]);

  console.log("anchorEl", anchorEl);
  return (
    <Box style={{ padding: "10px", display: "flex", alignItems: "center" }}>
      <Box
        style={{
          display: "flex",
          backgroundColor: "#272E42",
          borderRadius: "10px",
          padding: "5px 30px",
          flex: 1,
        }}
      >
        <SearchIcon style={{ color: "white", margin: "5px" }} />
        <input
          placeholder={"Search role"}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            border: "none",
            outline: "none",
            padding: "5px",
            width: "100%",
            backgroundColor: "transparent",
            color: "white",
          }}
        />
      </Box>
      <Box style={{ marginLeft: "20px" }}>
        <Button
          variant="contained"
          style={{ borderRadius: "20px" }}
          startIcon={<AddCircleRoundedIcon />}
          onClick={handleClick}
        >
          Add Role
        </Button>
      </Box>
      <Box>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          // onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          PaperProps={{
            style: {
              backgroundColor: "rgb(0,0,0,0.5)",
              borderRadius: "30px",
              width: "400px",
              margin: "10px 0px 20px 0px",
              color: "white",
              border: "1px solid #707070",
              padding: "30px",
              backdropFilter: "blur(10px)",
            },
          }}
        >
          <MenuItem
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "text",
            }}
            disableRipple
          >
            <img
              src={roundClose}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
            />
          </MenuItem>
          <Typography style={{ color: "#FF8800", fontSize: "22px" }}>
            Create New Role
          </Typography>
          <form onSubmit={onSubmit}>
            <MenuItem disableRipple>
              <TextField
                type="text"
                label="Role Name"
                variant="standard"
                required
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                style={{ width: "100%" }}
                value={roleUserName}
                onChange={(e) => setRoleUserName(e.target.value)}
              />
            </MenuItem>
            <MenuItem disableRipple>
              <TextField
                type="text"
                required
                label="Description"
                variant="standard"
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                style={{ width: "100%" }}
                value={roleDescription}
                onChange={(e) => setRoleDescription(e.target.value)}
              />
            </MenuItem>
            <MenuItem
              disableRipple
              style={{
                marginTop: "10px",
                display: "block",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                }}
              >
                <Typography style={{ fontSize: "12px" }}>
                  Search Filter
                </Typography>
              </Box>
            </MenuItem>
            <Box style={{ padding: "6px 16px" }}>
              <Typography
                style={{
                  fontSize: "12px",
                }}
              >
                Users will only be able to access logs that match the search
                filter. Leaving this blank will allow access to all data for
                users of this role, unless restricted by an additional role
              </Typography>
            </Box>
            <Box
              style={{
                padding: "1px 16px",
                marginTop: "30px",
              }}
            >
              <Typography style={{ fontSize: "14px" }}>Capabilities</Typography>
            </Box>
            <Box style={{ padding: "1px 16px" }}>
              <Typography style={{ fontSize: "14px" }}>
                Learn more about capabilities{" "}
                <span style={{ color: "#008CF2", marginRight: "5px" }}>
                  Learn
                </span>
                <img src={exp} width={"15"} height={"15"} />
              </Typography>
              <Box style={{ padding: "6px 24px" }}>
                <Typography
                  style={{
                    fontSize: "10px",
                    color: "#008CF2",
                    margin: "10px 0px",
                  }}
                >
                  Permissions
                </Typography>
                <Box
                  style={{
                    backgroundColor: "rgba(0,0,0,0.5)",
                    borderRadius: "30px",
                    display: "flex",
                    flexDirection: "column",
                    position: "relative",
                  }}
                >
                  <SubLoader isLoading={subLoader} />
                </Box>
                {rolesAllPermissions.map((item, index) => (
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      margin: "10px 0px",
                    }}
                  >
                    <Box>
                      <input
                        type="checkbox"
                        id="vehicle1"
                        name="vehicle1"
                        value="termsChecked"
                        style={{ backgroundColor: "white", color: "black" }}
                        onClick={() => getSelectedItem(index)}
                      />
                    </Box>
                    <Box style={{ marginLeft: "5px" }}>
                      <Typography for="vehicle1" style={{ fontSize: "12px" }}>
                        {" "}
                        {item?.permissionTitle}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                <Box>
                  <img src={plainLock} />
                </Box>
                <Box style={{ marginLeft: "5px" }}>
                  <Typography
                    for="vehicle1"
                    style={{ fontSize: "12px", color: "#008CF2" }}
                  >
                    {" "}
                    Privacy Policy
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{ padding: "8px 40px", borderRadius: "20px" }}
              >
                Save
              </Button>
            </Box>
          </form>
        </Menu>
      </Box>
    </Box>
  );
};

export default SearchBarRoles;
