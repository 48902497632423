import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useMemo, useState, useEffect } from "react";
import undo from "../../assets/img/undo.png";
import refresh from "../../assets/img/refresh.png";
import roundThreeDot from "../../assets/img/roundThreeDot.png";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ResetChildpasswordModel from "./ResetChildpasswordModel";
import EditDashboardModel from "./EditDashboardModel";
import DeleteUsersModel from "./DeleteUsersModel";
import Checkbox from "@mui/material/Checkbox";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import "./style.css";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    color: "#7CECF7",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #7CECF7",
    borderRadius: "50px",
  },
}));

const TableHeader = ({
  Userdatatabletitle,
  handleSelectAllUser,
  allUserSelectorCheckbox,
}) => {
  const cls = useMemo(() => "dashboardOne", []);
  const tabletitle = Userdatatabletitle;
  console.log("tabletitletabletitle", tabletitle);
  return (
    <tr
      style={{
        backgroundColor: "#272E42",
      }}
    >
      <th
        style={{
          color: "white",
          fontSize: "18px",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        <Checkbox
          onClick={handleSelectAllUser}
          checked={allUserSelectorCheckbox}
        />
      </th>
      {tabletitle?.slice(1)?.map((item, i) => {
        return (
          item !== "Status" && (
            <>
              <th
                style={{
                  color: "white",
                  fontSize: "18px",
                  fontWeight: "700",
                  textAlign: "center",
                }}
              >
                {item.charAt(0)?.toUpperCase() + item?.slice(1)}
              </th>
            </>
          )
        );
      })}
      <th
        style={{
          color: "white",
          fontSize: "18px",
          fontWeight: "700",
          textAlign: "center",
        }}
      >
        Status
      </th>
    </tr>
  );
};

const DashBoardTable = ({
  UserDataError,
  searched,
  setSelectedUserCount,
  AllUserData,
  Usertabletitle,
  refreshList,
  setRefresh,
  selectedUserList,
  setSelectedUserList,
}) => {
  const [listusername, setListUserName] = useState([]);
  const [resetChildpasswordModelOpen, setResetChildpasswordModelOpen] =
    useState(false);
  const [editDashboardModelOpen, seteditDashboardModelOpen] = useState(false);
  const [deleteUsersModelOpen, setDeleteUsersModelOpen] = useState(false);
  const [resetChildpasswordModelValue, setResetChildpasswordModelOpenValue] =
    useState();
  const [allUserSelectorCheckbox, setAllUserSelectorCheckbox] = useState(false);
  const [moreActionManu, setMoreActionManu] = useState(null);
  const [moreActionManuIndex, setMoreActionManuIndex] = useState(null);
  const searchedusername = searched;
  const UserData = AllUserData;
  const Userdatatabletitle = Usertabletitle;

  const _handleResetPassword = (row) => {
    setResetChildpasswordModelOpen(true);
    setResetChildpasswordModelOpenValue(row);
  };
  const _handleEditUser = (row) => {
    seteditDashboardModelOpen(true);
    setResetChildpasswordModelOpenValue(row);
  };
  const _handleDeleteUser = (row) => {
    setDeleteUsersModelOpen(true);
    setResetChildpasswordModelOpenValue(row.id);
  };
  const handleSelectUser = (e) => {
    if (!e.target.checked) {
      const updateSelectedItem = selectedUserList.filter(
        (el) => el !== e.target.value
      );
      if (updateSelectedItem.length !== UserData.length)
        setAllUserSelectorCheckbox(false);
      setSelectedUserList(updateSelectedItem);
    } else {
      setSelectedUserList([...selectedUserList, e.target.value]);
    }
  };
  const handleSelectAllUser = (e) => {
    let allUserList = [];
    if (e.target.checked) {
      UserData.map((item, index) => {
        allUserList[index] = item.id;
      });
      setSelectedUserList(allUserList);
      setAllUserSelectorCheckbox(true);
    } else {
      setSelectedUserList([]);
      setAllUserSelectorCheckbox(false);
    }
  };
  const selectUserValidation = (e) => {
    let selectedUserValidationArray = selectedUserList?.filter(
      (item) => item === e
    );
    if (selectedUserValidationArray.length !== 0) return true;

    return false;
  };
  const handleClickOpenMoreActionManu = (event, index) => {
    // console.log("index", index);
    setMoreActionManu(event.currentTarget);
    setMoreActionManuIndex(index);
  };
  const handleCloseMoreActionManu = () => {
    setMoreActionManu(null);
    setMoreActionManuIndex();
  };
  useEffect(() => {
    if (searchedusername != "") {
      let filteredRows = UserData?.filter((item) => {
        return item?.name
          ?.toLowerCase()
          ?.includes(searchedusername.toLowerCase());
      });
      setListUserName(filteredRows);
    }
  }, [UserData, searchedusername]);
  useEffect(() => {
    if (selectedUserList.length === UserData.length)
      setAllUserSelectorCheckbox(true);
    else setAllUserSelectorCheckbox(false);
  }, [selectedUserList, UserData]);
  console.log("moreActionManuIndexmoreActionManuIndex", moreActionManuIndex);
  return (
    <Box
      sx={{
        padding: "10px",
        overflow: "auto",
        "&::-webkit-scrollbar": {
          width: 10,
          height: 5,
        },
        "&::-webkit-scrollbar-track": {
          backgroundColor: "#0000",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#ADD8E6",
          borderRadius: 3,
          border: "0px solid #0000",
        },
      }}
    >
      {resetChildpasswordModelOpen ? (
        <ResetChildpasswordModel
          resetChildpasswordModelOpen={resetChildpasswordModelOpen}
          setResetChildpasswordModelOpen={setResetChildpasswordModelOpen}
          resetChildpasswordModelValue={resetChildpasswordModelValue}
          setResetChildpasswordModelOpenValue={
            setResetChildpasswordModelOpenValue
          }
        />
      ) : null}
      {editDashboardModelOpen ? (
        <EditDashboardModel
          refreshList={refreshList}
          setRefresh={setRefresh}
          editDashboardModelOpen={editDashboardModelOpen}
          seteditDashboardModelOpen={seteditDashboardModelOpen}
          resetChildpasswordModelValue={resetChildpasswordModelValue}
          setResetChildpasswordModelOpenValue={
            setResetChildpasswordModelOpenValue
          }
        />
      ) : null}
      {deleteUsersModelOpen ? (
        <DeleteUsersModel
          editDashboardModelOpen={deleteUsersModelOpen}
          seteditDashboardModelOpen={setDeleteUsersModelOpen}
          resetChildpasswordModelValue={resetChildpasswordModelValue}
          refreshList={refreshList}
          setRefresh={setRefresh}
          setResetChildpasswordModelOpenValue={
            setResetChildpasswordModelOpenValue
          }
          setSelectedUserList={setSelectedUserList}
          selectedUserList={selectedUserList}
        />
      ) : null}
      {UserDataError ? (
        <Typography
          variant="h5"
          sx={{
            margin: "50px",
          }}
          className="no_data_text_box"
        >
          No Data Available
        </Typography>
      ) : (
        <table style={{ width: "100%" }} cellSpacing={0}>
          <TableHeader
            Userdatatabletitle={Userdatatabletitle}
            handleSelectAllUser={handleSelectAllUser}
            allUserSelectorCheckbox={allUserSelectorCheckbox}
          />
          {searchedusername === ""
            ? UserData?.map((row, index) => {
                return (
                  <tr
                    className={
                      selectUserValidation(row.id) ||
                      moreActionManuIndex === index
                        ? "user_date_table_select_row_color"
                        : "user_date_table"
                    }
                  >
                    <td
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      <Checkbox
                        onClick={handleSelectUser}
                        value={row.id}
                        checked={selectUserValidation(row.id)}
                      />
                    </td>
                    {Userdatatabletitle?.slice(1)?.map((item, i) => {
                      return (
                        item !== "Status" && (
                          <td
                            style={{
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {row[`${item}`]}
                          </td>
                        )
                      );
                    })}
                    {row.Status && (
                      <td className="user_date_table_action">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            minHeight: "62px",
                            alignItems: "center",
                          }}
                        >
                          <HtmlTooltip title={"Reset password"}>
                            <img
                              src={undo}
                              style={{ cursor: "pointer" }}
                              alt=""
                              onClick={() => _handleResetPassword(row)}
                            />
                          </HtmlTooltip>
                          <HtmlTooltip title={"Update User"}>
                            <img
                              src={refresh}
                              style={{ cursor: "pointer" }}
                              alt=""
                              onClick={() => _handleEditUser(row)}
                            />
                          </HtmlTooltip>
                          <HtmlTooltip title={"More"}>
                            <img
                              src={roundThreeDot}
                              style={{ cursor: "pointer" }}
                              alt=""
                              onClick={(e) =>
                                handleClickOpenMoreActionManu(e, index)
                              }
                            />
                          </HtmlTooltip>
                          <Menu
                            anchorEl={moreActionManu}
                            id="account-menu"
                            open={moreActionManu}
                            onClose={handleCloseMoreActionManu}
                            onClick={handleCloseMoreActionManu}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                background: "#010A26",
                                border: "1px solid #fff",
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  border: "1px solid #fff",
                                  background: "#010A26",
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&:before": {
                                  borderTop: "1px solid #fff",
                                  borderLeft: "1px solid #fff",
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "#010A26",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              onClick={() => _handleDeleteUser(row)}
                              sx={{ color: "#fff" }}
                            >
                              Delete User
                            </MenuItem>
                          </Menu>
                        </Box>
                      </td>
                    )}

                    {row.Status ? (
                      <td
                        style={{
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        <Typography className="user_date_table_text">
                          {row.Status}
                        </Typography>
                      </td>
                    ) : (
                      <td
                        className="user_date_table_active"
                        style={{
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Action
                      </td>
                    )}
                  </tr>
                );
              })
            : listusername?.map((row, index) => {
                return (
                  <tr
                    className={
                      selectUserValidation(row.id) || moreActionManu
                        ? "user_date_table_select_row_color"
                        : "user_date_table"
                    }
                  >
                    <td
                      style={{
                        fontSize: "16px",
                        textAlign: "center",
                      }}
                    >
                      <Checkbox
                        onClick={handleSelectUser}
                        value={row.id}
                        checked={selectUserValidation(row.id)}
                      />
                    </td>
                    {Userdatatabletitle?.slice(1)?.map((item, i) => {
                      return (
                        item !== "Status" && (
                          <td
                            style={{
                              fontSize: "16px",
                              textAlign: "center",
                            }}
                          >
                            {row[`${item}`]}
                          </td>
                        )
                      );
                    })}
                    {row.Status && (
                      <td className="user_date_table_action">
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-around",
                            minHeight: "62px",
                            alignItems: "center",
                          }}
                        >
                          <HtmlTooltip title={"Reset password"}>
                            <img
                              src={undo}
                              style={{ cursor: "pointer" }}
                              alt=""
                              onClick={() => _handleResetPassword(row)}
                            />
                          </HtmlTooltip>
                          <HtmlTooltip title={"Update User"}>
                            <img
                              src={refresh}
                              style={{ cursor: "pointer" }}
                              alt=""
                              onClick={() => _handleEditUser(row)}
                            />
                          </HtmlTooltip>
                          <HtmlTooltip title={"More"}>
                            <img
                              src={roundThreeDot}
                              style={{ cursor: "pointer" }}
                              alt=""
                              // onClick={() => _handleDeleteUser(row)}
                              onClick={(e) =>
                                handleClickOpenMoreActionManu(e, index)
                              }
                              // onMouseEnter={handleClickOpenMoreActionManu}
                            />
                          </HtmlTooltip>
                          <Menu
                            anchorEl={moreActionManu}
                            id="account-menu"
                            open={moreActionManu}
                            onClose={handleCloseMoreActionManu}
                            onClick={handleCloseMoreActionManu}
                            PaperProps={{
                              elevation: 0,
                              sx: {
                                background: "#010A26",
                                border: "1px solid #fff",
                                overflow: "visible",
                                filter:
                                  "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                mt: 1.5,
                                "& .MuiAvatar-root": {
                                  border: "1px solid #fff",
                                  background: "#010A26",
                                  width: 32,
                                  height: 32,
                                  ml: -0.5,
                                  mr: 1,
                                },
                                "&:before": {
                                  borderTop: "1px solid #fff",
                                  borderLeft: "1px solid #fff",
                                  content: '""',
                                  display: "block",
                                  position: "absolute",
                                  top: 0,
                                  right: 14,
                                  width: 10,
                                  height: 10,
                                  bgcolor: "#010A26",
                                  transform: "translateY(-50%) rotate(45deg)",
                                  zIndex: 0,
                                },
                              },
                            }}
                            transformOrigin={{
                              horizontal: "right",
                              vertical: "top",
                            }}
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                          >
                            <MenuItem
                              onClick={() => _handleDeleteUser(row)}
                              sx={{ color: "#fff" }}
                            >
                              Delete User
                            </MenuItem>
                          </Menu>
                        </Box>
                      </td>
                    )}

                    {row.Status ? (
                      <td
                        style={{
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        <Typography className="user_date_table_text">
                          {row.Status}
                        </Typography>
                      </td>
                    ) : (
                      <td
                        className="user_date_table_active"
                        style={{
                          fontSize: "16px",
                          textAlign: "center",
                        }}
                      >
                        Action
                      </td>
                    )}
                  </tr>
                );
              })}
        </table>
      )}
    </Box>
  );
};
export default DashBoardTable;
