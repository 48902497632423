import ReactMarkdown from 'react-markdown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import logo from "../../assets/img/logo.png";

const formatTime = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
};


export const Message = ({ message, index, isLastMessage, messagesEndRef, isQuestion }) => (
  <>
    <div className={`${isLastMessage && !isQuestion && 'response-height'}`}>
      <div key={index} className={`message-container container-${message.sender}`} >
        <div className='message-owner'>
          {message.sender === "bot" ? (
            <img src={logo} alt="bot-logo" />
          ) : (
            <AccountCircleIcon className='user-icon' fontSize="medium" />
          )}
        </div>
        <div className={`message ${message.sender} markdown-content`}>
          {message.sender === "bot" ? (
            <div>
              <span className='left-arrow-chat'></span>
              <ReactMarkdown rehypePlugins={[rehypeRaw]} remarkPlugins={[remarkGfm]}>
                {message.text}
              </ReactMarkdown>
            </div>
          ) : (
            <p>
              <span className='right-arrow-chat'></span>
              {message.text}
              <span className='invisible'>
                {formatTime(message.timestamp)}
              </span>
            </p>
          )}
          <div className='message-timestamp'>
            {formatTime(message.timestamp)}
          </div>
        </div>
      </div>
    </div>
    {isLastMessage && <div ref={messagesEndRef} />}
  </>
);