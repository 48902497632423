import { useEffect, useState, useRef } from "react";
import cancelicn from "../../../assets/img/cancel_round_icn.png";
import { Typography } from "@mui/material";
import HighchartsReact from "highcharts-react-official";
import { toast } from "react-toastify";
import Highcharts from "highcharts";
import moment from "moment";
import { Box } from "@mui/system";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@material-ui/core/styles";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import filterIcon from "../../../assets/img/fillter2x.png";
import { createBrowserHistory } from "@remix-run/router";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { DateRangePicker } from "react-date-range";
import Stack from "@mui/material/Stack";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { Api } from "../../../MyNewUtils/Api";
import SubLoader from "../../../Component/Spinner/subLoader";
import "./iamMap.css";
require("highcharts/modules/exporting")(Highcharts);

const ChartTable = (item) => {
  let { selectChart } = item;
  const [chartTypes, setChartTypes] = useState(item?.item?.data);
  const [anchorElFilterManu, setAnchorElFilterManu] = useState(null);
  const [list, setList] = useState(item?.item?.accountResponseType);
  const history = createBrowserHistory();
  const [fristDataTypes, setFristDataTypes] = useState(
    Object.keys(chartTypes)[0]
  );
  const [data, setData] = useState(chartTypes[`${fristDataTypes}`]);
  const [tableTitle, setTableTitle] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [subLoader, setSubLoader] = useState(false);
  const [datePickerState, setDatePickerState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const useStyles = makeStyles({
    table: {
      minWidth: 100,
    },
  });
  const classes = useStyles();
  useEffect(() => {
    if (
      fristDataTypes === "Table" &&
      data[0] != undefined &&
      data[0].length != 0
    ) {
      let title = [];
      Object.keys(data[0]).map((key, i) => (title[i] = key));
      setTableTitle(title);
    }
  }, [fristDataTypes]);
  const getSelectChart = (itemData) => {
    setMenuPosition();
    handleClose();
    setFristDataTypes(itemData);
    setData(chartTypes[`${itemData}`]);
    let donnArray = [];
    let ItemNum = item?.Num;
    item?.doneData?.map((item, index) => {
      if (index === ItemNum) {
        donnArray[index] = {
          ...donnArray[index],
          Title: item.Title,
          accountResponseType: `${itemData}`,
          data: chartTypes[`${itemData}`],
          isFilterAllow: item.isFilterAllow,
          featureKey: item?.featureKey,
        };
      } else {
        donnArray[index] = item;
      }
    });

    item.setDoneData(donnArray);
  };

  const getClose = () => {
    console.log("close");
    let finelDataClose = [];
    let getCloseData = item?.Num;
    let CloseNum = item?.Num;
    item.showData.map((ChartData, index) => {
      if (index === CloseNum) {
        finelDataClose[index] = {
          ...finelDataClose[index],
          Title: ChartData?.Title,
          isFilterAllow: ChartData?.isFilterAllow,
          data: ChartData?.data,
          accountResponseType: ChartData?.accountResponseType,
          show: false,
        };
      } else {
        finelDataClose[index] = ChartData;
      }
    });
    item.setShowData(finelDataClose);
    item.handleRemove(finelDataClose);
  };

  const componentRef = useRef();
  const me = this;
  const elementRef = useRef();
  const handleOnChange = (ranges) => {
    const { selection } = ranges;
    setDatePickerState([selection]);
    // if (selection.startDate.toISOString() != selection.endDate.toISOString()) {
    //   handleDateRange();
    // }
  };

  const handleDateRange = async () => {
    setSubLoader(true);
    setAnchorElFilterManu(!anchorElFilterManu);

    // ---------- Date Filter API Call Start ---------- //
    let uri = item?.item?.request_data?.uri;
    let method = item?.item?.request_data?.method;
    let header = item?.item?.request_data?.headers;
    let body = item?.item?.request_data?.body;
    body = {
      ...body,
      from_date: moment(datePickerState[0]?.startDate).format("YYYY-MM-DD"),
      to_date: moment(datePickerState[0]?.endDate).format("YYYY-MM-DD"),
    };
    header = { ...header, "Content-Type": "application/json" };
    const { data } = await Api.customRequest(uri, method, header, body);
    const res = JSON.parse(data);
    if (res?.status === 1) {
      let finelDataClose = [];
      let CloseNum = item?.Num;
      item.showData.map((ChartData, index) => {
        if (index === CloseNum) {
          finelDataClose[index] = ChartData;
          finelDataClose[index] = {
            ...finelDataClose[index],
            data: res?.data,
          };
        } else {
          finelDataClose[index] = ChartData;
        }
      });
      let title = Object.keys(res?.data);
      setData(res?.data?.[`${title[0]}`]);
      setChartTypes(res?.data);
      item.setShowData(finelDataClose);
      setSubLoader(false);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setSubLoader(false);
    }
    // ---------- Date Filter API Call End ---------- //

    let ItemNum = item?.Num;
    let donnArray = item?.doneData;

    item?.doneData?.map((item, index) => {
      if (index === ItemNum) {
        donnArray[index] = {
          ...donnArray[index],
          from_date: moment(datePickerState[0]?.startDate).format("YYYY-MM-DD"),
          to_date: moment(datePickerState[0]?.endDate).format("YYYY-MM-DD"),
        };
      } else {
        donnArray[index] = item;
      }
    });

    item.setDoneData(donnArray);
  };

  return (
    <>
      <Modal
        open={anchorElFilterManu}
        // onClose={() => { setAnchorElFilterManu(!anchorElFilterManu) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            background: "#010A26",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            border: "1px solid #fff",
            borderRadius: "20px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{ color: "#fff", fontSize: "1.5rem" }}
            >
              Select Date
            </Typography>
            <Box
              onClick={() => {
                setAnchorElFilterManu(!anchorElFilterManu);
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={{
                color: "#000",
                fontSize: "1.5rem",
                background: "#fff",
                borderRadius: "100%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <CloseIcon style={{ fill: "#000" }} />
            </Box>
          </Box>
          <Box sx={{ position: "relative" }}>
            <DateRangePicker
              onChange={handleOnChange}
              showSelectionPreview={true}
              moveRangeOnFirstSelection={false}
              months={1}
              ranges={datePickerState}
              direction="vertical"

            />
          </Box>
          <Box sx={{ marginTop: "15px", justifyContent: "flex-end" }}>
            <Stack
              spacing={2}
              direction="row"
              sx={{ justifyContent: "flex-end" }}
            >
              <Button
                variant="outlined"
                onClick={() => {
                  setAnchorElFilterManu(!anchorElFilterManu);
                }}
              >
                cancel
              </Button>
              <Button variant="contained" onClick={handleDateRange}>
                Ok
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <Box
        sx={{
          padding: "10px",
          borderRadius: "20px",
          width: "100%",
          height: "500px",
          background: "#000000 0% 0% no-repeat padding-box",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <SubLoader isLoading={subLoader} />
        <Box
          style={{
            display: "flex",
            alignItems: "right",
            justifyContent: "right",
            width: "100%",
            padding: "10px 10px 0px 10px",
          }}
        >
          <img
            src={cancelicn}
            style={{ width: "25px", height: "25px", cursor: "pointer" }}
            onClick={() => getClose()}
            alt=""
          />
        </Box>

        <Box
          sx={{
            width: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              width: 3,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              position: "sticky",
              top: 0,
              backgroundColor: "#000",
              padding: "0px 50px",
              zIndex: "10",
            }}
          >
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                paddingLeft: "20px",
              }}
            > */}
            <Typography
              sx={{
                fontSize: "26px",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                color: "#fff",
              }}
            >
              {item?.item?.Title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
              }}
            >
              {item?.item?.isFilterAllow && (
                <Tooltip title="Filter" placement="top">
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={anchorElFilterManu ? "long-menu" : undefined}
                    aria-expanded={anchorElFilterManu ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={() => {
                      setAnchorElFilterManu(!anchorElFilterManu);
                    }}
                  >
                    <CalendarTodayIcon
                      style={{
                        width: "18px",
                        height: "18px",
                        marginLeft: "25px",
                        marginTop: "0px",
                        cursor: "pointer",
                        color: "#fff",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Box
                sx={{
                  display: "flex",
                  zIndex: "10",
                }}
              >
                <ul class="dropdown ul" sx={{ background: "#05012E" }}>
                  <li>
                    <img
                      src={filterIcon}
                      style={{
                        width: "15px",
                        height: "15px",
                        marginLeft: "10px",
                        marginTop: "5px",
                        cursor: "pointer",
                      }}
                      alt=""
                    />
                    <ul style={{ right: "0px", background: "#05012E" }}>
                      <li>
                        <Box
                          sx={{
                            display: "flex",
                            background: "#  ",
                            boxShadow: "1px 0px 9px #000",
                            flexDirection: "row-reverse",
                          }}
                        >
                          <Typography
                            sx={{
                              background: "#05012E",
                              padding: "7px 16px",
                              color: "#80F4FE",
                            }}
                          >
                            Type
                          </Typography>
                          <Typography
                            sx={{
                              background: "#05012E",
                              marginLeft: "20px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <ArrowLeftIcon sx={{ fill: "#80F4FE" }} />
                          </Typography>
                        </Box>
                        <ul
                          style={{
                            boxShadow: "1px 0px 9px #000",
                            marginLeft: "1px",
                            left: "-90%",
                            background: "#05012E",
                            minWidth: "100px",
                          }}
                        >
                          {list?.map((list) => {
                            return (
                              <>
                                <li
                                  value={list}
                                  onClick={() => {
                                    selectChart &&
                                      selectChart(item?.item, list);
                                    getSelectChart(list);
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <Typography
                                    sx={{
                                      background: "#05012E",
                                      padding: "7px 16px",
                                      color: "#80F4FE",
                                    }}
                                  >
                                    {list}
                                  </Typography>
                                </li>
                              </>
                            );
                          })}
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
              </Box>
            </Box>
          </Box>

          <Box>
            {fristDataTypes === undefined || data === undefined ? (
              <Paper style={{ background: "#0000", padding: "0px 40px" }}>
                <Typography
                  sx={{
                    height: "380px",
                  }}
                  className="no_data_text_box"
                >
                  No Data Available
                </Typography>
              </Paper>
            ) : fristDataTypes === "Table" ? (
              <Paper
                ref={componentRef}
                style={{ background: "#0000", padding: "0px 40px" }}
              >
                {data.length != 0 && data != undefined ? (
                  <TableContainer
                    sx={{
                      "&::-webkit-scrollbar": {
                        width: 3,
                        height: 10,
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#0000",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#ADD8E6",
                        borderRadius: 2,
                        border: "0px solid #0000",
                      },
                    }}
                  >
                    <Table
                      className={classes.table}
                      aria-label="simple table"
                      style={{ background: "#0000", width: "100%" }}
                    >
                      <TableHead>
                        <TableRow>
                          {tableTitle?.map((item, i) => {
                            return (
                              <>
                                <TableCell
                                  align="Left"
                                  style={{
                                    color: "#fff",
                                    borderBottom: "0px solid #fff",
                                    fontSize: "auto",
                                  }}
                                >
                                  {item}
                                </TableCell>
                              </>
                            );
                          })}
                        </TableRow>
                      </TableHead>
                      <TableBody style={{ background: "#0000" }}>
                        {data?.map((row, index) => {
                          return (
                            <TableRow>
                              {tableTitle?.map((item, i) => {
                                return (
                                  <TableCell
                                    style={{
                                      color: "#fff",
                                      borderBottom: "0px solid #fff",
                                    }}
                                  >
                                    {row[`${item}`]}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                ) : (
                  <Typography
                    sx={{
                      height: "380px",
                    }}
                    className="no_data_text_box"
                  >
                    No Data Available
                  </Typography>
                )}
              </Paper>
            ) : (
              <HighchartsReact
                options={data}
                highcharts={Highcharts}
                ref={elementRef}
                containerProps={{ style: { width: "100%", height: "100%" } }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default ChartTable;
