import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import roundClose from "../../../../assets/img/cancel_icn.png";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import { Api } from "../../../../MyNewUtils/Api";
import TextField from "@material-ui/core/TextField";
import SubLoader from "../../../Spinner/subLoader";
const UpdateRoleModel = ({
  updateRoleModelOpen,
  setUpdateRoleModelOpen,
  actionRolesValue,
  setActionRolesValue,
  refreshList,
  setSelectedRolesList,
  setRefreshList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [rolesAllPermissions, setRolesAllPermissions] = useState([]);
  const [updateRoleDetails, setUpdateRoleDetails] = useState({
    RoleName: actionRolesValue?.Rolename,
    RoleDesc: actionRolesValue?.Roledesc,
    Roleparmissions: JSON.parse(actionRolesValue.Rolepermissions),
  });
  const handleClose = () => {
    setUpdateRoleModelOpen(false);
    setSelectedRolesList([]);
    setActionRolesValue();
  };
  const onSubmit = async () => {
    if (
      updateRoleDetails?.RoleName &&
      updateRoleDetails?.RoleName.length !== 0
    ) {
      if (
        updateRoleDetails?.RoleDesc &&
        updateRoleDetails?.RoleDesc.length !== 0
      ) {
        setIsLoading(true);
        let final = {
          userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
          Rolename: updateRoleDetails?.RoleName,
          Roledesc: updateRoleDetails?.RoleDesc,
          Roleid: actionRolesValue?._id,
          Rolepermissions: JSON.stringify(updateRoleDetails?.Roleparmissions),
        };
        const { data } = await Api.postRequest("/users/update-role", final);
        const res = JSON.parse(data);

        if (res?.status === 1) {
          toast.success(res?.message, { autoClose: 1000 });
          setIsLoading(false);
          setRefreshList(!refreshList);
          handleClose();
        } else {
          toast.error("validation error", { autoClose: 1000 });
          setIsLoading(false);
        }
      } else {
        toast.error("Please Enter Valid Role Name", { autoClose: 1000 });
      }
    } else {
      toast.error("Please Enter Valid Role Rescription", { autoClose: 1000 });
    }
  };
  console.log("refreshList", refreshList, !refreshList);
  const getAllPermissions = async () => {
    setIsLoading(true);
    let final = { userid: JSON.parse(localStorage.getItem("user"))?.data?.id };
    const { data, statusCode } = await Api.postRequest(
      "/users/get-all-permissions",
      final
    );
    const res = JSON.parse(data);
    if (res?.status === 1) {
      setIsLoading(false);
      setRolesAllPermissions(res?.data);
    } else {
      toast.error(res?.message, { autoClose: 1000 });
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const handleSelectRole = (e) => {
    if (!e.target.checked) {
      const updateSelectedItem = updateRoleDetails.Roleparmissions.filter(
        (el) => el !== e.target.value
      );
      setUpdateRoleDetails({
        ...updateRoleDetails,
        Roleparmissions: updateSelectedItem,
      });
    } else {
      let addNewRolePermissions = updateRoleDetails.Roleparmissions;
      addNewRolePermissions.push(e.target.value);
      setUpdateRoleDetails({
        ...updateRoleDetails,
        Roleparmissions: addNewRolePermissions,
      });
    }
  };

  const selectRolesPermissionValidation = (e) => {
    let selectedRolesValidationArray =
      updateRoleDetails?.Roleparmissions?.filter((item) => item === e);
    if (selectedRolesValidationArray.length !== 0) return true;

    return false;
  };

  useEffect(() => {
    getAllPermissions();
  }, []);

  return (
    <div>
      <Modal
        open={updateRoleModelOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          className="Reset-Child-password-Model-box "
          sx={{
            minWidth: "25%",
            maxHeight: "90%",
            overflow: "auto",
            "&::-webkit-scrollbar": {
              width: 10,
              height: 10,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#0000",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#ADD8E6",
              borderRadius: 2,
              border: "0px solid #0000",
            },
          }}
        >
          {/* <MainLoader isLoading={isLoading} /> */}

          <SubLoader isLoading={isLoading} />
          <Box
            style={{
              display: "flex",
              justifyContent: "flex-end",
              cursor: "text",
              padding: "0px 0px 0px 0px",
            }}
          >
            <img
              src={roundClose}
              style={{ cursor: "pointer" }}
              onClick={handleClose}
              alt=""
            />
          </Box>
          <Typography
            variant="h5"
            align="center"
            style={{ marginTop: "30px", color: "white", textAlign: "inherit" }}
          >
            Update Role
          </Typography>
          <Box>
            <Typography sx={{ color: "#fff", marginTop: "20px" }}>
              Role Name
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                type="text"
                name="fname"
                variant="standard"
                inputProps={{}}
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                onChange={(e) =>
                  setUpdateRoleDetails({
                    ...updateRoleDetails,
                    RoleName: e.target.value,
                  })
                }
                defaultValue={updateRoleDetails?.RoleName}
                required
                fullWidth
              />
            </Box>
          </Box>
          <Box>
            <Typography sx={{ color: "#fff", marginTop: "20px" }}>
              Role Description
            </Typography>
            <Box sx={{ flexGrow: 1 }}>
              <TextField
                type="text"
                name="fname"
                variant="standard"
                inputProps={{}}
                InputProps={{
                  style: {
                    color: "white",
                    borderBottom: "2px solid rgb(0,117,210)",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "rgba(255,255,255,0.4)",
                  },
                }}
                onChange={(e) =>
                  setUpdateRoleDetails({
                    ...updateRoleDetails,
                    RoleDesc: e.target.value,
                  })
                }
                defaultValue={updateRoleDetails?.RoleDesc}
                required
                fullWidth
              />
            </Box>
          </Box>

          <Box>
            <Typography sx={{ color: "#fff", marginTop: "20px" }}>
              Role Parmissions
            </Typography>
            {rolesAllPermissions.map((item, index) => (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "10px 0px",
                }}
              >
                <Box>
                  <Checkbox
                    onClick={handleSelectRole}
                    value={item.permissionValue}
                    checked={selectRolesPermissionValidation(
                      item.permissionValue
                    )}
                  />
                </Box>
                <Box style={{ marginLeft: "5px" }}>
                  <Typography
                    for="vehicle1"
                    style={{ fontSize: "12px", color: "#fff" }}
                  >
                    {" "}
                    {item?.permissionTitle}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "40px 0px 20px 0px",
              }}
            >
              <Button
                type="submit"
                variant="contained"
                style={{ padding: "8px 40px", borderRadius: "20px" }}
                onClick={onSubmit}
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default UpdateRoleModel;
