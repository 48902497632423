import { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TableWithPagination from "../table/TableWithPagination";
import { Api } from "../../../../MyNewUtils/Api";
import { useDispatch, useSelector } from "react-redux";
import { supportTicket } from "../../../../redux/action";
import { useNavigate } from "react-router-dom";
import { createSearchParams } from "react-router-dom";

const SupportCenter = () => {
  const [rowLimitEnd, setRowLimitEnd] = useState(10);
  const [userSupportTicketsDetails, setUserSupportTicketsDetails] = useState(
    []
  );
  const [userSupportTicketsFilterDetails, setUserSupportTicketsFilterDetails] =
    useState([]);
  const TableTitle = [
    {
      id: "subject",
      numeric: true,
      disablePadding: false,
      label: "Subject",
      show: true,
      date: false,
    },
    {
      id: "caseid",
      numeric: true,
      disablePadding: false,
      label: "Case ID",
      show: true,
      date: false,
    },
    {
      id: "createdAt",
      numeric: true,
      disablePadding: false,
      label: "Create",
      show: true,
      date: true,
    },
    {
      id: "status",
      numeric: true,
      disablePadding: false,
      label: "Status",
      show: true,
      date: false,
    },
  ];

  let navigate = useNavigate();
  const handleChange = () => {
    navigate("/viewCasesDetails", { state: userSupportTicketsDetails });
  };
  useEffect(() => {
    getUserSupportTicketsDetails();
  }, []);
  useEffect(() => {
    if (userSupportTicketsDetails.length != 0) {
      let filterData = userSupportTicketsDetails;
      filterData = userSupportTicketsDetails.filter(
        (event) => event?.status === "1"
      );
      setUserSupportTicketsFilterDetails(filterData);
    }
  }, [userSupportTicketsDetails]);

  const dispatch = useDispatch();
  const getUserSupportTicketsDetails = async () => {
    let payload = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
    };
    const data = await Api.postRequest("/users/support-tickets", payload);
    const res = JSON.parse(data?.data);
    if (res?.status === 1) {
      setUserSupportTicketsDetails(res?.data);
      dispatch(supportTicket(res?.data));
    }
  };
  return (
    <>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box className="SupportCenter_Box">
          <Typography className="SupportCenter_heading_text">
            CLEVRONE Support Center
          </Typography>
          {/* <Box className="SupportCenter_Sub_Box">
            <Typography className="SupportCenter_Sub_heading_text">
              How can we Help?
            </Typography>
            <Box className="SupportCenter_Search_Box">
              <SearchIcon style={{ color: "white", margin: "5px" }} />
              <input
                placeholder={"Search AWS Support resources"}
                className="SupportCenter_Search_Input_Box"
              />
            </Box>
          </Box> */}
          <Box className="SupportCenter_Sub_Box">
            <Box className="d-flex justify-between">
              <Typography className="SupportCenter_Sub_heading_text">
                Open Support cases
              </Typography>
              <Box className="d-flex">
                <Box
                  className="SupportCenter_btn d-flex items-center cursor_pointer"
                  onClick={() => {
                    navigate("/allCases");
                  }}
                >
                  View all cases
                </Box>
                <Box
                  className="SupportCenter_btn_background_color SupportCenter_btn d-flex items-center margin-left-20px cursor_pointer"
                  onClick={() => {
                    navigate("/createCase");
                  }}
                >
                  Create case
                </Box>
              </Box>
            </Box>
            <Box>
              <TableWithPagination
                headCells={TableTitle}
                rows={userSupportTicketsFilterDetails}
                rowLimitEnd={rowLimitEnd}
                setRowLimitEnd={setRowLimitEnd}
                filter={true}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default SupportCenter;
