import {Box} from '@mui/system'
import {memo} from 'react'
import Loader from 'react-loader-spinner'
function Spinner({isLoading, styles, color}) {
  if (!isLoading) return null
  return (
    <Box style={styles}>
      <Loader type="Circles" height={100} width={100} color={color} />
    </Box>
  )
}
export default memo(Spinner)
