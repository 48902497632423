import { Box } from "@mui/system";
import RegistrationLayout from "../../Component/Layout/index";
import logo from "../../assets/img/logo_header.png";

import rocket from "../../assets/img/rocket.png";
import { Typography } from "@mui/material";
import CustomButton from "../../Component/CustomButton/index";
import { useNavigate } from "react-router-dom";
import { path } from "../../MyNewUtils/screenPath";
import { Api } from "../../MyNewUtils/Api";
import { toast } from "react-toastify";

const SignupStepFive = () => {
  const navigate = useNavigate();

  const onSubmit = async () => {
    let final = {
      userid: JSON.parse(localStorage.getItem("user"))?.data?.id,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    const Data = await Api.postRequest("/users/update-profile", final);
    const res = JSON.parse(Data?.data);
    let localData = JSON.parse(localStorage.getItem("userData"));
    localData = {
      ...localData,
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    localStorage.setItem("userData", JSON.stringify(localData));

    let user = JSON.parse(localStorage.getItem("user"));
    let data = { ...user["data"], ...localData };
    user["data"] = data;
    localStorage.setItem("user", JSON.stringify(user));

    // let localData = JSON.parse(localStorage.getItem("userData"));

    navigate("/cloud-setup-wizard");
  };

  return (
    <RegistrationLayout isHeaderShow={false}>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {/* Header */}
        <Box
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "30px",
          }}
        >
          <img src={logo} alt={"logo"} />
        </Box>

        {/* Body */}
        <Box
          style={{
            backgroundColor: "rgba(0,0,0,0.5)",
            width: "70%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "40px 20px",
            borderRadius: "30px",
            border: "1px solid gray",
          }}
        >
          <Box>
            <img src={rocket} />
          </Box>
          <Box
            style={{
              width: "65%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                fontSize: "36px",
                color: "#FF8800",
                textAlign: "center",
              }}
            >
              Congratulations
            </Typography>
            <Typography style={{ fontSize: "14px", textAlign: "center" }}>
              Thank you for signing up for CLEVRONE. We are activating your
              account, which should only take a few minutes. You will receive an
              email when this is complete.
            </Typography>

            <CustomButton
              text={"Go to CLEVRONE Management Console"}
              styles={{
                backgroundColor: "rgb(255,131,0)",
                color: "#FFF",
                marginTop: "20px",
                borderRadius: "2px",
                padding: "10px 18px",
                width: "70%",
              }}
              onButtonClick={onSubmit}
            />
            <Typography style={{ fontSize: "14px", marginTop: "30px" }}>
              <span
                style={{ color: "#008CF2", cursor: "pointer" }}
                onClick={() => {
                  navigate("/signup");
                }}
              >
                Signing up for another account{" "}
              </span>{" "}
              or <span style={{ color: "#008CF2" }}>contact sales</span>
            </Typography>
          </Box>
        </Box>
      </Box>
    </RegistrationLayout>
  );
};
export default SignupStepFive;
