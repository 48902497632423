import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useNavigate } from "react-router-dom";
import cancelIcon from "../../../../../../assets/img/cancel_round_icn.png";
import triangleIcon from "../../../../../../assets/img/triangle_icn@2x.png";
import { Typography } from "@mui/material";
import '../../createCase.css';
const style = {
  boxShadow: 24,
  p: 4,
};
const ClearPageModel = ({ clearModel, setClearModel }) => {
  let navigate = useNavigate();

  const onSubmit = () => {
    setClearModel(false);
    navigate("/supports");
  };
  return (
    <div>
      <Modal
        open={clearModel}
        onClose={() => setClearModel(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={style} className="clear-page-model-box">
          <Box>
            <Box className="cancel-icon-box">
              <img
                src={cancelIcon}
                alt=""
                style={{ cursor: "pointer" }}
                onClick={() => setClearModel(false)}
              />
            </Box>
            <Typography className="clear-page-model-header-title">
              Clear Page
            </Typography>
            <Box className="clear-page-model-warning-box">
              <Box>
                <img src={triangleIcon} alt="" width={20} />
              </Box>
              <Box>
                <Typography className="clear-page-model-warning-text">
                  Are you sure that you want to clear the current page? The
                  changes that you made won't be saved.
                </Typography>
              </Box>
            </Box>
            <Box className="clear-page-model-btn-part">
              <Box
                className="previous-btn SupportCenter_btn d-flex items-center cursor_pointer padding_top_bottom_10_15"
                style={{ color: "#fff" }}
                onClick={() => setClearModel(false)}
              >
                Cancel
              </Box>
              <Box
                className="SupportCenter_btn_background_color SupportCenter_btn d-flex items-center margin-left-20px cursor_pointer padding_top_bottom_10_15 "
                style={{ color: "#fff" }}
                onClick={onSubmit}
              >
                Confirm
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default ClearPageModel;
