import { Box } from "@mui/system";
import RegistrationLayout from "../../Component/Layout/index";
import SignupStep8Col1 from "../../Component/confirmIdentityDetails/logo";
import SignupStep8Col2 from "../../Component/confirmIdentityDetails/userIcon";
import SignupStep8Col3 from "../../Component/confirmIdentityDetails";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

const SignupStepFour = () => {
  const location = useLocation();
  const [MyData, setMyData] = useState(location?.state);

  return (
    <RegistrationLayout isHeaderShow={false}>
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          style={{
            width: "30%",
            display: "flex",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <SignupStep8Col1 />
        </Box>
        <Box
          style={{
            width: "20%",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            marginRight: "20px",
          }}
        >
          <SignupStep8Col2 />
        </Box>
        <Box
          style={{
            padding: "20px",
            width: "45%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            <SignupStep8Col3 MyData={MyData} />
          </Box>
        </Box>
      </Box>
    </RegistrationLayout>
  );
};
export default SignupStepFour;
