import { DISCOVER_REQUEST, DISCOVER_SUCCESS, DISCOVER_FAILURE, ADD_MESSAGE, RESET_MESSAGE } from '../constants/discoverActionTypes';

const initialState = {
  loading: false,
  error: null,
  answer: null
};

export const discoverReducer = (state = initialState, action) => {
  switch (action.type) {
    case DISCOVER_REQUEST:
      return { ...state, loading: true, error: null };
    case DISCOVER_SUCCESS:
      return { ...state, loading: false, answer: action.payload };
    case DISCOVER_FAILURE:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

const chatInitialState = {
  conversation: [],
};

export const chatReducer = (state = chatInitialState, action) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...state,
        conversation: [...state.conversation, action.payload],
      };
    case RESET_MESSAGE:
      return {
        ...state,
        conversation: [],
      };
    default:
      return state;
  }
};

