import * as React from "react";
import Modal from "@mui/material/Modal";
import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DonutChart from "react-donut-chart";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import more_btn_icn from "../../assets/img/more_btn_icn.png";
import information from "../../assets/img/information.png";
import ExitfullScreen from "../../assets/img/Exit full Screen@2x.png";
import orangealert from "../../assets/img/alert_icn.png";
import redalert from "../../assets/img/alert_red.png";
import searchicon from "../../assets/img/glass.png";
import rds from "../../assets/img/rds.png";
import { useMemo } from "react";
import DashboardHeader from "../../Component/DashboardHeader/DashboardHeader";
import DashboardAnalysisOneHeader from "../../Component/DashboardAnalysisHeader/index";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { Api } from "../../MyNewUtils/Api";
import { useEffect } from "react";
import BucketList from "../CloudInfo/BucketList";
const Zoom = ({ setShowZoomChart, allData, color, showerror }) => {
  console.log("allData", allData);
  const [open, setOpen] = useState(true);
  const [chartData, setChartData] = useState();
  const [showTable, setShowTable] = useState(false);
  const handleClose = () => setShowZoomChart(false);
  const [reactDonutChartdataAll, setReactDonutChartdataAll] = useState([]);
  const cls = useMemo(() => "CloudInfoChart", []);
  const navigate = useNavigate();
  const [awsServicesData, setAwsServicesData] = useState();
  const [awsChartData, setAwsChartData] = useState();
  const [model, setModel] = useState(false);
  const [tableTitle, setTableTitle] = useState([]);
  const [showTableData, setShowTableData] = useState([]);
  const data = [
    {
      label: "S3",
      value: 20,
      color: "#FF5774",
    },
    {
      label: "Lambda",
      value: 25,
      color: "#8A00FF",
    },
    {
      label: "CloudFront",
      value: 8,
      color: "#00B1FF",
    },
    {
      label: "Route 53",
      value: 12,
      color: "#64D600",
    },
    {
      label: "Lightsail",
      value: 23,
      color: "#FFBB00",
    },
    {
      label: "ES2",
      value: 10,
      color: "#F9874D",
    },
  ];
  const [selected, setSelected] = useState(0);
  const [hovered, setHovered] = useState(undefined);

  const lineWidth = 60;

  // new parameter

  const reactDonutChartdata =
    reactDonutChartdataAll.length != 0
      ? reactDonutChartdataAll
      : [
          {
            label: "",
            value: awsChartData?.numberOfS3Buckets,
            color: "",
            data: awsChartData?.S3BucketsArr,
          },
        ];

  const reactDonutChartSelectedOffset = 0.04;
  const reactDonutChartHandleClick = (item, toggled) => {
    if (toggled) {
      console.log(item);
    }
  };
  let reactDonutChartStrokeColor = "#FFFFFF";
  const reactDonutChartOnMouseEnter = (item) => {
    console.log("start");
    setChartData(item);
    let color = reactDonutChartdata?.find((q) => q.label === item.label).color;
    reactDonutChartStrokeColor = color;
    console.log("item", item.data);
    let title = [];
    Object.keys(item.data[0]).map((key, i) => (title[i] = key));
    setTableTitle(title);
    let tableData = [];
    item.data.slice(0, 2).map((item, index) => {
      console.log("item", item);
      tableData[index] = {
        ...tableData[index],
        Name: item?.Name,
        Date: item["Creation Date"]
          ? item["Creation Date"]
          : item[["Launch Date"]],
      };
    });
    setShowTableData(tableData);
    console.log("title", title);

    setShowTable(true);
    console.log("end");
  };
  function createData(name, icon, date) {
    return { name, icon, date };
  }

  const rows = [
    createData("Frozen yoghurt", orangealert, 159),
    createData("Ice cream sandwich", orangealert, 237),
    createData("Ice cream sandwich", orangealert, 237),
    createData("Eclair", redalert, 262),
  ];
  const getData = async () => {
    const res = allData;
    console.log("zoomIN", res);
    setAwsServicesData(res?.servicelist);
    setAwsChartData(res?.servicelist);

    let dataArray = [];
    res?.servicelist.map((item, index) => {
      dataArray[index] = {
        ...dataArray[index],
        label: item.text,
        value: item.totalNumberofData,
        color: item.color,
        icon: item.icon,
        data: item.data,
      };
    });
    console.log("dataArray", dataArray);
    setReactDonutChartdataAll(dataArray);
  };
  const modelOpen = () => {
    setModel(true);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div style={{ background: "red" }}>
      {model ? (
        <BucketList
          setModel={setModel}
          allChartdata={chartData}
          tableTitle={tableTitle}
        />
      ) : null}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          background: "rgb(225, 225, 225, 0.4)",
          backdropFilter: "blur(5px)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={{
            background: "#000",
            width: "80%",
            backgroundColor: "rgba(0,0,0,0.5)",
            padding: "20px 40px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "20px",
            margin: "auto",
          }}
        >
          <Box
            style={{
              display: "flex",
              marginTop: "10px",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Box>
                <Typography style={{ color: "white", fontSize: "14px" }}>
                  AWS Services
                </Typography>
              </Box>
              <Box>
                {reactDonutChartdata.map((row, index) => (
                  <Box
                    style={{
                      display: "flex",
                      marginTop: "10px",
                      color: "white",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        width: "17px",
                        height: "11px",
                        background: row?.color,
                        marginRight: "5px",
                      }}
                    ></Box>
                    <Typography style={{ fontSize: "12px" }}>
                      {row.label}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>
            <Box
              className="App"
              style={{ background: "#0000", width: "600px", height: "600px" }}
            >
              <Box
                className="zoomChart"
                sx={{
                  paddiing: "auto",
                  display: "flex",
                  position: "absolute",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  textAlign: "center",
                }}
              >
                <DonutChart
                  onMouseEnter={(item) => reactDonutChartOnMouseEnter(item)}
                  strokeColor={reactDonutChartStrokeColor}
                  data={reactDonutChartdata}
                  colors={color}
                  selectedOffset={reactDonutChartSelectedOffset}
                  onClick={(item, toggled) =>
                    reactDonutChartHandleClick(item, toggled)
                  }
                  legend={false}
                  width={500}
                  height={500}
                />
              </Box>
              <Box
                className="zoomChartBox"
                sx={{
                  marginLeft: "150px",
                  position: "absolute",
                  color: "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  width: "auto",
                  height: "auto",
                  textAlign: "center",
                }}
              >
                <Typography
                  className="zoomChartTitle"
                  style={showTable ? null : { display: "none" }}
                >
                  {chartData?.label}
                </Typography>
                <TableContainer
                  component={Paper}
                  style={
                    showTable
                      ? {
                          scrollbarWidth: "none",
                          width: "200px",
                          height: "150px",
                          background: "rgba(232, 84, 114,0.25)",
                          borderRadius: "30px",
                          padding: "0 15px 0 15px",
                        }
                      : { display: "none" }
                  }
                >
                  <Table sx={{ width: "100%" }}>
                    {/* <TableHead> */}
                    <TableRow style={{ paddingBottom: "30px" }}>
                      {/* {tableTitle.map((item, index) => {
                                                    return (<><TableCell sx={{ border: 'none', padding: '20px 0 0 0', color: '#fff' }}>{item}</TableCell></>)
                                                })} */}
                      <TableCell
                        align="left"
                        sx={{
                          fontSize: "15px",
                          border: "none",
                          padding: "20px 0 0 0",
                          color: "#fff",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          fontSize: "15px",
                          border: "none",
                          padding: "20px 0 0 0",
                          color: "#fff",
                        }}
                      >
                        Date
                      </TableCell>
                    </TableRow>
                    {/* </TableHead> */}
                    {/* <TableBody> */}
                    {/* {chartData?.data?.slice(0, 2).map((row, index) => (
                                                <TableRow>
                                                    {tableTitle.map((item, i) => {

                                                        return (<><TableCell align="right" sx={{ border: 'none', color: '#796473', fontSize: '12px', padding: '0px' }}>{row[item]}</TableCell></>)
                                                        // <TableRow
                                                        //     key={row[index]?.name}
                                                        //     sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        // >
                                                        //     <TableCell component="th" scope="row" sx={{ border: 'none', color: '#796473', fontSize: '12px', padding: '0px' }}>{row[index]?.title}</TableCell>

                                                        // </TableRow>
                                                    })}
                                                </TableRow>
                                            ))} */}
                    {showTableData.map((item, index) => {
                      console.log("hjkl", item);
                      let date = new Date(item?.Date);
                      let year = date.getFullYear();
                      let month = date.getMonth() + 1;
                      let dt = date.getDate();
                      let day = year + "-" + month + "-" + dt;
                      return (
                        <>
                          {/* sx={{ '&:last-child td, &:last-child th': { border: 0 } }} */}
                          <TableRow
                            sx={{
                              display: "flex",
                              height: "10px",
                              alignItems: "center",
                              width: "130%",
                            }}
                          >
                            <TableCell
                              align="left"
                              sx={{
                                lineBreak: "anywhere",
                                border: "none",
                                color: "#796473",
                                fontSize: "13px",
                                padding: "0px",
                                width: "100%",
                              }}
                            >
                              {/* {item?.Name} */}
                              {item?.Name?.length <= 20
                                ? item?.Name
                                : item?.Name?.slice(0, 20).concat("...")}
                            </TableCell>
                            <TableCell
                              align="right"
                              sx={{
                                lineBreak: "anywhere",
                                border: "none",
                                color: "#796473",
                                fontSize: "13px",
                                width: "100%",
                              }}
                            >
                              {item?.Date ? day : ""}
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    })}
                    {/* </TableBody> */}
                    {/* <Typography style={{ fontSize: '12px', color: '#fff', color: '#fff', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', width: '100%' }}>View in Details <img style={{ width: '13px', height: '13px' }} src={searchicon} /></Typography> */}
                  </Table>
                </TableContainer>
                <Typography
                  style={
                    showTable
                      ? {
                          marginTop: "-20px",
                          fontSize: "12px",
                          color: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          textAlign: "center",
                          cursor: "pointer",
                        }
                      : { display: "none" }
                  }
                  onClick={() => {
                    modelOpen();
                  }}
                >
                  View in Details{" "}
                  <img
                    style={{ width: "13px", height: "13px" }}
                    src={searchicon}
                  />
                </Typography>
                <Typography
                  style={
                    showTable
                      ? { fontSize: "50px", fontWeight: "bold" }
                      : { fontSize: "100px", fontWeight: "bold" }
                  }
                >
                  {allData?.totalservices}
                </Typography>
                {showerror ? (
                  <Typography
                    variant="h4"
                    style={{ margin: "100px 20px 100px 20px" }}
                  >
                    No Services
                    <br /> available.
                  </Typography>
                ) : (
                  <Typography
                    className="Services"
                    style={
                      showTable
                        ? { color: "rgba(255, 255, 255, 0.25)" }
                        : {
                            fontSize: "56px",
                            color: "rgba(255, 255, 255, 0.25)",
                          }
                    }
                  >
                    Services
                  </Typography>
                )}
              </Box>
            </Box>
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  position: "relative",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    position: "absolute",
                    right: "0",
                  }}
                >
                  <img
                    src={ExitfullScreen}
                    onClick={() => {
                      setShowZoomChart(false);
                    }}
                    style={{ width: "20px", height: "20px", cursor: "pointer" }}
                    alt=""
                  />
                  <img
                    src={information}
                    style={{
                      margin: "0px 20px",
                      width: "20px",
                      height: "20px",
                    }}
                    alt=""
                  />
                  {/* <img src={more_btn_icn} style={{ height: '20px' }} /> */}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default Zoom;
