export function getDashboardData() {
  return {
    type: "GET_DASHBOARD_TAB_DATA",
  };
}
export function upadateDashboardTabData(payload) {
  return {
    type: "UPADATE_DASHBOARD_TAB_DATA",
    payload,
  };
}
export function firstActivateTabData(payload) {
  return {
    type: "FIRST_ACTIVATE_TAB",
    payload,
  };
}
export function activateTabIndex(payload) {
  return {
    type: "ACTIVATE_TAB_INDEX",
    payload,
  };
}
export function activateTabData(payload) {
  return {
    type: "ACTIVATE_TAB_DATA",
    payload,
  };
}
export function supportTicket(payload) {
  return {
    type: "SUPPORT_TICKET",
    payload,
  }
};
